<template>
  <transition name="modal-fade">
    <div
      class="studyMedia"
      :class="{ 'modal-backdrop': isSelection, pageModal: isSelection, mediaModal: isSelection }"
      :id="id"
    >
      <div :class="{ baseModal: isSelection }" :role="{ dialog: isSelection }">
        <header v-if="isSelection" class="modal-header">
          {{headerText}}
          <i class="fa fa-window-close fa-lg closeButton" id="buttonCloseModal" @click="closeModal"></i>
        </header>
        <section :class="{ 'modal-body': isSelection }" class="smallerComp">
          <section v-if="!loading" class="wrapper media">
            <form @submit.prevent="submitFile">
              <input
                type="file"
                :id="'inputStudyMediaFile' + id"
                ref="fileRef"
                multiple
                :accept="acceptedFiles"
                :disabled="isUpdated"
                @change="handleFileUpload"
              />
              <button type="submit" :id="'buttonUploadStudyMedia' + id" :disabled="isUpdated">
                {{ 'elementsMediaTranslation.upload' | translate }}
              </button>

              <VTooltip class="far" theme="info-tooltip">
                <i class="far fa-question-circle"> </i>
                <template #popper>
                  <ol>
                    <li>{{ $t('elementsMediaTranslation.recommendedMaxSize') }}</li>
                    <li>{{ $t('elementsMediaTranslation.supportedFileTypes') }}</li>
                    <li>{{ $t('elementsMediaTranslation.videoMobileRecommendation') }}:
                      <ul class="ulTooltip">
                        <li>{{ $t('elementsMediaTranslation.videoMobileSpec') }}</li>
                        <li>{{ $t('elementsMediaTranslation.videoMobileRes1080') }}</li>
                        <li>{{ $t('elementsMediaTranslation.videoMobileRes480') }}</li>
                        <li>{{ $t('elementsMediaTranslation.videoMobileH265') }}</li>
                      </ul>
                    </li>
                  </ol>
                </template>
              </VTooltip>
            </form>
          </section>

          <BaseSearch
            v-show="!loading && (studyMediaListData.list.length > 0 || noSearchResults)"
            :type="typeForSearch"
            @get-new-list="getNewList"
            @reset-page="resetPage"
          ></BaseSearch>

          <section v-if="!loading" class="wrapper selectPagesButtons">
            <section class="col sec33">
              <button
                type="button"
                v-if="!isSelection && isOwner && studyMediaListData.list.length > 0"
                id="buttonSelectStudyMedia"
                class="buttonForm"
                :disabled="isUpdated"
                @click="selectMedia"
              >
                {{ selectMediaButtonText }}
              </button>

              <button
                type="button"
                v-show="!isSelection && isOwner && selectMediaBool && selectedFileId.length > 0"
                id="buttonDeleteStudyMedia"
                class="buttonForm"
                :disabled="isUpdated"
                @click="showDeleteMediaWarning"
              >
                {{ "elementsMediaTranslation.deleteMedia" | translate }}
              </button>
            </section>

            <section class="col sec33">
              <select
                v-if="studyMediaListData.allPages > 1"
                :value="pageSelect"
                :id="'inputSelectPageTop' + id"
                class="selectPages selectPagesMedia"
                @change="getStudyUploads($event.target.value, false)"
              >
                <option value="" :id="'inputSelectPageTop' + id + 'None'" disabled>
                  {{ "lessonTranslation.selectPage" | translate }}
                </option>
                <option
                  v-for="pagenumber in studyMediaListData.allPages"
                  :value="pagenumber"
                  :id="'inputSelectPageTop' + id + pagenumber"
                  :key="pagenumber"
                >
                  {{ "lessonTranslation.page" | translate }} {{pagenumber}}
                </option>
              </select>
            </section>

            <section class="col sec33">
              <BasePagination
                :id="'paginationMediaTop' + id"
                :numberOfPages="studyMediaListData.allPages"
                :currentPage="studyMediaListData.currentPage"
                @new-page="nextPageMedia"
              ></BasePagination>
            </section>
          </section>

          <section v-if="!loading">
            <section class="mediaSelection">
              <section v-if="studyMediaListData.list.length === 0" class="noAvailableElements" :id="'noMedia' + id">
                {{noMedia}}
                <i
                  class="far fa-question-circle"
                  v-if="!noSearchResults"
                  v-tooltip="{ content: $t('elementsMediaTranslation.noMediaTooltip'), theme: 'info-tooltip' }"
                ></i>
              </section>
              <div v-for="row in rowCount" :key="row" class="mediaRow">
                <span
                  v-for="file in itemCountInRow(row)"
                  :id="'entryFile' + id + file.id"
                  :key="file.id" class="col mediaColumn"
                  :class="{
                    selectedMediaElement: (isSelection && !multiple && selectedFileId === file.id) || ((selectMediaBool || multiple) && selectedFileId.includes(file.id)),
                    mediaColumnSelect: isSelection || selectMediaBool
                  }"
                  @click="setSelectedFile(file)"
                >
                  <section class="favorite">
                    <i
                      class="fa-star fa-lg"
                      :id="'buttonSetFavorite' + id + file.id"
                      :class="{ fas: file.attributes.favorite, far: !file.attributes.favorite }"
                      @click="setFavorite(file)"
                    ></i>
                  </section>
                  <section class="mediaFile">
                    <a
                      v-if="file.attributes.mimetype.startsWith('image')"
                      v-bind="linkAttributes(file.attributes.url)"
                      :id="'media' + id + 'Image'"
                    >
                      <img :src="getUrl(file.attributes.url)"/>
                    </a>
                    <video
                      v-else-if="file.attributes.mimetype.startsWith('video')"
                      controls
                      :src="getUrl(file.attributes.url)"
                      :id="'media' + id + 'Video'"
                    ></video>
                    <audio
                      v-else-if="file.attributes.mimetype.startsWith('audio')"
                      controls
                      :src="getUrl(file.attributes.url)"
                      :id="'media' + id + 'Audio'"
                    ></audio>
                    <a
                      v-if="hasTypeFile(file.attributes.mimetype)"
                      v-bind="linkAttributes(file.attributes.url)"
                      class="fas fa-5x"
                      :class="fileClass(file.attributes.mimetype)"
                      :id="'media' + id + 'File' + fileClass(file.attributes.mimetype)"
                    ></a>
                    <a
                      v-else-if="file.attributes.mimetype === 'text/vtt'"
                      v-bind="linkAttributes(file.attributes.url)"
                      class="fa fa-5x fa-closed-captioning"
                      :id="'media' + id + 'WebVTT'"
                    ></a>
                  </section>


                  <section class="mediaFileName" v-tooltip="{ content: file.attributes.original_name, placement: 'bottom' }">
                    <input
                      type="text"
                      v-model="file.attributes.original_name"
                      :id="'inputFileName' + id + file.id"
                      :disabled="editId != file.id"
                      :ref="'fileRefId' + file.id"
                    >
                    <i
                      class="fas fa-pen"
                      v-if="!isSelection && !selectMediaBool"
                      :id="'buttonEditFileName' + id + file.id"
                      @click="editName(file.id)"
                    ></i>
                  </section>
                </span>
              </div>
            </section>

            <section class="wrapper">
              <BasePagination
                :id="'paginationMediaBottom' + id"
                :numberOfPages="studyMediaListData.allPages"
                :currentPage="studyMediaListData.currentPage"
                @new-page="nextPageMedia"
                :class="{paginationBottom: isSelection }"
              ></BasePagination>
            </section>

            <button
              type="button"
              v-if="!isSelection && studyMediaListData.list.length > 0"
              id="buttonSaveStudyMedia"
              :disabled="isUpdated"
              @click="saveChanges"
            >
              {{ 'generalTranslation.save' | translate }}
            </button>
          </section>

          <i class="fa fa-spinner fa-spin fa-2x loadData" v-else></i>
        </section>
        <footer v-if="isSelection" class="modal-footer">
          <button type="button" id="buttonConfirmModal" :disabled="isUpdated || (selectedFileId === '')" @click="saveSelectedMedia">
            {{ 'generalTranslation.choose' | translate }}
          </button>
          <button type="button" id="buttonCancelModal" :disabled="isUpdated" @click="closeModal">
            {{ 'generalTranslation.abort' | translate }}
          </button>
        </footer>
      </div>

      <BaseModal
        v-if="deleteMediaVisible"
        id="deleteMediaModal"
        :headerText="$t('elementsMediaTranslation.deleteWarning')"
        :bodyText="$t('myStudiesTranslation.warning') + ' ' + $t('elementsMediaTranslation.deleteWarningText')"
        :leftButtonText="$t('generalTranslation.delete')"
        :disableButtons="isUpdated"
        @close-modal="closeModalDelete"
      ></BaseModal>
    </div>
  </transition>
</template>

<script>

/*
study media files are returned as only filename in url

used in:
studydetails -> picture (only filename)
interventiondetails -> picture (only filename)
lessondetails -> page_color (only filename)
diarydetails -> picture (only filename)
diarydetails -> page_color (only filename)
elementsMedia -> translations -> uri (full path)
elementsPage -> color (full path)
elementsText -> text -> filter for img tag (full path)
*/

import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import BasePagination from "./BasePagination.vue";
import BaseSearch from "./BaseSearch.vue";
import BaseModal from "./BaseModal.vue";
import httpHelper from "../mixins/httpHelper";
import elementsHelper from "../mixins/elementsHelper";

const FILE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/gif",
  "image/svg+xml",
  "audio/x-wav",
  "audio/x-m4a",
  "audio/mpeg",
  "audio/mpga",
  "audio/mp4",
  "video/mp4",
  "application/pdf",
  ".doc",
  ".docx",
  ".odt",
  ".ppt",
  ".pptx",
  ".vtt",
].join(",");

export default {
  name: "StudyMedia",

  components: {
    BasePagination,
    BaseSearch,
    BaseModal
  },

  mixins: [httpHelper, elementsHelper],

  props: {
    id: {
      required: true,
      type: Boolean,
    },

    allFiles: {
      required: true,
      type: Boolean,
    },

    subtitlesOnly: {
      required: false,
      type: Boolean,
    },

    isSelection: {
      required: true,
      type: Boolean,
    },

    studyId: {
      required: true,
      type: Number,
    },

    multiple: {
      required: true,
      type: Boolean,
    },
  },

  data: function() {
    return {
      isUpdated: false,
      studyMediaListData: {},
      pageSelect: 1,
      selectedFileId: "",
      selectedFileAttributes: "",
      itemsPerRow: 5,
      filterTerm: "",
      selectMediaBool: false,
      deleteMediaVisible: false,
      editId: -1,
      newAttributes: {},
      uploadedFiles: "",
      loading: true,
      deletedFiles: [],
    };
  },

  computed: {
    ...mapGetters([
      "getNotificationText",
      "getMyRoleForStudy",
    ]),

    isOwner: function() {
      return this.getMyRoleForStudy === "Owner";
    },

    acceptedFiles: function() {
      return !this.subtitlesOnly ? FILE_TYPES : ".vtt";
    },

    headerText: function() {
      if (this.allFiles) {
        return Vue.i18n.translate("elementsMediaTranslation.selectMedia");
      } else if (this.subtitlesOnly) {
        return Vue.i18n.translate("elementsMediaTranslation.selectSubtitles");
      } else {
        return Vue.i18n.translate("elementsMediaTranslation.selectPicture");
      }
    },

    rowCount: function() {
      return this.studyMediaListData.list ? Math.ceil(this.studyMediaListData.list.length / this.itemsPerRow) : 0;
    },

    selectMediaButtonText: function() {
      if (!this.selectMediaBool) {
        return Vue.i18n.translate("elementsMediaTranslation.selectMediaDelete");
      } else {
        return Vue.i18n.translate("elementsMediaTranslation.selectMediaAbort");
      }
    },

    typeForSearch: function() {
      if (this.allFiles && this.isSelection) {
        return "study-media-all-select";
      } else if (this.allFiles && !this.isSelection) {
        return "study-media-all";
      } else {
        return "study-media";
      }
    },

    noSearchResults: function() {
      return this.studyMediaListData.list.length === 0 && this.filterTerm != "";
    },

    noMedia: function() {
      if (this.filterTerm === "") {
        return Vue.i18n.translate("elementsMediaTranslation.noMedia");
      } else {
        return Vue.i18n.translate("generalTranslation.noResults");
      }
    },
  },

  created() {
    if (this.typeForSearch === "study-media-all-select") {
      document.body.style.overflow = "hidden";
      //this.filterTerm = "&favorite=1";
    }
    this.getStudyUploads(1, false);
  },

  beforeDestroy() {
    if (this.isSelection) {
      document.body.style.overflow = "visible";
    }
  },

  watch:{
    getNotificationText(newVal) {
      if (newVal !== "" && newVal.type === "error") {
        this.isUpdated = false;
        this.loading = false;
      }
    },
  },
  methods: {
    ...mapMutations([
      "SET_NOTIFICATIONTEXT",
    ]),

    getUrl: function(filename) {
      return this.$urlUploads + "/studies/" + this.studyId + "/" + filename;
    },

    linkAttributes: function(url) {
      if (!this.selectMediaBool && !this.isSelection) {
        return {
          href: this.getUrl(url),
          target: "_blank",
          rel: "noopener noreferrer",
        };
      } else {
        return {};
      }
    },

    fileClass: function(mimetype) {
      if (this.hasTypeWord(mimetype)) {
        return "fa-file-word";
      } else if (this.hasTypePPT(mimetype)) {
        return "fa-file-powerpoint";
      } else {
        return "fa-file-pdf";
      }
    },

    itemCountInRow:function(row) {
      return this.studyMediaListData.list.slice((row - 1) * this.itemsPerRow, row * this.itemsPerRow);
    },

    nextPageMedia: function(selectedNumber) {
      if (selectedNumber !== "") {
        this.getStudyUploads(selectedNumber, false);
      }
    },

    getNewList: function(filterTerm) {
      if (!this.isUpdated) {
        this.filterTerm = filterTerm;
        this.getStudyUploads(1, false);
      }
    },

    resetPage: function() {
      if (!this.isUpdated) {
        this.studyMediaListData.currentPage = 1;
        this.pageSelect = 1;
      }
    },

    setSelectedFile: function(file) {
      if (this.isSelection && !this.multiple) {
        this.selectedFileId = file.id;
        this.selectedFileAttributes = file.attributes;
      } else if ((this.isSelection && this.multiple) || this.selectMediaBool) {
        if (this.selectedFileId !== "") {
          const id = this.selectedFileId.findIndex(elem => elem === file.id);
          if (id !== -1) {
            this.selectedFileId.splice(id, 1);
            this.selectedFileAttributes.splice(id, 1);
          } else {
            this.selectedFileId.push(file.id);
            this.selectedFileAttributes.push(file.attributes);
          }
        } else {
          this.selectedFileId = [file.id];
          this.selectedFileAttributes = [file.attributes];
        }
      }
    },

    setFavorite: function(file) {
      if (!this.isSelection && !this.selectMediaBool) {
        file.attributes.favorite = !file.attributes.favorite;
        this.$emit("set-changed", { name: "media", value: true });
      }
    },

    editName: function(fileId) {
      if (!this.selectMediaBool) {
        this.editId = fileId;
        Vue.nextTick(() => {
          this.$refs["fileRefId" + fileId][0].focus();
        });
        this.$emit("set-changed", { name: "media", value: true });
      }
    },

    saveSelectedMedia: function() {
      if (this.selectedFileId !== "") {
        this.isUpdated = true;
        if (!this.multiple) {
          this.$emit(
            "item-selected",
            {
              link: this.getUrl(this.selectedFileAttributes.url),
              filename: this.selectedFileAttributes.original_name,
              mimetype: this.selectedFileAttributes.mimetype,
            },
          );
        } else {
          const files = [];
          for (const f in this.selectedFileAttributes) {
            const file = this.selectedFileAttributes[f];
            files.push({
              link: this.getUrl(file.url),
              filename: file.original_name,
              mimetype: file.mimetype,
            });
          }
          this.$emit("item-selected", files);
        }
      } else {
        this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("elementsMediaTranslation.errorSelectMedia") });
      }
    },

    getStudyUploads: function(page, showLastPage){
      this.loading = true;
      if (this.studyMediaListData.list && this.studyMediaListData.list.length > 0) {
        for (const f in this.studyMediaListData.list) {
          const file = this.studyMediaListData.list[f];
          if (!this.deletedFiles.includes(file.id)) {
            this.newAttributes[file.id] = {
              original_name: file.attributes.original_name,
              favorite: file.attributes.favorite,
            };
          }
        }
        this.deletedFiles = [];
      }
      let filterTerm = this.filterTerm;
      if (!this.allFiles) {
        filterTerm += this.subtitlesOnly ? "&mimetype=text/vtt" : "&mimetype=image/*";
      }
      this.requestStudyUploads(this.studyId, page, filterTerm, this.studyMediaListData.allPages, showLastPage, this.newAttributes)
        .then((response) => {
          this.studyMediaListData = response;
          this.pageSelect = this.studyMediaListData.currentPage;
          this.loading = false;
          this.isUpdated = false;
          this.$emit("set-changed", { name: "media", value: false });
        })
        .catch((error) => {
          this.handleErrors(error, () => { this.getStudyUploads(page, showLastPage) }, "");
        });
    },

    selectMedia: function() {
      this.selectedFileId = [];
      this.selectedFileAttributes = [];
      this.selectMediaBool = !this.selectMediaBool;
    },

    showDeleteMediaWarning: function() {
      this.deleteMediaVisible = true;
    },

    closeModalDelete: function(done) {
      if (done) {
        this.isUpdated = true;
        this.deleteMediaVisible = false;
        this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("elementsMediaTranslation.deleteMediaLoad") });
        const json = {
          data: {
            type: "studies",
            attributes: {
              ids: this.selectedFileId,
            },
          },
        };
        this.deleteStudyMediaRequest(json, this.studyId)
          .then(() => {
            this.isUpdated = false;
            this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("elementsMediaTranslation.deleteMediaSuccess") });
            this.deletedFiles = JSON.parse(JSON.stringify(this.selectedFileId));
            this.selectedFileId = [];
            this.selectMediaBool = false;
            this.getStudyUploads(this.studyMediaListData.currentPage, false);
          })
          .catch((error) => {
            this.handleErrors(error, () => { this.closeModalDelete(done) }, "");
          });
      } else {
        this.deleteMediaVisible = false;
      }
    },

    saveChanges: function() {
      this.isUpdated = true;
      this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("elementsMediaTranslation.updateMediaAttributesLoad") });
      for (const f in this.studyMediaListData.list) {
        const file = this.studyMediaListData.list[f];
        this.newAttributes[file.id] = {
          original_name: file.attributes.original_name,
          favorite: file.attributes.favorite,
        };
      }
      const attributes = [];
      for (const fileId in this.newAttributes) {
        const attribute = this.newAttributes[fileId];
        if (attribute != undefined) {
          attributes.push({
            id: fileId,
            original_name: attribute.original_name,
            favorite: attribute.favorite,
          });
        }
      }
      const json = {
        data: {
          type: "studies",
          attributes: {
            data: attributes,
          },
        },
      };
      this.updateStudyMediaAttributesRequest(json, this.studyId)
        .then(() => {
          this.isUpdated = false;
          this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("elementsMediaTranslation.updateMediaAttributesSuccess") });
          this.newAttributes = {};
          this.getStudyUploads(this.studyMediaListData.currentPage, false);
        })
        .catch((error) => {
          this.handleErrors(error, () => { this.saveChanges() }, "");
        });
    },

    handleFileUpload: function() {
      this.uploadedFiles = this.$refs.fileRef.files;
      this.SET_NOTIFICATIONTEXT("");
    },

    submitFile: function() {
      if (this.uploadedFiles === "" || this.uploadedFiles.length === 0) {
        this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("elementsMediaTranslation.errorSelectMedia") });
      } else {
        let fileTooLarge = false;
        //check file size
        for (const f in this.uploadedFiles) {
          const file = this.uploadedFiles[f];
          if (typeof file !== "undefined" && (file.size / 1000000) > 150) {
            fileTooLarge = true;
            break;
          }
        }
        if (fileTooLarge) {
          this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("elementsMediaTranslation.errorTooLarge") });
        } else {
          this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("elementsMediaTranslation.uploadStudyMediaLoad") });
          this.isUpdated = true;
          const formData = new FormData();
          for (const file in this.uploadedFiles) {
            formData.append("files[]", this.uploadedFiles[file]);
          }
          this.submitStudyMediaRequest(formData, this.studyId)
            .then(() => {
              this.getStudyUploads(this.studyMediaListData.allPages, true);
              this.$refs.fileRef.value = "";
              this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("elementsMediaTranslation.uploadStudyMediaSuccess") });
            })
            .catch((error) => {
              this.handleErrors(error, () => { this.submitFile() }, "submitFile");
            });
        }
      }
    },

    closeModal() {
      if (!this.isUpdated) {
        this.$emit("close-modal");
      }
    },
  }
}
</script>
