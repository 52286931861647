import Vue from "vue";
import { mapGetters } from "vuex";
import utils from "../mixins/utils";

export default {
  mixins: [utils],

  computed: {
    ...mapGetters(["getQuestionLabelList"]),
  },

  // it is now enough to fill in only one language, all translation fields of the language have to be filled in though (backend does
  // not accept translations with empty fields) (media subtitle & description are optional)
  // there can not be incomplete translation entries
  // for the old version where all languages have to be filled in see file elementsCompleteHelperAll
  // if translations are not filled in, they are removed in elementPropsToJSONHelper because backend does
  // not accept translations with empty fields
  methods: {
    //save true -> for save method of lesson elements editor -> check all
    //save false -> for condition editor -> only check question elements
    checkIfElementsComplete: function(elements, save, locales) {
      let newErrorTextArr = [];
      let elementsComplete = true;
      let currentPage = 0;
      const pagesWithErrors = [];
      for (const elem in elements) {
        const element = elements[elem];
        let errorText = "";
        switch (element.type) {
          case "ElementsHeadline":
            if (save) {
              errorText = this.checkIfHeadlineFieldsComplete(element, locales);
            }
            break;
          case "ElementsQuestion":
            errorText = this.checkIfQuestionFieldsComplete(element, locales);
            break;
          case "ElementsText":
            if (save) {
              errorText = this.checkIfTextFieldsComplete(element, locales);
            }
            break;
          case "ElementsMedia":
            if (save) {
              errorText = this.checkIfMediaFieldsComplete(element, locales);
            }
            break;
          case "ElementsBlock":
            if (save) {
              errorText = this.checkIfBlockFieldsComplete(element, locales);
            }
            break;
          case "ElementsSpace":
            if (save) {
              errorText = this.checkIfSpaceFieldsComplete(element);
            }
            break;
          case "ElementsPage":
            currentPage++;
            if (save) {
              errorText = this.checkIfPageFieldsComplete(element);
            }
            break;
          default:
            errorText = "";
        }

        if (element.type === "ElementsBlock") {
          //check children of block
          const resultBlock = this.checkIfElementsComplete(element.propsObj.elements, save, locales);
          if (errorText !== "" || !resultBlock.elementsComplete) {
            elementsComplete = false;
            if (!pagesWithErrors.includes(currentPage)) {
              pagesWithErrors.push(currentPage);
            }
          }
          newErrorTextArr[element.propsObj.id] = errorText;
          newErrorTextArr = Object.assign(newErrorTextArr, resultBlock.errorTextsForElements);
        } else {
          if (errorText !== "") {
            elementsComplete = false;
            if (!pagesWithErrors.includes(currentPage)) {
              pagesWithErrors.push(currentPage);
            }
          }
          newErrorTextArr[element.propsObj.id] = errorText;
        }
      }

      // TODO: check if two page elements after one another
      return {
        errorTextsForElements: newErrorTextArr,
        elementsComplete: elementsComplete,
        pagesWithErrors: pagesWithErrors,
      };
    },

    // errortext with languages
    //   var emptyTranslations = [];
    //   for(var translation in element.translations){
    //     if(element.translations[translation] === ""){
    //       emptyTranslations.push(translation);
    //     }else{
    //       allTranslationsEmpty = false;
    //     }
    //   }
    //   if(allTranslationsEmpty){
    //     errorText = Vue.i18n.translate('elementsTextTranslation.errorFillInOne') + Vue.i18n.translate('elementsHeadlineTranslation.headline') +
    //       " (" + emptyTranslations + ")";
    //   }

    checkIfHeadlineFieldsComplete: function(element, locales) {
      let errorText = "";
      const allTranslationsEmpty = locales.every(l => !element.translations[l]);
      if (allTranslationsEmpty) {
        errorText = Vue.i18n.translate("elementsTextTranslation.errorFillIn")
          + Vue.i18n.translate("elementsHeadlineTranslation.headline")
          + " "
          + Vue.i18n.translate("elementsTextTranslation.errorOneTranslation");
      }
      return errorText;
    },

    checkIfQuestionFieldsComplete: function(element, locales) {
      if (element.propsObj.selectedQuestionProp === "") {
        return Vue.i18n.translate("elementsTextTranslation.errorFillIn") + Vue.i18n.translate("elementsQuestionTranslation.questionType");
      }
      switch (element.propsObj.selectedQuestionProp) {
        case "SingleChoice":
        case "MultipleChoice":
          return this.checkIfQuestionSingleMultipleComplete(element, locales);
        case "Slider":
          return this.checkIfQuestionSliderComplete(element, locales);
        case "QuestionTable":
          return this.checkIfQuestionTableComplete(element, locales);
        default:
          return this.checkIfQuestionSimpleComplete(element, locales);
      }
    },

    checkIfQuestionSimpleComplete: function(element, locales){
      let errorText = "";
      if (element.propsObj.labelProp === "") {
        errorText = Vue.i18n.translate("elementsTextTranslation.errorFillIn") + Vue.i18n.translate("elementsQuestionTranslation.questionLabel");
      }
      const allTranslationsEmpty = locales.every(l => !element.translations[l]?.question);
      if (allTranslationsEmpty) {
        errorText += (errorText != "" ? ", " : Vue.i18n.translate("elementsTextTranslation.errorFillIn")) + Vue.i18n.translate("elementsQuestionTranslation.question") + " " + Vue.i18n.translate("elementsTextTranslation.errorOneTranslation");
      }
      return errorText;
    },

    checkIfQuestionSingleMultipleComplete: function(element, locales){
      const errorsArr = [];
      if (element.propsObj.labelProp === "") {
        errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.questionLabel"));
      }
      let errorSingleMultipleText = "";
      if (element.propsObj.values.length < 2) {
        errorSingleMultipleText = Vue.i18n.translate("elementsQuestionTranslation.singleMultipleChoiceError");
      }

      const valuesArr = [];
      for (const value in element.propsObj.values) {
        if (element.propsObj.values[value] === "") {
          errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.errorAnswerValueEmpty") + " " + Number(Number(value) + 1));
        }
        if (valuesArr.includes(element.propsObj.values[value])) {
          errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.errorDuplicateAnswerLabel"));
        }
        valuesArr.push(element.propsObj.values[value]);
      }

      let allTranslationsEmpty = true;
      const errorSingleMultipleArr = [];
      for (const locale of locales) {
        const translation = element.translations[locale];
        if (translation !== undefined) {
          if (translation.question != "") {
            allTranslationsEmpty = false;
          }
          if (element.propsObj.values.length !== translation.answers.length) {
            errorSingleMultipleArr.push(locale);
          }
        }
        // answers only checked on length, can be empty string though
        // todo maybe complete translation for one language
        // for(answer in element.translations[translation].answers){
        //   if(element.translations[translation].answers[answer] === ""){
        //     errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.answerOption') +  " " + Number(Number(answer) + 1) + " (" + translation + ")");
        //   }
        // }
      }

      if (allTranslationsEmpty) {
        errorsArr.push(
          Vue.i18n.translate("elementsQuestionTranslation.question") + " " + Vue.i18n.translate("elementsTextTranslation.errorOneTranslation")
        );
      }

      let errorText = "";
      if (errorsArr.length > 0) {
        errorText = Vue.i18n.translate("elementsTextTranslation.errorFillIn");
        for (const err in errorsArr) {
          errorText += (err > 0 ? ", " : "") + errorsArr[err];
        }
      }

      if (errorSingleMultipleArr.length > 0) {
        errorText += (errorText != "" ? ". " : "") + Vue.i18n.translate("elementsQuestionTranslation.errorSingleMultiple") + " (";
        for (const err in errorSingleMultipleArr) {
          errorText += (err > 0 ? ", " : "") + errorSingleMultipleArr[err];
        }
        errorText += ")";
      }

      if (errorSingleMultipleText != "") {
        errorText += (errorText != "" ? ". " : "") + errorSingleMultipleText;
      }

      return errorText;
    },

    checkIfQuestionSliderComplete: function(element, locales) {
      const errorsArr = [];

      if (element.propsObj.labelProp === "") {
        errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.questionLabel"));
      }

      let errorSlider = false;
      if (Number.isNaN(element.propsObj.values.min) || element.propsObj.values.min === "") {
        errorSlider = true;
        errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.valueMin"));
      }
      if (Number.isNaN(element.propsObj.values.max) || element.propsObj.values.max === "") {
        errorSlider = true;
        errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.valueMax"));
      }
      if (Number.isNaN(element.propsObj.values.step) || element.propsObj.values.step === "") {
        errorSlider = true;
        errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.step"));
      }
      if (Number.isNaN(element.propsObj.values.start) || element.propsObj.values.start === "") {
        errorSlider = true;
        errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.startValue"));
      }
      let errorSliderText = "";
      if (!errorSlider) {
        const start = Number(element.propsObj.values.min);
        const end = Number(element.propsObj.values.max);
        const step = Number(element.propsObj.values.step);
        const startVal = Number(element.propsObj.values.start);
        if (((end - start)/step) > 101) {
          errorSliderText = Vue.i18n.translate("elementsQuestionTranslation.errorSliderTooManyValues");
        }
        if (startVal < start || startVal > end) {
          errorSliderText += (errorSliderText != "" ? " " : "") + Vue.i18n.translate("elementsQuestionTranslation.startValueNotIncluded");
        }
        if (start > end) {
          errorSliderText += (errorSliderText != "" ? " " : "") + Vue.i18n.translate("elementsQuestionTranslation.minGreaterMax");
        } else if (start === end) {
          errorSliderText += (errorSliderText != "" ? " " : "") + Vue.i18n.translate("elementsQuestionTranslation.minIsMax");
        }
      }

      const allTranslationsEmpty = locales.every(l => !element.translations[l]?.question);
      if (allTranslationsEmpty) {
        errorsArr.push(
          Vue.i18n.translate("elementsQuestionTranslation.question") + " " + Vue.i18n.translate("elementsTextTranslation.errorOneTranslation")
        );
      }

      let errorText = "";
      if (errorsArr.length > 0) {
        errorText = Vue.i18n.translate("elementsTextTranslation.errorFillIn");
        for (const err in errorsArr) {
          errorText += (err > 0 ? ", " : "") + errorsArr[err];
        }
      }
      if (errorSliderText != "") {
        errorText += (errorText != "" ? ". " : "") + errorSliderText;
      }
      return errorText;
    },

    //todo allTranslationsEmpty
    checkIfQuestionTableComplete: function(element, locales) {
      let errorSingleMultipleText = "";
      const values = element.propsObj.values;

      let checkTable = true;
      if (values.max - values.min < 1) {
        checkTable = false;
        errorSingleMultipleText = Vue.i18n.translate("elementsQuestionTranslation.tableAnswersError");
      }
      if (values.questions < 2) {
        checkTable = false;
        errorSingleMultipleText += (errorSingleMultipleText != "" ? " " : "") + Vue.i18n.translate("elementsQuestionTranslation.tableQuestionsError");
      }

      const errorsArr = [];
      if (checkTable) {
        let invalidTable = true;
        translations_loop:
        for (const locale of locales) {
          const translation = element.translations[locale];
          if (!translation) {
            continue translations_loop;
          }
          for (let answer = values.min; answer <= values.max; answer++) {
            if (!translation.answers[answer]) {
              continue translations_loop;
            }
          }
          for (let question = 0; question < values.questions; question++) {
            if (!translation.question[question]) {
              continue translations_loop;
            }
          }
          invalidTable = false;
          break translations_loop; // 1 valid translation is enough
        }
        if (invalidTable) {
          const invalidInput = Vue.i18n.translate("elementsQuestionTranslation.invalidQuestionTable");
          const lang = Vue.i18n.translate("elementsTextTranslation.errorOneTranslation");
          errorsArr.push(`${invalidInput} ${lang}`);
        }
      }

      if (element.propsObj.labelProp === "") {
        errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.questionLabel"));
      }

      let errorText = "";
      if (errorsArr.length > 0) {
        errorText = Vue.i18n.translate("elementsTextTranslation.errorFillIn");
        for (const err in errorsArr) {
          errorText += (err > 0 ? ", " : "") + errorsArr[err];
        }
      }

      if (errorSingleMultipleText != "") {
        errorText += (errorText != "" ? ". " : "") + errorSingleMultipleText;
      }

      return errorText;
    },

    checkIfTextFieldsComplete: function(element, locales) {
      let allTranslationsEmpty = true;
      const localesWithInvalidPictures = [];
      for (const locale of locales) {
        const translation = element.translations[locale];
        if (translation) {
          allTranslationsEmpty = false;
          // Closing > is not used, because e.g. style="..." can come after src="..."
          const regEx = new RegExp(/<img src=["'][:A-Za-z0-9/.\-_]*["']/, "g");
          const matches = translation.match(regEx);
          for (const m in matches) {
            const match = matches[m];
            // Start of url (e.g. "https://...") is at index 10 of regex
            if (!match.startsWith(this.$urlUploads, 10)) {
              localesWithInvalidPictures.push(locale);
              break;
            }
          }
        }
      }
      let errorText = "";
      if (allTranslationsEmpty) {
        errorText = Vue.i18n.translate("elementsTextTranslation.errorFillIn")
          + Vue.i18n.translate("elementsTextTranslation.text")
          + " "
          + Vue.i18n.translate("elementsTextTranslation.errorOneTranslation");
      } else if (localesWithInvalidPictures.length > 0) {
        errorText = Vue.i18n.translate("elementsTextTranslation.errorOtherPictures") + " (" + localesWithInvalidPictures.join(", ") + ")";
      }
      return errorText;
    },

    checkIfMediaFieldsComplete: function(element, locales) {
      let errorText = "";
      if (element.propsObj.width > 100 || element.propsObj.width < 0) {
        errorText = Vue.i18n.translate("elementsTextTranslation.errorFillIn") + Vue.i18n.translate("elementsMediaTranslation.sizeValidValue");
      }
      let allTranslationsEmpty = true;
      for (const locale of locales) {
        const translation = element.translations[locale];
        if (translation?.uri) {
          allTranslationsEmpty = false;
        } else if (translation?.subtitle || translation?.description) {
          //title/description not empty, uri empty
          errorText += (errorText === "" ? Vue.i18n.translate("elementsTextTranslation.errorFillIn") : ", ") + Vue.i18n.translate("elementsMediaTranslation.file") + " (" + locale + ")";
        }
      }
      if (allTranslationsEmpty) {
        errorText = Vue.i18n.translate("elementsTextTranslation.errorFillIn") + Vue.i18n.translate("elementsMediaTranslation.file") + " " + Vue.i18n.translate("elementsTextTranslation.errorOneTranslation");
      }
      return errorText;
    },

    checkIfSpaceFieldsComplete: function(element) {
      return !element.propsObj.size ? Vue.i18n.translate("elementsSpaceTranslation.errorSize") : "";
    },

    checkIfBlockFieldsComplete: function(element, locales) {
      let errorsArr = [];
      if (element.propsObj.type === "") {
        errorsArr.push(Vue.i18n.translate("elementsBlockTranslation.errorTypeEmpty"));
      }

      let errorTextComplex = "";
      let errorValueSecondValue = false;
      if (element.propsObj.type != "details" && element.propsObj.type != "none") {
        const result = this.checkIfConditionComplete(element, errorsArr, true);
        errorTextComplex = result.errorTextComplex;
        errorsArr = result.errorsArr;
        errorValueSecondValue = result.errorValueSecondValue;
      }

      if (!element.propsObj.thenBlock.repeat.question) {
        if (element.propsObj.thenBlock.repeat.data < 1) {
          errorsArr.push(Vue.i18n.translate("elementsBlockTranslation.errorRepetitionMin"));
        }
      } else if (
        element.propsObj.thenBlock.repeat.data === ""
          || this.getQuestionLabelList[element.propsObj.thenBlock.repeat.data] === 0
          || this.getQuestionLabelList[element.propsObj.thenBlock.repeat.data].type === "text"
      ) {
        errorsArr.push(Vue.i18n.translate("elementsBlockTranslation.errorRepetitionLabel"));
      } else if (this.getQuestionLabelList[element.propsObj.thenBlock.repeat.data].type != "slider") {
        errorsArr.push(Vue.i18n.translate("elementsBlockTranslation.errorRepetitionLabel"));
      }

      if (element.propsObj.type != "conditional" && element.propsObj.type != "none") {
        const allTranslationsEmpty = locales.every(l => !element.translations[l]);
        if (allTranslationsEmpty) {
          errorsArr.push(Vue.i18n.translate("elementsBlockTranslation.errortextDetailsEmpty") + " " + Vue.i18n.translate("elementsTextTranslation.errorOneTranslation"));
        }
      }

      let errorText = "";
      if (errorsArr.length > 0) {
        errorText = Vue.i18n.translate("elementsTextTranslation.errorFillIn");
        for (const err in errorsArr) {
          errorText += (err > 0 ? ", " : "") + errorsArr[err];
        }

        if (element.propsObj.type === "conditional" || element.propsObj.type === "both" || element.propsObj.thenBlock.repeat.question) {
          //check if questionLabelList has questions that can be used in conditions
          let questionExists = false;
          if (this.getQuestionLabelList.length > 0) {
            for (const question in this.getQuestionLabelList) {
              if (this.getQuestionLabelList[question] != 0 && this.getQuestionLabelList[question].type != "text") {
                questionExists = true;
              }
            }
            if (!questionExists) {
              errorText += ". " + Vue.i18n.translate("elementsBlockTranslation.noQuestions");
            }
          }
        }
      }
      if (errorText != "" && !errorText.endsWith(".")) {
        errorText += ".";
      }
      if (errorValueSecondValue) {
        errorText += " " + Vue.i18n.translate("elementsBlockTranslation.errorValueSecondValue");
      } else if (errorTextComplex != "") {
        errorText += " " + errorTextComplex;
      }
      return errorText;
    },

    checkIfPageFieldsComplete: function(element){
      let errorText = "";
      let errorTextComplex = "";
      let errorValueSecondValue = false;
      let errorsArr = [];
      const isConditionalPage = element.propsObj.condition != "" && element.propsObj.condition != null;

      if(isConditionalPage){
        const result = this.checkIfConditionComplete(element, errorsArr, true);
        errorTextComplex = result.errorTextComplex;
        errorsArr = result.errorsArr;
        errorValueSecondValue = result.errorValueSecondValue;
      }

      if(errorsArr.length > 0){
        errorText = Vue.i18n.translate('elementsTextTranslation.errorFillIn');
        for(const err in errorsArr){
          errorText += (err > 0 ? ", " : "") + errorsArr[err];
        }

        if(isConditionalPage){
          //check if questionLabelList has questions that can be used in conditions
          let questionExists = false;
          if(this.getQuestionLabelList.length > 0){
            for(const i in this.getQuestionLabelList){
              const question = this.getQuestionLabelList[i];
              if(question != 0 && question.type != "text"){
                questionExists = true;
              }
            }
            if(!questionExists){
              errorText += ". " + Vue.i18n.translate('elementsBlockTranslation.noQuestions');
            }
          }
        }
      }

      if(errorText != "" && !errorText.endsWith(".")){
        errorText += ".";
      }

      if(errorValueSecondValue){
        errorText += " " + Vue.i18n.translate('elementsBlockTranslation.errorValueSecondValue');
      }else if(errorTextComplex != ""){
        errorText += " " + errorTextComplex;
      }

      return errorText
    },

    checkIfConditionComplete: function(element, errorsArr, returnErrorDetails) {
      let errorTextComplex = "";
      const condition = element.propsObj.condition;
      //todo check if condition is ever fulfillable (for example slider > slider.max)
      if (element.propsObj.isComplexCondition) {
        if (condition.nodeType === "leaf") {
          //user clicked on complex condition but did not define a condition
          element.propsObj.isComplexCondition = false;
          condition.questionLabel = -1;
        } else if (!this.checkIfComplexConditionComplete(condition)) {
          errorTextComplex = Vue.i18n.translate("elementsBlockTranslation.errorComplexCondition");
        }
      }
      let errorValueSecondValue = false;
      if (!element.propsObj.isComplexCondition) {
        if (condition.questionLabel === -1 || (!condition.questionLabel && condition.questionLabel !== 0)) {
          errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.questionLabel"));
        }
        if (condition.leafType !== "question" && condition.operation === "") {
          errorsArr.push(Vue.i18n.translate("elementsBlockTranslation.errorOperationEmpty"));
        }
        if (condition.operation !== "answered" && condition.value === "") {
          errorsArr.push(Vue.i18n.translate("elementsBlockTranslation.errorValueEmpty"));
        }
        if (condition.leafType !== "question" && condition.operation === "between") {
          if (condition.secondValue === "") {
            errorsArr.push(Vue.i18n.translate("elementsBlockTranslation.errorSecondValueEmpty"));
          } else {
            if (condition.leafType === "slider" || condition.leafType === "table") {
              if (Number(condition.value) > Number(condition.secondValue)) {
                errorValueSecondValue = true;
              }
            } else if (condition.value > condition.secondValue) {
              // Compare date-strings without turning them to numbers
              errorValueSecondValue = true;
            }
          }
        }
      }
      if (returnErrorDetails) {
        return { errorTextComplex, errorsArr, errorValueSecondValue };
      } else {
        return errorsArr.length === 0 && errorTextComplex === "" && !errorValueSecondValue;
      }
    },

    checkIfComplexConditionComplete: function(condition) {
      if (condition.nodeType === "leaf") {
        if (condition.questionLabel === "" || (condition.operation !== "answered" && condition.value === "")) {
          return false;
        }
        if (condition.leafType === "date") {
          if (condition.operation === "") {
            return false;
          } else if (condition.operation === "between") {
            return condition.secondValue !== "" && condition.value <= condition.secondValue;
          }
        } else if (condition.leafType === "slider" || condition.leafType === "table") {
          if (condition.operation === "") {
            return false;
          } else if (condition.operation === "between") {
            return (
              condition.secondValue !== "" &&
              Number(condition.value) <= Number(condition.secondValue)
            );
          }
        }
        return true;
      } else if (condition.nodeType === "and" || condition.nodeType === "or") {
        const children = condition.children;
        return children.length > 1 && children.every(child => this.checkIfComplexConditionComplete(child));
      } else if (condition.nodeType === "not") {
        const children = condition.children;
        return children.length === 1 && this.checkIfComplexConditionComplete(children[0]);
      }
      return false;
    },

    //function for non-nested json
    getRepetitionsAndSlider: function(jsonElementsList) {
      const repetitionsArr = [];
      const sliderArr = [];
      const repetitionBegin = [];
      for (const e in jsonElementsList) {
        const element = jsonElementsList[e];
        if (element.elementtype === "elements/blockopens") {
          const condition = element.condition;
          const start = Number(e) + 1;
          let question = "", data = "";
          if (condition.thenBlock) {
            question = JSON.parse(JSON.stringify(condition.thenBlock.repeat.question));
            data = JSON.parse(JSON.stringify(condition.thenBlock.repeat.data));
          }
          repetitionBegin.push({ start, question, data });
        } else if (element.elementtype === "elements/blockcloses") {
          const rep = repetitionBegin.pop();
          //add all repetitions whose repetition number is depending on a slider (question === true)
          if (typeof rep !== "undefined" && rep.question != "" && rep.question) {
            rep.end = Number(e) + 1;
            repetitionsArr.push(rep);
          }
        } else if (element.elementtype === "elements/questions" && element.questiontype === "Slider") {
          //check if slider inside repetition block (repetition > 1) -> add slider to list
          for (const r in repetitionBegin) {
            const repetition = repetitionBegin[r];
            if (
              (!repetition.question && Number(repetition.data) > 1) ||
              repetition.question
            ) {
              sliderArr.push(Number(e) + 1);
              break;
            }
          }
        }
      }
      const obj = {
        repetitions: repetitionsArr,
        sliderPos: sliderArr,
      };
      return obj;
    },

    //repeated slider must not be used for repetition number of other repetition
    //or: slider used for repetition number must not be in repetition
    //error shown at repeated slider element
    checkIfRepeatedSliderUsedInRepetition: function(obj, jsonElementsList, pages, errorPages) {
      const repetitions = obj.repetitions;
      //sliderArr contains repeated slider questions -> these must not be used for repetition number
      const sliderArr = obj.sliderPos;
      const errorLabels = [];
      for (const slider in sliderArr) {
        const index = repetitions.findIndex(elem => elem.data === sliderArr[slider]);
        if (index !== -1) {
          const pos = sliderArr[slider];
          const element = jsonElementsList[pos - 1];
          errorLabels.push(element.label);
          const page = pages.get(element.position);
          if (!errorPages.includes(page)) {
            errorPages.push(page);
          }
        }
      }
      return errorLabels;
    },

    //check if questions used in conditions, references, repetitions, which shall be copied, are also copied
    checkIfCopyElementsCompleteFunction: function(selectedElements, elements, addElementsIdCounter) {
      let questions = this.checkIfCopyElementsComplete(selectedElements, []);
      const missingQuestions = [];
      const flattedSelectedElements = this.flattenJSON(JSON.parse(JSON.stringify(selectedElements)), addElementsIdCounter + 1, 1).elements;
      while (questions.length > 0) {
        const qu = questions.pop();
        if (flattedSelectedElements.every(element => !element || element.propsObj.id !== qu)) {
          missingQuestions.push(qu);
        }
        questions = questions.filter(quest => quest != qu);
      }
      let errText = "";
      //questions used in conditions, references, repetitions are missing
      if (missingQuestions.length !== 0) {
        errText += Vue.i18n.translate("lessonTranslation.copyElementsQuestionsMissing") + " ";
        for (const missingQuest in missingQuestions) {
          errText += (missingQuest > 0) ? ", " : "";
          const element = elements.find(element => element.propsObj.id === missingQuestions[missingQuest]);
          if (element !== undefined) {
            errText += element.propsObj.labelProp;
          }
        }
      }
      return errText;
    },

    checkIfCopyElementsComplete: function(selectedElements, questions) {
      for (const elem in selectedElements) {
        const element = selectedElements[elem];
        switch (element.type) {
          case "ElementsBlock":
            //condition
            if (element.propsObj.type === "conditional" || element.propsObj.type === "both") {
              questions = questions.concat(this.getIncludedQuestionsInCondition(element.propsObj.condition));
            }
            //repetition
            if (element.propsObj.thenBlock && element.propsObj.thenBlock.repeat.question) {
              questions.push(element.propsObj.thenBlock.repeat.data);
            }
            //children
            questions = questions.concat(this.checkIfCopyElementsComplete(element.propsObj.elements, questions));
            break;
          case "ElementsPage":
            //condition
            if (element.propsObj.condition) {
              questions = questions.concat(this.getIncludedQuestionsInCondition(element.propsObj.condition));
            }
            break;
          case "ElementsHeadline":
          case "ElementsText":
          case "ElementsMedia":
          case "ElementsQuestion": {
            //references
            for (const t in element.translations) {
              const translation = element.translations[t];
              let referenceString;
              switch(element.type){
                case "ElementsHeadline":
                case "ElementsText":
                  referenceString = translation;
                  break;
                case "ElementsMedia":
                  referenceString = translation.subtitle + translation.description;
                  break;
                case "ElementsQuestion":
                  if (element.propsObj.selectedQuestionProp !== "QuestionTable") {
                    referenceString = translation.question;
                  } else {
                    referenceString = JSON.parse(JSON.stringify(translation.question)).join("");
                  }
                  if (
                    element.propsObj.selectedQuestionProp === "SingleChoice" ||
                    element.propsObj.selectedQuestionProp === "MultipleChoice" ||
                    element.propsObj.selectedQuestionProp === "QuestionTable"
                  ) {
                    referenceString += JSON.parse(JSON.stringify(translation.answers)).join("");
                  }
                  break;
                default:
                  referenceString = "";
                  break;
              }
              const references = referenceString.match(/\{{2}([^}{]+)}{2}/g);
              if (references != null) {
                const q = references.map(x => Number(x.replace(/{/g, "").replace(/}/g, "")));
                questions = questions.concat(q);
              }
            }
            break;
          }
          default:
            break;
        }
      }
      return questions;
    },

    getIncludedQuestionsInCondition: function(condition) {
      if (condition && condition.nodeType !== "leaf") {
        let includedQuestions = [];
        for (const child in condition.children) {
          const questions = this.getIncludedQuestionsInCondition(condition.children[child]);
          includedQuestions = includedQuestions.concat(questions);
        }
        return includedQuestions;
      } else {
        return [Number(condition.questionLabel)];
      }
    },
  }
};
