<template>
  <div class="lessonEditor">
    <section v-show="!loadingPage">
      <BaseMenuBar
        :nameList="[studyDetails.name, interventionDetails.name, lessonDetails.name]"
        :idList="[studyId, interventionId]"
      ></BaseMenuBar>

      <BaseTabs
        :tabDetails="tabDetails"
        :selectedView="selectedView"
        @show-view="showView"
      ></BaseTabs>

      <BaseSelectLanguage
        v-if="selectedView != 2 && lessonDetails.locales"
        :localesArray="lessonDetails.locales"
        :selectedLanguage2="selectedLanguageForTranslation"
        :isTranslationMode="isTranslationMode"
        @close-notification="closeNotification"
        @change-language2="changeLanguage2"
        @change-translationmode="changeTranslationMode"
      ></BaseSelectLanguage>

      <section v-show="selectedView === 0" :id="lessonDetailsFormId" class="wrapper">
        <button type="submit" disabled style="display: none"></button>
        <section v-show="!loadingLanguage">
          <!-- normal language (left) -->
          <section :class="{col: isTranslationMode, sec50:isTranslationMode}">
            <LessonEditorDetails
              :lessonDetails="lessonDetails"
              :translation="currentTranslation"
              :isTranslation="false"
              :isTranslationMode="isTranslationMode"
              :selectedLanguageForTranslation="selectedLanguageForTranslation"
              :studyId="studyId"
              @close-notification="closeNotification"
              @change-attribute="changeAttributeForDetails"
              @show-modal="showModal"
            ></LessonEditorDetails>
          </section>

          <!-- translation language (right) -->
          <section :class="{col: isTranslationMode, sec50:isTranslationMode}">
            <section v-if="isTranslationMode && getIsEditable">
              <LessonEditorDetails
                :lessonDetails="lessonDetails"
                :translation="currentTranslation2"
                :isTranslation="true"
                :isTranslationMode="isTranslationMode"
                :selectedLanguageForTranslation="selectedLanguageForTranslation"
                :studyId="studyId"
                @close-notification="closeNotification"
                @change-attribute="changeAttributeForDetails"
                @show-modal="showModal"
              ></LessonEditorDetails>
            </section>
          </section>

          <section class="wrapper">
            <button type="submit" id="buttonSaveLessonDetails" :disabled="isSaved || !formChanged" @click="saveLessonDetails">
              {{ saveLessonDetailsText }}
            </button>
            <button
              id="structureLockButtonLesson"
              :disabled="!getIsOwner || structureLockLoading"
              class="structureLockButton"
              v-tooltip="disabledStructureLockText"
              @click="structureLockModalVisible = true"
            >
              <span v-if="lessonDetails.structure_lock">
                <i class="fa fa-lock"></i>
                {{ $t("interventionTranslation.lockedStructure") }}
              </span>
              <span v-else>
                <i class="fa fa-lock-open"></i>
                {{ $t("interventionTranslation.unlockedStructure") }}
              </span>
            </button>
            <i
              class="far fa-question-circle"
              v-tooltip="{ content: structureLockTooltip, theme: 'info-tooltip' }"
            ></i>
          </section>
        </section>

        <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loadingLanguage"></i>
      </section>

      <section v-if="!loadingElements && !loadingIntDetails" id="lessonElements">
        <LessonEditorElements
          v-show="selectedView === 1"
          :editorModalsVisible="warningChangesVisible"
          :lessonDetails="lessonDetailsComplete"
          :interventionDetails="interventionDetails"
          :formChanged="formChanged"
          :isTranslationMode="isTranslationMode"
          :selectedLanguageForTranslation="selectedLanguageForTranslation"
          :isDiary="false"
          :isSkill="false"
          :studyId="studyId"
          :currentPageLessonElements="currentPageLessonElements"
          @close-notification="closeNotification"
          @show-modal="showModal"
          @route-to="routeTo"
        ></LessonEditorElements>
      </section>

      <section v-show="selectedView === 2" id="translationStatus" class="wrapper">
        <div v-if="lessonDetails.translation_status != null" key="progressDiv">
          <section v-for="language in Object.keys(lessonDetails.translation_status)" :key="language">
            <section class="wrapper">
              <section class="col sec3">
                {{language}}
              </section>
              <section class="col sec25">
                <progress :value="lessonDetails.translation_status[language]" max="1"></progress>
              </section>
            </section>
          </section>
        </div>
        <div v-else key="noProgressDiv">
          {{ 'interventionTranslation.noProgress' | translate }}
        </div>
      </section>
    </section>
    <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loadingPage"></i>

    <BaseModal
      v-if="warningChangesVisible"
      id="changesModal"
      :bodyText="warningText"
      :isLeaveSiteWarning="true"
      :leftButtonText="$t('interventionTranslation.leave')"
      @close-modal="resolvePromise"
    ></BaseModal>

    <BaseModal
      v-if="deleteLanguageModalVisible"
      id="deleteLessonLanguageModal"
      :headerText="deleteLanguageHeaderText"
      :bodyText="deleteLanguageText"
      :leftButtonText="$t('interventionTranslation.ok')"
      @close-modal="deleteLanguageModal"
    ></BaseModal>

    <BaseModal
      v-if="structureLockModalVisible"
      id="structureLockModal"
      class="structureLockModal"
      :headerText="$t('interventionTranslation.structureLockModalHeader')"
      :leftButtonText="$t('generalTranslation.yes')"
      @close-modal="changeStructureLock"
    >
      <template v-slot:body>
        <div v-if="lessonDetails.structure_lock">
          <label>{{ $t("interventionTranslation.structureLockModalLocked") }}</label>
          <p>{{ $t("interventionTranslation.structureLockModalLockedInfo") }}</p>
        </div>
        <div v-else>
          <label>{{ $t("interventionTranslation.structureLockModalUnlocked") }}</label>
          <p>{{ $t("interventionTranslation.structureLockModalUnlockedInfo") }}</p>
        </div>
        <p>{{ $t("interventionTranslation.structureLockModalChanges") }}</p>
      </template>
    </BaseModal>

    <BaseNotification ref="notificationRef"></BaseNotification>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import router from "../router";
import BaseMenuBar from "./BaseMenuBar.vue";
import BaseTabs from "./BaseTabs.vue";
import BaseNotification from "./BaseNotification.vue";
import BaseModal from "./BaseModal.vue";
import BaseSelectLanguage from "./BaseSelectLanguage.vue";
import LessonEditorDetails from "./LessonEditorDetails.vue";
import LessonEditorElements from "./LessonEditorElements.vue";
import httpHelper from "../mixins/httpHelper";
import utils from "../mixins/utils";

export default {
  name: "LessonEditor",

  components: {
    BaseMenuBar,
    BaseTabs,
    BaseNotification,
    BaseModal,
    BaseSelectLanguage,
    LessonEditorDetails,
    LessonEditorElements
  },

  mixins: [httpHelper, utils],

  props: ["view"],

  data: function() {
    return {
      selectedView: 1,
      warningChangesVisible: false,
      lessonDetails: [],
      currentTranslation: "",
      currentTranslation2: "",
      isSaved: false,
      nextRouteName: "",
      loadingLanguage: true,
      otherModalVisible: false,
      selectedLanguageForTranslation: "",
      formChanged: false, //true if lesson details changed by user & not yet saved
      isTranslationMode: false,
      lessonDetailsComplete: [],
      interventionDetails: [],
      selectMediaVisible: false,
      studyDetails: [],
      loadingPage: true,
      loadingElements: true,
      copyElementsBool: false,
      resolvePromise: null,
      rejectPromise: null,
      currentPageLessonElements: 1,
      loadingIntDetails: false,
      lessonDetailsFormId: "lessonDetailsForm",
      deleteLanguageModalVisible: false,
      deleteLanguage: "",
      structureLockModalVisible: false,
      structureLockLoading: false,
    }
  },

  computed: {
    ...mapGetters([
      "getSelectedLanguage",
      "getMyRoleForStudy",
      "getPageRefreshBool",
      "getNotificationText",
      "getFormElementsChanged",
      "getAllDetailsLocalesChanged",
      "getChangedDetailsLocales",
      "getConditionEditor",
      "getPageRefreshDetails",
      "getUserId",
      "getIsEditable",
      "getIsOwner",
    ]),

    changedLocales: function() {
      const newChangedLocales = [];
      for (const l in this.lessonDetailsComplete.locales) {
        const locale = this.lessonDetailsComplete.locales[l];
        if (this.getChangedDetailsLocales.includes(locale)) {
          newChangedLocales.push(locale);
        }
      }
      return newChangedLocales;
    },

    saveLessonDetailsText: function() {
      if (this.changedLocales.length > 0) {
        const locales = `(${this.changedLocales.join(", ")})`;
        return Vue.i18n.translate("generalTranslation.saveLocales", { locales });
      } else {
        return Vue.i18n.translate("generalTranslation.save");
      }
    },

    disabledStructureLockText: function() {
      return !this.getIsOwner ?
        Vue.i18n.translate("interventionTranslation.structureLockButtonDisabled") :
        "";
    },

    structureLockTooltip: function() {
      return this.lessonDetails.structure_lock ?
        Vue.i18n.translate("interventionTranslation.structureLockModalLockedInfo") :
        Vue.i18n.translate("interventionTranslation.structureLockModalUnlockedInfo");
    },

    studyId: function() {
      return Number(this.interventionDetails.study_id);
    },

    interventionId: function() {
      return Number(this.lessonDetails.intervention_id);
    },

    lessonId: function() {
      return Number(this.$route.params.lessonId);
    },

    warningText: function() {
      let string = Vue.i18n.translate("generalTranslation.unsavedChanges") + ": ";
      if (this.formChanged) {
        string += Vue.i18n.translate("lessonTranslation.lessonDetails");
      }
      if (this.getFormElementsChanged && this.nextRouteName != "conditionEditor") {
        if (this.formChanged) {
          string += ", ";
        }
        string += Vue.i18n.translate("lessonTranslation.lessonElements");
      }
      return string;
    },

    deleteLanguageText: function() {
      return Vue.i18n.translate("lessonTranslation.deleteLanguageConfirm", { language: this.deleteLanguage });
    },

    deleteLanguageHeaderText: function() {
      return Vue.i18n.translate("lessonTranslation.deleteLanguageConfirmHeader");
    },

    tabDetails: function() {
      return [
        { text: Vue.i18n.translate("lessonTranslation.lessonDetails"), show: true, id: "details" },
        { text: Vue.i18n.translate("lessonTranslation.lessonElements"), show: true, id: "elements" },
        { text: Vue.i18n.translate("interventionTranslation.translationProgress"), show: true, id: "progress" },
      ];
    },
  },

  watch: {
    "$route.params.lessonId": function() {
      if (this.copyElementsBool) {
        //get new data after copy elements in different lesson
        this.copyElementsBool = false;
        this.loadingPage = true;
        this.loadingLanguage = true;
        this.loadingIntDetails = true;
        this.loadingElements = false;
        this.allElementsChanged();
        this.getDetailsOfLesson(true);
      } else {
        //get new data when going back from lesson where elements were copied to to lesson where elements where copied from
        this.loadingPage = true;
        this.loadingLanguage = true;
        this.loadingElements = true;
        this.getElementsAndDetails();
      }
    },

    formChanged(newVal) {
      if (!newVal) {
        this.clearChangedDetailsLocales();
      }
    },

    getNotificationText(newVal) {
      if (newVal == "") {
        return;
      }
      if (newVal.type === "error") {
        this.isSaved = false;
        this.loadingLanguage = false;
      } else if (newVal.type === "success") {
        this.isSaved = false;
      }
    },

    getSelectedLanguage() {
      this.setCurrentTranslations(false);
    },

    selectedLanguageForTranslation(newVal) {
      if (newVal != "") {
        this.setCurrentTranslations(true);
      } else if (this.currentTranslation2 != "") {
        this.addTranslation(this.lessonDetails.translations, this.currentTranslation2);
        this.currentTranslation2 = "";
      }
    },
  },

  mounted() {
    if (this.getNotificationText.text != Vue.i18n.translate("lessonTranslation.createLessonSuccess")) {
      this.closeNotification();
    }

    this.SET_PREVIEWANSWERS({});

    if ((this.getPageRefreshBool || this.getConditionEditor) && this.getPageRefreshDetails.id === this.lessonId) {
      this.SET_CONDITIONEDITOR(false);
      this.lessonDetails = this.getPageRefreshDetails.lessonDetails;
      this.lessonDetailsComplete = JSON.parse(JSON.stringify(this.lessonDetails));
      this.interventionDetails = this.getPageRefreshDetails.interventionDetails;
      this.studyDetails = this.getPageRefreshDetails.studyDetails;
      this.formChanged = this.getPageRefreshDetails.formChanged;
      this.loadingElements = false;
      this.loadingIntDetails = false;
      this.init();
      this.loadingPage = false;
      this.selectedView = this.getPageRefreshDetails.view;
      this.currentPageLessonElements = this.getPageRefreshDetails.currentPage
    } else {
      if (this.view != undefined) {
        this.selectedView = this.view;
      }
      this.getElementsAndDetails();
    }
    this.SET_CONDITIONEDITOR(false);
    this.SET_PAGEREFRESHBOOL(false);
    this.SET_PAGEREFRESHDETAILS([]);

    window.addEventListener("beforeunload", this.beforeunloadFunction);
    window.addEventListener("unload", this.unloadFunction);

    if (document.getElementById(this.lessonDetailsFormId) != null) {
      document.getElementById(this.lessonDetailsFormId).addEventListener("input", this.changeFormHasChanged);
    }
    if (!this.formChanged) {
      this.clearChangedDetailsLocales();
    }
  },

  beforeRouteUpdate(to, from, next) {
    this.nextRouteName = to.name;
    if (to.name === from.name && !this.copyElementsBool && (this.formChanged || this.getFormElementsChanged)) {
      const self = this;
      this.showWarningModal()
        .then(ok => {
          self.closeModal();
          if (ok) {
            self.formChanged = false;
            self.clearChangedDetailsLocales();
            if (to.name != "conditionEditor") {
              self.SET_FORMELEMENTSCHANGED(false);
            }
            next();
          } else {
            next(false);
          }
        });
    } else {
      next();
    }
  },

  beforeRouteLeave(to, _from, next) {
    this.nextRouteName = to.name;
    this.SET_PAGEREFRESHBOOL(false);
    this.SET_PAGEREFRESHDETAILS([]);
    if (this.otherModalVisible || this.selectMediaVisible || (!this.getConditionEditor && to.name === "conditionEditor")) {
      next(false);
    } else if (this.formChanged || (this.getFormElementsChanged && to.name != "conditionEditor")) {
      const self = this;
      this.showWarningModal()
        .then(ok => {
          self.closeModal();
          if (ok) {
            self.formChanged = false;
            self.clearChangedDetailsLocales();
            if (to.params.oldLessonId === undefined && to.name === "interventionEditor") {
              to.params.oldLessonId = self.lessonId;
            }
            if (to.name === "conditionEditor") {
              const data = self.setRefreshData();
              self.SET_PAGEREFRESHDETAILS(data);
            }
            if (to.name != "conditionEditor") {
              self.SET_QUESTIONLABELLIST([]);
              self.SET_FORMELEMENTSCHANGED(false);
            }
            next();
          } else {
            next(false);
          }
        });
    } else {
      if (to.params.oldLessonId === undefined && to.name === "interventionEditor") {
        to.params.oldLessonId = this.lessonId;
      }

      if (to.name === "conditionEditor") {
        const data = this.setRefreshData();
        this.SET_PAGEREFRESHDETAILS(data);
      }
      this.formChanged = false;
      this.clearChangedDetailsLocales();
      if (to.name != "conditionEditor") {
        this.SET_QUESTIONLABELLIST([]);
        this.SET_FORMELEMENTSCHANGED(false);
      }
      next();
    }
  },

  beforeDestroy() {
    if (document.getElementById(this.lessonDetailsFormId)) {
      document.getElementById(this.lessonDetailsFormId).removeEventListener("input", this.changeFormHasChanged);
    }
    window.removeEventListener("beforeunload", this.beforeunloadFunction);
    window.removeEventListener("unload", this.unloadFunction);
  },

  methods: {
    ...mapMutations([
      "SET_NOTIFICATIONTEXT",
      "SET_SELECTEDLANGUAGE",
      "SET_PREVIEWANSWERS",
      "SET_PAGEREFRESHBOOL",
      "SET_FORMELEMENTSCHANGED",
      "SET_QUESTIONLABELLIST",
      "SET_PAGEREFRESHDETAILS",
      "SET_LESSONELEMENTSARR",
      "SET_CONDITIONEDITOR",
      "SET_ALLELEMLOCALESCHANGED",
      "SET_ALLDETAILSLOCALESCHANGED",
      "ADD_CHANGEDDETAILSLOCALE",
      "CLEAR_CHANGEDDETAILSLOCALES",
    ]),

    clearChangedDetailsLocales: function() {
      this.SET_ALLDETAILSLOCALESCHANGED(false);
      this.CLEAR_CHANGEDDETAILSLOCALES();
    },

    allElementsChanged: function() {
      this.SET_FORMELEMENTSCHANGED(true);
      this.SET_ALLELEMLOCALESCHANGED(true);
    },

    showWarningModal: function() {
      return new Promise((resolve, reject) => {
        this.warningChangesVisible = true;
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    getElementsAndDetails: function() {
      const self = this;
      this.requestLessonElements(this.lessonId, false, true)
        .then(function(response) {
          self.SET_LESSONELEMENTSARR(response);
          self.loadingIntDetails = true;
          self.loadingElements = false;
          self.getDetailsOfLesson(true);
        })
        .catch(function(error) {
          self.handleErrors(error, function() { self.getElementsAndDetails() }, "studyDetails");
        });
    },

    getDetailsOfLesson: async function(initBool) {
      try {
        const response = await this.requestLessonDetails(this.lessonId);
        this.lessonDetails = response;
        if (initBool) {
          this.init();
          this.getInterventionAndStudy();
        } else {
          this.setCurrentTranslations(false);
          if (this.selectedLanguageForTranslation != "") {
            this.setCurrentTranslations(true);
          }
        }
        this.lessonDetailsComplete = JSON.parse(JSON.stringify(this.lessonDetails));
      } catch (error) {
        const self = this;
        this.handleErrors(error, function() { self.getDetailsOfLesson(initBool) }, "studyDetails");
      }
    },

    getInterventionAndStudy: function() {
      const self = this;
      this.requestInterventionDetails(this.interventionId)
        .then(function(response) {
          self.interventionDetails = response;
          self.loadingIntDetails = false;
          return self.requestCollaboratorsIncludingPermissions("page=0&id=" + self.getUserId, self.studyId, false, -1, -1);
        })
        .then(function() {
          return self.requestStudyDetails(self.studyId);
        })
        .then(function(response) {
          self.studyDetails = response;
          self.loadingPage = false;
        })
        .catch(function(error) {
          self.handleErrors(error, function() { self.getInterventionAndStudy() }, "");
        });
    },

    init: function() {
      if (!this.lessonDetails.locales || this.lessonDetails.locales.length === 0) {
        this.lessonDetails.locales = ["de", "en"];
      }
      if (!this.lessonDetails.locales.includes(this.getSelectedLanguage)) {
        const language = (this.lessonDetails.locales.includes("de")) ? "de" : this.lessonDetails.locales[0];
        this.SET_SELECTEDLANGUAGE(language);
      }
      this.selectedLanguageForTranslation = "";
      this.isTranslationMode = false;
      this.setCurrentTranslations(false);
    },

    showModal: function(obj) {
      switch (obj.type) {
        case "StudyMedia":
          this.selectMediaVisible = obj.value;
          break;
        case "LanguageError":
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('interventionTranslation.deleteSelectedLanguageWarning')});
          break;
        case "LessonPreview":
          this.setUpdatedLessonDetailsForPreview();
          break;
        case "any":
          this.otherModalVisible = obj.value;
          break;
        case "DeleteLanguage":
          this.deleteLanguage = obj.deleteLanguage;
          this.deleteLanguageModalVisible = true;
          break;
        default:
          break;
      }
    },

    deleteLanguageModal(done) {
      if (done) {
        const locales = this.lessonDetails.locales.filter(l => l !== this.deleteLanguage);
        this.lessonDetails.locales = locales;
        this.lessonDetailsComplete.locales = locales;
        if (!this.getAllDetailsLocalesChanged) {
          this.SET_ALLDETAILSLOCALESCHANGED(true);
        }
        this.formChanged = true;
      }
      this.deleteLanguageModalVisible = false;
    },

    changeLanguage2: function(language) {
      this.selectedLanguageForTranslation = language;
    },

    changeTranslationMode: function(isTranslationMode) {
      this.isTranslationMode = isTranslationMode;
    },

    showView: function(view) {
      this.closeNotification();
      this.selectedView = view;
    },

    changeAttributeForDetails: function(obj) {
      this.closeNotification();
      if (obj.name === "title" || obj.name === "description") {
        if (!obj.isTranslation) {
          this.currentTranslation[obj.name] = obj.value;
        } else {
          this.currentTranslation2[obj.name] = obj.value;
        }
        const locale = !obj.isTranslation ? this.getSelectedLanguage : this.selectedLanguageForTranslation;
        this.ADD_CHANGEDDETAILSLOCALE(locale);
      } else {
        if (obj.name === "AddLanguage") {
          const language = obj.value;
          this.addLanguage(language);
          this.ADD_CHANGEDDETAILSLOCALE(language);
          this.SET_SELECTEDLANGUAGE(language);
        } else {
          this.lessonDetails[obj.name] = obj.value;
          this.lessonDetailsComplete[obj.name] = obj.value;
        }
        if (!this.getAllDetailsLocalesChanged) {
          this.SET_ALLDETAILSLOCALESCHANGED(true);
        }
      }
      this.formChanged = true;
    },

    changeStructureLock: function(done) {
      if (done) {
        this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("interventionTranslation.structureLockModalLoading") });
        this.structureLockLoading = true;
        const lock = !this.lessonDetails.structure_lock;
        const self = this;
        this.updateQuestionnaireStructureLock(this.lessonId, lock).then(
          function() {
            self.lessonDetails.structure_lock = lock;
            self.lessonDetailsComplete.structure_lock = lock;
            self.structureLockLoading = false;
            self.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("interventionTranslation.structureLockModalSuccess") });
          }
        ).catch(
          function(error) {
            self.handleErrors(error, function() { self.changeStructureLock(done) }, "StructureLock");
            self.structureLockLoading = false;
          }
        );
      }
      this.structureLockModalVisible = false;
    },

    addLanguage(language) {
      const locales = JSON.parse(JSON.stringify(this.lessonDetails.locales));
      locales.push(language);
      locales.sort();
      this.lessonDetails.locales = locales;
      this.lessonDetailsComplete.locales = locales;
    },

    changeFormHasChanged: function() {
      this.closeNotification();
      this.formChanged = true;
    },

    routeTo: function(obj) {
      if (obj.name === "conditionEditor") {
        this.currentPageLessonElements = obj.params.page;
        delete obj.params["page"];
        obj.params.lessonId = this.lessonId;
      } else if (obj.name === "lessonEditor") {
        //route changes -> get new lessonDetails, interventionDetails, studyDetails
        //permissions and elements already set
        //in route.params.lessonId watch function
        this.copyElementsBool = true;
      }
      router.push(obj);
    },

    getLessonDetailsErrorText() {
      let errorText = "";
      const lengthErrors = {
        name: false,
        title: [],
        description: [],
      };
      if (!this.lessonDetails.name) {
        errorText += Vue.i18n.translate("generalTranslation.name");
      } else {
        lengthErrors.name = this.lessonDetails.name.length > 255;
      }
      if (!this.currentTranslation.title) {
        errorText += ((errorText !== "") ? ", " : "") + Vue.i18n.translate("interventionTranslation.title");
      } else if (this.currentTranslation.title.length > 255) {
        lengthErrors.title.push(this.getSelectedLanguage);
      }
      if (!this.currentTranslation.description) {
        errorText += ((errorText !== "") ? ", " : "") + Vue.i18n.translate("interventionTranslation.description");
      } else if (this.currentTranslation.description.length > 255) {
        lengthErrors.description.push(this.getSelectedLanguage);
      }
      if (this.currentTranslation2 !== "") {
        if (!this.currentTranslation2.title) {
          errorText += ((errorText !== "") ? ", " : "") + Vue.i18n.translate("interventionTranslation.titleTranslation");
        } else if (this.currentTranslation2.title.length > 255) {
          lengthErrors.title.push(this.selectedLanguageForTranslation);
        }
        if (!this.currentTranslation2.description) {
          errorText += ((errorText !== "") ? ", " : "") + Vue.i18n.translate("interventionTranslation.descriptionTranslation");
        } else if (this.currentTranslation2.description.length > 255) {
          lengthErrors.description.push(this.selectedLanguageForTranslation);
        }
      }
      if (errorText.length > 0) {
        errorText = Vue.i18n.translate("adminUsersTranslation.errorFillIn") + errorText;
      }

      for (const t in this.lessonDetails.translations) {
        const translation = this.lessonDetails.translations[t];
        const locale = translation.locale;
        if (!this.lessonDetails.locales.includes(locale)) continue;
        if (!lengthErrors.title.includes(locale)) {
          if (translation.title && translation.title.length > 255) {
            lengthErrors.title.push(locale);
          }
        }
        if (!lengthErrors.description.includes(locale)) {
          if (translation.description && translation.description.length > 255) {
            lengthErrors.description.push(locale);
          }
        }
      }
      let needsPrefix = errorText.length > 0;
      if (lengthErrors.name) {
        errorText += (needsPrefix ? "; " : "") + Vue.i18n.translate("interventionTranslation.nameTooLong");
        needsPrefix = true;
      }
      if (lengthErrors.title.length > 0) {
        lengthErrors.title.sort();
        const locales = " (" + lengthErrors.title.join(", ") + ")";
        errorText += (needsPrefix ? "; " : "") + Vue.i18n.translate("interventionTranslation.titleTooLong") + locales;
        needsPrefix = true;
      }
      if (lengthErrors.description.length > 0) {
        lengthErrors.description.sort();
        const locales = " (" + lengthErrors.description.join(", ") + ")";
        errorText += (needsPrefix ? "; " : "") + Vue.i18n.translate("interventionTranslation.descriptionTooLong") + locales;
        needsPrefix = true;
      }
      return errorText;
    },

    saveLessonDetails: function() {
      const errorText = this.getLessonDetailsErrorText();
      if (errorText.length > 0) {
        this.SET_NOTIFICATIONTEXT({ type: "error", text: errorText });
      } else {
        this.isSaved = true;
        this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("lessonTranslation.updateLessonDetailsLoad") });
        const details = JSON.parse(JSON.stringify(this.lessonDetails));
        if (!details.page_color) {
          details.page_color = this.$defaultLessonPageColor;
        }
        // Add current translations in case they are missing
        if (this.currentTranslation != "") {
          this.addTranslation(details.translations, this.currentTranslation);
        }
        if (this.currentTranslation2 != "") {
          this.addTranslation(details.translations, this.currentTranslation2);
        }
        details.translations = details.translations.filter(t => this.changedLocales.includes(t.locale));
        this.updateDetails(details);
      }
    },

    updateDetails: function(details) {
      const self = this;
      this.updateLessonDetailsRequest(details, this.lessonId)
        .then(function() {
          self.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("lessonTranslation.updateLessonDetailsSuccess") });
          self.formChanged = false;
          self.getDetailsOfLesson(false);
        })
        .catch(function(error) {
          self.handleErrors(error, function() { self.updateDetails(details) }, "");
        });
    },

    setCurrentTranslations: function(isTranslation) {
      const language = !isTranslation ? this.getSelectedLanguage : this.selectedLanguageForTranslation;
      if (this.selectedLanguageForTranslation === "" && this.currentTranslation2 != "") {
        this.addTranslation(this.lessonDetails.translations, this.currentTranslation2);
        this.currentTranslation2 = "";
      }
      let translation = "";
      const index = this.lessonDetails.translations.findIndex(elem => elem.locale === language);
      if (index != -1) {
        translation = this.lessonDetails.translations[index];
        this.lessonDetails.translations.splice(index, 1);
      } else if (language != "") {
        translation = {
          locale: language,
          title: "",
          description: "",
        };
      }
      if (!isTranslation) {
        if (this.currentTranslation != "") {
          this.addTranslation(this.lessonDetails.translations, this.currentTranslation);
        }
        if (translation != "") {
          this.currentTranslation = translation;
        }
      } else {
        if (this.currentTranslation2 != "") {
          this.addTranslation(this.lessonDetails.translations, this.currentTranslation2);
        }
        if (translation != "" && this.selectedLanguageForTranslation != "") {
          this.currentTranslation2 = translation;
        }
      }
      this.loadingLanguage = false;
    },

    unloadFunction: function() {
      this.SET_PAGEREFRESHBOOL(true);
      this.closeNotification();
      this.isTranslationMode = false;
      this.currentPageLessonElements = 1;
      const data = this.setRefreshData();
      this.SET_PAGEREFRESHDETAILS(data);
    },

    setRefreshData: function() {
      //all translations in translations array
      const lessonDetailsArr = JSON.parse(JSON.stringify(this.lessonDetails));
      if (this.currentTranslation != "") {
        this.addTranslation(lessonDetailsArr.translations, this.currentTranslation);
        this.currentTranslation = "";
      }
      if (this.currentTranslation2 != "") {
        this.addTranslation(lessonDetailsArr.translations, this.currentTranslation2);
        this.currentTranslation2 = "";
      }
      const data = {
        id: this.lessonId,
        lessonDetails: lessonDetailsArr,
        interventionDetails: this.interventionDetails,
        studyDetails: this.studyDetails,
        view: this.selectedView,
        formChanged: this.formChanged,
        currentPage: this.currentPageLessonElements,
      };
      return data;
    },

    beforeunloadFunction: function(e) {
      if (this.formChanged || this.getFormElementsChanged) {
        e.preventDefault();
        e.returnValue = "";
      } else {
        delete e["returnValue"];
      }
    },

    setUpdatedLessonDetailsForPreview: function() {
      this.closeNotification();
      const lessonDetailsArr = JSON.parse(JSON.stringify(this.lessonDetails));
      if (this.currentTranslation) {
        const translation = JSON.parse(JSON.stringify(this.currentTranslation));
        this.addTranslation(lessonDetailsArr.translations, translation);
      }
      if (this.currentTranslation2) {
        const translation = JSON.parse(JSON.stringify(this.currentTranslation2));
        this.addTranslation(lessonDetailsArr.translations, translation);
      }
      this.lessonDetailsComplete = lessonDetailsArr;
    },

    closeModal: function() {
      this.warningChangesVisible = false;
    },

    closeNotification: function() {
      this.$refs["notificationRef"].closeNotification();
    },
  }
}
</script>
