<template>
  <BaseModal
    id="createAnnouncementModal"
    class="adminAnnouncementCreate"
    :headerText="headerText"
    :leftButtonText="buttonText"
    :disableButtons="isCreated"
    :disableLeftButton="leftDisabled"
    @close-modal="closeCreateModal"
  >
    <template v-slot:body> <!--test-->
      <section class="wrapper">
        <section class="wrapper">
          <label>
            {{ 'adminAnnouncementsTranslation.clients' | translate }} *
          </label>
          <section class="notBold notNameTd">
            <section v-for="client in clientsArray" :key="client" class="checkboxSection checkboxSectionAnnouncements">
              <label class="container">
                {{ 'adminAnnouncementsTranslation.' + client | translate }}
                <input type="checkbox" v-model="selectedClients" :value="client" :id="'inputAnnouncementClient' + client">
                <span class="checkmark"></span>
              </label>
            </section>
          </section>
        </section>

        <label>
          {{ 'adminAnnouncementsTranslation.type' | translate }} *
        </label>
        <select v-model="type" id="inputAnnouncementType">
          <option value="" id="inputAnnouncementTypeNone" disabled>
            {{ 'generalTranslation.select' | translate }}
          </option>
          <option v-for="data in typesArray" :value="data" :id="'inputAnnouncementType' + data" :key="data">
            {{ 'adminAnnouncementsTranslation.' + data | translate }}
          </option>
        </select>

        <form @submit.prevent="createNewAnnouncement">
          <input type="submit" style="display:none"/>

          <section class="wrapper">
            <section class="col sec50">
              <label>
                {{ 'adminAnnouncementsTranslation.title' | translate }} *
              </label>
              <input type="text" v-model="title" id="inputAnnouncementTitle" :placeholder="$t('adminAnnouncementsTranslation.title')">

              <label>
                {{ 'adminAnnouncementsTranslation.messageWithChars' | translate }} *
              </label>
              <textarea v-model="message" id="inputAnnouncementMessage" :placeholder="$t('adminAnnouncementsTranslation.message')" maxlength="255"/>
            </section>
            <section class="col sec50">
              <label>
                {{ 'adminAnnouncementsTranslation.titleTranslation' | translate }} *
              </label>
              <input type="text" v-model="titleEN" id="inputAnnouncementTitleTranslation" :placeholder="$t('adminAnnouncementsTranslation.titleTranslation')">

              <label>
                {{ 'adminAnnouncementsTranslation.messageWithCharsTranslation' | translate }} *
              </label>
              <textarea v-model="messageEN" id="inputAnnouncementMessageTranslation" :placeholder="$t('adminAnnouncementsTranslation.messageTranslation')" maxlength="255"/>
            </section>
          </section>
          
          <label>
            {{ 'adminAnnouncementsTranslation.startDate' | translate }} *
          </label>
          <flatPickr v-model="startDate" :config="config" id="inputAnnouncementStart" :placeholder="$tlang(getAppLanguage, 'elementsQuestionTranslation.selectDate')">
          </flatPickr>

          <label>
            {{ 'adminAnnouncementsTranslation.endDate' | translate }} *
          </label>
          <flatPickr v-model="endDate" :config="config" id="inputAnnouncementEnd" :placeholder="$tlang(getAppLanguage, 'elementsQuestionTranslation.selectDate')">
          </flatPickr>
        </form>
      </section>
    </template>
  </BaseModal>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import BaseModal from './BaseModal.vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/l10n/de.js';

export default {
  name: 'AdminAnnouncementCreate',

  components: {
    BaseModal,
    flatPickr,
  },

  mixins: [httpHelper],

  props: {
    //for edit: title, titleEN, message, messageEN, type, client, start, end, id
    //for create: empty
    announcementData: {
      required: true,
      type: Object,
    }
  },

  data: function(){
    return{
      title: '',
      message: '',
      titleEN: '',
      messageEN: '',
      selectedClients: [],
      type: '',
      startDate: "",
      endDate: "",
      isCreated: false,
      clientsArray: ["cms", "ecoach", "app"],
      typesArray: ["success", "info", "warning", "error"],
    }
  },

  computed: {
    ...mapGetters([
      'getNotificationText',
      'getAppLanguage'
    ]),

    headerText: function(){
      return Vue.i18n.translate('adminAnnouncementsTranslation.' + (this.isCreate ? 'createAnnouncement' : 'editAnnouncement'))
    },

    buttonText: function(){
      return Vue.i18n.translate('generalTranslation.' + (this.isCreate ? 'create' : 'save'))
    },

    config: function(){
      return {
        altFormat: Vue.i18n.translateIn(this.getAppLanguage, 'adminAnnouncementsTranslation.dateTimeFormat'),
        altInput: true,
        dateFormat: 'U',
        enableTime: true,
        locale: this.getAppLanguage,
        position: "auto center",
      }
    },

    leftDisabled: function(){
      return (this.title === "" || this.message === "" || this.titleEN === "" || this.messageEN === "" || this.type === "" || this.startDate === "" || this.endDate === "" || this.selectedClients.length === 0)
    },

    isCreate: function(){
      return Object.keys(this.announcementData).length === 0
    },
  },

  mounted(){
    //if edit
    if(!this.isCreate){
      this.title = this.announcementData.title;
      this.titleEN = this.announcementData.titleEN;
      this.message = this.announcementData.message;
      this.messageEN = this.announcementData.messageEN;
      this.selectedClients = this.announcementData.client;
      this.type = this.announcementData.type;

      this.startDate = new Date(this.announcementData.start * 1000);
      this.endDate = new Date(this.announcementData.end * 1000);
    }
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.isCreated = false;
      }
    },
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
    ]),

    closeCreateModal: function(done){
      if(done){
        this.createNewAnnouncement();
      }else{
        this.closeModal(false);
      }
    },

    createNewAnnouncement: function() {
      var errorText = (this.title === "") ? Vue.i18n.translate('adminAnnouncementsTranslation.title') : "";

      if(this.titleEN === ""){
        errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('adminAnnouncementsTranslation.titleTranslation');
      }

      if(this.message === ""){
        errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('adminAnnouncementsTranslation.message');
      }

      if(this.messageEN === ""){
        errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('adminAnnouncementsTranslation.messageTranslation');
      }

      if(this.type === ""){
        errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('adminAnnouncementsTranslation.type');
      }

      if(this.startDate === ""){
        errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('adminAnnouncementsTranslation.startDate');
      }

      if(this.endDate === ""){
        errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('adminAnnouncementsTranslation.endDate');
      }

      if(this.selectedClients.length === 0){
        errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('adminAnnouncementsTranslation.clients');
      }

      if(errorText != ""){
        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('adminAnnouncementsTranslation.errorFillIn') + errorText});
      }else{
        this.isCreated = true;

        if(this.isCreate){
          this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('adminAnnouncementsTranslation.createAnnouncementLoad')});
        }else{
          this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('adminAnnouncementsTranslation.updateAnnouncementLoad')});
        }

        var json = {
          "data" : {
            "type" : "announcement",
            "attributes" : {
              "type" : this.type,
              "client" : this.selectedClients,
              "start": this.startDate,
              "end": this.endDate,
              "translations": [
                {
                  "locale" : "de",
                  "title" : this.title,
                  "message" : this.message
                },
                {
                  "locale" : "en",
                  "title" : this.titleEN,
                  "message" : this.messageEN
                }
              ]
            }
          }
        };

        var self = this;

        if(this.isCreate){
          this.createAnnouncementRequest(json)
          .then(function (){
            self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('adminAnnouncementsTranslation.createAnnouncementSuccess')});
            self.isCreated = false;
            self.closeModal(true);
          })
          .catch(function (error){
            self.handleErrors(error, function(){ self.createNewAnnouncement() }, "");
          });
        }else{
          this.updateAnnouncementRequest(this.announcementData.id, json)
          .then(function (){
            self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('adminAnnouncementsTranslation.updateAnnouncementSuccess')});
            self.isCreated = false;
            self.closeModal(true);
          })
          .catch(function (error){
            self.handleErrors(error, function(){ self.createNewAnnouncement() }, "");
          });
        }
      }
    },

    closeModal(done) {
      if(!this.isCreated){
        if(this.isCreate || done){
          //create & (un)saved || update & saved
          this.$emit('close-modal', { done: done });
        }else{
          //update & unsaved -> check for unsaved changes
          var dataChanged = false;

          if(this.title != this.announcementData.title || this.titleEN != this.announcementData.titleEN || this.message != this.announcementData.message || this.messageEN != this.announcementData.messageEN || 
              this.selectedClients != this.announcementData.client || this.type != this.announcementData.type || this.startDate != this.announcementData.start || this.endDate != this.announcementData.end){
            dataChanged = true;
          }

          if(dataChanged && (this.getNotificationText === "" || this.getNotificationText.text != Vue.i18n.translate('adminAnnouncementsTranslation.unsavedChanges'))){
            this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('adminAnnouncementsTranslation.unsavedChanges')});
          }else{
            this.$emit('close-modal', { done: done });
          }
        }
      }
    },
  }
}
</script>
