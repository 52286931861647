<template>
  <div class="elementsBlock">
    <section v-if="getIsEditable && errorTextsForElements[propsObj.id] != ''" :id="'errorTextElement' + propsObj.id" class="wrapper errorMessage">
      {{errorTextsForElements[propsObj.id]}}
    </section>

    <section v-if="getIsEditable" class="wrapper">
      <label>
        {{ 'elementsBlockTranslation.typeOfBlock' | translate }} *
      </label>
      <select :value="propsObj.type" :id="'inputBlockType' + propsObj.id" @input="$emit('change-element', {'type': 'attribute',
          'name': 'typeBlock', 'value': $event.target.value})" :disabled="disableInputs">
        <option value="" :id="'inputBlockType' + propsObj.id + 'None'" disabled selected>
          {{ 'elementsBlockTranslation.selectTypeOfBlock' | translate }}
        </option>
        <option v-for="data in blockTypeList" :value="data.value" :id="'inputBlockType' + propsObj.id + data.value" :key="data.value">
          {{data.text}}
        </option>
      </select>
    </section>

    <!-- condition (conditional or both) -->
    <section v-if="getIsEditable && hasTypeCondition" class="wrapper">

      <label class="labelWithMargin">
        {{ 'elementsBlockTranslation.condition' | translate }} *
      </label>
      <select :value="propsObj.isComplexCondition" :id="'inputConditionType' + propsObj.id" @input="$emit('change-element', {'type': 'attribute', 'name': 'isComplexCondition',
          'value': Array.from($event.target.options).filter(o => o.selected).map(x => '_value' in x ? x._value : x.value)[0]})" :disabled="disableInputs">
        <option value="" :id="'inputConditionType' + propsObj.id + 'None'" disabled>
          {{ 'generalTranslation.select' | translate }}
        </option>
        <option :value="false" :id="'inputConditionType' + propsObj.id + 'Simple'" :key="'conditionSimple' + propsObj.id">
          {{ 'elementsBlockTranslation.simpleCondition' | translate }}
        </option>
        <option :value="true" :id="'inputConditionType' + propsObj.id + 'Complex'" :key="'conditionComplex' + propsObj.id">
          {{ 'elementsBlockTranslation.complexCondition' | translate }}
        </option>
      </select>

      <section v-if="!propsObj.isComplexCondition" :id="'simpleCondition' + propsObj.id" key="simpleSec" class="wrapper datepicker">
        <BaseCondition
          :id="propsObj.id"
          :condition="propsObj.condition"
          :valuesForLabel="valueListForSelectedLabel"
          :allowStructureChanges="allowStructureChanges"
          @change-condition="changeConditionAttribute"
        ></BaseCondition>
      </section>
      <section v-else key="complexSec" class="complexSec">
        <section :id="'complexCondition' + propsObj.id" class="labelWithMargin">
          {{complexConditionAsString}}
        </section>
        <button type="button" :id="'buttonOpenConditionEditor' + propsObj.id" class="buttonMiddle" @click="openConditionEditor" :disabled="disableInputs">
          {{ 'elementsBlockTranslation.defineCondition' | translate }}
        </button>
      </section>

      <label>
        {{ 'elementsBlockTranslation.conditionDescription' | translate }}
      </label>
      <input type="text" :value="propsObj.conditionDescription" :id="'inputConditionDescription' + propsObj.id" :placeholder="$t('elementsBlockTranslation.conditionDescription')"
        @input="$emit('change-element', {'type': 'attribute', 'name': 'condition-description', 'value': $event.target.value })" :disabled="disableInputs">
    </section>

    <!-- details (details or both) -->
    <section v-if="getIsEditable && hasTypeDetails" class="wrapper">
      <div class="col sec50">
        <label>
          {{labelForTextInLanguage(false)}} *
        </label>
        <input type="text" :value="translation" :id="'inputDetailsText' + propsObj.id" :placeholder="labelForTextInLanguage(false)" @input="$emit('change-element',
          {'type': 'translation-attribute', 'name': 'text', 'isTranslation': false, 'value': $event.target.value })">
      </div>
      <!-- translation -->
      <div v-if="isTranslationMode" class="col sec50">
        <label>
          {{labelForTextInLanguage(true)}} *
        </label>
        <input type="text" :value="translation2" :id="'inputDetailsTextTranslation' + propsObj.id" :placeholder="labelForTextInLanguage(true)" @input="$emit('change-element',
          {'type': 'translation-attribute', 'name': 'text', 'isTranslation': true, 'value': $event.target.value })">
      </div>
    </section>

    <!-- repetition (for all types) -->
    <section v-if="getIsEditable" class="wrapper repetitionData">
      <label>
        {{ 'elementsBlockTranslation.repetition' | translate }} *
      </label>
      <div class="repetitionInstr">
        {{ 'elementsBlockTranslation.repetitionInstruction' | translate }}:
      </div>
      <select v-if="!isSkill" :value="propsObj.thenBlock.repeat.question" :id="'inputRepetitionType' + propsObj.id" @input="$emit('change-element', {'type': 'attribute',
          'name': 'repeatQuestion', 'value': Array.from($event.target.options).filter(o => o.selected).map(x => '_value' in x ? x._value : x.value)[0]})" :disabled="disableInputs">
        <option value="" :id="'inputRepetitionType' + propsObj.id + 'None'" disabled>
          {{ 'generalTranslation.select' | translate }}
        </option>
        <option :value="false" :id="'inputRepetitionType' + propsObj.id + 'Constant'" :key="'yes2' + propsObj.id">
          {{ 'elementsBlockTranslation.constant' | translate }}
        </option>
        <option :value="true" :id="'inputRepetitionType' + propsObj.id + 'Question'" :key="'no2' + propsObj.id">
          {{ 'elementsBlockTranslation.dependingOnQuestion' | translate }}
        </option>
      </select>
      <div v-else>
        {{ 'elementsBlockTranslation.constant' | translate }}
      </div>

      <input type="number" v-if="!propsObj.thenBlock.repeat.question" :value="propsObj.thenBlock.repeat.data" min="1" :id="'inputRepetitionNumber' + propsObj.id"
        @input="$emit('change-element', {'type': 'attribute', 'name': 'repeatData', 'value': $event.target.value})" :disabled="disableInputs">

      <select v-else :value="propsObj.thenBlock.repeat.data" :id="'inputRepetitionQuestion' + propsObj.id" @input="$emit('change-element', {'type': 'attribute',
          'name': 'repeatData', 'value': $event.target.value})" :disabled="disableInputs">
        <option value="" :id="'inputRepetitionQuestion' + propsObj.id + 'None'" disabled selected>
          {{ 'elementsBlockTranslation.selectLabel' | translate }}
        </option>
        <template v-for="(option, index) in getQuestionLabelList">
          <option v-if="option && option != 0 && option.type === 'slider'" :id="'inputRepetitionQuestion' + propsObj.id + '-' + index" :value="index" :key="index">
            {{option.label}}
          </option>
        </template>
      </select>
    </section>

    <section v-if="!getIsEditable" class="wrapper">
      <label>
        {{selectedTypeAsString}}<br><br>
      </label>

      <!-- condition (conditional or both) -->
      <section v-if="hasTypeCondition">
        <label>
          {{ 'elementsBlockTranslation.condition' | translate }}
        </label>

        <section v-if="!propsObj.isComplexCondition" key="simpleSec" class="wrapper labelWithMargin">
          {{simpleConditionAsString(propsObj, false)}}
        </section>

        <section v-else key="complexSec" class="labelWithMargin">
          {{complexConditionAsString}}
        </section>
      </section>

      <!-- details (details or both) -->
      <section v-if="hasTypeDetails">
        <label>
          {{ 'elementsBlockTranslation.textForDetails' | translate }}
        </label>
        {{translation}}
      </section>

      <!-- repetition -->
      <p>
        <label>
          {{ 'elementsBlockTranslation.repetition' | translate }}
        </label>
        {{ 'elementsBlockTranslation.repetitionInstruction' | translate }}:
        {{repetitionText}}
      </p>
    </section>

    <div v-if="propsObj.elements.length === 0 && getIsEditable" id="noBlockElements" class="addElementsText">
      {{ 'lessonTranslation.addNewElementText' | translate }}
    </div>

    <BaseAddElement
      v-if="!disableInputs"
      :id="propsObj.id"
      index="0"
      :top="true"
      :isInBlock="true"
      :isSkill="isSkill"
      class="block"
      @change-element="changeNestedElement($event, {type: 'ElementsBlock'}, -1)"
    ></BaseAddElement>
    <div v-else class="baseAddElementSpace"></div>

    <draggable
      tag="ul"
      :id="'listOfElements' + propsObj.id"
      :list="propsObj.elements"
      :group="{ name: 'lessonElementsGroup'}"
      :scroll-sensitivity="40"
      :force-fallback="true"
      :fallback-tolerance="1"
      :swapThreshold="0.5"
      handle=".handle"
      :disabled="!getIsEditable"
      class="ulElements list-group"
      :class="{dragItems: (propsObj.elements.length === 0)}"
      :move="checkMoveElement"
      @add="addNewElementFromSide"
      @change="moveElementDragDrop"
    >
      <div v-for="(element, index) in propsObj.elements" :key="element.propsObj.id" class="list-group-item block">
        <LessonElement
          :id="'element' + element.propsObj.id"
          :index="index"
          :element="element"
          :numberOfElements="propsObj.elements.length"
          :isTranslationMode="isTranslationMode"
          defaultPageColor=""
          :selectedElements="selectedElements"
          :errorTextsForElements="errorTextsForElements"
          :closedElements="closedElements"
          :sliderRepetitionErrorLabels="sliderRepetitionErrorLabels"
          :selectedLanguageForTranslation="selectedLanguageForTranslation"
          :studyId="studyId"
          :showTextEditor="showTextEditor"
          :isInBlock="true"
          :isAfterBlock="propsObj.elements[index - 1] && propsObj.elements[index - 1].type === 'ElementsBlock'"
          :isBeforeBlock="propsObj.elements[index + 1] && propsObj.elements[index + 1].type === 'ElementsBlock'"
          :isSkill="isSkill"
          :showProgressBars="showProgressBars"
          :selectElementsBool="selectElementsBool"
          :allowStructureChanges="allowStructureChanges"
          @change-element="changeNestedElement($event, element, index)"
        ></LessonElement>

        <BaseAddElement
          v-if="!disableInputs"
          :id="element.propsObj.id"
          :index="index"
          :top="false"
          :isInBlock="true"
          :isSkill="isSkill"
          @change-element="changeNestedElement($event, element, index)"
        ></BaseAddElement>
        <div v-else class="baseAddElementSpace"></div>
      </div>
    </draggable>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters, mapMutations} from 'vuex';
import draggable from "vuedraggable";
import BaseCondition from './BaseCondition.vue';
import BaseAddElement from './BaseAddElement.vue';
import questionConditionHelper from '../mixins/questionConditionHelper';
import elementsHelper from '../mixins/elementsHelper';

export default {
  name: 'ElementsBlock',

  components: {
    draggable,
    BaseCondition,
    BaseAddElement,
    LessonElement: () => import('./LessonElement.vue')
  },

  mixins: [questionConditionHelper, elementsHelper],

  props: {
    propsObj: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: String,
    },

    translation2: {
      required: true,
      type: String,
    },

    isTranslationMode: {
      required: true,
      type: Boolean,
    },

    errorTextsForElements: {
      required: true,
      type: Array,
    },

    sliderRepetitionErrorLabels: {
      required: false,
      type: Array
    },

    selectedLanguageForTranslation: {
      required: true,
      type: String,
    },

    studyId: {
      required: true,
      type: Number,
    },

    showTextEditor: {
      required: true,
      type: Number,
    },

    selectedElements: {
      required: true,
      type: Array,
    },

    closedElements: {
      required: true,
      type: Array,
    },

    selectElementsBool: {
      required: true,
      type: Boolean,
    },

    isInBlock: { //true if nested block
      required: true,
      type: Boolean,
    },

    isSkill: {
      required: true,
      type: Boolean,
    },

    allowStructureChanges: {
      type: Boolean,
      default: null,
    },
  },

  data: function(){
    return {
    }
  },

  computed: {
    ...mapGetters([
      'getQuestionLabelList',
      'getConditionEditorData',
      'getIsEditable',
    ]),

    hasTypeCondition: function(){
      return this.propsObj.type === "conditional" || this.propsObj.type === "both"
    },

    hasTypeDetails: function(){
      return this.propsObj.type === "details" || this.propsObj.type === "both"
    },

    blockTypeList: function(){
      if(!this.isSkill){
        return [
          {
            text: Vue.i18n.translate('elementsBlockTranslation.none'),
            value: "none"
          },
          {
            text: Vue.i18n.translate('elementsBlockTranslation.typeConditional'),
            value: "conditional"
          },
          {
            text: Vue.i18n.translate('elementsBlockTranslation.typeDetails'),
            value: "details"
          },
          {
            text: Vue.i18n.translate('elementsBlockTranslation.typeBoth'),
            value: "both"
          }
        ]
      }else{
        return [
          {
            text: Vue.i18n.translate('elementsBlockTranslation.none'),
            value: "none"
          },
          {
            text: Vue.i18n.translate('elementsBlockTranslation.typeDetails'),
            value: "details"
          },
        ]
      }
    },

    selectedTypeAsString: function(){
      if(this.propsObj.type === "conditional"){
        return Vue.i18n.translate('elementsBlockTranslation.typeConditional')
      }else if(this.propsObj.type === "details"){
        return Vue.i18n.translate('elementsBlockTranslation.typeDetails')
      }else if(this.propsObj.type === "both"){
        return Vue.i18n.translate('elementsBlockTranslation.typeBoth')
      }else if(this.propsObj.type === "none"){
        return Vue.i18n.translate('elementsBlockTranslation.typeNone')
      }else {
        return ""
      }
    },

    repetitionText: function(){
      if(!this.propsObj.thenBlock.repeat.question){
        return Vue.i18n.translate('elementsBlockTranslation.constant') + " " + this.propsObj.thenBlock.repeat.data
      }else{
        var string = Vue.i18n.translate('elementsBlockTranslation.dependingOnQuestion') + " ";
        if(this.propsObj.thenBlock.repeat.data != "" && this.getQuestionLabelList[this.propsObj.thenBlock.repeat.data] != 0){
          string += this.getQuestionLabelList[this.propsObj.thenBlock.repeat.data].label;
        }
        return string
      }
    },

    valueListForSelectedLabel: function(){
      return (this.propsObj.condition.questionLabel != "" && this.getQuestionLabelList[this.propsObj.condition.questionLabel] != 0) ?
        this.getQuestionLabelList[this.propsObj.condition.questionLabel].value : []
    },

    complexConditionAsString: function(){
      return Vue.i18n.translate('conditionEditorTranslation.conditionString') +
        this.treeToString(this.propsObj.condition, this.getQuestionLabelList, true);
    },

    childIds: function(){
      var ids = [];
      for(var elem in this.propsObj.elements){
        ids.push(Number(this.propsObj.elements[elem].propsObj.id))
      }
      return ids
    },

    structureChangesAllowed: function() {
      return this.getIsEditable && this.allowStructureChanges;
    },

    disableInputs: function() {
      return !this.getIsEditable || this.allowStructureChanges === false;
    },
  },

  watch:{
    //needs deep:true & handler because array in array changes -> 'normal' watch function does not detect changes in nested array
    getQuestionLabelList: {
      handler: function(newVal, oldVal){
        if(this.propsObj.condition != ""){
          this.$emit('change-element', {'type': 'attribute', 'name': 'change-condition', 'oldVal': oldVal, 'newVal': newVal});
        }

        if(this.propsObj.thenBlock.repeat.question){
          if(newVal[this.propsObj.thenBlock.repeat.data] != 0){
            if(newVal[this.propsObj.thenBlock.repeat.data].type != "slider"){
              this.$emit('change-element', {'type': 'attribute', 'name': 'repeatData', 'value': ""});
            }
          }else{
            this.$emit('change-element', {'type': 'attribute', 'name': 'repeatData', 'value': ""});
          }
        }
      },
      deep: true
    },
  },

  created(){
    //only if block has id that is specified in data
    if(this.getConditionEditorData != "" && this.getConditionEditorData.pos === this.propsObj.id){
      var data = this.getConditionEditorData.condition;
      this.SET_CONDITIONEDITORDATA("");
      this.$emit('change-element', {'type': 'attribute', 'name': 'set-condition', 'value': data});
    }
  },

  methods:{
    ...mapMutations([
      'SET_CONDITIONEDITOR',
      'SET_CONDITIONEDITORDATA',
      'SET_FORMELEMENTSCHANGED',
      'SET_ALLELEMLOCALESCHANGED',
    ]),

    openConditionEditor: function(){
      this.SET_CONDITIONEDITOR(true);
      this.SET_CONDITIONEDITORDATA({ "pos": this.propsObj.id, "condition": this.propsObj.condition });
    },

    changeConditionAttribute: function(obj){
      this.$emit('change-element', {'type': 'attribute', 'name': "condition-" + obj.name, 'value': obj.value});
    },

    moveElementDragDrop: function(){
      this.allElementsChanged();
    },

    allElementsChanged: function() {
      this.SET_FORMELEMENTSCHANGED(true);
      this.SET_ALLELEMLOCALESCHANGED(true);
    },

    addNewElementFromSide: function(evt){
      //added from side; else dragged from somewhere else
      if(this.addElementsButtons(true, this.isSkill)[evt.oldIndex]){
        var type = this.addElementsButtons(true, this.isSkill)[evt.oldIndex].elementType;
        this.$emit('change-element', {'type': 'add-entry-to-labellist', 'value': type});
      }
    },

    //called when element dropped to different position (return false cancels move)
    checkMoveElement: function(evt){
      //element can not be dragged to position of page element
      return (!evt.relatedContext.element || evt.relatedContext.element.type != "ElementsPage")
    },

    labelForTextInLanguage: function(isTranslation){
      return Vue.i18n.translate('elementsBlockTranslation.textForDetails' + (isTranslation ? 'Translation' : ''))
    },

    changeNestedElement: function(evt, element, index){
      //if element is move to different level we also need the parent of the parent if nested & moved out of block
      if(evt.type === "change-positions" && evt.changeLevel && !evt.parentOfParent && evt.parent && evt.element){
        evt.parentOfParent = element;
      }

      //this function is first called for inner element which for example should be deleted -> we need the parent
      // -> delete is called on parent
      if(!evt.parent && evt.element){
        evt.parent = element;
        evt.parentIndex = index;
      }

      if(!evt.element){
        evt.element = element;
      }
      this.$emit('change-element', evt);
    }
  }
}
</script>
