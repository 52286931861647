<template>
  <div class="skillEditor">
    <section v-show="!loadingPage">
      <BaseMenuBar
        :nameList="[studyDetails.name, interventionDetails.name, skillDetails.name]"
        :idList="[studyId, interventionId]"
      ></BaseMenuBar>

      <BaseTabs
        :tabDetails="tabDetails"
        :selectedView="selectedView"
        @show-view="showView"
      ></BaseTabs>

      <BaseSelectLanguage
        v-if="selectedView != 2 && skillDetails.locales"
        :localesArray="skillDetails.locales"
        :selectedLanguage2="selectedLanguageForTranslation"
        :isTranslationMode="isTranslationMode"
        @close-notification="closeNotification"
        @change-language2="changeLanguage2"
        @change-translationmode="changeTranslationMode"
      ></BaseSelectLanguage>

      <section v-show="selectedView === 0" :id="skillDetailsFormId" class="wrapper">
        <button type="submit" disabled style="display: none"></button>
        <section v-show="!loadingLanguage">
          <!-- normal language (left) -->
          <section :class="{col: isTranslationMode, sec50:isTranslationMode}">
            <SkillEditorDetails
              :skillDetails="skillDetails"
              :translation="currentTranslation"
              :isTranslation="false"
              :isTranslationMode="isTranslationMode"
              :selectedLanguageForTranslation="selectedLanguageForTranslation"
              :studyId="studyId"
              @close-notification="closeNotification"
              @change-attribute="changeAttributeForDetails"
              @show-modal="showModal"
            ></SkillEditorDetails>
          </section>

          <!-- translation language (right) -->
          <section :class="{col: isTranslationMode, sec50:isTranslationMode}">
            <SkillEditorDetails
              v-if="isTranslationMode && getIsEditable"
              :skillDetails="skillDetails"
              :translation="currentTranslation2"
              :isTranslation="true"
              :isTranslationMode="isTranslationMode"
              :selectedLanguageForTranslation="selectedLanguageForTranslation"
              :studyId="studyId"
              @close-notification="closeNotification"
              @change-attribute="changeAttributeForDetails"
              @show-modal="showModal"
            ></SkillEditorDetails>
          </section>

          <section v-if="getIsEditable" class="wrapper">
            <button type="submit" id="buttonSaveSkillDetails" :disabled="isSaved || !formChanged" @click="saveSkillDetails">
              {{ saveSkillDetailsText }}
            </button>
            <button
              id="structureLockButtonSkill"
              :disabled="!getIsOwner || structureLockLoading"
              class="structureLockButton"
              v-tooltip="disabledStructureLockText"
              @click="structureLockModalVisible = true"
            >
              <span v-if="skillDetails.structure_lock">
                <i class="fa fa-lock"></i>
                {{ $t("interventionTranslation.lockedStructureSkill") }}
              </span>
              <span v-else>
                <i class="fa fa-lock-open"></i>
                {{ $t("interventionTranslation.unlockedStructureSkill") }}
              </span>
            </button>
            <i
              class="far fa-question-circle"
              v-tooltip="{ content: structureLockTooltip, theme: 'info-tooltip' }"
            ></i>
          </section>
        </section>

        <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loadingLanguage"></i>
      </section>

      <section v-if="!loadingElements" id="lessonElements">
        <LessonEditorElements
          v-show="selectedView === 1"
          :editorModalsVisible="warningChangesVisible"
          :lessonDetails="skillDetailsComplete"
          :interventionDetails="interventionDetails"
          :formChanged="formChanged"
          :isTranslationMode="isTranslationMode"
          :selectedLanguageForTranslation="selectedLanguageForTranslation"
          :isDiary="false"
          :isSkill="true"
          :studyId="studyId"
          :currentPageLessonElements="currentPageSkillElements"
          @close-notification="closeNotification"
          @show-modal="showModal"
          @route-to="routeTo"
        ></LessonEditorElements>
      </section>

      <section v-show="selectedView === 2" id="translationStatus" class="wrapper"> <!-- todo skill translation status -->
        <div v-if="skillDetails.translation_status != null" key="progressDiv">
          <section v-for="language in Object.keys(skillDetails.translation_status)" :key="language">
            <section class="wrapper">
              <section class="col sec3">
                {{language}}
              </section>
              <section class="col sec25">
                <progress :value="skillDetails.translation_status[language]" max="1"></progress>
              </section>
            </section>
          </section>
        </div>
        <div v-else key="noProgressDiv">
          {{ 'interventionTranslation.noProgress' | translate }}
        </div>
      </section>
    </section>
    <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loadingPage"></i>

    <BaseModal
      v-if="warningChangesVisible"
      id="changesModal"
      :bodyText="warningText"
      :isLeaveSiteWarning="true"
      :leftButtonText="$t('interventionTranslation.leave')"
      @close-modal="resolvePromise"
    ></BaseModal>

    <BaseModal
      v-if="deleteLanguageModalVisible"
      id="deleteSkillLanguageModal"
      :headerText="deleteLanguageHeaderText"
      :bodyText="deleteLanguageText"
      :leftButtonText="$t('interventionTranslation.ok')"
      @close-modal="deleteLanguageModal"
    ></BaseModal>

    <BaseModal
      v-if="structureLockModalVisible"
      id="structureLockModal"
      class="structureLockModal"
      :headerText="$t('interventionTranslation.structureLockModalHeaderSkill')"
      :leftButtonText="$t('generalTranslation.yes')"
      @close-modal="changeStructureLock"
    >
      <template v-slot:body>
        <div v-if="skillDetails.structure_lock">
          <label>{{ $t("interventionTranslation.structureLockModalLockedSkill") }}</label>
          <p>{{ $t("interventionTranslation.structureLockModalLockedInfoSkill") }}</p>
        </div>
        <div v-else>
          <label>{{ $t("interventionTranslation.structureLockModalUnlockedSkill") }}</label>
          <p>{{ $t("interventionTranslation.structureLockModalUnlockedInfoSkill") }}</p>
        </div>
        <p>{{ $t("interventionTranslation.structureLockModalChangesSkill") }}</p>
      </template>
    </BaseModal>

    <BaseNotification ref="notificationRef"></BaseNotification>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import router from '../router';
import BaseMenuBar from './BaseMenuBar.vue';
import BaseTabs from './BaseTabs.vue';
import BaseNotification from './BaseNotification.vue';
import BaseModal from './BaseModal.vue';
import BaseSelectLanguage from './BaseSelectLanguage.vue';
import SkillEditorDetails from './SkillEditorDetails.vue';
import LessonEditorElements from './LessonEditorElements.vue';
import httpHelper from '../mixins/httpHelper';
import utils from '../mixins/utils';

export default {
  name: 'SkillEditor',

  components: {
    BaseMenuBar,
    BaseTabs,
    BaseNotification,
    BaseModal,
    BaseSelectLanguage,
    SkillEditorDetails,
    LessonEditorElements
  },

  mixins: [httpHelper, utils],

  props: ['view'],

  data: function(){
    return {
      selectedView: 1,
      warningChangesVisible: false,
      skillDetails: [],
      currentTranslation: "",
      currentTranslation2: "",
      isSaved: false,
      nextRouteName: '',
      loadingLanguage: true,
      otherModalVisible: false,
      selectedLanguageForTranslation: "",
      formChanged: false, //true if skill details changed by user & not yet saved
      isTranslationMode: false,
      skillDetailsComplete: [],
      interventionDetails: [],
      selectMediaVisible: false,
      studyDetails: [],
      loadingPage: true,
      loadingElements: true,
      copyElementsBool: false,
      resolvePromise: null,
      rejectPromise: null,
      currentPageSkillElements: 1,
      skillDetailsFormId: "skillDetailsForm",
      deleteLanguage: "",
      deleteLanguageModalVisible: false,
      structureLockModalVisible: false,
      structureLockLoading: false,
    }
  },

  computed: {
    ...mapGetters([
      "getSelectedLanguage",
      "getMyRoleForStudy",
      "getPageRefreshBool",
      "getNotificationText",
      "getFormElementsChanged",
      "getConditionEditor",
      "getPageRefreshDetails",
      "getUserId",
      "getIsEditable",
      "getAllDetailsLocalesChanged",
      "getChangedDetailsLocales",
      "getIsOwner",
    ]),

    deleteLanguageText: function() {
      return Vue.i18n.translate('lessonTranslation.deleteLanguageConfirm', { language: this.deleteLanguage });
    },

    deleteLanguageHeaderText: function() {
      return Vue.i18n.translate('lessonTranslation.deleteLanguageConfirmHeader');
    },

    changedLocales: function() {
      const newChangedLocales = [];
      for (const l in this.skillDetailsComplete.locales) {
        const locale = this.skillDetailsComplete.locales[l];
        if (this.getChangedDetailsLocales.includes(locale)) {
          newChangedLocales.push(locale);
        }
      }
      return newChangedLocales;
    },

    disabledStructureLockText: function() {
      return !this.getIsOwner ?
        Vue.i18n.translate("interventionTranslation.structureLockButtonDisabled") :
        "";
    },

    structureLockTooltip: function() {
      return this.skillDetails.structure_lock ?
        Vue.i18n.translate("interventionTranslation.structureLockModalLockedInfoSkill") :
        Vue.i18n.translate("interventionTranslation.structureLockModalUnlockedInfoSkill");
    },

    saveSkillDetailsText: function() {
      if (this.changedLocales.length > 0) {
        const languages = `(${this.changedLocales.join(", ")})`;
        return Vue.i18n.translate("generalTranslation.saveLocales", { locales: languages });
      } else {
        return Vue.i18n.translate("generalTranslation.save");
      }
    },

    studyId: function() {
      return Number(this.interventionDetails.study_id);
    },

    interventionId: function() {
      return Number(this.skillDetails.intervention_id);
    },

    skillId: function() {
      return Number(this.$route.params.skillId);
    },

    warningText: function() {
      let string = Vue.i18n.translate("generalTranslation.unsavedChanges") + ": ";
      string += this.formChanged ? Vue.i18n.translate("interventionTranslation.skillDetails") : "";
      if (this.getFormElementsChanged) {
        string += (this.formChanged ? ", " : "") + Vue.i18n.translate("interventionTranslation.skillDescription");
      }
      return string;
    },

    tabDetails: function() {
      return [
        { text: Vue.i18n.translate("interventionTranslation.skillDetails"), show: true, id: "details" },
        { text: Vue.i18n.translate("interventionTranslation.skillDescription"), show: true, id: "elements" },
        { text: Vue.i18n.translate("interventionTranslation.translationProgress"), show: true, id: "progress" },
      ];
    },
  },

  watch: {
    //todo copy skill elements in other skill
    // '$route.params.lessonId': function(){
    //   if(this.copyElementsBool){
    //     //get new data after copy elements in different lesson
    //     this.copyElementsBool = false;
    //     this.loadingPage = true;
    //     this.loadingLanguage = true;
    //     this.loadingElements = false;
    //     this.SET_FORMELEMENTSCHANGED(true);
    //     this.getDetailsOfLesson(true);
    //   }else{
    //     //get new data when going back from lesson where elements were copied to to lesson where elements where copied from
    //     this.loadingPage = true;
    //     this.loadingLanguage = true;
    //     this.loadingElements = true;
    //     this.getElementsAndDetails();
    //   }
    // },
    formChanged(newVal) {
      if (!newVal) {
        this.clearChangedDetailsLocales();
      }
    },

    getNotificationText(newVal) {
      if (newVal == "") {
        return;
      }
      if (newVal.type === "error") {
        this.isSaved = false;
        this.loadingLanguage = false;
      } else if (newVal.type === "success") {
        this.isSaved = false;
      }
    },

    getSelectedLanguage(){
      this.setCurrentTranslations(false);
    },

    selectedLanguageForTranslation(newVal){
      if (newVal != "") {
        this.setCurrentTranslations(true);
      } else if (this.currentTranslation2 != "") {
        this.addTranslation(this.skillDetails.translations, this.currentTranslation2);
        this.currentTranslation2 = "";
      }
    },
  },

  mounted(){
    if(this.getNotificationText.text != Vue.i18n.translate('interventionTranslation.createSkillSuccess')){
      this.closeNotification();
    }

    this.SET_PREVIEWANSWERS([]);

    if(this.getPageRefreshBool && this.getPageRefreshDetails.id === this.skillId){
      this.SET_CONDITIONEDITOR(false);
      this.skillDetails = this.getPageRefreshDetails.skillDetails;
      this.skillDetailsComplete = JSON.parse(JSON.stringify(this.skillDetails));
      this.interventionDetails = this.getPageRefreshDetails.interventionDetails;
      this.studyDetails = this.getPageRefreshDetails.studyDetails;
      this.formChanged = this.getPageRefreshDetails.formChanged;
      this.loadingElements = false;
      this.init();
      this.loadingPage = false;
      this.selectedView = this.getPageRefreshDetails.view;
      this.currentPageSkillElements = this.getPageRefreshDetails.currentPage
    }else{
      if(this.view != undefined){
        this.selectedView = this.view;
      }
      this.getElementsAndDetails();
    }
    this.SET_CONDITIONEDITOR(false);
    this.SET_PAGEREFRESHBOOL(false);
    this.SET_PAGEREFRESHDETAILS([]);

    window.addEventListener('beforeunload', this.beforeunloadFunction);
    window.addEventListener('unload', this.unloadFunction);

    if(document.getElementById(this.skillDetailsFormId) != null){
      document.getElementById(this.skillDetailsFormId).addEventListener("input", this.changeFormHasChanged);
    }
  },

  beforeRouteUpdate(to, from, next) {
    this.nextRouteName = to.name;
    if(to.name === from.name && !this.copyElementsBool && (this.formChanged || this.getFormElementsChanged)){
      var self = this;
      this.showWarningModal()
      .then(ok => {
        self.closeModal();
        if(ok){
          self.formChanged = false;
          self.clearChangedDetailsLocales();
          self.SET_FORMELEMENTSCHANGED(false);
          next();
        }else{
          next(false);
        }
      });
    }else{
      next();
    }
  },

  beforeRouteLeave(to, _from, next){
    this.nextRouteName = to.name;
    this.SET_PAGEREFRESHBOOL(false);
    this.SET_PAGEREFRESHDETAILS([]);
    if(this.otherModalVisible || this.selectMediaVisible || to.name === "conditionEditor"){
      next(false);
    }else if(this.formChanged || this.getFormElementsChanged){
      var self = this;
      this.showWarningModal()
      .then(ok => {
        self.closeModal();
        if(ok){
          if(to.params.oldSkillId === undefined && to.name === "interventionEditor"){
            to.params.oldSkillId = self.skillId;
          }
          self.formChanged = false;
          self.clearChangedDetailsLocales();
          self.SET_QUESTIONLABELLIST([]);
          self.SET_FORMELEMENTSCHANGED(false);
          next();
        }else{
          next(false);
        }
      });
    }else{
      if(to.params.oldSkillId === undefined && to.name === "interventionEditor"){
        to.params.oldSkillId = this.skillId;
      }
      this.formChanged = false;
      this.clearChangedDetailsLocales();
      this.SET_QUESTIONLABELLIST([]);
      this.SET_FORMELEMENTSCHANGED(false);
      next();
    }
  },

  beforeDestroy(){
    if(document.getElementById(this.skillDetailsFormId)){
      document.getElementById(this.skillDetailsFormId).removeEventListener("input", this.changeFormHasChanged);
    }
    window.removeEventListener('beforeunload', this.beforeunloadFunction);
    window.removeEventListener('unload', this.unloadFunction);
  },

  methods:{
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
      'SET_SELECTEDLANGUAGE',
      'SET_PREVIEWANSWERS',
      'SET_PAGEREFRESHBOOL',
      'SET_FORMELEMENTSCHANGED',
      'SET_QUESTIONLABELLIST',
      'SET_PAGEREFRESHDETAILS',
      'SET_LESSONELEMENTSARR',
      'SET_CONDITIONEDITOR',
      'SET_ALLDETAILSLOCALESCHANGED',
      'CLEAR_CHANGEDDETAILSLOCALES',
      'ADD_CHANGEDDETAILSLOCALE',
    ]),

    deleteLanguageModal(done) {
      if (done) {
        const locales = this.skillDetails.locales.filter(l => l !== this.deleteLanguage);
        this.skillDetails.locales = locales;
        this.skillDetailsComplete.locales = locales;
        if (!this.getAllDetailsLocalesChanged) {
          this.SET_ALLDETAILSLOCALESCHANGED(true);
        }
        this.formChanged = true;
      }
      this.deleteLanguageModalVisible = false;
    },

    clearChangedDetailsLocales: function() {
      this.SET_ALLDETAILSLOCALESCHANGED(false);
      this.CLEAR_CHANGEDDETAILSLOCALES();
    },

    showWarningModal: function(){
      return new Promise((resolve, reject) => {
        this.warningChangesVisible = true;
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      })
    },

    getElementsAndDetails: function(){
      var self = this;
      this.requestSkillElements(this.skillId, false)
      .then(function (response){
        self.SET_LESSONELEMENTSARR(response);
        self.loadingElements = false;
        self.getDetailsOfSkill(true);
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.getElementsAndDetails() }, "studyDetails");
      });
    },

    getDetailsOfSkill: function(initBool) {
      const self = this;
      this.requestSkillDetails(this.skillId)
        .then(function(response) {
          self.skillDetails = response;
          if (initBool) {
            self.init();
            self.getInterventionAndStudy();
          } else {
            self.setCurrentTranslations(false);
            if (self.selectedLanguageForTranslation != "") {
              self.setCurrentTranslations(true);
            }
          }
          self.skillDetailsComplete = JSON.parse(JSON.stringify(self.skillDetails));
        })
        .catch(function(error) {
          self.handleErrors(error, function() { self.getDetailsOfSkill(initBool) }, "studyDetails");
        });
    },

    getInterventionAndStudy: function(){
      const self = this;
      this.requestInterventionDetails(this.interventionId)
        .then(function(response) {
          self.interventionDetails = response;
          return self.requestCollaboratorsIncludingPermissions("page=0&id=" + self.getUserId, self.studyId, false, -1, -1);
        })
        .then(function() {
          return self.requestStudyDetails(self.studyId);
        })
        .then(function(response) {
          self.studyDetails = response;
          self.loadingPage = false;
        })
        .catch(function(error) {
          self.handleErrors(error, function() { self.getInterventionAndStudy() }, "");
        });
    },

    init: function() {
      if (!this.skillDetails.locales || this.skillDetails.locales.length === 0) {
        this.skillDetails.locales = ["de", "en"];
      }
      if (!this.skillDetails.locales.includes(this.getSelectedLanguage)) {
        const language = this.skillDetails.locales.includes("de") ? "de" : this.skillDetails.locales[0];
        this.SET_SELECTEDLANGUAGE(language);
      }
      this.selectedLanguageForTranslation = "";
      this.isTranslationMode = false;
      this.setCurrentTranslations(false);
    },

    showModal: function(obj){
      switch (obj.type) {
        case "StudyMedia":
          this.selectMediaVisible = obj.value;
          break;
        case "LanguageError":
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('interventionTranslation.deleteSelectedLanguageWarning')});
          break;
        case "LessonPreview":
          this.setUpdatedSkillDetailsForPreview();
          break;
        case "any":
          this.otherModalVisible = obj.value;
          break;
        case "DeleteLanguage":
          this.deleteLanguage = obj.deleteLanguage;
          this.deleteLanguageModalVisible = true;
          break;
        default:
          break;
      }
    },

    changeLanguage2: function(language){
      this.selectedLanguageForTranslation = language;
    },

    changeTranslationMode: function(isTranslationMode){
      this.isTranslationMode = isTranslationMode;
    },

    showView: function(view){
      this.closeNotification();
      this.selectedView = view;
    },

    changeAttributeForDetails: function(obj) {
      this.closeNotification();
      if (obj.name === "title") {
        if (!obj.isTranslation) {
          this.currentTranslation[obj.name] = obj.value;
        } else {
          this.currentTranslation2[obj.name] = obj.value;
        }
        const locale = !obj.isTranslation ? this.getSelectedLanguage : this.selectedLanguageForTranslation;
        this.ADD_CHANGEDDETAILSLOCALE(locale);
      } else {
        if (obj.name === "AddLanguage") {
          const language = obj.value;
          this.addLanguage(language);
          this.ADD_CHANGEDDETAILSLOCALE(language);
          this.SET_SELECTEDLANGUAGE(language);
        } else {
          this.skillDetails[obj.name] = obj.value;
          this.skillDetailsComplete[obj.name] = obj.value;
        }
        if (!this.getAllDetailsLocalesChanged) {
          this.SET_ALLDETAILSLOCALESCHANGED(true);
        }
      }
      this.formChanged = true;
    },

    changeStructureLock: function(done) {
      if (done) {
        this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("interventionTranslation.structureLockModalLoading") });
        this.structureLockLoading = true;
        const lock = !this.skillDetails.structure_lock;
        const self = this;
        this.updateSkillStructureLock(this.skillId, lock).then(
          function() {
            self.skillDetails.structure_lock = lock;
            self.skillDetailsComplete.structure_lock = lock;
            self.structureLockLoading = false;
            self.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("interventionTranslation.structureLockModalSuccess") });
          }
        ).catch(
          function(error) {
            self.handleErrors(error, function() { self.changeStructureLock(done) }, "StructureLock");
            self.structureLockLoading = false;
          }
        );
      }
      this.structureLockModalVisible = false;
    },

    addLanguage(language) {
      const locales = JSON.parse(JSON.stringify(this.skillDetails.locales));
      locales.push(language);
      locales.sort();
      this.skillDetails.locales = locales;
      this.skillDetailsComplete.locales = locales;
    },

    changeFormHasChanged: function(){
      this.closeNotification();
      this.formChanged = true;
    },

    routeTo: function(obj) {
      //todo copy skill elements in other skill
      if (obj.name === "skillEditor") {
        //route changes -> get new skillDetails, interventionDetails, studyDetails
        //permissions and elements already set
        //in route.params.skillId watch function
        this.copyElementsBool = true;
      }
      router.push(obj);
    },

    getSkillDetailsErrorText: function() {
      let errorText = "";
      const lengthErrors = {
        name: false,
        title: [],
      };
      if (!this.skillDetails.name) {
        errorText += Vue.i18n.translate("generalTranslation.name");
      } else {
        lengthErrors.name = this.skillDetails.name.length > 255;
      }
      if (!this.currentTranslation.title) {
        errorText += ((errorText !== "") ? ", " : "") + Vue.i18n.translate("interventionTranslation.title");
      } else if (this.currentTranslation.title.length > 255) {
        lengthErrors.title.push(this.getSelectedLanguage);
      }
      if (this.currentTranslation2 !== "") {
        if (!this.currentTranslation2.title) {
          errorText += ((errorText !== "") ? ", " : "") + Vue.i18n.translate("interventionTranslation.titleTranslation");
        } else if (this.currentTranslation2.title.length > 255) {
          lengthErrors.title.push(this.selectedLanguageForTranslation);
        }
      }
      if (errorText.length > 0) {
        errorText = Vue.i18n.translate("adminUsersTranslation.errorFillIn") + errorText;
      }

      for (const t in this.skillDetails.translations) {
        const translation = this.skillDetails.translations[t];
        const locale = translation.locale;
        if (!this.skillDetails.locales.includes(locale)) continue;
        if (!lengthErrors.title.includes(locale)) {
          if (translation.title && translation.title.length > 255) {
            lengthErrors.title.push(locale);
          }
        }
      }
      let needsPrefix = errorText.length > 0;
      if (lengthErrors.name) {
        errorText += (needsPrefix ? "; " : "") + Vue.i18n.translate("interventionTranslation.nameTooLong");
        needsPrefix = true;
      }
      if (lengthErrors.title.length > 0) {
        lengthErrors.title.sort();
        const locales = " (" + lengthErrors.title.join(", ") + ")";
        errorText += (needsPrefix ? "; " : "") + Vue.i18n.translate("interventionTranslation.titleTooLong") + locales;
        needsPrefix = true;
      }
      return errorText;
    },

    saveSkillDetails: function() {
      const errorText = this.getSkillDetailsErrorText();
      if (errorText.length > 0) {
        this.SET_NOTIFICATIONTEXT({ type: "error", text: errorText });
      } else {
        this.isSaved = true;
        this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("interventionTranslation.updateSkillDetailsLoad") });

        const details = JSON.parse(JSON.stringify(this.skillDetails));
        if (!details.color) {
          details.color = "#15417e";
        }
        //all translations in translations array
        if (this.currentTranslation !== "") {
          this.addTranslation(details.translations, this.currentTranslation);
        }
        if (this.currentTranslation2 !== "") {
          this.addTranslation(details.translations, this.currentTranslation2);
        }
        details.translations = details.translations.filter(t => this.changedLocales.includes(t.locale));
        this.updateDetails(details);
      }
    },

    updateDetails: async function(details) {
      this.updateSkillDetailsRequest(details, this.skillId)
        .then(() => {
          this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("interventionTranslation.updateSkillDetailsSuccess") });
          this.formChanged = false;
          this.getDetailsOfSkill(false);
        })
        .catch((error) => {
          this.handleErrors(error, () => this.updateDetails(details), "");
        });
    },

    setCurrentTranslations: function(isTranslation){
      const language = !isTranslation ? this.getSelectedLanguage : this.selectedLanguageForTranslation;

      if (!this.skillDetails.translations) {
        this.skillDetails.translations = [];
      }

      if (this.selectedLanguageForTranslation === "" && this.currentTranslation2 != "") {
        this.addTranslation(this.skillDetails.translations, this.currentTranslation2);
        this.currentTranslation2 = "";
      }

      let translation = "";
      const index = this.skillDetails.translations.findIndex(elem => elem.locale === language);
      if (index != -1) {
        translation = this.skillDetails.translations[index];
        this.skillDetails.translations.splice(index, 1);
      } else if (language != "") {
        translation = { locale: language, title: "" };
      }

      if (!isTranslation) {
        if (this.currentTranslation != "") {
          this.addTranslation(this.skillDetails.translations, this.currentTranslation);
        }
        if (translation != "") {
          this.currentTranslation = translation;
        }
      } else {
        if (this.currentTranslation2 != "") {
          this.addTranslation(this.skillDetails.translations, this.currentTranslation2);
        }
        if (translation != "" && this.selectedLanguageForTranslation != "") {
          this.currentTranslation2 = translation;
        }
      }
      this.loadingLanguage = false;
    },

    unloadFunction: function(){
      this.SET_PAGEREFRESHBOOL(true);
      this.closeNotification();
      this.isTranslationMode = false;
      this.currentPageSkillElements = 1;
      var data = this.setRefreshData();
      this.SET_PAGEREFRESHDETAILS(data);
    },

    setRefreshData: function(){
      //all translations in translations array
      const skillDetailsArr = JSON.parse(JSON.stringify(this.skillDetails));
      if (this.currentTranslation) {
        this.addTranslation(skillDetailsArr.translations, this.currentTranslation);
        this.currentTranslation = "";
      }
      if (this.currentTranslation2) {
        this.addTranslation(skillDetailsArr.translations, this.currentTranslation2);
        this.currentTranslation2 = "";
      }
      const data = {
        id: this.skillId,
        skillDetails: skillDetailsArr,
        interventionDetails: this.interventionDetails,
        studyDetails: this.studyDetails,
        view: this.selectedView,
        formChanged: this.formChanged,
        currentPage: this.currentPageSkillElements
      };
      return data
    },

    beforeunloadFunction: function(e){
      if(this.formChanged || this.getFormElementsChanged){
        e.preventDefault();
        e.returnValue = '';
        return;
      }
      delete e['returnValue'];
    },

    setUpdatedSkillDetailsForPreview: function(){
      this.closeNotification();
      const skillDetailsArr = JSON.parse(JSON.stringify(this.skillDetails));
      if(this.currentTranslation){
        const translation = JSON.parse(JSON.stringify(this.currentTranslation));
        this.addTranslation(skillDetailsArr.translations, translation);
      }
      if(this.currentTranslation2){
        const translation = JSON.parse(JSON.stringify(this.currentTranslation2));
        this.addTranslation(skillDetailsArr.translations, translation);
      }
      this.skillDetailsComplete = skillDetailsArr;
    },

    closeModal: function(){
      this.warningChangesVisible = false;
    },

    closeNotification: function(){
      this.$refs["notificationRef"].closeNotification();
    },
  }
}
</script>
