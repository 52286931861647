class Node {
  // is array with at least 1 other Node or false (= no/valid dependencies)
  dependentOn;

  constructor(dependentOn) {
    this.dependentOn = dependentOn;
  }
}

const LESSON_PREFIX = "l_";
const DIARY_PREFIX = "d_";

const SUCCESS = 0;
const LESSON_OR_DIARY_NOT_FOUND = 1;
const AFTER_PREVIOUS_FAILED = 2;
const CYCLE = 3;
const ALL_DEPENDENCIES_INVALID = 4;

export class DependencyGraph {
  lessonConfigs;
  customOrder;
  unlockedDiares;

  lessonIds;
  graph;

  constructor(lessonConfigs, customOrder, unlockedDiares) {
    this.lessonConfigs = lessonConfigs;
    this.customOrder = customOrder;
    this.unlockedDiares = unlockedDiares;
    this.lessonIds = [];
    this.graph = new Map();
    for (const d in unlockedDiares.int) {
      const diaryId = DIARY_PREFIX + unlockedDiares.int[d];
      const node = new Node(false);
      this.graph.set(diaryId, node);
    }
    for (let i = 0; i < customOrder.length; i++) {
      const lesson = lessonConfigs.find(l => l.id == customOrder[i]);
      const lessonId = LESSON_PREFIX + lesson.id;
      this.lessonIds.push(lessonId);
      let node;
      switch (lesson.unlock_type) {
        case "conditional": {
          const dependsOn = [];
          for (const c in lesson.condition) {
            const condition = lesson.condition[c];
            if (condition.diary_id != null && condition.diaryId != "") {
              dependsOn.push(DIARY_PREFIX + condition.diary_id);
            } else {
              dependsOn.push(LESSON_PREFIX + condition.questionnaire_id);
            }
          }
          node = new Node(dependsOn);
          break;
        }
        case "after_previous": {
          const prevLessonId = customOrder[i - 1];
          node = prevLessonId !== undefined ? new Node([LESSON_PREFIX + prevLessonId]) : null;
          const diaries = unlockedDiares[lesson.id];
          for (const d in diaries) {
            const diary = DIARY_PREFIX + diaries[d];
            const depending = this.graph.get(diary);
            if (depending === undefined) {
              const d_node = new Node([lessonId]);
              this.graph.set(diary, d_node);
            } else if (depending.dependentOn) {
              depending.dependentOn.push(lessonId);
            }
          }
          break;
        }
        default: { // Lesson is guaranteed to be unlocked
          node = new Node(false);
          const diariesIds = unlockedDiares[lesson.id];
          for (const d in diariesIds) {
            const diaryId = DIARY_PREFIX + diariesIds[d];
            const depending = this.graph.get(diaryId);
            if (depending === undefined) {
              const d_node = new Node(false);
              this.graph.set(diaryId, d_node);
            } else {
              depending.dependentOn = false;
            }
          }
        }
      }
      this.graph.set(lessonId, node);
    }
  }

  static check(lessons, customOrder, unlockedDiares) {
    const graph = new DependencyGraph(lessons, customOrder, unlockedDiares);
    return graph.validate() ?? graph.validateComplete();
  }

  validate() {
    for (const lessonId of this.lessonIds) {
      const result = this.validateLesson(lessonId);
      if (result !== SUCCESS) {
        let text;
        switch (result) {
          case LESSON_OR_DIARY_NOT_FOUND:
            text = "interventionTranslation.errorConfigNotFoundLesson";
            break;
          case AFTER_PREVIOUS_FAILED:
            text = "interventionTranslation.errorConfigAfterPreviousFirst";
            break;
          case CYCLE:
            text = "interventionTranslation.errorConfigCycle";
            break;
          default:
            text = "interventionTranslation.errorConfigInvalidConditions";
        }
        const id = Number(lessonId.slice(LESSON_PREFIX.length));
        return { id, text };
      }
    }
    return undefined;
  }

  validateComplete() {
    const nonDefaultLessons = this.lessonConfigs.filter(l => !this.customOrder.some(id => l.id == id));
    for (const lesson of nonDefaultLessons) {
      const lessonId = LESSON_PREFIX + lesson.id;
      let node;
      switch (lesson.unlock_type) {
        case "conditional": {
          const dependsOn = [];
          for (const c in lesson.condition) {
            const condition = lesson.condition[c];
            if (condition.diary_id != null && condition.diaryId != "") {
              dependsOn.push(DIARY_PREFIX + condition.diary_id);
            } else {
              dependsOn.push(LESSON_PREFIX + condition.questionnaire_id);
            }
          }
          node = new Node(dependsOn);
          break;
        }
        default: { // unlock_type "after_previous" is treated as always unlocked for non-default lessons
          node = new Node(false);
          const diariesIds = this.unlockedDiares[lesson.id];
          for (const d in diariesIds) {
            const diaryId = DIARY_PREFIX + diariesIds[d];
            const depending = this.graph.get(diaryId);
            if (depending === undefined) {
              const d_node = new Node(false);
              this.graph.set(diaryId, d_node);
            } else {
              depending.dependentOn = false;
            }
          }
        }
      }
      this.graph.set(lessonId, node);
    }
    for (const lesson of nonDefaultLessons) {
      const lessonId = LESSON_PREFIX + lesson.id;
      const result = this.validateLesson(lessonId);
      if (result !== SUCCESS) {
        let text;
        switch (result) {
          case LESSON_OR_DIARY_NOT_FOUND:
            text = "interventionTranslation.errorConfigNotFoundLesson";
            break;
          case AFTER_PREVIOUS_FAILED:
            text = "interventionTranslation.errorConfigAfterPreviousFirst";
            break;
          case CYCLE:
            text = "interventionTranslation.errorConfigCycle";
            break;
          default:
            text = "interventionTranslation.errorConfigInvalidConditions";
        }
        const id = Number(lesson.id);
        return { id, text };
      }
    }
    return undefined;
  }

  validateLesson(lessonId, tracking = []) {
    const node = this.graph.get(lessonId);
    if (node === undefined) { return LESSON_OR_DIARY_NOT_FOUND; }
    if (node === null) { return AFTER_PREVIOUS_FAILED; }
    if (!node.dependentOn) { return SUCCESS; }
    if (tracking.includes(lessonId)) { return CYCLE; }
    tracking.push(lessonId);
    const failures = [];
    for (const otherNode of node.dependentOn) {
      const result = this.validateLesson(otherNode, [...tracking]);
      if (result === SUCCESS) {
        node.dependentOn = false;
        return SUCCESS;
      } else {
        failures.push(result);
      }
    }
    if (failures.length > 0) {
      for (let i = 1; i < failures.length; i++) {
        if (failures[i-1] !== failures[i]) {
          return ALL_DEPENDENCIES_INVALID;
        }
      }
      return failures[0];
    } else {
      return ALL_DEPENDENCIES_INVALID;
    }
  }
}
