<template>
  <div class="adminUsers">
    <section class="wrapper">
      <section class="headlineClass">
        <h1>
          {{ 'adminUsersTranslation.manageUsers' | translate }}
        </h1>
      </section>
      <button type="button" v-if="!loading && selectedView === 0" id="buttonCreateAccount" class="createButton" @click="showCreateAccountModal">
        {{ 'adminUsersTranslation.createAccount' | translate }}
      </button>
    </section>

    <BaseTabs
      v-if="$enabledFeatures.includes('deletion-requests')"
      :tabDetails="tabDetails"
      :selectedView="selectedView"
      @show-view="showView"
    ></BaseTabs>

    <section class="smallerComp">
      <section v-show="!loading && selectedView === 0" id="sectionUsers">
        <BaseSearch
          id="searchUsers"
          type="admin-users"
          @get-new-list="getNewList"
          @reset-page="resetPage"
        ></BaseSearch>

        <BasePagination
          id="paginationUsersTop"
          :numberOfPages="userListData.allPages"
          :currentPage="userListData.currentPage"
          @new-page="getNextPage"
        ></BasePagination>

        <table v-if="userListData.list && userListData.list.length > 0" id="userList" class="permissionsTable">
          <tr v-for="(user, userIndex) in userListData.list" :id="'entryUser' + user.id" :key="user.id" class="wrapper interventionElement">
            <div class="tableFirstColumn">
              <label>
                {{user.firstname}} {{user.lastname}} ({{user.name}})
              </label>
              {{user.email}}
            </div>
            <template v-if="user.verified">
              <div v-for="role in rolesArray" :key="role" class="tableCell notBold">
                <label :class="{notSelectedRole: !user.role.includes(role)}">
                  {{ 'adminUsersTranslation.' + role | translate }}
                  <i class="fas" :class="{'fa-check': user.role.includes(role), 'fa-times': !user.role.includes(role)}" :id="'role' + role + user.id"></i>
                </label>
              </div>
            </template>
            <div v-else class="resendButtonSec">
              <button type="button" :id="'buttonResendVerification' + user.id" :disabled="isUpdated" @click="resendVerification(user.email)">
                {{ 'adminUsersTranslation.resendVerification' | translate }}
              </button>
            </div>
            <div class="adminButtons">
              <i
                class="fas fa-pen fa-lg"
                :id="'buttonEditUser' + user.id"
                v-tooltip="$t('adminUsersTranslation.edit')"
                :disabled="isUpdated"
                :style="{ visibility: showIfVerified(user.verified) }"
                @click="changeRolesOfUser(userIndex, user)"
              ></i>

              <i
                class="fa fa-trash fa-lg"
                :id="'buttonDeleteUser' + user.id"
                v-tooltip="$t('generalTranslation.delete')"
                :disabled="isUpdated"
                :style="{ visibility: showIfOtherUser(user.id) }"
                @click="showDeleteUser(user)"
              ></i>
            </div>
          </tr>
        </table>

        <section v-else id="noUsers">
          {{ 'permissionsTranslation.noUsers' | translate }}
        </section>

        <BasePagination
          id="paginationUsersBottom"
          :numberOfPages="userListData.allPages"
          :currentPage="userListData.currentPage"
          class="paginationBottom"
          @new-page="getNextPage"
        ></BasePagination>
      </section>

      <section v-show="!loading && selectedView === 1" id="sectionDeletion">
        <!--
        <BaseSearch
          id="searchDeletions"
          type="admin-users-deletion"
          @get-new-list="getNewListDeletion"
          @reset-page="resetPageDeletion"
        ></BaseSearch> -->

        <BasePagination
          id="paginationDeletionsTop"
          :numberOfPages="requestListData.allPages"
          :currentPage="requestListData.currentPage"
          @new-page="getNextPageDeletion"
        ></BasePagination>

        <table v-if="requestListData.list && requestListData.list.length > 0" id="deletionList" class="deleteReqTable">
          <tr>
            <th>
              {{ 'adminUsersTranslation.userDetails' | translate }}
            </th>
            <th>
              {{ 'adminUsersTranslation.studiesOfUser' | translate }}
            </th>
            <th>
              {{ 'adminUsersTranslation.eCoachManager' | translate }}
            </th>
            <th>
              {{ 'adminUsersTranslation.data' | translate }}
            </th>
            <th>
              {{ 'adminUsersTranslation.acceptDecline' | translate }}
            </th>
          </tr>
          <tr v-for="request in requestListData.list" :id="'entryDeletion' + request.id" :key="request.id" class="interventionElement">
            <td>
              <div v-if="request.relationships.users && request.relationships.users.data">
                <label>
                  {{request.relationships.users.data.attributes.email}}
                </label>
                {{getUserName(request.relationships.users.data.attributes)}}
              </div>
            </td>
            <td>
              <ul v-if="request.relationships.studies && request.relationships.studies.data && request.relationships.studies.data.length > 0">
                <li v-for="study in request.relationships.studies.data" :key="study.id">
                  {{getStudyName(request, study)}}
                </li>
              </ul>
              <div v-else>
                {{ 'adminUsersTranslation.userHasNoStudies' | translate }}
              </div>
            </td>
            <td>
              <ul v-if="request.attributes.studies && request.attributes.studies.length > 0">
                <li v-for="(study, index) in request.attributes.studies" :key="index"> <!-- todo key -->
                  {{getECoachManagers(study.ecoach_managers)}}
                </li>
              </ul>
              <div v-else>
                {{ 'generalTranslation.none' | translate }}
              </div>
            </td>
            <td>
              {{getDataText(request.attributes.pseudonymized)}}
            </td>
            <td>
              <div class="adminButtons">
                <i
                  class="fas fa-check fa-lg"
                  :id="'buttonAcceptDeletion' + request.id"
                  v-tooltip="$t('adminUsersTranslation.acceptDeletion')"
                  :disabled="isUpdated"
                  @click="showAccountDeletion(request, true)"
                ></i>
                <i
                  class="fa fa-times fa-lg"
                  :id="'buttonDeclineDeletion' + request.id"
                  v-tooltip="$t('adminUsersTranslation.declineDeletion')"
                  :disabled="isUpdated"
                  @click="showAccountDeletion(request, false)"
                ></i>
              </div>
            </td>
          </tr>
        </table>

        <section v-else id="noDeletions">
          {{ 'adminUsersTranslation.noDeletionRequests' | translate }}
        </section>

        <BasePagination
          id="paginationDeletionsBottom"
          :numberOfPages="requestListData.allPages"
          :currentPage="requestListData.currentPage"
          class="paginationBottom"
          @new-page="getNextPageDeletion"
        ></BasePagination>
      </section>

      <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loading"></i>
    </section>

    <AdminUserCreate
      v-if="createAccountVisible"
      @close-modal="closeModalCreate"
    ></AdminUserCreate>

    <BaseModal
      v-if="updateRolesVisible"
      id="editUserModal"
      :headerText="$t('adminUsersTranslation.changePatientRoles', { email: selectedUser.email })"
      :leftButtonText="$t('generalTranslation.save')"
      :disableLeftButton="invalidUserUpdate(selectedUser)"
      @close-modal="closeModalRoles"
    >
      <template v-slot:body>
        <h4>
          {{nameOfUser}}
        </h4>

        <section v-for="role in rolesArray" :key="role" class="checkboxSection notBold">
          <label class="container" :class="{notSelectedRole: role === 'admin' && Number(idOfUpdateUser) === Number(userId)}">
            <i class="fas fa-check" v-if="role === 'admin' && Number(idOfUpdateUser) === Number(userId)"></i>
            {{ 'adminUsersTranslation.' + role | translate }}
            <input
              type="checkbox"
              v-if="role !== 'admin' || Number(idOfUpdateUser) != Number(userId)"
              v-model="selectedUser.roles"
              :value="role" :id="'inputEditUserRole' + role"
            />
            <span class="checkmark" v-if="role !== 'admin' || Number(idOfUpdateUser) != Number(userId)"></span>
          </label>
        </section>

        <section class="wrapper">
          <label>
            {{ 'adminUsersTranslation.username' | translate }} {{ selectedUser.roles.length > 0 ? '*' : '' }}
          </label>
          <input
            class="block"
            type="text"
            v-model="selectedUser.name"
            id="inputPatientName"
            :placeholder="$t('adminUsersTranslation.username')"
          />
          <label>
            {{ 'adminUsersTranslation.firstname' | translate }} *
          </label>
          <input
            :disabled="selectedUser.roles.length === 0"
            class="block" type="text"
            v-model="selectedUser.firstname"
            id="inputPatientFirstname"
            :placeholder="$t('adminUsersTranslation.firstname')"
          />
          <label>
            {{ 'adminUsersTranslation.lastname' | translate }} *
          </label>
          <input
            :disabled="selectedUser.roles.length === 0"
            class="block"
            type="text"
            v-model="selectedUser.lastname"
            id="inputPatientLastname"
            :placeholder="$t('adminUsersTranslation.lastname')"
          />

          <div v-if="invalidRoleChange(selectedUser)" class="textMiddle errorMessage">
            {{ $t('adminUsersTranslation.errorIncompleteForm') }}
          </div>
          <div v-else-if="selectedUser.roles.length === 0 && (selectedUser.firstname || selectedUser.lastname)" class="textMiddle textTip">
            {{ $t('adminUsersTranslation.infoDeletedUserNames') }}
          </div>
          <div v-else-if="!changedUser(selectedUser)" class="textMiddle textInfo">
            {{ $t('adminUsersTranslation.infoNoChangesMade') }}
          </div>
        </section>
      </template>
    </BaseModal>

    <BaseModal
      v-if="deleteUserVisible"
      id="deleteUserModal"
      :headerText="$t('adminUsersTranslation.deleteWarning', { email: selectedUser.email })"
      :bodyText="$t('myStudiesTranslation.warning')"
      :leftButtonText="$t('generalTranslation.delete')"
      :disableButtons="isUpdated"
      @close-modal="closeModalDeleteUser"
    ></BaseModal>

    <BaseModal
      v-if="accountDeletionVisible"
      id="accountDeletionModal"
      :headerText="$t('adminUsersTranslation.' + (selectedRequest.accept ? 'deleteWarning' : 'declineWarning'), { email: selectedRequest.email })"
      :bodyText="accountDeletionBodyText"
      :leftButtonText="accountDeletionButtonText"
      :disableButtons="isUpdated"
      @close-modal="closeModalDeleteAccount"
    ></BaseModal>

    <BaseNotification ref="notificationRef"></BaseNotification>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import utils from '../mixins/utils';
import AdminUserCreate from './AdminUserCreate.vue';
import BaseTabs from './BaseTabs.vue';
import BaseNotification from './BaseNotification.vue';
import BaseModal from './BaseModal.vue';
import BasePagination from './BasePagination.vue';
import BaseSearch from './BaseSearch.vue';

export default {
  name: 'AdminUsers',

  components: {
    AdminUserCreate,
    BaseTabs,
    BaseNotification,
    BaseModal,
    BasePagination,
    BaseSearch,
  },

  mixins: [httpHelper, utils],

  data: function(){
    return{
      updateRolesVisible: false,
      loading: true,
      isUpdated: false,
      userListData: "",
      requestListData: "",
      filterTerm: "",
      deleteUserVisible: false,
      selectedRequest: "",
      selectedUser: "",
      createAccountVisible: false,
      selectedView: 0,
      accountDeletionVisible: false,
      rolesArray: ["admin", "ecoach", "editor"]
    }
  },

  computed: {
    ...mapGetters([
      'getUserId',
      'getNotificationText',
    ]),

    userId: function(){
      return this.getUserId
    },

    idOfUpdateUser: function(){
      return this.selectedUser ? this.userListData.list[this.selectedUser.index].id : ""
    },

    nameOfUser: function(){
      let name = "";
      if(this.selectedUser){
        const user = this.userListData.list[this.selectedUser.index];
        if(user.firstname){
          name += user.firstname;
        }
        if(user.lastname){
          name += " " + user.lastname;
        }
        if(user.name){
          name += ` (${user.name})`;
        }
      }
      return name;
    },

    accountDeletionBodyText: function(){
      if(this.selectedRequest.accept){
        var text = ' ' + Vue.i18n.translate('myStudiesTranslation.warning');
        if(this.selectedRequest.pseudonymize){
          return Vue.i18n.translate('adminUsersTranslation.dataPseudonymized') + text
        }else{
          return Vue.i18n.translate('adminUsersTranslation.dataDeleted') + text
        }
      }else{
        return Vue.i18n.translate('adminUsersTranslation.declineText')
      }
    },

    accountDeletionButtonText: function(){
      return this.selectedRequest.accept ? Vue.i18n.translate('generalTranslation.delete') : Vue.i18n.translate('adminUsersTranslation.decline')
    },

    tabDetails: function(){
      return [
        {text: Vue.i18n.translate('adminUsersTranslation.allUsers'), show: true, id: "users"},
        {text: Vue.i18n.translate('adminUsersTranslation.deletionRequests'), show: true, id: "deletion"},
      ]
    }
  },

  mounted(){
    this.closeNotification();
    this.getAllUsersIncludingPermissions(1);
  },

  beforeRouteLeave(to, from, next){
    if(this.createAccountVisible || this.updateRolesVisible){
      next(false);
    }else{
      next();
    }
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.loading = false;
        this.isUpdated = false;
      }else if(newVal != "" && newVal.type === "success"){
        this.isUpdated = false;
      }
    },
  },

  methods:{
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
    ]),

    changeRolesOfUser: function(index, user){
      const roles = this.userListData.list[index].role;
      this.selectedUser = {
        index: index,
        roles: roles,
        isPatient: roles.length === 0,
        email: user.email,
        name: user.name,
        firstname: user.firstname,
        lastname: user.lastname,
      };
      this.updateRolesVisible = true;
    },

    invalidUserUpdate(user) {
      if (user.isPatient) {
        const oldUser = this.userListData.list[user.index];
        // If loaded patient had names defined, they should still be deletable even with no changes
        const unchangedPatient = user.roles.length === 0
          && this.usernameEquals(oldUser.name, user.name)
          && this.stringEquals(oldUser.firstname, user.firstname) && !user.firstname
          && this.stringEquals(oldUser.lastname, user.lastname) && !user.lastname;
        return unchangedPatient || this.invalidRoleChange(user);
      } else {
        return this.invalidRoleChange(user) || !this.changedUser(user);
      }
    },

    invalidRoleChange: function(user) {
      return user.roles.length > 0 && this.incompleteUserForm(user);
    },

    incompleteUserForm: function(user) {
      return !user.name || !user.firstname || !user.lastname;
    },

    changedUser(user) {
      const oldUser = this.userListData.list[user.index];
      const changedNames = this.usernameNotEquals(oldUser.name, user.name)
        || this.stringNotEquals(oldUser.firstname, user.firstname)
        || this.stringNotEquals(oldUser.lastname, user.lastname);
      return changedNames || this.changedRoles(user.roles, oldUser.role);
    },

    changedRoles(roles1, roles2) {
      if (roles1 === roles2) { return false; }
      if (roles1.length !== roles2.length) { return true; }
      const rolesCount = new Map();
      for (const role of roles1) {
        const count = rolesCount.get(role);
        rolesCount.set(role, count === undefined ? 1 : count + 1);
      }
      for (const role of roles2) {
        const count = rolesCount.get(role);
        rolesCount.set(role, count === undefined ? -1 : count - 1);
      }
      for (const count of rolesCount.values()) {
        if (count !== 0) {
          return true;
        }
      }
      return false;
    },

    closeModalRoles: async function(done){
      if (done) {
        if (this.selectedUser.isPatient) {
          this.isUpdated = true;
          this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('adminUsersTranslation.savePatientRolesLoad')});
          await this.savePatientUser(done);
        } else if (this.changedUser(this.selectedUser)) {
          this.isUpdated = true;
          this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('adminUsersTranslation.savePatientRolesLoad')});
          await this.saveChangedUser(done);
        }
      } else if (!this.isUpdated) {
        if (this.changedUser(this.selectedUser) && (this.getNotificationText === "" || this.getNotificationText.text != Vue.i18n.translate('adminUsersTranslation.unsavedChangesRoles'))) {
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('adminUsersTranslation.unsavedChangesRoles')});
        } else {
          this.closeNotification();
          this.updateRolesVisible = false;
        }
      }
    },

    async savePatientUser(done) {
      const newRoles = this.selectedUser.roles;
      const oldUser = this.userListData.list[this.selectedUser.index];
      const changedName = this.usernameNotEquals(oldUser.name, this.selectedUser.name);
      const name = !this.selectedUser.name ? null : (changedName ? this.selectedUser.name : undefined);
      if (newRoles.length > 0) {
        const user = {
          name: name,
          firstname: this.selectedUser.firstname,
          lastname: this.selectedUser.lastname,
        };
        try {
          await this.updateUserProfileAsAdmin(this.idOfUpdateUser, user);
        } catch (error) {
          this.handleErrors(error, function(){ this.closeModalRoles(done) }, "addRoleToPatient");
          return;
        }
        try {
          await this.addRolesToUserRequest(this.idOfUpdateUser, newRoles);
          this.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('adminUsersTranslation.savePatientRolesSuccess')});
          this.getAllUsersIncludingPermissions(this.userListData.currentPage);
          this.updateRolesVisible = false;
        } catch (error) {
          this.handleErrors(error, function(){ this.closeModalRoles(done) }, "");
        }
      } else {
        const user = { name: name, firstname: null, lastname: null };
        try {
          await this.updateUserProfileAsAdmin(this.idOfUpdateUser, user);
          this.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('adminUsersTranslation.savePatientRolesSuccess')});
          this.getAllUsersIncludingPermissions(this.userListData.currentPage);
          this.updateRolesVisible = false;
        } catch (error) {
          this.handleErrors(error, function(){ this.closeModalRoles(done) }, "addRoleToPatient");
        }
      }
    },

    async saveChangedUser(done) {
      const oldUser = this.userListData.list[this.selectedUser.index];
      const oldRoles = oldUser.role;
      const newRoles = this.selectedUser.roles;
      const rolesToRemove = [];
      const rolesToAdd = [];
      for (const role of this.rolesArray) {
        if (oldRoles.includes(role) && !newRoles.includes(role)) {
          rolesToRemove.push(role);
        }
        if (!oldRoles.includes(role) && newRoles.includes(role)) {
          rolesToAdd.push(role);
        }
      }
      const changedName = this.usernameNotEquals(oldUser.name, this.selectedUser.name);
      const changedNames = newRoles.length === 0
        || changedName
        || this.stringEquals(oldUser.firstname, this.selectedUser.firstname)
        || this.stringEquals(oldUser.lastname, this.selectedUser.lastname);
      if (changedNames) {
        const name = !this.selectedUser.name ? null : (changedName ? this.selectedUser.name : undefined);
        const user = {
          name: name,
          firstname: newRoles.length === 0 ? null : this.selectedUser.firstname,
          lastname: newRoles.length === 0 ? null : this.selectedUser.lastname,
        };
        try {
          await this.updateUserProfileAsAdmin(this.idOfUpdateUser, user);
        } catch (error) {
          this.handleErrors(error, function(){ this.closeModalRoles(done) }, "addRoleToPatient");
          return;
        }
      }
      try {
        if (rolesToRemove.length !== 0) {
          await this.removeRolesFromUserRequest(this.idOfUpdateUser, rolesToRemove);
        }
        if (rolesToAdd.length !== 0) {
          await this.addRolesToUserRequest(this.idOfUpdateUser, rolesToAdd);
        }
        this.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('adminUsersTranslation.savePatientRolesSuccess')});
        this.getAllUsersIncludingPermissions(this.userListData.currentPage);
        this.updateRolesVisible = false;
      } catch (error) {
        this.handleErrors(error, function(){ this.closeModalRoles(done) }, "");
      }
    },

    getRoleChangeHeader: function(user) {
      // const key = user.isPatient ? "adminUsersTranslation.changePatientRoles" : "adminUsersTranslation.changeRoles";
      const key = "adminUsersTranslation.changePatientRoles";
      return Vue.i18n.translate(key, { email: user.email });
    },

    resendVerification: function(userMail) {
      this.isUpdated = true;
      this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('adminUsersTranslation.resendVerificationLoad')});
      this.resendVerificationForUserRequest(userMail);
    },

    getNewList: function(filterTerm){
      this.filterTerm = filterTerm;
      this.getAllUsersIncludingPermissions(1);
    },

    getNewListDeletion: function(filterTerm){
      this.filterTerm = filterTerm;
      this.getDeletionRequests(1);
    },

    resetPage: function(){
      this.userListData.currentPage = 1;
    },

    resetPageDeletion: function(){
      this.requestListData.currentPage = 1;
    },

    getNextPage(selectedNumber){
      if(selectedNumber != ""){
        this.getAllUsersIncludingPermissions(selectedNumber);
      }
    },

    getNextPageDeletion(selectedNumber){
      if(selectedNumber != ""){
        this.getDeletionRequests(selectedNumber);
      }
    },

    showView: function(view){
      this.loading = true;
      this.selectedView = view;
      this.filterTerm = "";
      if(view === 0){
        this.getAllUsersIncludingPermissions(1);
      }else{
        this.getDeletionRequests(1);
      }
    },

    getAllUsersIncludingPermissions: function(page){
      this.loading = true;
      var self = this;
      this.requestAllUsersIncludingPermissions(page, this.filterTerm)
      .then( function(response){
        self.userListData = response;
        self.loading = false;
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.getAllUsersIncludingPermissions(page) }, "");
      });
    },

    getDeletionRequests: function(page){
      this.loading = true;
      var self = this;
      this.requestAccountDeletionRequests(page, this.filterTerm)
      .then( function(response){
        self.requestListData = response;
        self.loading = false;
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.getDeletionRequests(page) }, "");
      });
    },

    closeModalDeleteUser: function(done){
      if(done){
        this.isUpdated = true;
        this.deleteUserVisible = false;
        this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('adminUsersTranslation.deleteUserLoad')});
        var requestPage = this.userListData.currentPage;
        if(this.userListData.list.length === 1){
          //delete last element of page -> request page before that
          requestPage--;
        }

        var self = this;
        this.deleteUserRequest(this.selectedUser.id)
        .then(function (){
          self.getAllUsersIncludingPermissions(requestPage);
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('adminUsersTranslation.deleteUserSuccess')});
          self.selectedUser = "";
        })
        .catch(function (error){
          self.handleErrors(error, function(){ self.closeModalDeleteUser(done) }, "");
        });
      }else{
        this.deleteUserVisible = false;
      }
    },

    closeModalDeleteAccount: function(done){
      this.accountDeletionVisible = false;

      if(done){
        this.isUpdated = true;
        if(this.selectedRequest.accept){
          this.acceptAccountDeletion();
        }else{
          this.declineAccountDeletion();
        }
      }
    },

    acceptAccountDeletion: function(){
      this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('adminUsersTranslation.acceptAccountDeletionLoad')});
      var requestPage = this.requestListData.currentPage;
      if(this.requestListData.list.length === 1){
        //delete last element of page -> request page before that
        requestPage--;
      }

      var self = this;
      if(this.selectedRequest.pseudonymize){
        this.pseudonymizeUserRequest(this.selectedRequest.userId)
        .then(function (){
          self.getDeletionRequests(requestPage);
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('adminUsersTranslation.acceptAccountDeletionSuccess')});
          self.selectedRequest = "";
        })
        .catch(function (error){
          self.handleErrors(error, function(){ self.acceptAccountDeletion() }, "");
        });
      }else{
        this.deleteUserCompletelyRequest(this.selectedRequest.userId)
        .then(function (){
          self.getDeletionRequests(requestPage);
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('adminUsersTranslation.acceptAccountDeletionSuccess')});
          self.selectedRequest = "";
        })
        .catch(function (error){
          self.handleErrors(error, function(){ self.acceptAccountDeletion() }, "");
        });
      }
    },

    declineAccountDeletion: function(){
      this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('adminUsersTranslation.declineAccountDeletionLoad')});
      var requestPage = this.requestListData.currentPage;
      if(this.requestListData.list.length === 1){
        //delete last element of page -> request page before that
        requestPage--;
      }

      var self = this;
      this.declineAccountDeletionRequest(this.selectedRequest.id)
      .then(function (){
        self.getDeletionRequests(requestPage);
        self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('adminUsersTranslation.declineAccountDeletionSuccess')});
        self.electedRequest = "";
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.declineAccountDeletion() }, "");
      });
    },

    closeNotification: function(){
      this.$refs["notificationRef"].closeNotification();
    },

    showCreateAccountModal: function(){
      this.closeNotification();
      this.createAccountVisible = true;
    },

    closeModalCreate: function(created){
      this.createAccountVisible = false;

      if(created){
        //get last page
        var reqPage = Math.ceil((this.userListData.total + 1) / this.$perPage);
        this.getAllUsersIncludingPermissions(reqPage);
      }
    },

    showDeleteUser: function(user){
      this.closeNotification();
      this.selectedUser = user;
      this.deleteUserVisible = true;
    },

    showAccountDeletion: function(request, accept){
      this.closeNotification();
      this.selectedRequest = {
        accept: accept,
        id: request.id,
        userId: request.attributes.user_id,
        email: request.relationships.users && request.relationships.users.data ? request.relationships.users.data.attributes.email : "",
        pseudonymize: request.attributes.pseudonymized
      };
      this.accountDeletionVisible = true;
    },

    showIfVerified: function(verified){
      return verified ? 'visible' : 'hidden'
    },

    showIfOtherUser: function(idOfUser){
      return (Number(idOfUser) === Number(this.userId)) ? 'hidden' : 'visible'
    },

    getDataText: function(pseudonymized){
      return Vue.i18n.translate('adminUsersTranslation.' + (pseudonymized ? 'pseudonymize' : 'deleteAll'))
    },

    getStudyName: function(request, study){
      var index = request.relationships.studies.data.findIndex(s => s.id === study.id);
      return (index != -1) ? request.relationships.studies.data[index].attributes.name : ""
    },

    getUserName: function(attributes){
      var name = (attributes.firstname) ? (attributes.firstname) : "";
      if(attributes.lastname){
        name += ((name != "") ? " " : "") + attributes.lastname;
      }
      if(attributes.name){
        name += ((name != "") ? " " : "") + "(" + attributes.name + ")"
      }

      return name
    },

    getECoachManagers: function(arr){
      if(arr.length > 0){
        var string = "";
        for(var entry in arr){
          string += ((entry > 0) ? ", " : "") + arr[entry];
        }
        return string
      }else{
        return Vue.i18n.translate('generalTranslation.none')
      }
    }
  }
}
</script>
