<template>
  <div class="baseCondition">
    <!--label-->
    <select
      :disabled="disableInputs"
      :value="condition.questionLabel"
      :id="'inputConditionLabel' + id"
      @change="changeAttribute('label', $event.target.value)"
    >
      <option value="" :id="'inputConditionLabel' + id + 'None'" disabled selected>
        {{ 'elementsBlockTranslation.selectLabel' | translate }}
      </option>
      <template v-for="(option, index) in getQuestionLabelList">
        <option v-if="option && option != 0" :value="index" :id="'inputConditionLabel' + id + 'Option' + index" :key="index">
          {{option.label + (option.type === 'table' ? ' (' + $tlang(getSelectedLanguage, 'elementsBlockTranslation.sum') + ')' : '')}}
        </option>
      </template>
    </select>

    <!--operation question-->
    <select
      :disabled="disableInputs"
      v-if="condition.leafType === 'question'"
      :value="condition.operation"
      :id="'inputConditionOperation' + id"
      key="operationQuestion"
      @input="changeAttribute('operation', $event.target.value)"
    >
      <option value="-1" :id="'inputConditionOperation' + id + 'None'" disabled>
        {{ 'conditionEditorTranslation.selectOperation' | translate }}
      </option>
      <option value="" :id="'inputConditionOperation' + id + 'AnsweredWith'" key="operation1" selected>
        {{ 'elementsBlockTranslation.answeredWith' | translate }}
      </option>
      <option value="answered" :id="'inputConditionOperation' + id + 'AnsweredAny'" key="operation2">
        {{ 'elementsBlockTranslation.answeredAny' | translate }}
      </option>
    </select>

    <!--operation date slider-->
    <select
      :disabled="disableInputs"
      v-else-if="(condition.leafType === 'date' && valuesForLabel[0] != 'TextTime') || condition.leafType === 'slider' || condition.leafType === 'table'"
      :value="condition.operation"
      :id="'inputConditionOperationNumber' + id"
      key="operationDateSlider"
      @change="changeAttribute('operation', $event.target.value)"
    >
      <option value="" :id="'inputConditionOperationNumber' + id + 'None'" disabled selected>
        {{ 'conditionEditorTranslation.selectOperation' | translate }}
      </option>
      <option
        v-for="data in operationTypeList"
        :id="'inputConditionOperationNumber' + id + 'Operation' + data.value"
        :key="data.value"
        :value="data.value"
      >
        {{ data.text }}
      </option>
      <option :id="'inputConditionOperationNumber' + id + 'AnsweredAny'" key="operation" value="answered">
        {{ 'elementsBlockTranslation.answeredAny' | translate }}
      </option>
    </select>

    <div v-else :id="'conditionOperationText' + id + 'AnsweredAny'" class="textAnswered">
      {{ 'elementsBlockTranslation.answeredAny' | translate }}
    </div>

    <!--value question-->
    <select
      :disabled="disableInputs"
      v-if="condition.leafType === 'question' && condition.operation != 'answered'"
      :value="condition.value"
      :id="'inputConditionValueQuestion' + id"
      key="valueQuestion"
      @change="changeAttribute('value', Array.from($event.target.options).filter(o => o.selected).map(x => '_value' in x ? x._value : x.value)[0])"
    >
      <option value="" :id="'inputConditionValueQuestion' + id + 'None'" disabled selected>
        {{ 'elementsBlockTranslation.selectAnswer' | translate }}
      </option>
      <option
        v-for="(value, index) in valuesForLabel"
        :value="value"
        :id="'inputConditionValueQuestion' + id + 'Index' + index + 'Value' + value"
        :key="index + value"
      >
        {{ getValueText(value) }}
      </option>
    </select>

    <!--value slider-->
    <input
      :disabled="disableInputs"
      type="number"
      v-else-if="condition.leafType === 'slider' && condition.operation != 'answered'"
      :value="condition.value"
      :min="valuesForLabel.min"
      :max="valuesForLabel.max"
      :step="valuesForLabel.step"
      :id="'inputConditionValueSlider' + id" key="valueSlider"
      @change="changeNumberAttribute('value', $event.target.value)"
    >

    <!--value table-->
    <input
      :disabled="disableInputs"
      type="number"
      v-else-if="condition.leafType === 'table' && condition.operation != 'answered'"
      :value="condition.value"
      :min="0"
      :step="1"
      :id="'inputConditionValueTable' + id"
      key="valueTable"
      @change="changeNumberAttribute('value', $event.target.value)"
    >

    <!--value date-->
    <flatPickr
      :disabled="disableInputs"
      v-else-if="condition.leafType === 'date' && condition.operation != 'answered' && valuesForLabel[0] != 'TextTime'"
      :value="condition.value"
      :config="config"
      :placeholder="$tlang(getSelectedLanguage, 'elementsQuestionTranslation.selectDate')"
      :id="'inputConditionValueDate' + id"
      @input="newValueDate($event)"
    ></flatPickr>

    <!--range for slider, date and table -->
    <div v-if="condition.operation === 'between'" class="divSecondVal">
      {{ 'conditionEditorTranslation.betweenAnd' | translate }}

      <input
        :disabled="disableInputs"
        type="number"
        v-if="condition.leafType === 'slider'"
        :value="condition.secondValue"
        :min="valuesForLabel.min"
        :max="valuesForLabel.max"
        :step="valuesForLabel.step"
        :id="'inputConditionSecondValueSlider' + id"
        key="secondValueSlider"
        @change="changeNumberAttribute('second-value', $event.target.value)"
      >

      <flatPickr
        :disabled="disableInputs"
        v-if="condition.leafType === 'date' && valuesForLabel[0] != 'TextTime'"
        :value="condition.secondValue"
        :config="config"
        :placeholder="$tlang(getSelectedLanguage, 'elementsQuestionTranslation.selectDate')"
        :id="'inputConditionSecondValueDate' + id"
        @input="newSecondValueDate($event)"
      ></flatPickr>

      <input
        :disabled="disableInputs"
        type="number"
        v-if="condition.leafType === 'table'"
        :value="condition.secondValue"
        :min="0"
        :step="1"
        :id="'inputConditionSecondValueSlider' + id"
        key="secondValueTable"
        @change="changeNumberAttribute('second-value', $event.target.value)"
      >
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/l10n/de.js";
import questionConditionHelper from "../mixins/questionConditionHelper";

export default {
  name: "BaseCondition",

  components: {
    flatPickr
  },

  mixins: [questionConditionHelper],

  props: {
    id: {
      required: true,
      type: Number,
    },

    condition: {
      required: true,
      type: Object,
    },

    valuesForLabel: {
      required: true,
      type: Array,
    },

    allowStructureChanges: {
      type: Boolean,
      default: null,
    },
  },

  computed: {
    ...mapGetters([
      "getQuestionLabelList",
      "getSelectedLanguage",
    ]),

    operationTypeList: function() {
      return [
        { text: "<", value: "less" },
        { text: "≤", value: "lessEqual" },
        { text: ">", value: "greater" },
        { text: "≥", value: "greaterEqual" },
        { text: "=", value: "equals" },
        { text: "≠", value: "notEquals" },
        { text: Vue.i18n.translate("conditionEditorTranslation.between"), value: "between" },
      ];
    },

    config: function() {
      return {
        altFormat: Vue.i18n.translateIn(this.getSelectedLanguage, "elementsQuestionTranslation.dateFormatCondition"),
        altInput: true,
        dateFormat: "Y-m-d",
        enableTime: false,
        locale: this.getSelectedLanguage,
        position: "auto center",
      };
    },

    disableInputs: function() {
      return this.allowStructureChanges === false;
    },
  },

  methods: {
    changeAttribute: function(name, val) {
      this.$emit("change-condition", { name: name, value: val });
    },

    changeNumberAttribute: function(name, val) {
      let value;
      if ((!val && val !== 0) || isNaN(val)) {
        value = "";
      } else {
        value = Number(val)
      }
      this.$emit("change-condition", { name, value });
    },

    newValueDate: function(val) {
      this.$emit("change-condition", { name: "value", value: val });
    },

    newSecondValueDate: function(val) {
      this.$emit("change-condition", { name: "second-value", value: val });
    },
  },
}
</script>
