<template>
  <div class="lessonElement">
    <section v-if="element.type != 'ElementsPage'" class="wrapper">
      <section v-if="selectElementsBool" class="col selectElementsLeft">
        <section class="checkboxSection notBold">
          <label class="container">
            <input type="checkbox" :id="'inputSelectElement' + element.propsObj.id" :value="Number(element.propsObj.id)" :checked="isSelected" @input="checkSelection($event.target.value)">
            <span class="checkmark"></span>
          </label>
        </section>
      </section>

      <section class="wrapper liBorder" :class="{notEditableSpace: !getIsEditable, selectElementsRight: selectElementsBool, col: selectElementsBool}">
        <section class="wrapper wrapperElement" :class="'border' + element.type">
          <section
            v-if="!disableInputs"
            class="col"
            :class="{
              openElement: detailsOpen,
              sectionLeft2: detailsOpen,
              elementFunctions: !detailsOpen && !isInBlock,
              elementFunctionsBlock: !detailsOpen && isInBlock
            }"
          >
            <i
              v-if="index === 0 && isInBlock || isAfterBlock"
              :id="'buttonChangeElementLevelUp' + element.propsObj.id"
              class="fas fa-level-up-alt"
              v-tooltip="elementTooltip(getBlockArrowTextUp)"
              @click="$emit('change-element', { type: 'change-positions', index1: index - 1, index2: index, changeLevel: true, direction: 'up' })"
            ></i>

            <i
              v-if="index > 0 && isInBlock || index > 1 && !isInBlock"
              :id="'buttonChangeElementPositionUp' + element.propsObj.id"
              class="fa fa-chevron-up"
              v-tooltip="elementTooltip($t('lessonTranslation.changePosition'))"
              @click="$emit('change-element', { type: 'change-positions', index1: index - 1, index2: index, changeLevel: false})"
            ></i>

            <div v-else-if="detailsOpen && !isInBlock" :class="{arrowPlaceholder: detailsOpen}"></div>

            <i
              class="fa fa-trash"
              :id="'buttonDeleteElement' + element.propsObj.id"
              :class="{ spaceLeft: !detailsOpen && index === 0 }"
              v-tooltip="elementTooltip($t('lessonTranslation.removeElement'))"
              @click="$emit('change-element', { type: 'delete-element', index: index, id: element.propsObj.id })"
            ></i>

            <i
              class="fa fa-align-justify handle"
              :id="'buttonDragDropElement' + element.propsObj.id"
              v-tooltip="elementTooltip($t('lessonTranslation.dragAndDrop'))"
            ></i>

            <i
              class="fa fa-clone"
              :id="'buttonDuplicateElement' + element.propsObj.id"
              v-tooltip="elementTooltip($t('lessonTranslation.duplicateElement'))"
              @click="$emit('change-element', { type: 'add-element', index: index + 1, elementType: 'duplicate' })"
            ></i>

            <i
              v-if="index < numberOfElements - 1"
              :id="'buttonChangeElementPositionDown' + element.propsObj.id"
              class="fa fa-chevron-down"
              v-tooltip="elementTooltip($t('lessonTranslation.changePosition'))"
              @click="$emit('change-element', { type: 'change-positions', index1: index, index2: index + 1, changeLevel: false })"
            ></i>
            <i v-else-if="!(index === numberOfElements - 1 && isInBlock || isBeforeBlock)" class="fa" />

            <i
              v-if="index === numberOfElements - 1 && isInBlock || isBeforeBlock"
              :id="'buttonChangeElementLevelDown' + element.propsObj.id"
              class="fas fa-level-down-alt"
              v-tooltip="{
                content: getBlockArrowTextDown,
                placement: 'right',
                distance: -40,
                arrowPadding: 10,
              }"
              @click="$emit('change-element', { type: 'change-positions', index1: index, index2: index + 1, changeLevel: true, direction: 'down' })"
            ></i>
            <i v-else-if="index < numberOfElements - 1" class="fas" />
          </section>

          <section class="col" :class="{elementDetails: !detailsOpen && !isInBlock, elementDetailsBlock: !detailsOpen && isInBlock, elementDetailsSection: detailsOpen}">
            <details :id="detailsTagId" class="detailsClass" open> <!-- dont remove detailsClass, is used in lessonEditorElements -->
              <summary :class="{errorMessage: elementHasErrors}" @click="toggleSummary">
                {{getElementSummary(element, detailsOpen)}}
              </summary>

              <div class="wrapper">
                <!-- normal language (left) -->  <!-- "is: Componentname" to create new child components -->
                <section :class="{col: secondLanguageShown, sec50: secondLanguageShown}">
                  <section
                    :is="element.type"
                    :propsObj="element.propsObj"
                    :translation="element.translations ? element.translations[getSelectedLanguage] : []"
                    :translation2="secondLanguageForBlock && element.translations ? element.translations[selectedLanguageForTranslation] : []"
                    linkForMedia=""
                    :isTranslation="false"
                    :errorTextsForElements="errorTextsForElements"
                    :sliderRepetitionErrorLabels="sliderRepetitionErrorLabels"
                    :isTranslationMode="isTranslationMode"
                    :showTextEditor="showTextEditor"
                    :selectedLanguageForTranslation="selectedLanguageForTranslation"
                    :isInBlock="isInBlock"
                    :selectedElements="selectedElements"
                    :closedElements="closedElements"
                    :selectElementsBool="selectElementsBool"
                    :studyId="studyId"
                    :isSkill="isSkill"
                    :showProgressBars="showProgressBars"
                    :allowStructureChanges="allowStructureChanges"
                    @change-element="$emit('change-element', $event)"
                  ></section>
                </section>

                <!-- translation language (right) -->
                <section :class="{col: secondLanguageShown, sec50: secondLanguageShown}">
                  <section v-if="secondLanguageShown">
                    <section
                      :is="element.type"
                      :propsObj="element.propsObj"
                      :translation="element.translations ? element.translations[selectedLanguageForTranslation] : []"
                      :linkForMedia="element.translations && element.translations[getSelectedLanguage] ? element.translations[getSelectedLanguage].uri : ''"
                      :isTranslation="true"
                      :errorTextsForElements="errorTextsForElements"
                      :sliderRepetitionErrorLabels="sliderRepetitionErrorLabels"
                      :isTranslationMode="isTranslationMode"
                      :showTextEditor="showTextEditor"
                      :selectedLanguageForTranslation="selectedLanguageForTranslation"
                      :isInBlock="isInBlock"
                      :studyId="studyId"
                      :isSkill="isSkill"
                      :showProgressBars="showProgressBars"
                      :allowStructureChanges="allowStructureChanges"
                      @change-element="$emit('change-element', $event)"
                    ></section>
                  </section>
                </section>
              </div>
            </details>
          </section>
        </section>
      </section>
    </section>

    <section v-else class="wrapper"> <!-- page element -->
      <ElementsPage
        :propsObj="element.propsObj"
        :index="index"
        :defaultPageColor="defaultPageColor"
        :studyId="studyId"
        :errorTextsForElements="errorTextsForElements"
        :showProgressBars="showProgressBars"
        :allowStructureChanges="allowStructureChanges"
        :translation="showProgressBars && element.translations ? element.translations[getSelectedLanguage] : []"
        :translation2="showProgressBars && element.translations ? element.translations[selectedLanguageForTranslation] : []"
        :isTranslationMode="isTranslationMode"
        @change-element="$emit('change-element', $event)"
      ></ElementsPage>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';
import ElementsBlock from './ElementsBlock.vue';
import ElementsHeadline from './ElementsHeadline.vue';
import ElementsMedia from './ElementsMedia.vue';
import ElementsPage from './ElementsPage.vue';
import ElementsLine from './ElementsLine.vue';
import ElementsSpace from './ElementsSpace.vue';
import ElementsQuestion from './ElementsQuestion.vue';
import ElementsTable from './ElementsTable.vue';
import ElementsText from './ElementsText.vue';
import elementsHelper from '../mixins/elementsHelper';

export default {
  name: 'LessonElement',

  components: {
    ElementsBlock,
    ElementsHeadline,
    ElementsMedia,
    ElementsPage,
    ElementsLine,
    ElementsSpace,
    ElementsQuestion,
    ElementsTable,
    ElementsText,
  },

  mixins: [elementsHelper],

  props: {
    index: {
      required: true,
      type: Number,
    },

    element: {
      required: true,
      type: Object,
    },

    numberOfElements: {
      required: true,
      type: Number,
    },

    isTranslationMode: {
      required: true,
      type: Boolean,
    },

    defaultPageColor: {
      required: true,
      type: String,
    },

    selectedElements: {
      required: true,
      type: Array,
    },

    errorTextsForElements: {
      required: true,
      type: Array,
    },

    sliderRepetitionErrorLabels: {
      required: false,
      type: Array
    },

    closedElements: {
      required: true,
      type: Array,
    },

    selectedLanguageForTranslation: {
      required: true,
      type: String,
    },

    studyId: {
      required: true,
      type: Number,
    },

    showTextEditor: {
      required: true,
      type: Number,
    },

    isInBlock: {
      required: true,
      type: Boolean,
    },

    selectElementsBool: {
      required: true,
      type: Boolean,
    },

    isAfterBlock: {
      required: true,
      type: Boolean,
    },

    isBeforeBlock: {
      required: true,
      type: Boolean,
    },

    isSkill: {
      required: true,
      type: Boolean,
    },

    showProgressBars: {
      required: true,
      type: Boolean
    },

    allowStructureChanges: {
      type: Boolean,
      default: null,
    },
  },

  data: function(){
    return {
      detailsElement: null,
      detailsTagId: "",
    }
  },

  computed: {
    ...mapGetters([
      'getIsEditable',
      'getSelectedLanguage'
    ]),

    getBlockArrowTextUp: function(){
      return this.isInBlock ? Vue.i18n.translate('lessonTranslation.changePositionOutBlockUp') :
        Vue.i18n.translate('lessonTranslation.changePositionInBlockUp')
    },

    getBlockArrowTextDown: function(){
      return this.isInBlock ? Vue.i18n.translate('lessonTranslation.changePositionOutBlockDown') :
        Vue.i18n.translate('lessonTranslation.changePositionInBlockDown')
    },

    detailsOpen: function(){
      return !this.closedElements.includes(this.element.propsObj.id)
    },

    elementHasErrors: function(){
      var bool = !!this.errorTextsForElements[this.element.propsObj.id];
      if(this.element.type === 'ElementsBlock'){
        bool = bool || this.errorElementsInBlock(this.element.propsObj.elements);
      }
      return bool
    },

    secondLanguageShown: function(){
      return this.isTranslationMode && this.getIsEditable && this.element.type != 'ElementsBlock'
    },

    secondLanguageForBlock: function(){
      return this.isTranslationMode && this.getIsEditable && this.element.type === 'ElementsBlock'
    },

    isSelected: function(){
      return JSON.stringify(this.selectedElements).includes('"id":' + this.element.propsObj.id)
    },

    disableInputs: function() {
      return !this.getIsEditable || this.allowStructureChanges === false;
    },
  },

  created(){
    this.detailsTagId = "details" + this.element.propsObj.id; //has to be the same as in elementsPage
  },

  mounted(){
    this.detailsElement = document.getElementById(this.detailsTagId);
    if(this.closedElements.includes(this.element.propsObj.id)){
      this.detailsElement.removeAttribute('open');
    }
  },

  methods: {
    elementTooltip: function(message) {
      return {
        content: message,
        placement: "right",
        distance: -40,
        skidding: -16,
        arrowPadding: 10,
      };
    },

    checkSelection: function(value){
      this.$emit('change-element', {'type': 'select-element', 'value': value });
    },

    toggleSummary: function(){
      this.$emit('change-element', {'type': 'toggle-summary', 'id': this.element.propsObj.id });
    },

    errorElementsInBlock: function(elements){
      var hasErrors = false;
      for(var elem in elements){
        hasErrors = hasErrors || !!this.errorTextsForElements[elements[elem].propsObj.id];
        if(elements[elem].type === 'ElementsBlock'){
          hasErrors = hasErrors || this.errorElementsInBlock(elements[elem].propsObj.elements);
        }
      }
      return hasErrors
    }
  }
}
</script>
