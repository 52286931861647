<template>
  <div class="baseTabs tabContainer" id="tabMenu">
    <template v-for="(tab, index) in tabDetails">
      <button
        type="button"
        v-if="tab.show"
        :class="{ selectedTab: selectedView === index }"
        :id="'tab-' + tab.id"
        :key="index"
        @click="$emit('show-view', index)"
      >
        {{ tab.text }}
      </button>
    </template>
  </div>
</template>

<script>
export default {
  name: "BaseTabs",

  props: {
    tabDetails: {
      required: true,
      type: Array,
    },

    selectedView: {
      required: true,
      type: Number,
    },
  },
}
</script>
