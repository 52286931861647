<template>
  <BaseModal
    id="createSkillModal"
    class="skillCreate"
    :headerText="$t('interventionTranslation.createSkillHeader', { name: targetData.name })"
    :leftButtonText="$t('generalTranslation.create')"
    :disableButtons="isCreated"
    :disableLeftButton="name === ''"
    @close-modal="closeModal"
  >
    <template v-slot:body>
      <section class="wrapper">
        <form @submit.prevent="createNewSkill">
          <label>
            {{ 'generalTranslation.name' | translate }} *
          </label>
          <input type="text" v-model="name" id="inputNewName" :placeholder="$t('generalTranslation.name')" required>
        </form>
      </section>
    </template>
  </BaseModal>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import BaseModal from './BaseModal.vue';

export default {
  name: 'SkillCreate',

  components: {
    BaseModal,
  },

  mixins: [httpHelper],

  props: {
    targetData: { //intervention in which skill should be created (details)
      required: true,
      type: Object,
    },
  },

  data: function(){
    return{
      name: '',
      isCreated: false,
    }
  },

  computed: {
    ...mapGetters([
      'getNotificationText',
      'getSelectedLanguage',
    ]),

    targetInterventionId: function(){ //skill created for this intervention
      return Number(this.$route.params.interventionId)
    }
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.isCreated = false;
      }
    },
  },
  methods: {
    ...mapMutations([
      "SET_NOTIFICATIONTEXT",
      "SET_LESSONELEMENTSARR",
    ]),

    createNewSkill: function() {
      if(!this.name){
        this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("generalTranslation.errorNameEmpty") });
        return;
      }
      this.isCreated = true;
      this.$emit("set-changed", { name: "all", value: false });
      this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate("interventionTranslation.createSkillLoad")});
      const json = {
        data: {
          type: "skills",
          attributes: {
            name: this.name,
            locales: this.targetData.locales,
          },
        },
      };
      this.SET_LESSONELEMENTSARR([]);
      const self = this;
      this.createSkillForInterventionRequest(this.targetInterventionId, json)
        .then(function(newSkillId) {
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate("interventionTranslation.createSkillSuccess")});
          self.$emit("route-to", { name: "skillEditor", params: {skillId: newSkillId, view: 0 } });
        })
        .catch(function(error) {
          self.handleErrors(error, function() { self.createNewSkill() }, "");
        });
    },

    closeModal(done) {
      if(done){
        this.createNewSkill();
      }else if(!this.isCreated){
        this.$emit('close-modal');
      }
    },
  }
}
</script>
