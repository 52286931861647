import {
  validateComplete,
  validateSimplified,
} from "../components/conditionschemas_compiled";

export function validateCondition(json, complete) {
  let valid, errors;
  if (complete) {
    valid = validateComplete(json);
    errors = !valid ? validateComplete.errors : [];
  } else {
    valid = validateSimplified(json);
    errors = !valid ? validateSimplified.errors : [];
  }
  return { valid, errors };
}
