<template>
  <div>
    <BaseModal
      id="publishInterventionModal"
      class="interventionPublish pageModal"
      :class="{pageModalActivate: isFirstPage}"
      :headerText="headerText"
      :leftButtonText="$t('generalTranslation.publish')"
      :disableButtons="isCopied"
      :disableLeftButton="selectedStudyData === ''"
      :disableNextButton="loadingDiaryUpdate || !allAccepted || noLanguagePublished"
      :hasSecondPage="true"
      :isFirstPage="isFirstPage"
      :backButtonText="$t('interventionTranslation.publishInterventionBackToSelection')"
      @modal-page-changed="showNewPage"
      @close-modal="closeModal"
    >
      <template v-slot:body>
        <div v-if="isFirstPage">
          <label>
            {{ 'myStudiesTranslation.activateMode' | translate }}
          </label>
          <select v-model="activationTypeIsTest" id="inputPublishInterventionType">
            <option :value="-1" id="inputPublishInterventionTypeNone" disabled selected>
              {{ 'generalTranslation.select' | translate }}
            </option>
            <option :value="true" id="inputPublishInterventionTypeTest" key="actTest">
              {{ 'myStudiesTranslation.activateTest' | translate }}
            </option>
            <option :value="false" id="inputPublishInterventionTypeReal" key="actReal">
              {{ 'myStudiesTranslation.activateReal' | translate }}
            </option>
          </select>
          <br>
          <br>
          <section v-if="activationTypeIsTest && activationTypeIsTest != -1">
            <section class="checkboxSection notBold">
              <label class="container">
                {{ 'myStudiesTranslation.activateTestConfirm' | translate }}
                <input type="checkbox" v-model="confirmTest" id="inputPublishTestConfirm">
                <span class="checkmark"></span>
              </label>
            </section>
          </section>

          <section v-if="!activationTypeIsTest && activationTypeIsTest != -1">
            <label>
              {{ 'myStudiesTranslation.activateHint' | translate }}
            </label>
            <br>
            <section class="checkboxSection notBold">
              <label class="container">
                {{ 'myStudiesTranslation.activateChecked' | translate }}
                <input type="checkbox" v-model="confirmChecked" id="inputPublishChecked">
                <span class="checkmark"></span>
              </label>
            </section>
            <br>
            <br>
            <section class="checkboxSection notBold">
              <label class="container">
                {{ 'myStudiesTranslation.activateCritical' | translate }}
                <input type="checkbox" v-model="confirmCritical" id="inputPublishCritical">
                <span class="checkmark"></span>
              </label>
            </section>
            <section class="checkboxSection notBold">
              <label class="container">
                {{ 'myStudiesTranslation.activateEmergencyText' | translate }}
                <input type="checkbox" v-model="confirmEmergencyText" id="inputPublishEmergency">
                <span class="checkmark"></span>
              </label>
            </section>
            <section class="checkboxSection notBold">
              <label class="container">
                {{ 'myStudiesTranslation.activateIntro' | translate }}
                <input type="checkbox" v-model="confirmIntro" id="inputPublishIntro">
                <span class="checkmark"></span>
              </label>
            </section>
            <section class="checkboxSection notBold">
              <label class="container">
                {{ 'myStudiesTranslation.activateContact' | translate }}
                <input type="checkbox" v-model="confirmContact" id="inputPublishContact">
                <span class="checkmark"></span>
              </label>
            </section>
          </section>

          <section class="alignBottom">
            <section v-if="activationTypeIsTest != -1" :class="{ textOfficial: !activationTypeIsTest, textTest: activationTypeIsTest }">
              {{ 'myStudiesTranslation.activateOtherPerson' | translate }}
            </section>
            {{ 'myStudiesTranslation.activateText' | translate }}
            <section>
              <br>
              <label>
                {{ 'myStudiesTranslation.chooseLanguageToPublish' | translate }}
              </label>
              <br>
              <section class="checkboxSection notBold wrapper">
                <div v-for="lang in languages" :key="lang.code">
                  <label class="container">
                    {{ lang.code }}
                    <input v-model="lang.getsPublished" type="checkbox" :id="'publishLanguageCheckbox' + lang.code">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </section>
            </section>
          </section>
        </div>

        <div v-else>
          <h3 class="hCopy">
            {{ $t("interventionTranslation.activateInstruction") }}
          </h3>
          <p>
            {{ 'interventionTranslation.shownStudies' | translate }}
          </p>
          <StudyList
            :studyListData="studyListData"
            :copyBool="true"
            :activateBool="true"
            :disableWhileCopy="isCopied"
            @get-new-list="getNewStudies"
            @item-selected="setSelectedStudy"
            @close-notification="closeNotification"
            @reset-page="resetPage"
          ></StudyList>
        </div>
      </template>
    </BaseModal>

    <InterventionPublishCheckModal
      v-if="showProblemModal"
      :interventionToCheck="interventionToCheck"
      :sourceData="sourceData"
      :publishedLocales="publishedLocales"
      :activationTypeIsTest="activationTypeIsTest"
      @return-to-selection="returnToSelection"
      @close-modal="closeProblemModal"
      @close-notification="closeNotification"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import httpHelper from "../mixins/httpHelper";
import StudyList from "./StudyList.vue";
import BaseModal from "./BaseModal.vue";
import InterventionPublishCheckModal from "./InterventionPublishCheckModal.vue";

export default {
  name: "InterventionPublish",

  components: {
    BaseModal,
    InterventionPublishCheckModal,
    StudyList,
  },

  mixins: [httpHelper],

  props: {
    sourceData: { //data of the intervention which should be copied or activated (selectedIntervention (id, name, config, interventionType))
      required: true,
      type: Object,
    },
  },

  data: function() {
    const languages = this.sourceData.selectedIntervention.attributes.locales.map(l => ({ code: l, getsPublished: false }));
    return {
      isFirstPage: true,
      activationTypeIsTest: -1,
      confirmTest: false,
      confirmChecked: false,
      confirmCritical: false,
      confirmEmergencyText: false,
      confirmIntro: false,
      confirmContact: false,
      isCopied: false,
      studyListData: "",
      selectedStudyData: "",
      languages,
      showProblemModal: false,
      loadingDiaryUpdate: false,
    };
  },

  computed: {
    ...mapGetters([
      "getNotificationText",
    ]),

    headerText: function() {
      const name = this.interventionToCheck.name;
      const locales = this.publishedLocales.length > 0 && !this.isFirstPage ? `(${this.publishedLocales.join(", ")})` : "";
      const prefix = Vue.i18n.translate("interventionTranslation.activateIntervention", { name, locales });
      let header = prefix + " >> ";
      if (this.isFirstPage) {
        const selection = Vue.i18n.translate("interventionTranslation.selection");
        header += selection + " (1/3)";
      } else {
        const publication = Vue.i18n.translate("interventionTranslation.publication");
        header += publication + " (3/3)";
      }
      return header;
    },

    allAccepted: function() {
      const typeSelected = this.activationTypeIsTest !== -1;
      const testIntAccepted = this.activationTypeIsTest && this.confirmTest;
      const officialIntAccepted = !this.activationTypeIsTest && this.confirmChecked && this.confirmCritical && this.confirmEmergencyText && this.confirmIntro && this.confirmContact;
      return typeSelected && (testIntAccepted || officialIntAccepted);
    },

    publishedLocales: function() {
      return this.languages.filter(l => l.getsPublished).map(l => l.code);
    },

    noLanguagePublished: function() {
      return this.publishedLocales.length === 0;
    },

    interventionToCheck: function() {
      return {
        name: this.sourceData.selectedIntervention.attributes.name,
        id: this.sourceData.selectedIntervention.id,
      };
    },
  },

  async created() {
    document.body.style.overflow = "hidden";
    this.loadingDiaryUpdate = true;
    this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("interventionTranslation.checkConfigLoadDiaries") });
    const checkElements = false;
    const self = this;
    const interventionAllDetails = await this.requestPublishInterventionDetails(
      this.interventionToCheck.id,
      checkElements,
    ).catch(function(error) {
        return self.handleErrors(
          error,
          function() { return self.requestPublishInterventionDetails(self.interventionToCheck.id, false); },
          "",
        );
      });
    if (interventionAllDetails) {
      const checkedDiaires = [];
      for (const diary of interventionAllDetails.unlock_diaries || []) {
        if (checkedDiaires.every(id => id != diary.id)) {
          await this.updateDiaryQuestionnaire(diary);
          checkedDiaires.push(diary.id);
        }
      }
      for (const lesson of interventionAllDetails.questionnaires || []) {
        for (const diary of lesson.unlock_diaries || []) {
          if (checkedDiaires.every(id => id != diary.id)) {
            await this.updateDiaryQuestionnaire(diary);
            checkedDiaires.push(diary.id);
          }
        }
      }
      this.closeNotification();
    }
    this.loadingDiaryUpdate = false;
  },

  beforeDestroy() {
    document.body.style.overflow = "visible";
  },

  watch:{
    getNotificationText(newVal) {
      if (newVal != "" && newVal.type === "error") {
        this.isCopied = false;
      }
    },
  },

  methods: {
    ...mapMutations([
      "SET_NOTIFICATIONTEXT",
    ]),

    updateDiaryQuestionnaire: async function(diary) {
      const { locales, questionnaire } = diary;
      if (locales.length !== questionnaire.locales?.length || JSON.stringify(locales) !== JSON.stringify(questionnaire.locales)) {
        const self = this;
        this.updateLessonDetailsRequest({ locales }, questionnaire.id)
          .catch(function(error) {
            self.handleErrors(error, function() { self.updateLessonDetailsRequest({ locales }, questionnaire.id) }, "");
          });
      }
    },

    loadStudies: function() {
      this.isFirstPage = false;
      this.getNewStudies({ page: 1, filterTerm: "" });
    },

    showNewPage: function(next) {
      if (next) {
        this.showProblemModal = true;
      } else {
        this.selectedStudyData = "";
        this.isFirstPage = true;
      }
    },

    returnToSelection: function() {
      this.isFirstPage = true;
      this.showProblemModal = false;
    },

    setSelectedStudy: function(obj) {
      this.selectedStudyData = obj;
    },

    closeProblemModal: async function(done) {
      if (done) {
        this.loadStudies();
      } else if (done === null) { // Close both publish and problem modals
        this.$emit("close-modal", { done: false });
      } else {
        this.isFirstPage = true;
      }
      this.showProblemModal = false;
    },

    closeModal: function(done) {
      if (done) {
        if (this.selectedStudyData === "") {
          this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("generalTranslation.errorSelectStudy") });
        } else {
          this.isCopied = true;
          this.$emit("set-changed", { name: "all", value: false });
          const targetStudyId = this.selectedStudyData.studyId;
          this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("interventionTranslation.publishInterventionLoad") });
          this.getLessons(targetStudyId);
        }
      } else if (!this.isCopied) {
        this.$emit("close-modal", { done: false });
      }
    },

    getLessons: function(targetStudyId) {
      const self = this;
      const userCanEditTarget = (this.selectedStudyData.role === "Owner" || this.selectedStudyData.role === "Collaborator");
      this.requestLessonsOfIntervention(this.sourceData.selectedIntervention.id, 0, userCanEditTarget, false, -1, -1, false)
        .then(function(response) {
          if (response.allLessons.length === 0) {
            self.$emit("close-modal", { done: false });
            self.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("myStudiesTranslation.lessonWarning") });
            return;
          }
          const allLessons = response.allLessons;
          //customorder is not changed when positions of lessons change
          //-> is only collection of lesson ids which are included in the intervention per default
          //-> set in correct order
          const customOrder = self.sourceData.selectedIntervention.attributes.default_configuration.custom_order;
          const newCustomOrder = [];
          const questionnaireConfig = self.sourceData.selectedIntervention.attributes.default_configuration.questionnaire_configuration;
          const allLessonIds = [];
          for (const i in allLessons) {
            const lessonId = Number(allLessons[i].id);
            if (customOrder.includes(lessonId)) {
              newCustomOrder.push(lessonId);
            }
            allLessonIds.push(lessonId);
            //add entries for lessons not included in config
            const inConfig = questionnaireConfig.findIndex(elem => Number(elem.id) === lessonId);
            if (inConfig === -1) {
              questionnaireConfig.push({
                id: lessonId.toString(),
                unlock_type: "after_previous",
                unlock_days_after_start: null,
                feedback_required: false,
              });
            }
          }
          //remove config of deleted questionnaires and duplicate entries from questionnaire_configuration
          const newQuestionnaireConfig = [];
          for (const i in questionnaireConfig) {
            const qreConfig = questionnaireConfig[i];
            if (allLessonIds.includes(Number(qreConfig.id))) {
              newQuestionnaireConfig.push(qreConfig);
            }
          }
          //change unlocktype of first lesson if after_previous; unaccompannied intervention can not have manually; remove condition labels
          const firstLesson = newCustomOrder[0];
          for (const i in newQuestionnaireConfig) {
            const qreConfig = newQuestionnaireConfig[i];

            if (qreConfig.unlock_type === "manually" && self.sourceData.selectedIntervention.attributes.intervention_type === "unaccompanied") {
              qreConfig.unlock_type = "after_previous";
            } else if (qreConfig.unlock_type === "after_previous" && qreConfig.id === firstLesson) {
              qreConfig.unlock_type = "always";
            }

            if (self.sourceData.selectedIntervention.attributes.intervention_type === "unaccompanied") {
              qreConfig.feedback_required = false;
            }

            if (self.$enabledFeatures.includes("jitai")) {
              if (qreConfig.unlock_type === "conditional") {
                for (const condition in qreConfig.condition) {
                  delete qreConfig.condition[condition]["labels"];
                }
                if (qreConfig.bonus_lesson === undefined) {
                  qreConfig.bonus_lesson = true;
                }
              } else {
                qreConfig.condition = null;
              }
            } else if (qreConfig.unlock_type === "conditional") {
              qreConfig.unlock_type = "always";
              delete qreConfig["condition"];
            }
          }

          const defaultConfig = {
            questionnaire_configuration: newQuestionnaireConfig,
            custom_order: newCustomOrder,
          };
          const locales = self.publishedLocales;
          self.updateConfigAndCopyInStudy(defaultConfig, targetStudyId, locales);
        })
        .catch(function(error) {
          self.handleErrors(error, function() { self.getLessons(targetStudyId) }, "");
        });
    },

    updateConfigAndCopyInStudy: function(defaultConfig, targetStudyId, locales) {
      const self = this;
      this.copyInterventionInStudyRequest(
        this.sourceData.selectedIntervention.id,
        targetStudyId,
        defaultConfig,
        locales,
        this.activationTypeIsTest,
      ).then(function() {
          self.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("interventionTranslation.publishInterventionSuccess") });
          self.$emit("close-modal", { done: false }); //done false because no further actions after close (only success notification)
        })
        .catch(function(error) {
          self.handleErrors(error, function() { self.updateConfigAndCopyInStudy(defaultConfig, targetStudyId, locales) }, "");
        });
    },

    closeNotification: function() {
      this.$emit("close-notification");
    },

    getNewStudies: function(obj) {
      //activate needs owner or ecoachmanager in target study
      const filterTerm = obj.filterTerm + "&role=study.owner,study.ecoachmanager";
      const self = this;
      this.requestCollaboratingOrNonCollaboratingStudies(obj.page, filterTerm, "study", true, false, -1)
        .then(function(response) {
          self.studyListData = response;
        })
        .catch(function(error) {
          self.handleErrors(error, function() { self.getNewStudies(obj) }, "");
        });
    },

    resetPage: function() {
      this.studyListData.currentPage = 1;
    },
  }
}
</script>
