<template>
  <div class="elementsQuestionSlider">
    <section class="wrapper">
      <section v-if="!isTranslation" class="col sec15">
        <label>
          {{ 'elementsQuestionTranslation.valueMin' | translate }} *
        </label>
        <input type="number" :value="propsObj.values.min" :disabled="disableInputs" :id="'inputSliderMinValue' + propsObj.id"
          :placeholder="$t('elementsQuestionTranslation.valueMin')" @input="changeValue($event.target.value, 'min')">

        <label>
          {{ 'elementsQuestionTranslation.valueMax' | translate }} *
        </label>
        <input type="number" :value="propsObj.values.max" :disabled="disableInputs" :id="'inputSliderMaxValue' + propsObj.id"
          :placeholder="$t('elementsQuestionTranslation.valueMax')" @input="changeValue($event.target.value, 'max')">

        <label>
          {{ 'elementsQuestionTranslation.step' | translate }} *
        </label>
        <input type="number" :value="propsObj.values.step" :disabled="disableInputs" :id="'inputSliderStepValue' + propsObj.id"
          min="0" :placeholder="$t('elementsQuestionTranslation.step')" @input="changeValue($event.target.value, 'step')">

        <label>
          {{ 'elementsQuestionTranslation.startValue' | translate }} *
        </label>
        <input type="number" :value="propsObj.values.start" :disabled="disableInputs" :id="'inputSliderStartValue' + propsObj.id"
          :placeholder="$t('elementsQuestionTranslation.startValue')" @input="changeValue($event.target.value, 'start')">
      </section>
      <section class="col secSliderMiddle">
        <label>
          {{sliderTextLabelInLanguage}} *
        </label>
        <input type="text" v-if="getIsEditable" :value="translation.answers[0].label" maxlength="40" :id="'inputSliderMinText' + propsObj.id + (isTranslation ? 'Translation' : '')"
          :placeholder="$t('elementsQuestionTranslation.labelMinMax')" @input="changeSliderLabel($event.target.value, 0)">
        <div v-else :id="'sliderMinText' + propsObj.id + (isTranslation ? 'Translation' : '')">
          <br>
          {{translation.answers[0].label}}
          <br><br>
        </div>

        <label>
          {{sliderTextLabelInLanguage}} *
        </label>
        <input type="text" v-if="getIsEditable" :value="translation.answers[1].label" maxlength="40" :id="'inputSliderMaxText' + propsObj.id + (isTranslation ? 'Translation' : '')"
          :placeholder="$t('elementsQuestionTranslation.labelMinMax')" @input="changeSliderLabel($event.target.value, 1)">
        <div v-else :id="'sliderMaxText' + propsObj.id + (isTranslation ? 'Translation' : '')">
          <br>
          {{translation.answers[0].label}}
          <br><br>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';

export default {
  name: 'ElementsQuestionSlider',

  props: {
    propsObj: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: Object,
    },

    isTranslation: {
      required: true,
      type: Boolean,
    },

    allowStructureChanges: {
      type: Boolean,
      default: null,
    },
  },

  computed: {
    ...mapGetters([
      'getIsEditable',
    ]),

    sliderTextLabelInLanguage: function(){
      return Vue.i18n.translate('elementsQuestionTranslation.labelMinMax' + (this.isTranslation ? 'Translation' : ''))
    },

    disableInputs: function() {
      return !this.getIsEditable || this.allowStructureChanges === false;
    },
  },

  methods:{
    changeValue: function(value, pos){
      this.$emit(
        'change-element',
        { type: 'attribute', name: 'values', pos: pos, isTranslation: this.isTranslation, value: value },
      );
    },

    changeSliderLabel: function(value, pos){
      this.$emit(
        'change-element',
        {type: 'translation-attribute', name: 'sliderAnswer', pos: pos, isTranslation: this.isTranslation, value: value },
      );
    },
  }
}
</script>
