<template>
  <BaseModal
    id="createInterventionModal"
    class="interventionCreate"
    :class="{pageModal: !targetData.isInStudy}"
    :headerText="headerText"
    :leftButtonText="$t('generalTranslation.create')"
    :disableButtons="isCreated"
    :disableLeftButton="name === '' || !targetData.isInStudy && selectedStudyData === ''"
    :disableNextButton="!targetData.isInStudy && selectedStudyData === ''"
    :hasSecondPage="!targetData.isInStudy"
    :isFirstPage="isFirstPage"
    :backButtonText="$t('interventionTranslation.backToStudySelection')"
    @modal-page-changed="showNewPage"
    @close-modal="closeModal"
  >
    <template v-slot:body>
      <section class="wrapper">
        <h3 class="hCopy" v-if="!targetData.isInStudy && isFirstPage">
          {{ 'interventionTranslation.selectStudy' | translate }}
        </h3>
        <p v-if="!targetData.isInStudy && isFirstPage">
          {{ 'interventionTranslation.shownGroups' | translate }}
        </p>

        <form v-if="targetData.isInStudy || !isFirstPage" :class="{createInterventionInput: !targetData.isInStudy}" @submit.prevent="closeModal(true)">
          <section v-if="!targetData.isInStudy">
            <p>
              <b>
                {{ 'interventionTranslation.selectedStudy' | translate }}:
              </b>
              {{selectedStudyData.studyName}}
            </p>
          </section>
          <label>
            {{labelName}} *
          </label>
          <input type="text" v-model="name" id="inputNewName" :placeholder="labelName" required><br>
        </form>

        <section v-else>
          <StudyList
            :studyListData="studyListData"
            :copyBool="true"
            :activateBool="false"
            :disableWhileCopy="isCreated"
            @get-new-list="getNewStudies"
            @item-selected="setSelectedStudy"
            @close-notification="closeNotification"
            @reset-page="resetPage"
          ></StudyList>
        </section>
      </section>
    </template>
  </BaseModal>
</template>

<script>
import Vue from "vue";
import {mapGetters,mapMutations} from "vuex";
import httpHelper from "../mixins/httpHelper";
import utils from "../mixins/utils";
import StudyList from "./StudyList.vue";
import BaseModal from "./BaseModal.vue";

export default {
  name: "InterventionCreate",

  components: {
    StudyList,
    BaseModal,
  },

  mixins: [httpHelper, utils],

  props: {
    //if used in study editor route (isInStudy true): study in which intervention should be created (isInStudy, name, id)
    //if used on myMoreInterventions: study is selected (only isInStudy)
    targetData: {
      required: true,
      type: Object
    },
  },

  data: function() {
    return {
      name: "",
      isCreated: false,
      isFirstPage: true,
      studyListData: "",
      selectedStudyData: "",
    };
  },

  computed: {
    ...mapGetters([
      "getNotificationText",
    ]),

    headerText: function(){
      if(!this.targetData.isInStudy){
        return Vue.i18n.translate("interventionTranslation.newIntervention")
      }else{
        return Vue.i18n.translate("interventionTranslation.newInterventionGroup", { name: this.targetData.name })
      }
    },

    labelName: function(){
      return this.targetData.isInStudy ? Vue.i18n.translate("generalTranslation.name") : Vue.i18n.translate("generalTranslation.nameIntervention")
    }
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.isCreated = false;
      }
    },
  },

  created(){
    document.body.style.overflow = "hidden";
    this.getNewStudies({"page": 1, "filterTerm": ""});
  },

  beforeDestroy(){
    document.body.style.overflow = "visible";
  },

  methods: {
    ...mapMutations([
      "SET_NOTIFICATIONTEXT",
      "SET_MYROLEFORSTUDY",
    ]),

    closeModal: function(done) {
      if(done){
        if (!this.targetData.isInStudy && this.selectedStudyData === "") {
          this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("generalTranslation.errorSelectWorkgroup") });
          return;
        }
        if (!this.name) {
          this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("generalTranslation.errorNameEmpty") });
          return;
        }
        this.isCreated = true;
        this.$emit("set-changed", { name: "all", value: false });
        this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("interventionTranslation.createInterventionLoad") });
        let studyIdForInt;
        if (!this.targetData.isInStudy) {
          this.SET_MYROLEFORSTUDY(this.selectedStudyData.role);
          studyIdForInt = this.selectedStudyData.studyId;
        } else {
          studyIdForInt = this.targetData.id;
        }
        const locales = this.getDefaultLocales();
        const self = this;
        this.createInterventionRequest(studyIdForInt, this.name, locales)
          .then(function(newInterventionId) {
            self.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("interventionTranslation.createInterventionSuccess") });
            self.$emit("route-to", { name: "interventionEditor", params: { interventionId: newInterventionId, view: 0 }});
          })
          .catch(function(error) {
            self.handleErrors(error, function() { self.closeModal(done) }, "");
          });
      }else if(!this.isCreated){
        this.$emit("close-modal", { done: false });
      }
    },

    setSelectedStudy: function(obj){
      this.selectedStudyData = obj;
    },

    showNewPage: function(next){
      if(next){
        if(this.selectedStudyData === ""){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('generalTranslation.errorSelectWorkgroup')});
        }else{
          this.isFirstPage = false;
        }
      }else{
        this.selectedStudyData = "";
        this.isFirstPage = true;
        this.getNewStudies({"page": 1, "filterTerm": ""});
      }
    },

    getNewStudies: function(obj){
      var filterTerm = obj.filterTerm + "&role=study.owner,study.collaborator";
      var self = this;
      this.requestCollaboratingOrNonCollaboratingStudies(obj.page, filterTerm, "workgroup", true, false, -1)
      .then( function(response){
        self.studyListData = response;
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.getNewStudies(obj) }, "");
      });
    },

    resetPage: function(){
      this.studyListData.currentPage = 1;
    },

    closeNotification: function(){
      this.$emit('close-notification');
    },
  }
}
</script>
