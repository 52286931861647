<template>
  <div v-if="schedules">
    <div v-for="(schedule, index) in schedules" :key="'schedule' + index" :id="'schedule' + index" class="scheduleBorder">
      <div class="scheduleInsideBorderMargin">
        <div>
          <strong :style="{ 'margin-right': '5px' }">{{ index + 1 }}</strong>
          <i
            v-if="getIsEditable"
            @click="showDeleteScheduleModal(index)"
            :id="'scheduleDelete' + index"
            v-tooltip="{ content: $t('schedules.deleteSchedule'), placement: 'right' }"
            class="fa fa-trash"
          ></i>
        </div>
          <strong>{{ $t("schedules.type") }}: </strong>
          <select v-model="schedule.type" :id="'scheduleType' + index" :disabled="!getIsEditable">
            <option value="" id="inputGuidedNone" disabled>
              {{ $t("generalTranslation.select") }}
            </option>
            <option v-for="type in types" :value="type.value" :key="type.value + '_' + index" :id="'scheduleType' + index + type.value">
              {{ type.text }}
            </option>
          </select>
        <div :key="schedule.type" class="row">
          <div class="col sec50">
            <strong>{{ $t("schedules.timeOfDay") }}: </strong>
            <flatPickr
              :value="schedule.time_of_day.hour + ':' + schedule.time_of_day.minute"
              :config="timeConfig"
              :id="getInputScheduleTimeOfDayIdPrefix + index"
              :placeholder="$tlang(getAppLanguage, 'generalTranslation.select')"
              class="scheduleTimeOfDay"
            ></flatPickr>
          </div>
          <div v-if="schedule.type === 'weekly'" class="col sec50">
            <strong>{{ $t("schedules.dayOfWeek") }}: </strong>
            <select v-model="schedule.day_of_week" :id="'scheduleDayOfWeek' + index">
              <option value="" id="inputGuidedNone" disabled>
                {{ $t("generalTranslation.select") }}
              </option>
              <option v-for="day in weekdays" :value="day.value" :key="day.value + 'w_' + index" :id="'scheduleDayOfWeek' + index + day.value">
                {{ day.text }}
              </option>
            </select>
          </div>
          <div v-else-if="schedule.type === 'monthly'" class="col sec50">
            <strong>{{ $t("schedules.dayOfMonth") }}: </strong>
            <input
              type="number"
              v-model.number="schedule.day_of_month"
              :id="'inputScheduleDayOfMonth' + index"
              :min="1"
              :max="31"
              :disabled="!getIsEditable"
              class="scheduleDayOfMonth"
              @change="onDayOfMonthChange($event, schedule)"
              @keypress="allowOnlyDigits($event)"
            >
          </div>
          <div v-else class="col sec50"></div>
        </div>
        <!-- <div> -->
        <!--   <input class="checkmark" v-model="schedule.__state.expire_in_seconds" type="checkbox" :id="'scheduleUseExpireInSeconds' + index"> -->
        <!--   <strong :class="{ disabledInput: !schedule.__state.expire_in_seconds }">{{ $t("schedules.expireInSeconds") }}: </strong> -->
        <!--   <input -->
        <!--     type="number" -->
        <!--     v-model.number="schedule.expire_in_seconds" -->
        <!--     :id="'inputScheduleExpiresInSeconds' + index" -->
        <!--     :min="0" -->
        <!--     :disabled="!getIsEditable || !schedule.__state.expire_in_seconds" -->
        <!--     class="scheduleExpiresInSeconds" -->
        <!--     @change="onExpireInSecondsChange($event, schedule)" -->
        <!--     @keypress="allowOnlyDigits($event)" -->
        <!--   > -->
        <!-- </div> -->
        <div>
            <section class="col sec50" :style="{width: '53%'}">
            <input class="checkmark" v-model="schedule.__state.start" type="checkbox" :id="'scheduleUseExpireInSeconds' + index">
            <strong :class="{ disabledInput: !schedule.__state.start }">{{ $t("schedules.start") }}: </strong>
            <flatPickr
              :value="schedule.start"
              :config="dateTimeConfig"
              :id="getInputScheduleStartIdPrefix + scheduleStartEndSeparator + index"
              :placeholder="$tlang(getAppLanguage, 'generalTranslation.select')"
              :disabled="!schedule.__state.start"
              class="dateTimePicker"
            ></flatPickr>
          </section>
          <section class="col sec50">
            <input class="checkmark" v-model="schedule.__state.end" type="checkbox" :id="'scheduleUseExpireInSeconds' + index">
            <strong :class="{ disabledInput: !schedule.__state.end }">{{ $t("schedules.end") }}: </strong>
            <flatPickr
              :value="schedule.end"
              :config="dateTimeConfig"
              :id="getInputScheduleEndIdPrefix + scheduleStartEndSeparator + index"
              :placeholder="$tlang(getAppLanguage, 'generalTranslation.select')"
              :disabled="!schedule.__state.end"
              class="dateTimePicker"
            ></flatPickr>
          </section>
        </div>
        <div>
          <strong>{{ $t("schedules.reminderAfterMinutes") }}: </strong>
          <span v-if="schedule.reminders_after_minutes.length > 0">{{ schedule.reminders_after_minutes }}</span>
          <button
            type="button"
            :id="'scheduleReminders' + index"
            :disabled="!getIsEditable"
            @click="showScheduleRemindersModal(schedule.reminders_after_minutes, index)"
            :style="{ 'margin-left': '5px' }"
          >
            {{ $t("schedules.manageReminders") }}
          </button>
        </div>
      </div>
    </div>
    <button
      type="button"
      id="scheduleAddBottom"
      :disabled="!getIsEditable"
      @click="showAddScheduleModal"
    >
      {{ $t("schedules.addSchedule") }}
    </button>

    <BaseModal
      v-if="addScheduleModalVisible"
      id="handleAddScheduleModal"
      :headerText="$t('schedules.addSchedule')"
      :leftButtonText="$t('interventionTranslation.ok')"
      @close-modal="addSchedule"
    >
      <template v-slot:body>
        <strong>{{ $t("schedules.amount") }}: </strong>
        <select v-model="addScheduleAmount" id="addScheduleDropdown" :disabled="!getIsEditable">
          <option value="" id="inputGuidedNone" disabled>
            {{ $t("generalTranslation.select") }}
          </option>
          <option v-for="n in 5" :value="n" :key="n + '_add'" :id="'addSchedule' + n">
            {{ n }}
          </option>
        </select>
      </template>
    </BaseModal>

    <BaseModal
      v-if="scheduleRemindersModalVisible"
      id="handleScheduleRemindersModal"
      :headerText="$t('schedules.reminderAfterMinutes')"
      :leftButtonText="$t('interventionTranslation.ok')"
      @close-modal="setScheduleReminder"
    >
      <template v-slot:body>
        <input
          class="addScheduleReminderInput"
          type="number"
          v-model.number="scheduleReminder"
          :id="'inputScheduleReminder' + index"
          :min="1"
          :disabled="!getIsEditable"
        >
        <button
          type="button"
          :id="'addScheduleReminder' + index"
          :disabled="!getIsEditable || scheduleReminder === ''"
          @click="addScheduleReminder"
        >
          {{ $t("interventionTranslation.add") }}
        </button>
        <ul :key="scheduleReminders.length">
          <li class="scheduleReminders" v-for="(minutes, index) in scheduleReminders" :id="'scheduleReminders' + index" :key="'scheduleReminders' + index">
            <i
              v-if="getIsEditable"
              @click="deleteScheduleReminder(index)"
              :id="'scheduleReminderDelete' + index"
              v-tooltip="$t('generalTranslation.delete')"
              class="fa fa-trash"
            ></i>
            {{ minutes }}
          </li>
        </ul>
      </template>
    </BaseModal>

    <BaseModal
      v-if="deleteScheduleModalVisible"
      id="deleteScheduleModal"
      :headerText="deleteScheduleHeaderText"
      :bodyText="deleteScheduleText"
      :leftButtonText="$t('interventionTranslation.ok')"
      @close-modal="deleteSchedule"
    ></BaseModal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import BaseModal from "./BaseModal.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/l10n/de.js";

export default {
  name: "Schedules",

  components: {
    BaseModal,
    flatPickr,
  },

  props: {
    schedules: {
      required: true,
      type: Array,
    },
  },

  data: function() {
    return {
      addScheduleModalVisible: false,
      addScheduleAmount: 1,
      scheduleRemindersModalVisible: false,
      scheduleReminder: 10,
      scheduleReminders: [],
      scheduleRemindersIndex: -1,
      scheduleStartEndSeparator: "#",
      deleteScheduleModalVisible: false,
      deleteScheduleIndex: -1,
    };
  },

  computed: {
    ...mapGetters([
      "getAppLanguage",
      "getIsEditable",
    ]),

    timeConfig: function() {
      return {
        altFormat: Vue.i18n.translateIn(this.getAppLanguage, "elementsQuestionTranslation.timeFormat"),
        altInput: true,
        dateFormat: "H:i",
        enableTime: true,
        time_24hr: this.show24hrs,
        noCalendar: true,
        defaultDate: "12:00",
        locale: this.getAppLanguage,
        position: "auto center",
        onClose: (selectedDates, datestr, instance) => {
          if (selectedDates.length === 0) {
            return;
          }
          const idPrefix = this.getInputScheduleTimeOfDayIdPrefix.length;
          const index = parseInt(instance.element.id.slice(idPrefix), 10);
          const [hourStr, minuteStr] = datestr.split(":");
          const timeOfDay = {
            hour: parseInt(hourStr, 10),
            minute: parseInt(minuteStr, 10),
          };
          this.$emit("update-schedule", { name: "timeOfDay", timeOfDay, index });
        },
      };
    },

    dateTimeConfig: function() {
      return {
        altFormat: this.dateFormat,
        altInput: true,
        enableTime: true,
        time_24hr: this.show24hrs,
        defaultHour: 12,
        defaultMinute: 0,
        locale: this.getAppLanguage,
        position: "auto center",
        onClose: (selectedDates, _datestr, instance) => {
          if (selectedDates.length === 0) {
            return; // No date was selected
          }
          const idAndIndex = instance.element.id.split(this.scheduleStartEndSeparator);
          if (idAndIndex.length !== 2) {
            return; // Invalid ID for flatPickr, shouldn't happen
          }
          const [id, indexStr] = idAndIndex;
          const index = parseInt(indexStr, 10);
          if (Number.isNaN(index)) {
            return; // Invalid ID for flatPickr, shouldn't happen
          }
          const selectedDate = selectedDates[0];
          const name = this.getInputScheduleStartIdPrefix === id ? "start" : "end";
          this.$emit("update-schedule", { name, selectedDate, index });
        },
      };
    },

    show24hrs: function() {
      return Vue.i18n.translateIn(this.getAppLanguage, "elementsQuestionTranslation.ampm") === "false";
    },

    dateFormat: function() {
      return this.getAppLanguage !== "en" ? "l, j. F Y, H:i" : "l, F j, Y, H:i K";
    },

    getInputScheduleTimeOfDayIdPrefix: function() {
      return "inputScheduleTimeOfDay";
    },

    getInputScheduleStartIdPrefix: function() {
      return "inputScheduleStart";
    },

    getInputScheduleEndIdPrefix: function() {
      return "inputScheduleEnd";
    },

    types: function() {
      return [
        { value: "daily", text: Vue.i18n.translate("interventionTranslation.daily") },
        { value: "weekly", text: Vue.i18n.translate("interventionTranslation.weekly") },
        { value: "monthly", text: Vue.i18n.translate("interventionTranslation.monthly") },
      ];
    },

    weekdays: function() {
      return [
        { value: "monday", text: Vue.i18n.translate("interventionTranslation.monday") },
        { value: "tuesday", text: Vue.i18n.translate("interventionTranslation.tuesday") },
        { value: "wednesday", text: Vue.i18n.translate("interventionTranslation.wednesday") },
        { value: "thursday", text: Vue.i18n.translate("interventionTranslation.thursday") },
        { value: "friday", text: Vue.i18n.translate("interventionTranslation.friday") },
        { value: "saturday", text: Vue.i18n.translate("interventionTranslation.saturday") },
        { value: "sunday", text: Vue.i18n.translate("interventionTranslation.sunday") },
      ];
    },

    deleteScheduleText: function() {
      return Vue.i18n.translate("schedules.deleteScheduleText");
    },

    deleteScheduleHeaderText: function() {
      return Vue.i18n.translate("schedules.deleteSchedule");
    },
  },

  methods: {
    showAddScheduleModal: function() {
      this.addScheduleModalVisible = true;
    },

    addSchedule: function(done) {
      if (done && this.addScheduleAmount > 0) {
        this.$emit("update-schedule", { name: "add", amount: this.addScheduleAmount });
      }
      this.addScheduleModalVisible = false;
    },

    deleteSchedule: function(done) {
      if (done) {
        this.$emit("update-schedule", { name: "delete", index: this.deleteScheduleIndex });
      }
      this.deleteScheduleModalVisible = false;
    },

    showDeleteScheduleModal: function(index) {
      this.deleteScheduleIndex = index;
      this.deleteScheduleModalVisible = true;
    },

    showScheduleRemindersModal: function(reminders, index) {
      this.scheduleReminders = JSON.parse(JSON.stringify(reminders));
      this.scheduleRemindersIndex = index;
      this.scheduleRemindersModalVisible = true;
    },

    setScheduleReminder: function(done) {
      if (done) {
        this.$emit(
          "update-schedule",
          {
            name: "reminders",
            reminders: this.scheduleReminders,
            index: this.scheduleRemindersIndex,
          },
        );
      }
      this.scheduleRemindersModalVisible = false;
    },

    addScheduleReminder: function() {
      const minutes = this.scheduleReminder;
      this.scheduleReminder = minutes > 1 ? Math.floor(minutes) : 1;
      this.scheduleReminders.push(this.scheduleReminder);
    },

    deleteScheduleReminder: function(index) {
      this.scheduleReminders.splice(index, 1);
    },

    onDayOfMonthChange: function(event, schedule) {
      const value = event.target.value || 1;
      if (value <= 1) {
        schedule.day_of_month = 1;
      } else if (value >= 31) {
        schedule.day_of_month = 31;
      } else {
        schedule.day_of_month = Math.floor(value);
      }
    },

    onExpireInSecondsChange: function(event, schedule) {
      const value = event.target.value || 0;
      schedule.expire_in_seconds = value > 0 ? Math.floor(value) : 0;
    },

    allowOnlyDigits(event) {
      // Valid examples: Digit0, ..., Digit9; Numpad0, ..., Numpad9
      const code = event.code;
      if (code) {
        let digit;
        if (code.startsWith("Digit")) {
          digit = code.slice(5);
        } else if (code.startsWith("Numpad")) {
          digit = code.slice(6);
        } else {
          event.preventDefault();
          return;
        }
        if (digit.length !== 1 || digit < "0" || digit > "9") {
          event.preventDefault();
        }
      }
    }
  },
}
</script>
