<template>
  <BaseModal
    id="publishInterventionCheckModal"
    class="interventionPublish pageModal pageModalActivate"
    :headerText="headerText"
    :leftButtonText="$t('interventionTranslation.publication')"
    :backButtonText="$t('interventionTranslation.publishInterventionBackToChecks')"
    :firstPageBackButtonText="$t('interventionTranslation.publishInterventionBackToSelection')"
    :isFirstPage="isFirstProblemPage"
    :hasSecondPage="true"
    :hideLeftButton="!allowPublishing"
    :disableLeftButton="!activationTypeIsTest"
    :leftButtonDisabledTooltip="$t('interventionTranslation.publishInterventionOfficialPublishError')"
    :hasFirstPageBack="true"
    :cancelButtonText="$t('interventionTranslation.publishInterventionBackToSelection')"
    @modal-first-page-back="returnToStart"
    @modal-page-changed="showNewProblemPage"
    @close-modal="closeProblemModal"
  >
    <template v-slot:body>
      <div v-if="isFirstProblemPage" class="alignBottom">
        <div class="wrapper">
          <label>{{ $t("interventionTranslation.publishInterventionCheck") }}</label>
          <p>
            {{ $t("interventionTranslation.publishInterventionCompleteCheckTooltip", { locales: "(" + publishedLocales.join(", ") + ")" }) }}
          </p>
          <section class="checkboxSection notBold">
            <label class="container">
              {{ $t("interventionTranslation.publishInterventionCompleteCheck") }}
              <input v-model="checkedElements" type="checkbox" id="complexValidationToggle">
              <span class="checkmark"></span>
            </label>
            <button :disabled="noLanguagePublished" @click="checkTranslations">
              {{ $t("interventionTranslation.publishInterventionCheck") }}
            </button>
          </section>
        </div>
        <div v-if="$enabledFeatures.includes('jitai')">
          <label>{{ $t("interventionTranslation.checkConfig") }}</label>
          <p>{{ $t("interventionTranslation.checkInterventionConfiguration") }}</p>
          <section class="checkboxSection notBold">
            <label class="container">
              {{ $t("interventionTranslation.checkConfigComplexConditions") }}
              <input v-model="checkedComplexConditions" type="checkbox" id="complexConditionValidationToggle">
              <span class="checkmark"></span>
            </label>
            <button type="button" id="buttonPublishCheckConfig" class="buttonForm" @click="checkConfiguration">
              {{ $t("interventionTranslation.checkConfig") }}
            </button>
          </section>
        </div>
        <div v-if="$enabledFeatures.includes('jitai')" class="alignBottom">
          <hr>
          <label>{{ $t("interventionTranslation.checkConfigAndTranslations") }}</label>
          <p>{{ $t("interventionTranslation.checkInterventionConfigurationAndTranslations") }}</p>
          <button type="button" id="buttonPublishCheckInterventionAndConfig" class="buttonForm" @click="checkTranslationsAndConfiguration">
            {{ $t("interventionTranslation.checkConfigAndTranslations") }}
          </button>
        </div>
      </div>
      <div v-else class="problemList3">
        <div v-if="configurationProblems !== null && configurationProblems.hasErrors">
          <hr class="problemListLine">
          <h2 class="problemList4">
            {{ $t("interventionTranslation.checkInterventionConfigurationHeader") }}
          </h2>
          <div v-if="configurationProblems.hasErrors">
            <label class="errorMessage" v-html="sanitizeHTML(configurationErrorText)" />
            <hr class="problemListLine">
          </div>
          <div v-if="configurationProblems.general.length > 0">
            <h3>{{ $t("interventionTranslation.checkInterventionConfigurationGeneralErrors") }}</h3>
            <dl>
              <div class="problemList" v-for="problem in configurationProblems.general" :key="problem">
                <dt class="errorMessage">{{ $t("adminAnnouncementsTranslation.errors") }}</dt>
                <dd>
                  - {{ problem }}
                </dd>
              </div>
            </dl>
            <hr>
          </div>
          <div v-if="configurationProblems.conditions.length > 0">
            <h3>{{ $t("interventionTranslation.checkInterventionConfigurationConditionErrors") }}</h3>
            <dl>
              <div class="problemList" v-for="problem in configurationProblems.conditions" :key="problem.name">
                <dt><label>- {{ problem.name }}</label></dt>
                <div class="problemList2" v-if="problem.errors.length > 0">
                  <dt class="errorMessage">{{ $t("adminAnnouncementsTranslation.errors") }}</dt>
                  <dd v-for="text in problem.errors" :key="text">
                    - {{ text }}
                  </dd>
                </div>
              </div>
            </dl>
            <hr>
          </div>
        </div>
        <div v-if="publishCheckResult !== null && (publishCheckResult.publishError || publishCheckResult.publishWarning)">
          <hr class="problemListLine">
          <h2 class="problemList4">
            {{ $t("interventionTranslation.publishInterventionProblemsHeader") }}
          </h2>
          <div v-if="publishCheckResult.publishError"> <label class="errorMessage">{{ publishErrorText }}</label> <br> </div>
          <div v-if="publishCheckResult.publishWarning"> <label class="warningMessage" v-html="sanitizeHTML(publishWarningText)" /> <br> </div>
          <hr class="problemListLine">
          <div v-if="publishCheckResult.publishProblems.intervention.length > 0">
            <h3>{{ $t("interventionTranslation.intervention") }}</h3>
            <dl>
              <div class="problemList" v-for="problem in publishCheckResult.publishProblems.intervention" :key="problem.name">
                <dt><label>- {{ problem.name }}</label></dt>
                <div class="problemList2" v-if="problem.errors.length > 0">
                  <dt class="errorMessage">{{ $t("adminAnnouncementsTranslation.errors") }}</dt>
                  <dd v-for="text in problem.errors" :key="text">
                    - {{ text }}
                  </dd>
                </div>
                <div class="problemList2" v-if="problem.warnings.length > 0">
                  <dt class="warningMessage">{{ $t("adminAnnouncementsTranslation.warnings") }}</dt>
                  <dd v-for="text in problem.warnings" :key="text">
                    - {{ text }}
                  </dd>
                </div>
              </div>
            </dl>
            <hr>
          </div>
          <div v-if="publishCheckResult.publishProblems.lessons.length > 0">
            <h3>{{ $t("generalTranslation.lessons") }}</h3>
            <dl>
              <div class="problemList" v-for="problem in publishCheckResult.publishProblems.lessons" :key="problem.name">
                <dt><label>- {{ problem.name }}</label></dt>
                <div class="problemList2" v-if="problem.errors.length > 0">
                  <dt class="errorMessage">{{ $t("adminAnnouncementsTranslation.errors") }}</dt>
                  <dd v-for="text in problem.errors" :key="text">
                    - {{ text }}
                  </dd>
                </div>
                <div class="problemList2" v-if="problem.warnings.length > 0">
                  <dt class="warningMessage">{{ $t("adminAnnouncementsTranslation.warnings") }}</dt>
                  <dd v-for="text in problem.warnings" :key="text">
                    - {{ text }}
                  </dd>
                </div>
              </div>
            </dl>
            <hr>
          </div>
          <div v-if="publishCheckResult.publishProblems.skills.length > 0">
            <h3>{{ $t("interventionTranslation.skills") }}</h3>
            <dl>
              <div class="problemList" v-for="problem in publishCheckResult.publishProblems.skills" :key="problem.name">
                <dt><label>- {{ problem.name }}</label></dt>
                <div class="problemList2" v-if="problem.errors.length > 0">
                  <dt class="errorMessage">{{ $t("adminAnnouncementsTranslation.errors") }}</dt>
                  <dd v-for="text in problem.errors" :key="text">
                    - {{ text }}
                  </dd>
                </div>
                <div class="problemList2" v-if="problem.warnings.length > 0">
                  <dt class="warningMessage">{{ $t("adminAnnouncementsTranslation.warnings") }}</dt>
                  <dd v-for="text in problem.warnings" :key="text">
                    - {{ text }}
                  </dd>
                </div>
              </div>
            </dl>
            <hr>
          </div>
          <div v-if="publishCheckResult.publishProblems.diaries.length > 0">
            <h3>{{ $t("studyTranslation.diaries") }}</h3>
            <dl>
              <div class="problemList" v-for="problem in publishCheckResult.publishProblems.diaries" :key="problem.name">
                <dt><label>- {{ problem.name }}</label></dt>
                <div class="problemList2" v-if="problem.errors.length > 0">
                  <dt class="errorMessage">{{ $t("adminAnnouncementsTranslation.errors") }}</dt>
                  <dd v-for="text in problem.errors" :key="text">
                    - {{ text }}
                  </dd>
                </div>
                <div class="problemList2" v-if="problem.warnings.length > 0">
                  <dt class="warningMessage">{{ $t("adminAnnouncementsTranslation.warnings") }}</dt>
                  <dd v-for="text in problem.warnings" :key="text">
                    - {{ text }}
                  </dd>
                </div>
              </div>
            </dl>
            <hr>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import httpHelper from "../mixins/httpHelper";
import BaseModal from "./BaseModal.vue";
import utils from "../mixins/utils";
import interventionChecker from "../mixins/interventionChecker";
import elementJSONToPropsHelper from "../mixins/elementJSONToPropsHelper";
import questionConditionHelper from "../mixins/questionConditionHelper";
import { DependencyGraph } from "../utils/DependencyGraph";

export default {
  name: "InterventionPublishCheckModal",

  components: {
    BaseModal,
  },

  mixins: [
    httpHelper,
    interventionChecker,
    elementJSONToPropsHelper,
    questionConditionHelper,
    utils,
  ],

  props: {
    interventionToCheck: {
      required: true,
      type: Object,
    },

    sourceData: {
      required: true,
      type: Object,
    },

    publishedLocales: {
      required: true,
      type: Array,
    },

    activationTypeIsTest: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      isFirstProblemPage: true,
      allowPublishing: false,
      publishCheckResult: null,
      configurationProblems: null,
      checkedElements: false,
      checkedComplexConditions: false,
    };
  },

  computed: {
    ...mapGetters([
      "getIsEditable",
    ]),

    headerText: function() {
      const name = this.interventionToCheck.name;
      const locales = this.publishedLocales.length > 0 ? `(${this.publishedLocales.join(", ")})` : "";
      const prefix = Vue.i18n.translate("interventionTranslation.activateIntervention", { name, locales });
      let header = prefix + " >> ";
      if (this.isFirstProblemPage) {
        const checks = Vue.i18n.translate("interventionTranslation.checks");
        header += checks;
      } else {
        const problems = Vue.i18n.translate("interventionTranslation.problems");
        header += problems;
      }
      return header + " (2/3)";
    },

    noUnlockedLessonInConfig: function() {
      const prefix = Vue.i18n.translate("interventionTranslation.errorConfigNoUnlockedLesson");
      const always = Vue.i18n.translate("interventionTranslation.always");
      const atDate = Vue.i18n.translate("interventionTranslation.atDate");
      const or = Vue.i18n.translate("interventionTranslation.or");
      if (this.sourceData.selectedIntervention.attributes.intervention_type === "accompanied") {
        const manually = Vue.i18n.translate("interventionTranslation.manually");
        return `${prefix}: "${always}", "${manually}" ${or} "${atDate}".`;
      } else {
        return `${prefix}: "${always}" ${or} "${atDate}".`;
      }
    },

    noLanguagePublished: function() {
      return this.publishedLocales.length === 0;
    },

    configurationErrorText: function() {
      const errors = Vue.i18n.translate("interventionTranslation.checkInterventionConfigurationHasErrors") + "<br>";
      if (this.configurationProblems.conditions.length > 0) {
        const noConditions = Vue.i18n.translate("interventionTranslation.checkInterventionConfigurationHasErrorsNoConditions");
        const emptyValues = Vue.i18n.translate("interventionTranslation.checkInterventionConfigurationHasErrorsMissing");
        if (this.checkedComplexConditions) {
          const invalidLink = Vue.i18n.translate("interventionTranslation.checkInterventionConfigurationHasErrorsInvalidLink");
          const complexCondition = Vue.i18n.translate("interventionTranslation.checkInterventionConfigurationHasErrorsComplexCondition");
          return `${errors}<ol><li>${noConditions}</li><li>${emptyValues}</li><li>${invalidLink}</li><li>${complexCondition}</li></ol>`;
        } else {
          return `${errors}<ol><li>${noConditions}</li><li>${emptyValues}</li></ol>`;
        }
      } else {
        return errors;
      }
    },

    publishErrorText: function() {
      return Vue.i18n.translate("interventionTranslation.publishInterventionErrors");
    },

    publishWarningText: function() {
      const warnings = Vue.i18n.translate("interventionTranslation.publishInterventionWarnings");
      const unsopportedLocales = Vue.i18n.translate("interventionTranslation.publishInterventionWarningsLocales");
      if (this.checkedElements) {
        const incompleteElements = Vue.i18n.translate("interventionTranslation.publishInterventionWarningsElements");
        return `${warnings}<ol><li>${unsopportedLocales}</li><li>${incompleteElements}</li></ol>`;
      } else {
        return `${warnings}<ol><li>${unsopportedLocales}</li></ol>`;
      }
    },
  },

  methods: {
    ...mapMutations([
      "SET_NOTIFICATIONTEXT",
    ]),

    returnToStart: function() {
      this.$emit("return-to-selection");
    },

    requestAndCheckInterventionConfiguration: async function(checkElements) {
      try {
        const interventionId = this.interventionToCheck.id;
        const completeInterventionDetails = await this.requestPublishInterventionDetails(interventionId, checkElements);
        this.publishCheckResult = this.checkInterventionConfiguration(completeInterventionDetails, this.publishedLocales, checkElements);
      } catch (error) {
        const self = this;
        this.handleErrors(error, function() { self.requestAndCheckInterventionConfiguration() }, "");
      }
    },

    showNewProblemPage: async function(next) {
      if (next) {
        this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("interventionTranslation.publishInterventionCheckConfig") });
        await this.requestAndCheckInterventionConfiguration(false);
        this.configurationProblems = await this.validateConfiguration(false);
        this.closeNotification();
        if (this.publishCheckResult.publishError || this.configurationProblems.hasErrors) {
          this.allowPublishing = true;
          this.isFirstProblemPage = false;
        } else {
          this.$emit("close-modal", true);
        }
      } else {
        this.isFirstProblemPage = true;
      }
    },

    checkTranslations: async function() {
      this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("interventionTranslation.publishInterventionCheckConfig") });
      await this.requestAndCheckInterventionConfiguration(this.checkedElements);
      if (this.publishCheckResult.publishWarning || this.publishCheckResult.publishError) {
        this.closeNotification();
        this.configurationProblems = null;
        this.allowPublishing = false;
        this.isFirstProblemPage = false;
      } else {
        this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("interventionTranslation.publishInterventionCheckConfigSuccess") });
      }
    },

    checkConfiguration: async function() {
      this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("interventionTranslation.checkConfigLoad") });
      this.configurationProblems = await this.validateConfiguration(this.checkedComplexConditions);
      if (this.configurationProblems.hasErrors) {
        this.publishCheckResult = null;
        this.allowPublishing = false;
        this.isFirstProblemPage = false;
        this.closeNotification();
      } else {
        this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("interventionTranslation.checkConfigValid") });
      }
    },

    checkTranslationsAndConfiguration: async function() {
      this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("interventionTranslation.publishInterventionCheckConfig") });
      await this.requestAndCheckInterventionConfiguration(this.checkedElements);
      this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("interventionTranslation.checkConfigLoad") });
      this.configurationProblems = await this.validateConfiguration(this.checkedComplexConditions);
      if (this.publishCheckResult.publishWarning || this.publishCheckResult.publishError || this.configurationProblems.hasErrors) {
        this.closeNotification();
        this.allowPublishing = false;
        this.isFirstProblemPage = false;
      } else {
        this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("interventionTranslation.publishInterventionCheckConfigAndTranslationsSuccess") });
      }
    },

    validateConfiguration: async function(checkComplexConditions) {
      const interventionDetails = JSON.parse(JSON.stringify(this.sourceData.selectedIntervention));
      const allLessonsArray = await this.requestAllLessons();
      if (!allLessonsArray) {
        return { publishError: true, publishWarning: false, publishProblems: { intervention: [], lessons: [], skills: [], diaries: [] } };
      }
      const unsortedConfig = interventionDetails.attributes.default_configuration.questionnaire_configuration ?? [];
      const configJSON = [];
      for (const lesson in allLessonsArray) {
        const lessonId = Number(allLessonsArray[lesson].id);
        const cfg = unsortedConfig.find(elem => elem.id == lessonId);
        if (cfg === undefined) {
          configJSON.push(
            {
              id: lessonId,
              unlock_type: "after_previous",
              unlock_days_after_start: null,
              feedback_required: false,
            },
          );
        } else {
          configJSON.push(cfg);
        }
      }
      const unlockedDiaries = {};
      for (const l in allLessonsArray) {
        const lessonConfig = allLessonsArray[l];
        const unlock = lessonConfig.attributes.unlock_diaries || [];
        unlockedDiaries[lessonConfig.id] = unlock;
      }
      unlockedDiaries.int = interventionDetails.attributes.unlock_diaries || [];
      const customOrder = interventionDetails.attributes.default_configuration.custom_order;
      return this.getConfigurationProblems(configJSON, customOrder, unlockedDiaries, allLessonsArray, checkComplexConditions);
    },

    getConfigurationProblems: async function(configJSON, customOrder, unlockedDiaries, allLessonsArray, checkComplexConditions) {
      let hasNoUnlockedLesson = true;
      let hasConditionalLesson = false;
      let hasNoInvalidCondition = true;
      const lessonIdsWithLinkedQuestion = new Map();
      const allComplexConditions = [];
      const problems = { conditions: [], general: [], hasErrors: false };
      for (let l = 0; l < configJSON.length; l++) {
        const lesson = configJSON[l];
        const lessonId = Number(lesson.id);
        if (lesson.unlock_type === "conditional") {
          const conditionalProblem = { id: lesson.id, name: undefined, errors: [] };
          if (lesson.condition && lesson.condition.length > 0) {
            const complexConditions = [];
            for (let c = 0, position = 1; c < lesson.condition.length; c++, position++) {
              const lessonCondition = lesson.condition[c];
              if (!lessonCondition) {
                conditionalProblem.errors.push(
                  Vue.i18n.translate("interventionTranslation.checkInterventionConfigurationErrorEmptyCondition", { position }),
                );
                hasNoInvalidCondition = false;
              } else {
                if (!lessonCondition.questionnaire_id) {
                  conditionalProblem.errors.push(
                    Vue.i18n.translate(
                      "interventionTranslation.checkInterventionConfigurationErrorMissingId",
                      { name: this.getConditionDescription(lessonCondition), position },
                    ),
                  );
                  hasNoInvalidCondition = false;
                } else if (!lessonCondition.ifBlock) {
                  conditionalProblem.errors.push(
                    Vue.i18n.translate(
                      "interventionTranslation.checkInterventionConfigurationErrorMissingType",
                      { name: this.getConditionDescription(lessonCondition), position },
                    ),
                  );
                } else if (lessonCondition.ifBlock !== true) {
                  complexConditions.push([position, lessonCondition]);
                  const linkedId = Number(lessonCondition.questionnaire_id);
                  let lessons = lessonIdsWithLinkedQuestion.get(linkedId);
                  if (lessons === undefined) {
                    lessons = [];
                    lessonIdsWithLinkedQuestion.set(linkedId, lessons);
                  }
                  if (lessons.every(({ id }) => id != lessonId)) {
                    lessons.push({ id: lessonId, position: position, condition: lessonCondition });
                  }
                }
                if (!lessonCondition.description) {
                  conditionalProblem.errors.push(
                    Vue.i18n.translate("interventionTranslation.checkInterventionConfigurationErrorMissingDescription", { position }),
                  );
                }
              }
            }
            if (complexConditions.length > 0) {
              allComplexConditions.push([lesson.id, complexConditions]);
            }
          } else {
            conditionalProblem.errors.push(
              Vue.i18n.translate("interventionTranslation.checkInterventionConfigurationErrorNoConditions"),
            );
          }
          hasConditionalLesson = true;
          if (conditionalProblem.errors.length > 0) {
            const { name } = allLessonsArray.find(l => l.id == lesson.id).attributes;
            conditionalProblem.name = name;
            problems.conditions.push(conditionalProblem);
          }
        } else if (lesson.unlock_type === "after_previous") {
          hasConditionalLesson = true;
        } else if (hasNoUnlockedLesson && customOrder.some(id => id == lesson.id)) {
          hasNoUnlockedLesson = false;
        }
      }
      if (hasNoUnlockedLesson) {
        problems.general.push(this.noUnlockedLessonInConfig);
      } else if (hasConditionalLesson && hasNoInvalidCondition) {
        const result = DependencyGraph.check(configJSON, customOrder, unlockedDiaries);
        if (result !== undefined) {
          const text = this.getDependencyGraphResultText(result, allLessonsArray);
          problems.general.push(text);
        }
      }
      if (checkComplexConditions) {
        const lessonElementsLabels = {};
        for (const [linkedLessonId, lessons] of lessonIdsWithLinkedQuestion) {
          try {
            const elements = await this.exportLessonElementsRequest(linkedLessonId);
            const elementProps = this.jsonToProperties(elements, false);
            const labelList = this.getLabelList(elementProps).list;
            lessonElementsLabels[linkedLessonId] = { elements: elementProps, labelList: labelList };
          } catch (error) { // Happens if linked questionnaire is invalid
            for (const { id: lessonId, position, condition } of lessons) {
              const lessonConditionProblems = problems.conditions.find(({ id }) => id == lessonId);
              const text = Vue.i18n.translate(
                "interventionTranslation.checkInterventionConfigurationErrorInvalidId",
                { name: this.getConditionDescription(condition), position: position, questionnaireId: linkedLessonId },
              );
              if (lessonConditionProblems === undefined) {
                const { name } = allLessonsArray.find(l => l.id == lessonId).attributes;
                problems.conditions.push({ id: lessonId, name, errors: [text] });
              } else {
                lessonConditionProblems.errors.push(text);
              }
            }
          }
        }
        for (const [lessonId, conditions] of allComplexConditions) {
          for (const [conditionPosition, condition] of conditions) {
            if (lessonElementsLabels[condition.questionnaire_id] === undefined) {
              continue; // Happens if linked questionnaire is invalid
            }
            const result = this.treeToString(condition.ifBlock, lessonElementsLabels[condition.questionnaire_id].labelList, false);
            if (result.includes("...")) {
              const text = Vue.i18n.translate(
                "interventionTranslation.checkInterventionConfigurationErrorInvalidCondition",
                { name: this.getConditionDescription(condition), position: conditionPosition },
              );
              const lessonConditionProblems = problems.conditions.find(({ id }) => id == lessonId);
              if (lessonConditionProblems === undefined) {
                const { name } = allLessonsArray.find(l => l.id == lessonId).attributes;
                problems.conditions.push({ id: lessonId, name, errors: [text] });
              } else {
                lessonConditionProblems.errors.push(text);
              }
            }
          }
        }
      }
      problems.hasErrors = problems.conditions.length > 0 || problems.general.length > 0;
      return problems;
    },

    requestAllLessons: async function() {
      const interventionId = this.interventionToCheck.id;
      const page = 0;
      const specificPage = false;
      const oldLessonId = "";
      const copyLessonId = "";
      const includeTranslationStatus = false;
      const self = this;
      const result = await this.requestLessonsOfIntervention(
        interventionId,
        page,
        this.getIsEditable,
        specificPage,
        oldLessonId,
        copyLessonId,
        includeTranslationStatus,
      ).catch(function(error) {
        return self.handleErrors(error, function() { return self.requestAllLessons(); }, "");
       });
      return result?.allLessons;
    },

    getConditionDescription: function(condition) {
      return condition.description ? `"${condition.description}"` : "";
    },

    getDependencyGraphResultText: function(result, allLessonsArray) {
      const invalidLesson = allLessonsArray.find(l => l.id == result.id)?.attributes;
      const general = Vue.i18n.translate(
        "interventionTranslation.errorConfigLockedLesson",
        { position: invalidLesson?.position, lesson: invalidLesson?.name },
      );
      const specific = Vue.i18n.translate(result.text);
      return `${general} ${specific}`;
    },

    closeProblemModal: async function(done) {
      this.$emit("close-modal", done);
    },

    closeNotification: function() {
      this.$emit("close-notification");
    },
  },
}
</script>
