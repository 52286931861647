<template>
  <div class="lessonEditorElements">
    <section v-show="!loading">
      <section v-if="!isSkill" class="buttonFormRight">
        <i
          v-if="currentPage != 0"
          id="buttonShowLessonPreviewCurrentPage"
          class="fas fa-search fa-lg buttonMiddle previewButton"
          v-tooltip="textPreviewButtonPage"
          @click="showPreview(false)"
        >
          <div class="pageText">
            {{ 'lessonTranslation.page' | translate }}
            {{currentPage}}
          </div>
        </i>
        <i
          class="fas fa-search fa-lg buttonMiddle previewButton2"
          id="buttonShowLessonPreviewStart"
          v-tooltip="textPreviewButton"
          @click="showPreview(true)"
        >
          <div class="pageText">
            {{textPreviewButtonLesson}}
          </div>
        </i>
      </section>
      <section v-else class="buttonFormRight">
        <i
          class="fas fa-search fa-lg buttonMiddle previewButton"
          id="buttonShowSkillPreview"
          v-tooltip="$t('interventionTranslation.skill')"
          @click="showPreview(false)"
        >
          <div class="pageText">
            {{ 'interventionTranslation.skill' | translate }}
          </div>
        </i>
      </section>

      <section class="wrapper selectPagesButtons">
        <section class="col sec33">
          <button type="button" v-if="elements.length > 0" id="buttonOpenElements" class="buttonForm" @click="openCloseAllDetailElements(true)">
            {{ 'lessonTranslation.openAll' | translate }}
          </button>

          <button type="button" v-if="elements.length > 0" id="buttonCloseElements" class="buttonForm" @click="openCloseAllDetailElements(false)">
            {{ 'lessonTranslation.closeAll' | translate }}
          </button>

          <button type="button" v-if="!disableInputs" id="buttonToggleDragDrop" class="buttonForm" @click="showDragAndDrop = !showDragAndDrop">
            {{dragDropButtonText}}
          </button>

          <button
            type="button"
            v-if="elements.length > 0 && getIsEditable"
            id="buttonCheckElements"
            :disabled="isSaved"
            class="buttonForm"
            @click="showCheckLanguagesModal"
          >
            {{ $t("lessonTranslation.checkLesson") }}
          </button>
        </section>

        <section v-if="!isSkill" class="col sec33">
          <i
            v-if="currentPage > 1"
            class="fa fa-chevron-left fa-lg"
            id="buttonElementsLastPageTop"
            v-tooltip="$t('lessonTranslation.previousPage')"
            @click="lastPage"
          ></i>

          <select v-if="elements.length > 0" v-model="currentPage" id="inputSelectPageTop" class="selectPages" @change="changePage">
            <option value="" id="inputSelectPageTopNone" disabled>
              {{ 'lessonTranslation.selectPage' | translate }}
            </option>
            <option v-for="page in pages" :value="page" :id="'inputSelectPageTop' + page" :key="page">
              {{ 'lessonTranslation.page' | translate }} {{page}}
              {{conditionalPages.includes(page) ? '(' + $t('elementsPageTranslation.conditional') + ')' : ''}}
            </option>
          </select>

          <i
            v-if="currentPage < pages"
            class="fa fa-chevron-right fa-lg"
            id="buttonElementsNextPageTop"
            v-tooltip="$t('lessonTranslation.nextPage')"
            @click="nextPage"
          ></i>
        </section>

        <section class="col sec33">
          <button type="button" v-if="!disableInputs && pages > 1 && !isSkill" id="buttonDeletePage" class="buttonFormRightLesson" @click="showDeletePageWarning">
            {{ 'lessonTranslation.deletePage' | translate }}
          </button>

          <button type="button" v-if="elements.length > 0 && !isSkill" id="buttonSelectElements" class="buttonFormRightLesson" @click="selectElements"> <!-- todo select elements skill -->
            {{selectElementsButtonText}}
          </button>

          <button type="button" v-show="selectElementsBool && selectedElements.length > 0 && !isSkill" id="buttonCopyElements" class="buttonFormRightLesson" @click="showCopyElements"> <!-- todo copy elements skill -->
            {{ 'lessonTranslation.copyElements' | translate }}
          </button>
        </section>
      </section>

      <!--
      <div v-for="elem in elements" :key="elem.propsObj.id">
        {{elem.type}} {{elem.propsObj.id}} {{elem.translations}}<br>
      </div>
      {{selectedElements}}
      {{selectedElements.map(x => x.propsObj.id)}}
      -->

      <section class="wrapper">
        <section v-if="!disableInputs && showDragAndDrop" class="col elementsDragAndDropSelection">
          <div class="elementsSelection">
            <div class="buttonDiv">
              <i
                class="fas fa-angle-left fa-lg"
                id="buttonToggleDragDropLeft"
                v-tooltip="dragDropButtonText"
                @click="showDragAndDrop = !showDragAndDrop"
              ></i>
            </div>

            <label>
              {{ 'lessonTranslation.addElementsDragDrop' | translate }}
            </label>

            <draggable
              tag="ul"
              id="listAddElements"
              :list="addElementsButtons(true, isSkill)"
              :group="{ name: 'lessonElementsGroup', pull: 'clone', put: false}"
              :scroll-sensitivity="40"
              :force-fallback="true"
              :fallback-tolerance="1"
              :swapThreshold="0.9"
              :sort="false"
              :disabled="!getIsEditable"
              :clone="cloneFunction"
              :move="checkMoveElement"
              class="list-group ulElements"
            >
              <div v-for="element in addElementsButtons(true, isSkill)" :id="'dragDropAdd' + element.elementType" :key="element.elementType" class="list-group-item liBorder">
                <div :class="'border' + element.elementType">
                  {{element.text}}
                </div>
              </div>
            </draggable>
          </div>
        </section>

        <section id="lessonElementsFormId" :class="{col: showDragAndDrop, sec85Elements: showDragAndDrop}">
          <!-- set new key when translation changes to update reference fields-->
          <section class="wrapper" :key="reInitKey">
            <button type="submit" disabled style="display: none"></button>
            <draggable
              tag="ul"
              id="listOfElements"
              :list="elements"
              :group="{ name: 'lessonElementsGroup'}"
              :scroll-sensitivity="40"
              :force-fallback="true"
              :fallback-tolerance="1"
              :swapThreshold="0.5"
              handle=".handle"
              :disabled="disableInputs"
              class="ulElements list-group"
              :move="checkMoveElement"
              @add="addNewElementFromSide"
              @change="moveElementDragDrop"
            >
              <div v-for="(element, index) in elements" :key="element.propsObj.id" class="list-group-item">
                <div v-if="!hideOtherPages.includes(element.propsObj.id)">
                  <LessonElement
                    :id="'element' + element.propsObj.id"
                    :index="index"
                    :element="element"
                    :numberOfElements="elements.length"
                    :isTranslationMode="isTranslationMode"
                    :defaultPageColor="lessonPageColor"
                    :selectedElements="selectedElements"
                    :errorTextsForElements="errorTextsForElements"
                    :closedElements="closedElements"
                    :sliderRepetitionErrorLabels="sliderRepetitionErrorLabels"
                    :selectedLanguageForTranslation="selectedLanguageForTranslation"
                    :studyId="studyId"
                    :showTextEditor="showTextEditor"
                    :isInBlock="false"
                    :isAfterBlock="elements[index - 1] && elements[index - 1].type === 'ElementsBlock'"
                    :isBeforeBlock="elements[index + 1] && elements[index + 1].type === 'ElementsBlock'"
                    :isSkill="isSkill"
                    :showProgressBars="getShowProgressBars(interventionDetails.gamification)"
                    :selectElementsBool="selectElementsBool"
                    :allowStructureChanges="allowStructureChanges"
                    @change-element="changeNestedElement($event, element, index)"
                  ></LessonElement>

                  <BaseAddElement
                    v-if="!disableInputs"
                    :id="element.propsObj.id"
                    :index="index"
                    :top="false"
                    :isInBlock="false"
                    :isSkill="isSkill"
                    @change-element="changeElementFunction"
                  ></BaseAddElement>
                  <div v-else class="baseAddElementSpace"></div>
                </div>
              </div>
            </draggable>

            <section v-if="elements.length === 1 && hideOtherPages.length === 0" id="noElements" class="noAvailableElements">
              {{ 'generalTranslation.noLessonElements' | translate }}
            </section>
            <br>
            <div v-if="elements.length === 1 && hideOtherPages.length === 0 && !disableInputs" class="addElementsText">
              {{ 'lessonTranslation.addNewElementText' | translate }}
            </div>

            <draggable
              v-if="!disableInput && shownElements.length === 1"
              id="listOfElementsNone"
              tag="ul"
              :list="addToElementsList"
              :group="{ name: 'lessonElementsGroup'}"
              :sort="false"
              class="ulElements list-group dragDropPlaceholder"
            ></draggable>
          </section>
        </section>
      </section>

      <section class="wrapper selectPagesButtons">
        <section class="col sec33">
          <button
            type="button"
            v-if="elements.length > 0 && getIsEditable"
            id="buttonSaveElements"
            :disabled="isSaved || !getFormElementsChanged"
            class="saveElementsButton"
            @click="saveElements"
          >
            {{ saveButtonText }}
          </button>
        </section>

        <section v-if="!isSkill" class="col sec33">
          <i
            v-if="currentPage > 1"
            class="fa fa-chevron-left fa-lg"
            id="buttonElementsLastPage"
            v-tooltip="$t('lessonTranslation.previousPage')"
            @click="lastPage"
          ></i>

          <select v-if="elements.length > 0" v-model="currentPage" id="inputSelectPageBottom" class="selectPages" @change="changePage">
            <option value="" id="inputSelectPageBottomNone" disabled>
              {{ 'lessonTranslation.selectPage' | translate }}
            </option>
            <option v-for="page in pages" :value="page" :id="'inputSelectPageBottom' + page" :key="page">
              {{ 'lessonTranslation.page' | translate }} {{page}}
              {{conditionalPages.includes(page) ? '(' + $t('elementsPageTranslation.conditional') + ')' : ''}}
            </option>
          </select>

          <i
            v-if="currentPage < pages"
            class="fa fa-chevron-right fa-lg"
            id="buttonElementsNextPage"
            v-tooltip="$t('lessonTranslation.nextPage')"
            @click="nextPage"
          ></i>
        </section>

        <section class="col sec33">
        </section>
      </section>
    </section>

    <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loading"></i>

    <div class="pageModal lessonPreviewModal">
      <template v-if="lessonPreviewVisible">
        <transition name="modal-fade">
          <div class="modal-backdrop">
            <div class="baseModal" role="dialog" :id="previewContentId">
              <header class="modal-header">
                {{ 'interventionTranslation.preview' | translate }}
                <i class="fa fa-window-close fa-lg closeButton" id="buttonCloseLessonPreview" @click="closeModalPreview"></i>
              </header>
              <section class="modal-body">
                <LessonPreview
                  :isShownFromStart="isShownFromStart"
                  :previewPage="previewPageToShow"
                  :pageChanged="previewPageToShow != currentPage"
                  :isDiary="isDiary"
                  :lessonDetails="lessonDetails"
                  :interventionDetails="interventionDetails"
                  :isSkill="isSkill"
                  @new-page="setPreviewPageTop"
                  @close-modal="closeModalPreview"
                ></LessonPreview>
              </section>
            </div>
          </div>
        </transition>
      </template>
    </div>

    <BaseModal
      v-if="deleteQuestionVisible"
      id="deleteQuestionModal"
      :headerText="deleteQuestionHeaderText"
      :bodyText="deleteQuestionBodyText"
      :leftButtonText="$t('generalTranslation.delete')"
      @close-modal="closeModalWarning"
    ></BaseModal>

    <BaseModal
      v-if="checkLanguagesModalVisible"
      id="checkLanguagesModal"
      :headerText="$t('lessonTranslation.checkLesson')"
      :leftButtonText="$t('generalTranslation.check')"
      @close-modal="checkElements"
    >
      <template v-slot:body>
        <label>{{ $t("lessonTranslation.checkLessonTranslations") }}</label>
        <br>
        <section class="checkboxSection notBold wrapper">
          <div v-for="lang in checkedLanguages" :key="lang.code">
            <label class="container">
              {{ lang.code }}
              <input v-model="lang.check" type="checkbox" :id="'checkLanguageCheckbox' + lang.code">
              <span class="checkmark"></span>
            </label>
          </div>
        </section>
        <span v-if="languagesToCheck.length === 0">{{ $t("lessonTranslation.checkLessonNoTranslations") }}</span>
      </template>
    </BaseModal>

    <LessonEditorElementsCopy
      v-if="copyElementsVisible"
      :isDiary="isDiary"
      :studyId="studyId"
      :elementsToCopy="elementsToCopy"
      @close-notification="closeNotification"
      @close-modal="closeModalCopy"
    ></LessonEditorElementsCopy>
  </div>
</template>

<script>
import Vue from "vue";
import {mapGetters, mapMutations} from "vuex";
import draggable from "vuedraggable";
//import CollapseItem from "element-ui";
//import Collapse from "element-ui";
import httpHelper from "../mixins/httpHelper";
import elementJSONToPropsHelper from "../mixins/elementJSONToPropsHelper";
import elementPropsToJSONHelper from "../mixins/elementPropsToJSONHelper";
import questionConditionHelper from "../mixins/questionConditionHelper";
import elementsCompleteHelper from "../mixins/elementsCompleteHelper";
import elementsCompleteHelperAll from "../mixins/elementsCompleteHelperAll";
import elementsHelper from "../mixins/elementsHelper";
import BaseModal from "./BaseModal.vue";
import BaseAddElement from "./BaseAddElement.vue";
import LessonElement from "./LessonElement.vue";
import LessonPreview from "./LessonPreview.vue";
import LessonEditorElementsCopy from "./LessonEditorElementsCopy.vue";

export default {
  name: "LessonEditorElements",

  components: {
    draggable,
    //Collapse,
    //CollapseItem
    BaseModal,
    BaseAddElement,
    LessonElement,
    LessonPreview,
    LessonEditorElementsCopy
  },

  mixins: [
    httpHelper,
    questionConditionHelper,
    elementPropsToJSONHelper,
    elementsHelper,
    elementsCompleteHelper,
    elementsCompleteHelperAll,
    elementJSONToPropsHelper,
  ],

  props: {
    editorModalsVisible: {
      required: true,
      type: Boolean,
    },

    lessonDetails: {
      required: true,
      type: Object,
    },

    formChanged: {
      required: true,
      type: Boolean,
    },

    selectedLanguageForTranslation: {
      required: true,
      type: String,
    },

    isTranslationMode: {
      required: true,
      type: Boolean,
    },

    isDiary: {
      required: true,
      type: Boolean,
    },

    isSkill: {
      required: true,
      type: Boolean,
    },

    studyId: {
      required: true,
      type: Number,
    },

    interventionDetails: {
      required: true,
      type: Object,
    },

    currentPageLessonElements: {
      required: true,
      type: Number,
    },
  },

  data: function() {
    return {
      selectElementsBool: false,
      selectedElements: [],
      elements: [],
      addToElementsList: [],
      addElementsIdCounter: -1,
      questionLabelList: [],
      isSaved: false,
      currentPopup: "",
      errorTextsForElements: [],
      hideOtherPages: [],
      currentPage: 1,
      pages: 0,
      deleteQuestionVisible: false,
      copyElementsVisible: false,
      deleteElementData: "",
      elementsToCopy: [],
      closedElements: [],
      loading: true,
      lessonPreviewVisible: false,
      isShownFromStart: true,
      sliderRepetitionErrorLabels: [],
      showTextEditor: -1, //only show editor on click to improve performance -> close when click on different one
      insertReferenceSelectMediaVisible: false,
      showDragAndDrop: true,
      currentLanguage: "",
      reInitKey: 0,
      pageIds: [],
      previewPageToShow: 1,
      lessonElementsFormId: "lessonElementsForm",
      previewContentId: "previewContent",
      checkedLanguages: [],
      checkLanguagesModalVisible: false,
      scrollPositionX: 0,
      scrollPositionY: 0,
    };
  },

  computed: {
    ...mapGetters([
      "getIsEditable",
      "getSelectedLanguage",
      "getConditionEditor",
      "getLessonElementsArr",
      "getNotificationText",
      "getFormElementsChanged",
      "getChangedElemLocales",
      "getAllElemLocalesChanged",
    ]),

    changedLocales: function() {
      const changedLocales = [];
      for (const l in this.lessonDetails.locales) {
        const locale = this.lessonDetails.locales[l];
        if (this.getChangedElemLocales.includes(locale)) {
          changedLocales.push(locale);
        }
      }
      return changedLocales;
    },

    languagesToCheck: function() {
      return this.checkedLanguages.filter(l => l.check).map(l => l.code);
    },

    interventionId: function() {
      return Number(this.lessonDetails.intervention_id);
    },

    lessonId: function() {
      return !this.isDiary ? Number(this.$route.params.lessonId) : Number(this.lessonDetails.questionnaire_id);
    },

    lessonPageColor: function() {
      return !this.lessonDetails.page_color ? this.$defaultLessonPageColor : this.lessonDetails.page_color;
    },

    selectElementsButtonText: function() {
      return !this.selectElementsBool ? Vue.i18n.translate("lessonTranslation.selectElements") : Vue.i18n.translate("lessonTranslation.selectElementsAbort");
    },

    skillId: function() {
      return Number(this.$route.params.skillId);
    },

    textPreviewButton: function() {
      return Vue.i18n.translate("interventionTranslation.preview") + " " + this.textPreviewButtonLesson;
    },

    textPreviewButtonLesson: function() {
      return !this.isDiary ? Vue.i18n.translate("generalTranslation.lesson") : Vue.i18n.translate("diaryTranslation.diary");
    },

    textPreviewButtonPage: function() {
      return Vue.i18n.translate("interventionTranslation.preview") + " " + Vue.i18n.translate("lessonTranslation.page") + " " + this.currentPage;
    },

    dragDropButtonText: function() {
      if (this.showDragAndDrop) {
        return Vue.i18n.translate("lessonTranslation.toggleDragDropFalse");
      } else {
        return Vue.i18n.translate("lessonTranslation.toggleDragDropTrue");
      }
    },

    anyModalVisible: function() {
      return this.insertReferenceSelectMediaVisible || this.lessonPreviewVisible || this.deleteQuestionVisible || this.copyElementsVisible;
    },

    shownElements: function() {
      return this.elements.filter(elem => !this.hideOtherPages.includes(elem.propsObj.id));
    },

    conditionalPages: function() { //includes incomplete conditions (questionLabel has to be set though)
      const conditionalPageNumbers = [];
      for (let index = 0; index < this.pages; index++) {
        if (this.pageIds.length > 0) {
          const pageId = this.pageIds[index];
          const elementIndex = this.elements.findIndex(el => el.propsObj.id === pageId);
          if (elementIndex != -1 && this.elements[elementIndex].propsObj.condition != "" && this.elements[elementIndex].propsObj.condition.questionLabel != "") {
            conditionalPageNumbers.push(index + 1);
          }
        }
      }
      return conditionalPageNumbers;
    },

    nonConditionalPages: function() { //includes pages without conditions & with incomplete conditions
      const pageNumbers = [];
      for (let index = 0; index < this.pages; index++) {
        if (this.pageIds.length > 0) {
          const pageId = this.pageIds[index];
          const elementIndex = this.elements.findIndex(el => el.propsObj.id === pageId);
          if (elementIndex != -1 && (this.elements[elementIndex].propsObj.condition === "" || !this.checkIfConditionComplete(this.elements[elementIndex], [], false))) {
            pageNumbers.push(index + 1);
          }
        }
      }
      return pageNumbers;
    },

    deleteQuestionHeaderText: function() {
      if (this.deleteElementData.deletePage) {
        return Vue.i18n.translate("lessonTranslation.deletePageWarning");
      } else {
        return this.deleteElementData.element.type === "ElementsQuestion"
          ? Vue.i18n.translate("lessonTranslation.deleteQuestionWarning", { name: this.deleteElementData.element.propsObj.labelProp })
          : Vue.i18n.translate("lessonTranslation.deleteBlockWarning");
      }
    },

    deleteQuestionBodyText: function() {
      if (this.deleteElementData.deletePage) {
        return Vue.i18n.translate("lessonTranslation.warningeDeletePage");
      } else {
        return this.deleteElementData.element.type === "ElementsQuestion"
          ? Vue.i18n.translate("lessonTranslation.warningDeleteQuestion")
          : Vue.i18n.translate("lessonTranslation.warningDeleteBlockQuestion");
      }
    },

    allowStructureChanges: function() {
      return !this.lessonDetails.structure_lock;
    },

    disableInputs: function() {
      return !this.getIsEditable || !this.allowStructureChanges;
    },

    saveButtonText: function() {
      if (!this.allowStructureChanges) {
        const languages = this.changedLocales.length > 0 ? `(${this.changedLocales.join(", ")})` : "";
        return Vue.i18n.translate("lessonTranslation.saveIndividualLesson", { languages });
      } else {
        return Vue.i18n.translate("lessonTranslation.saveLesson");
      }
    },
  },

  watch: {
    getConditionEditor(newVal) {
      if (newVal) {
        this.closeNotification();
        const elementsArr = JSON.parse(JSON.stringify(this.elements));
        const elementsCompleteResult = this.checkIfElementsComplete(elementsArr, false, this.lessonDetails.locales);
        if (elementsCompleteResult.elementsComplete) {
          if (this.checkDuplicateQuestionLabels(elementsArr)) {
            this.SET_LESSONELEMENTSARR(elementsArr);
            this.SET_CODEVIEWDATA("");
            this.$emit("route-to", { name: "conditionEditor", params: { lessonId: -1, page: this.currentPage } });
          } else {
            this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("lessonTranslation.errorElementsLabelsCondition") });
            this.SET_CONDITIONEDITOR(false);
          }
        } else {
          this.errorTextsForElements = elementsCompleteResult.errorTextsForElements;
          this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("lessonTranslation.errorElementsCondition") });
          this.SET_CONDITIONEDITOR(false);
        }
      }
    },

    getSelectedLanguage(newVal) {
      this.elements = this.setMissingTranslations(JSON.parse(JSON.stringify(this.elements)), false, newVal);
      this.reInitKey++;
    },

    selectedLanguageForTranslation(newVal) {
      if (newVal != "") {
        this.elements = this.setMissingTranslations(JSON.parse(JSON.stringify(this.elements)), true, newVal);
        this.reInitKey++;
      }
    },

    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.isSaved = false;
        this.loading = false;
      }else if(newVal != "" && newVal.type === "success"){
        this.isSaved = false;
      }
    },

    anyModalVisible(newVal){
      this.$emit('show-modal', {type: 'any', value: newVal})
    },

    addToElementsList(newVal){
      if(newVal.length > 0){
        var index = -1;
        //get last shown element & add after this
        for(var elem in this.elements){
          if(!this.hideOtherPages.includes(this.elements[elem].propsObj.id)){
            index = elem;
          }
        }

        this.elements.splice(index + 1, 0, newVal[0]);
        this.addToElementsList = [];
        this.addEntryToLabellist(newVal[0].type);
      }
    },
  },

  created(){
    this.currentPage = this.currentPageLessonElements;
    //elements requested or set from pagerefreshdata in lessonEditor or DiaryEditor
    this.initElements();
    if(!this.getIsEditable){
      this.showDragAndDrop = false;
    }
  },

  mounted() {
    if (this.getIsEditable) {
      const element = document.getElementById(this.lessonElementsFormId);
      if (element != null) {
        element.addEventListener("input", this.changeFormElementsHasChanged);
      }
    }
    window.addEventListener("unload", this.unloadFunction);
  },

  beforeDestroy() {
    if (this.getIsEditable) {
      const element = document.getElementById(this.lessonElementsFormId);
      if (element != null) {
        element.removeEventListener("input", this.changeFormElementsHasChanged);
      }
    }
    window.removeEventListener("unload", this.unloadFunction);
  },

  methods: {
    ...mapMutations([
      "SET_QUESTIONLABELLIST",
      "SET_CODEVIEWDATA",
      "SET_FORMELEMENTSCHANGED",
      "ADD_CHANGEDELEMLOCALE",
      "SET_ALLELEMLOCALESCHANGED",
      "SET_NOTIFICATIONTEXT",
      "SET_MYROLEFORSTUDY",
      "SET_LESSONELEMENTSARR",
      "SET_CONDITIONEDITOR",
      "SET_SELECTEDLANGUAGE",
    ]),

    initElements: function() {
      this.elements = this.getLessonElementsArr;
      this.changePage();

      const result = this.getLabelList(this.elements);
      this.addElementsIdCounter = result.counter;
      this.questionLabelList = result.list;
      this.SET_QUESTIONLABELLIST(this.questionLabelList);

      this.loading = false;
    },

    openCloseAllDetailElements: function(open) {
      if (open) {
        this.closedElements = [];
      } else {
        const closed = [];
        for (let i = 0; i <= this.addElementsIdCounter; i++) {
          closed.push(Number(i));
        }
        this.closedElements = closed;
      }
      this.closeNotification();
      const detailElements = document.getElementsByClassName("detailsClass");
      Array.prototype.filter.call(detailElements, function(detailElement) {
        detailElement.open = open;
      });
    },

    selectElements: function() {
      this.selectElementsBool = !this.selectElementsBool;
      this.selectedElements = [];
    },

    popupId: function(id, isAboveElement) {
      //has to be the same as in lessonEditorElements
      return "popupId" + id + (isAboveElement ? "top" : "");
    },

    showPopUp: function(id, isAboveElement) {
      const popupId = this.popupId(id, isAboveElement);
      document.getElementById(popupId).classList.toggle("show");
      if (this.currentPopup != "" && this.currentPopup != popupId && document.getElementById(this.currentPopup)) {
        document.getElementById(this.currentPopup).classList.remove("show");
      }
      this.currentPopup = popupId;
    },

    detailsChanged: function(id) {
      const ind = this.closedElements.findIndex((x) => x === Number(id));
      if (ind !== -1) {
        this.closedElements.splice(ind, 1);
      } else {
        this.closedElements.splice(0, 0, Number(id));
      }
    },

    closeNotification: function() {
      this.$emit("close-notification");
    },

    //called when element dropped to different position (return false cancels move)
    checkMoveElement: function(evt) {
      //element can not be dragged to position of page element
      return !evt.relatedContext.element || evt.relatedContext.element.type != "ElementsPage";
    },

    //called when element dragged to different position (drop)
    moveElementDragDrop: function() {
      this.closeNotification();
      this.allElementsChanged();
    },

    addNewElementFromSide: function(evt) {
      const type = this.addElementsButtons(true, this.isSkill)[evt.oldIndex].elementType;
      this.addEntryToLabellist(type);
    },

    addEntryToLabellist: function(type) {
      const newLabelList = JSON.parse(JSON.stringify(this.questionLabelList));
      if (type === "ElementsQuestion") {
        newLabelList[this.addElementsIdCounter] = {
          label: "",
          value: [true,false],
          type: "question",
        };
      } else {
        newLabelList[this.addElementsIdCounter] = 0;
      }
      this.questionLabelList = newLabelList.map(item => item === null ? 0 : item);
      this.SET_QUESTIONLABELLIST(this.questionLabelList);
      this.allElementsChanged();
      this.getNumberOfPages(false);
    },

    cloneFunction: function(obj) {
      const newEl = this.addElementToJSON(obj, true);
      this.getNumberOfPages(true);
      return newEl.newElement;
    },

    showDeletePageWarning: function() {
      this.deleteElementData = { deletePage: true };
      this.deleteQuestionVisible = true;
    },

    deletePage: function() {
      //get elements to remove
      let removeElementIds = [];
      for (const element in this.elements) {
        if (!this.hideOtherPages.includes(this.elements[element].propsObj.id)) {
          removeElementIds.push(Number(this.elements[element].propsObj.id));
          if (this.elements[element].type === "ElementsBlock") {
            removeElementIds = removeElementIds.concat(this.getIdsOfElementsInBlock(this.elements[element].propsObj.elements));
          }
        }
      }
      //remove elements (including elements nested in block)
      this.elements = this.elements.filter(el => !removeElementIds.includes(Number(el.propsObj.id)));
      //delete selection of deleted elements
      this.selectedElements = this.selectedElements.filter(el => !removeElementIds.includes(Number(el.propsObj.id)));
      //update labellist
      const newLabelList = JSON.parse(JSON.stringify(this.questionLabelList));
      this.questionLabelList = newLabelList.map((item, index) => removeElementIds.includes(Number(index)) ? 0 : item);
      this.SET_QUESTIONLABELLIST(this.questionLabelList);
      this.getNumberOfPages(true);
      this.allElementsChanged();
    },

    showCheckLanguagesModal: function() {
      const newCheckedLanguages = [];
      for (const locale of this.lessonDetails?.locales || []) {
        const obj = this.checkedLanguages.find(l => l.code === locale);
        newCheckedLanguages.push(obj ?? { code: locale, check: true });
      }
      this.checkedLanguages = newCheckedLanguages;
      this.checkLanguagesModalVisible = true;
    },

    initPagesForElements: function(elements, pages, currentPage = 0) {
      for (const e in elements) {
        const element = elements[e];
        if (element.type === "ElementsPage") {
          currentPage++;
        } else if (element.type === "ElementsBlock") {
          pages.set(element.propsObj.id, currentPage);
          currentPage = this.initPagesForElements(element.propsObj.elements, pages, currentPage);
        } else {
          pages.set(element.propsObj.id, currentPage);
        }
      }
      return currentPage;
    },

    setDuplicateQuestionLabelPages: function(labelList, pages, errorPages) {
      const questionLabels = new Map();
      for (const e in labelList) {
        const entry = labelList[e];
        if (entry && entry.label) {
          if (!questionLabels.has(entry.label)) {
            questionLabels.set(entry.label, e);
          } else {
            const firstId = questionLabels.get(entry.label);
            if (firstId !== null) {
              const id = parseInt(firstId, 10);
              const page = pages.get(id);
              if (!errorPages.includes(page)) {
                errorPages.push(page);
              }
              questionLabels.set(entry.label, null);
            }
            const id = parseInt(e, 10);
            const page = pages.get(id);
            if (!errorPages.includes(page)) {
              errorPages.push(page);
            }
          }
        }
      }
    },

    checkElements: function(done) {
      if (done) {
        this.closeNotification();
        this.isSaved = true;
        this.SET_NOTIFICATIONTEXT({
          type: "load",
          text: Vue.i18n.translate("lessonTranslation.checkElementsLoad"),
        });

        const elementsCompleteResult = this.checkIfElementsCompleteAll(this.elements, this.languagesToCheck);
        const elementErrorPages = elementsCompleteResult.pagesWithErrors;

        const pages = new Map();
        this.initPagesForElements(this.elements, pages);

        this.setDuplicateQuestionLabelPages(
          this.questionLabelList,
          pages,
          elementErrorPages,
        );

        const elementsCopy = JSON.parse(JSON.stringify(this.elements));
        const locales = !this.allowStructureChanges ? this.changedLocales : undefined;
        const elementsJSON = this.propertiesToJSON(elementsCopy, this.addElementsIdCounter, locales);
        const data = this.getRepetitionsAndSlider(elementsJSON.data.attributes.elements);
        this.sliderRepetitionErrorLabels = this.checkIfRepeatedSliderUsedInRepetition(
          data,
          elementsJSON.data.attributes.elements,
          pages,
          elementErrorPages,
        );
        this.errorTextsForElements = elementsCompleteResult.errorTextsForElements;

        this.isSaved = false;
        if (elementErrorPages.length > 0) {
          elementErrorPages.sort((a, b) => a < b ? -1 : 1);
          this.SET_NOTIFICATIONTEXT({
            type: "error",
            text: Vue.i18n.translate(
              "lessonTranslation.checkElementsIncomplete",
              { pages: elementErrorPages.join(",") },
            ),
          });
        } else {
          this.SET_NOTIFICATIONTEXT({
            type: "success",
            text: Vue.i18n.translate("lessonTranslation.checkElementsComplete"),
          });
        }
      }
      this.checkLanguagesModalVisible = false;
    },

    saveElements: function() {
      this.closeNotification();
      this.isSaved = true;
      if (!this.allowStructureChanges && this.getAllElemLocalesChanged) {
        this.SET_NOTIFICATIONTEXT({
          type: "error",
          text: Vue.i18n.translate("lessonTranslation.errorStructureLockChanges"),
        });
        this.isSaved = false;
        return;
      }

      const elementsCompleteResult = this.checkIfElementsComplete(
        this.elements,
        true,
        this.lessonDetails.locales,
      );
      const elementErrorPages = elementsCompleteResult.pagesWithErrors;

      const pages = new Map();
      this.initPagesForElements(this.elements, pages);

      this.setDuplicateQuestionLabelPages(
        this.questionLabelList,
        pages,
        elementErrorPages,
      );

      const elementsCopy = JSON.parse(JSON.stringify(this.elements));
      const locales = !this.allowStructureChanges ? this.changedLocales : undefined;
      const elementsJSON = this.propertiesToJSON(elementsCopy, this.addElementsIdCounter, locales);
      const data = this.getRepetitionsAndSlider(elementsJSON.data.attributes.elements);

      this.sliderRepetitionErrorLabels = this.checkIfRepeatedSliderUsedInRepetition(
        data,
        elementsJSON.data.attributes.elements,
        pages,
        elementErrorPages,
      );
      this.errorTextsForElements = elementsCompleteResult.errorTextsForElements;

      if (elementErrorPages.length > 0) {
        elementErrorPages.sort((a, b) => a < b ? -1 : 1);
        this.SET_NOTIFICATIONTEXT({
          type: "error",
          text: Vue.i18n.translate(
            "lessonTranslation.errorElements",
            { pages: elementErrorPages.join(",") },
          ),
        });
        this.isSaved = false;
        return;
      }

      this.updateElements(elementsJSON);
    },
    
    updateElements: function(elementsJSON) {
      if (!this.allowStructureChanges) {
        const changedLocales = this.changedLocales;
        if (changedLocales.length === 0) {
          this.isSaved = false;
          return;
        }
        const jsonObjects = [];
        for (const element of elementsJSON.data.attributes.elements) {
          // Lines and Spaces don't have translations
          const translations = element.translations;
          if (translations !== undefined && translations.length > 0) {
            jsonObjects.push({
              id: element.id,
              translations: translations,
            });
          }
        }
        elementsJSON.data.attributes.elements = jsonObjects;
        if (!this.isSkill) {
          elementsJSON.data.attributes.questionnaire_id = this.lessonId;
          elementsJSON.data.attributes.skill_id = undefined;
          const translationKey = this.isDiary ? "lessonTranslation.updateDiaryElementsLoad" : "lessonTranslation.updateLessonElementsLoad";
          this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate(translationKey) });
          this.updateLessonElementsTranslations(elementsJSON);
        } else {
          elementsJSON.data.attributes.questionnaire_id = undefined;
          elementsJSON.data.attributes.skill_id = this.skillId;
          this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("interventionTranslation.updateSkillElementsLoad") });
          this.updateSkillElementsTranslations(elementsJSON);
        }
      } else {
        if (!this.isSkill) {
          const translationKey = this.isDiary ? "lessonTranslation.updateDiaryElementsLoad" : "lessonTranslation.updateLessonElementsLoad";
          this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate(translationKey) });
          this.createLessonElements(elementsJSON);
        } else {
          this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("interventionTranslation.updateSkillElementsLoad") });
          this.createSkillElements(elementsJSON);
        }
      }
    },

    updateLessonElementsTranslations: async function(elementsJSON) {
      let newElements;
      try {
        newElements = await this.updateElementsTranslations(elementsJSON);
      } catch (error) {
        const self = this;
        this.handleErrors(error, function() { self.updateLessonElementsTranslations(elementsJSON) }, "saveElementTranslations");
        return;
      }
      newElements = this.setMissingTranslationsForShownElements(newElements);
      this.saveScrollPosition();
      this.SET_LESSONELEMENTSARR(newElements);
      this.initElements();
      const translationKey = this.isDiary ? "lessonTranslation.updateDiaryElementsSuccess" : "lessonTranslation.updateLessonElementsSuccess";
      this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate(translationKey) });
      this.SET_FORMELEMENTSCHANGED(false);
      this.setScrollPosition();
    },

    updateSkillElementsTranslations: async function(elementsJSON) {
      let newElements;
      try {
        newElements = await this.updateElementsTranslations(elementsJSON);
      } catch (error) {
        const self = this;
        this.handleErrors(error, function() { self.updateSkillElementsTranslations(elementsJSON) }, "saveElementTranslations");
        return;
      }
      newElements = this.setMissingTranslationsForShownElements(newElements);
      this.saveScrollPosition();
      this.SET_LESSONELEMENTSARR(newElements);
      this.initElements();
      this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("interventionTranslation.updateSkillElementsSuccess") });
      this.SET_FORMELEMENTSCHANGED(false);
      this.setScrollPosition();
    },

    createLessonElements: async function(elementsJSON) {
      let newElements;
      try {
        newElements = await this.createLessonElementsRequest(this.lessonId, elementsJSON);
      } catch (error) {
        const self = this;
        this.handleErrors(error, function() { self.createLessonElements(elementsJSON) }, "createElements");
        return;
      }
      newElements = this.setMissingTranslationsForShownElements(newElements);
      this.saveScrollPosition();
      this.SET_LESSONELEMENTSARR(newElements);
      this.initElements();
      const translationKey = this.isDiary ? "lessonTranslation.updateDiaryElementsSuccess" : "lessonTranslation.updateLessonElementsSuccess";
      this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate(translationKey) });
      this.SET_FORMELEMENTSCHANGED(false);
      this.setScrollPosition();
    },

    createSkillElements: async function(elementsJSON) {
      let newElements;
      try {
        newElements = await this.createSkillElementsRequest(this.skillId, elementsJSON);
      } catch (error) {
        const self = this;
        this.handleErrors(error, function() { self.createSkillElements(elementsJSON) }, "createElements");
        return;
      }
      newElements = this.setMissingTranslationsForShownElements(newElements);
      this.saveScrollPosition();
      this.SET_LESSONELEMENTSARR(newElements);
      this.initElements();
      this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("interventionTranslation.updateSkillElementsSuccess") });
      this.SET_FORMELEMENTSCHANGED(false);
      this.setScrollPosition();
    },

    setMissingTranslationsForShownElements: function(elements) {
      let newElements = this.setMissingTranslations(elements, false, this.getSelectedLanguage);
      if (this.isTranslationMode) {
        newElements = this.setMissingTranslations(newElements, true, this.selectedLanguageForTranslation);
      }
      return newElements;
    },

    saveScrollPosition: function() {
      this.scrollPositionX = window.scrollX;
      this.scrollPositionY = window.scrollY;
    },

    setScrollPosition: function() {
      window.scroll(this.scrollPositionX, this.scrollPositionY);
      this.scrollPositionX = 0;
      this.scrollPositionY = 0;
    },

    changeNestedElement: function(evt, element, index) {
      //if element is move to different level we also need the parent of the parent if nested & moved out of block
      if (evt.type === "change-positions" && evt.changeLevel && !evt.parentOfParent && evt.parent && evt.element) {
        evt.parentOfParent = element;
      }
      if (!evt.parent && evt.element) {
        evt.parent = element;
        evt.parentIndex = index;
      }
      if (!evt.element) {
        evt.element = element;
      }
      this.changeElementFunction(evt);
    },

    changeElementFunction: function(obj){
      let updated = false;
      let updatedLocale = false;
      switch (obj.type) {
        case "attribute":
          updated = this.changeAttributeForElement(obj);
          break;
        case "media-attributes": {
          const updatedLang = this.changeMediaAttributesForElement(obj);
          updated = true;
          updatedLocale = updatedLang;
          break;
        }
        case "translation-attribute": {
          const [oldUpdated, updatedLang] = this.changeTranslationAttributeForElement(obj);
          updated = oldUpdated;
          updatedLocale = updatedLang;
          break;
        }
        case "add-element": {
          const newLabelList = this.addElementToJSON(obj, false);
          this.questionLabelList = newLabelList;
          this.SET_QUESTIONLABELLIST(newLabelList);
          updated = true;
          this.getNumberOfPages(true);
          break;
        }
        case "delete-element":
          this.deleteElementFromJSON(obj);
          break;
        case "change-positions":
          this.changeElementPositionsInJSON(obj);
          updated = true;
          this.getNumberOfPages(false);
          break;
        case "select-element":
          this.selectElement(obj);
          break;
        case "show-popup":
          this.showPopUp(obj.id, obj.top);
          break;
        case "show-texteditor":
          this.showTextEditor = obj.value;
          break;
        case "show-media-reference-modal":
          this.insertReferenceSelectMediaVisible = obj.value;
          break;
        case "toggle-summary":
          this.detailsChanged(obj.id);
          break;
        case "add-entry-to-labellist":
          this.addEntryToLabellist(obj.value);
          break;
        default:
          break;
      }
      if (updated) {
        // updatedLocale should only be true if just 1 locale changed
        // Only track updated locales if not all elements have already been changed
        if (updatedLocale && !this.getAllElemLocalesChanged) {
          const lang = !obj.isTranslation ? this.getSelectedLanguage : this.selectedLanguageForTranslation;
          this.ADD_CHANGEDELEMLOCALE(lang);
        } else if (!this.getAllElemLocalesChanged) {
          this.SET_ALLELEMLOCALESCHANGED(true);
        }
        this.SET_FORMELEMENTSCHANGED(true);
      }
    },

    allElementsChanged: function() {
      this.SET_FORMELEMENTSCHANGED(true);
      this.SET_ALLELEMLOCALESCHANGED(true);
    },

    selectElement(obj) {
      //each element has max 1 entry (either element itself or as child of block; childs of block also marked as selected)
      if (this.selectedElements.findIndex(el => Number(el.propsObj.id) === Number(obj.value)) >= 0) { //element is included -> remove
        this.selectedElements = this.selectedElements.filter(el => Number(el.propsObj.id) !== Number(obj.value));
      } else if (JSON.stringify(this.selectedElements).includes('"id":' + obj.value)) { //element is selected because parent block is selected
        //child is now deselected -> deselect whole parent block; add children of block except this one

        // example: block is selected >> 4 child elements are selected
        // el        >>     el
        // x block          block
        //   el               x el
        //   block            block
        //     el <             el
        //     el               x el
        //     el               x el
        //   el               x el
        // el               el
        this.selectAllExceptChild(this.selectedElements, obj.value, true);
      } else { //element is not included -> add
        if (obj.element.type === "ElementsBlock") {
          //check if children already included separately, if so remove & only add parent block
          // var elements = JSON.stringify(this.selectedElements);
          // var includedIds = elements.match(regEx);
          // includedIds = includedIds ? includedIds.map(string => string.split(':')[1]) : [];
          const blockElements = JSON.stringify(obj.element.propsObj.elements);
          const regEx = new RegExp(/"id":[0-9]*/, "g");
          let childIds = blockElements.match(regEx);
          childIds = childIds ? childIds.map(id => id.split(':')[1]) : [];
          for (const child in childIds) {
            this.selectedElements = this.selectedElements.filter(el => Number(el.propsObj.id) !== Number(childIds[child]));
          }
        }
        this.selectedElements.push(obj.element);
      }
    },

    //isOuter true: first find outer parent block, which includes the element (other selected elements on the same level are not affected)
    //then inside set isOuter to false so that all child elements are  updated
    selectAllExceptChild: async function(elements, childId, isOuter){
      for(var elem in elements){
        if(elements[elem].type != "ElementsBlock"){ //single element
          if(!isOuter && Number(elements[elem].propsObj.id) != Number(childId)){
            //if it is not the element which was deselected -> select
            this.selectedElements.push(elements[elem]);
            // console.log("add elem " + elements[elem].propsObj.id)
          }
        }else if(JSON.stringify(elements[elem].propsObj.elements).includes('"id":' + childId)){ //block which includes child -> deselect, select children
          //select all except the child which was deselected
          await this.selectAllExceptChild(elements[elem].propsObj.elements, childId, false);
          //deselect parent
          this.selectedElements = this.selectedElements.filter(el => Number(el.propsObj.id) !== Number(elements[elem].propsObj.id));
          // console.log("remove " + elements[elem].propsObj.id)
        }else if(!isOuter){ //block which does not include child -> select
          this.selectedElements.push(elements[elem]);
          // console.log("add elem " + elements[elem].propsObj.id)
        }
      }
    },

    changeAttributeForElement: function(obj) {
      this.closeNotification();
      let updated = true;
      switch(obj.name){
        case "selectedQuestionProp":
          this.changeSelectedQuestionTypeForQuestionElement(obj);
          break;
        case "labelProp": //question label
          obj.element.propsObj[obj.name] = obj.value;
          this.getNewLabelList(obj, "label");
          break;
        case "values":
          this.changeValuesOfQuestionElement(obj);
          break;
        case "addAnswer": //answer for question element
          for (const translationLanguage in obj.element.translations) {
            obj.element.translations[translationLanguage].answers.push("");
          }
          obj.element.propsObj.values.splice(obj.element.propsObj.values.length, 0, "");
          this.getNewLabelList(obj, "values");
          break;
        case "removeAnswer": //answer for question element
          for (const translationLanguage in obj.element.translations) {
            obj.element.translations[translationLanguage].answers.splice(obj.pos, 1);
          }
          obj.element.propsObj.values.splice(obj.pos, 1);
          this.getNewLabelList(obj, "values");
          break;
        case "addQuestion": //question for question table
          obj.element.propsObj.values.questions++;
          for (const translationLanguage in obj.element.translations) {
            obj.element.translations[translationLanguage].question.push("");
          }
          break;
        case "removeQuestion": //question for question table
          obj.element.propsObj.values.questions--;
          for (const translationLanguage in obj.element.translations) {
            obj.element.translations[translationLanguage].question.splice(obj.pos, 1);
          }
          break;
        case "typeBlock":
          obj.name = "type";
          this.changeBlockTypeForBlockElement(obj);
          break;
        case "set-condition":
          obj.element.propsObj.condition = obj.value;
          obj.element.propsObj.isComplexCondition = (obj.value && obj.value.nodeType != "leaf");
          if (!obj.element.propsObj.isComplexCondition && obj.element.propsObj.condition.operation === "answered") {
            obj.element.propsObj.condition.value = "";
            obj.element.propsObj.condition.secondValue = "";
          }
          break;
        case "change-condition": {
          const condition = JSON.parse(JSON.stringify(obj.element.propsObj.condition));
          const updatedCondition = this.updateValuesInComplexCondition(condition, obj.newVal, obj.oldVal);
          if (JSON.stringify(updatedCondition) != JSON.stringify(obj.element.propsObj.condition)) {
            obj.element.propsObj.condition = updatedCondition;
            obj.element.propsObj.isComplexCondition = (updatedCondition.nodeType != "leaf");
          } else {
            updated = false;
          }
          break;
        }
        case "isComplexCondition": //block element
          obj.element.propsObj[obj.name] = obj.value;
          if (!obj.value){
            //complex -> simple
            //if nodetype leaf: just switched from simple -> complex & back, simple condition is still in there -> use the old simple one (leave it as it is)
            //else: set empty
            if (obj.element.propsObj.condition.nodeType != "leaf") {
              obj.element.propsObj.condition = {
                leafType: "question",
                nodeType: "leaf",
                questionLabel: "",
                value: "",
                operation: "",
                secondValue: ""
              };
            }
          }
          break;
        case "condition-label":
          this.changeSelectedQuestionLabelInConditionForBlockElement(obj);
          break;
        // case "condition-leafType":
        //   obj.element.propsObj.condition.leafType = obj.value;
        //   if(this.questionLabelList[Number(obj.element.propsObj.condition.questionLabel)].value[0] === "TextTime"){
        //     obj.element.propsObj.condition.operation = "answered";
        //   }
        // break;
        case "condition-operation": //block element
          obj.element.propsObj.condition.operation = obj.value;
          if (obj.value === "answered") {
            obj.element.propsObj.condition.value = "";
          }
          if (obj.value != "between") {
            obj.element.propsObj.condition.secondValue = "";
          }
          break;
        case "condition-value":
          obj.element.propsObj.condition.value = obj.value;
          break;
        case "condition-second-value":
          obj.element.propsObj.condition.secondValue = obj.value;
          break;
        case "condition-description":
          obj.element.propsObj.conditionDescription = obj.value;
          break;
        case "repeatQuestion": //repetition is question for block element
          obj.element.propsObj.thenBlock.repeat.question = obj.value;
          obj.element.propsObj.thenBlock.repeat.data = obj.value ? "" : 1;
          break;
        case "repeatData":
          obj.element.propsObj.thenBlock.repeat.data = Number(obj.value);
          break;
        default:
          obj.element.propsObj[obj.name] = obj.value;
          break;
      }
      return updated;
    },

    getNewLabelList: function(obj, update){
      //labellist as new element instead of reference in order to get new & old value in elementsBlock
      var newLabelList = JSON.parse(JSON.stringify(this.questionLabelList));
      if(update === "values"){
        newLabelList[obj.element.propsObj.id].value = JSON.parse(JSON.stringify(obj.element.propsObj.values));
      }else{
        newLabelList[obj.element.propsObj.id].label = obj.value;
      }
      this.questionLabelList = newLabelList;
      this.SET_QUESTIONLABELLIST(newLabelList);
    },

    changeSelectedQuestionTypeForQuestionElement: function(obj){
      var oldQuestionType = obj.element.propsObj.selectedQuestionProp;
      obj.element.propsObj[obj.name] = obj.value;

      var newLabelList = JSON.parse(JSON.stringify(this.questionLabelList));
      if(newLabelList[obj.element.propsObj.id] === 0){
        newLabelList[obj.element.propsObj.id] = { label: "", value: "", type: "" }
      }
      var answersTranslation = -1;
      var questionTranslation = -1;
      if(obj.value === "YesNoSwitch" || obj.value === "SingleChoice" || obj.value === "MultipleChoice"){
        if(obj.value === "YesNoSwitch"){
          obj.element.propsObj.values = [true,false];
          if((this.getSelectedLanguage === "de" && !obj.isTranslation) ||
            (this.selectedLanguageForTranslation === "de" && obj.isTranslation)){
            answersTranslation = ["JA","NEIN"];
          }else{
            answersTranslation = ["YES","NO"];
          }
        }else if(obj.value === "SingleChoice"){
          if(oldQuestionType != "MultipleChoice"){
            obj.element.propsObj.values = [];
            answersTranslation = [];
          }
        }else if(oldQuestionType != "SingleChoice"){
          obj.element.propsObj.values = [];
          answersTranslation = [];
        }
        newLabelList[obj.element.propsObj.id].type = "question";

      }else if(obj.value === "Slider"){
        newLabelList[obj.element.propsObj.id].type = "slider";
        obj.element.propsObj.values = { min: "", max: "", step: "", start: "" };
        answersTranslation = [ { value: "", label: "" }, { value: "", label: "" }];

      }else if(obj.value === "QuestionTable"){
        newLabelList[obj.element.propsObj.id].type = "table";
        obj.element.propsObj.values = { min: 0, max: 2, questions: 3 };
        answersTranslation = [];
        questionTranslation = [];

      }else if(obj.value === "TextDate" || obj.value === "TextDateTime" || obj.value === "TextTime"){
        newLabelList[obj.element.propsObj.id].type = "date";
        obj.element.propsObj.values = [obj.value];
        answersTranslation = [];

      }else if(obj.value === "TextString" || obj.value === "TextArea"){
        newLabelList[obj.element.propsObj.id].type = "text";
        obj.element.propsObj.values = [];
        answersTranslation = [];
      }
      if(oldQuestionType === "QuestionTable"){
        questionTranslation = "";
      }

      for(var translationLanguage in obj.element.translations){
        if(answersTranslation != -1){
          obj.element.translations[translationLanguage].answers = JSON.parse(JSON.stringify(answersTranslation));
        }
        if(questionTranslation != -1){
          obj.element.translations[translationLanguage].question = JSON.parse(JSON.stringify(questionTranslation));
        }
      }

      newLabelList[obj.element.propsObj.id].value = JSON.parse(JSON.stringify(obj.element.propsObj.values));
      this.questionLabelList = newLabelList;
      this.SET_QUESTIONLABELLIST(newLabelList);
    },

    changeValuesOfQuestionElement: function(obj){
      var translationLanguage;
      var answers;

      var values = JSON.parse(JSON.stringify(obj.element.propsObj.values))
      values[obj.pos] = obj.value;

      if(obj.element.propsObj.selectedQuestionProp === "Slider"){
        values[obj.pos] = Number(values[obj.pos]);

        if(obj.pos === "min" || obj.pos === "max"){
          var answerPos = 0;
          if(obj.pos === "max"){
            answerPos = 1;
          }
          for(translationLanguage in obj.element.translations){
            answers = JSON.parse(JSON.stringify(obj.element.translations[translationLanguage].answers));
            answers[answerPos].value = obj.value;
            obj.element.translations[translationLanguage].answers = answers;
          }
        }
      }else if(obj.element.propsObj.selectedQuestionProp === "QuestionTable"){
        values[obj.pos] = Number(values[obj.pos]);
      }

      obj.element.propsObj.values = values;
      this.getNewLabelList(obj, "values");
    },

    changeBlockTypeForBlockElement: function(obj){
      var oldType = obj.element.propsObj[obj.name];
      if(obj.value === "none" || obj.value === "details"){
        obj.element.propsObj.condition = "";
        obj.element.propsObj.isComplexCondition = false;
      }else if((obj.value === "conditional" && oldType != "both") ||
          (obj.value === "both" && oldType != "conditional")){
        obj.element.propsObj.condition = {
          leafType: "question",
          nodeType: "leaf",
          questionLabel: "",
          value: "",
          operation: "",
          secondValue: ""
        };
      }
      obj.element.propsObj[obj.name] = obj.value;

      if(obj.value === "none" || obj.value === "conditional"){
        for(var translationLanguage in obj.element.translations){
          obj.element.translations[translationLanguage] = "";
        }
      }
    },

    changeSelectedQuestionLabelInConditionForBlockElement: function(obj){
      var oldLeafType = obj.element.propsObj.condition.leafType;
      var leafType = this.questionLabelList[Number(obj.value)].type;

      var operation = obj.element.propsObj.condition.operation;
      var value = obj.element.propsObj.condition.value;
      var secondValue = obj.element.propsObj.condition.secondValue;

      if(leafType === "question"){
        operation = "";
        if(!this.questionLabelList[Number(obj.value)].value.includes(value)){
          value = "";
        }
        secondValue = "";
      }else if(leafType === "slider"){
        if(oldLeafType != "slider"){
          value = "";
          secondValue = "";
        }else{
          var min = this.questionLabelList[Number(obj.value)].value.min;
          var max = this.questionLabelList[Number(obj.value)].value.max;
          var step = this.questionLabelList[Number(obj.value)].value.step;
          var start = this.questionLabelList[Number(obj.value)].value.start;

          var valueList = [min];
          for(var i = min; i <= max; i = i + step){
            valueList.push(i);
          }

          if(!valueList.includes(value)){
            value = "";
          }
          if(!valueList.includes(start)){
            start = valueList[0];
          }
          if(!valueList.includes(secondValue)){
            secondValue = "";
          }
        }
      }else if(leafType === "date"){
        if(oldLeafType != "date"){
          value = "";
          secondValue = "";
        }else{
          if(!this.isValidDate(value)){
            value = "";
          }
          if(!this.isValidDate(secondValue)){
            secondValue = "";
          }
        }

        if(this.questionLabelList[Number(obj.value)].value[0] === "TextTime"){
          operation = "answered";
          value = "";
          secondValue = "";
        }
      }else if(leafType === "table"){
        value = "";
        secondValue = "";
        operation = "";
      }else{
        operation = "answered";
        value = "";
        secondValue = "";
      }

      obj.element.propsObj.condition.questionLabel = Number(obj.value);
      obj.element.propsObj.condition.leafType = this.questionLabelList[Number(obj.value)].type;
      obj.element.propsObj.condition.operation = operation;
      obj.element.propsObj.condition.value = value;
      obj.element.propsObj.condition.secondValue = secondValue;
    },

    changeMediaAttributesForElement: function(obj) {
      let updatedLocale = false;
      const language = !obj.isTranslation ? this.getSelectedLanguage : this.selectedLanguageForTranslation;
      const translation = obj.element.translations[language];
      translation.uri = obj.link;
      translation.filename = obj.filename;
      //if same file -> also set for translations
      if (obj.sameFile) {
        for (const l in this.lessonDetails.locales) {
          const locale = this.lessonDetails.locales[l];
          if (!obj.element.translations[locale]) {
            obj.element.translations[locale] = {
              uri: obj.link,
              filename: obj.filename,
              description: "",
              subtitle: "",
              video_subtitle_uri: "",
              video_subtitle_original_name: "",
            };
          }
        }
        // Iterate of translations to update deleted ones as well
        for (const translationLanguage in obj.element.translations) {
          if (translationLanguage != language) {
            obj.element.translations[translationLanguage].uri = obj.link;
            obj.element.translations[translationLanguage].filename = obj.filename;
          }
        }
      } else {
        updatedLocale = true;
      }
      obj.element.propsObj.linkType = obj.mimetype;
      if (obj.mimetype.startsWith("video")) {
        obj.element.propsObj.isAudio = false;
      }
      return updatedLocale;
    },

    changeTranslationAttributeForElement: function(obj) {
      let updated = true;
      if (obj.name !== "text" && obj.name !== "init-table") {
        this.closeNotification();
      }

      const language = !obj.isTranslation ? this.getSelectedLanguage : this.selectedLanguageForTranslation;

      if (obj.name === "answers") { //question answers
        const answers = JSON.parse(JSON.stringify(obj.element.translations[language].answers));
        answers[obj.pos] = obj.value;
        obj.element.translations[language].answers = answers;
      } else if (obj.name === "tableQuestions") { //question texts (question table)
        const questions = JSON.parse(JSON.stringify(obj.element.translations[language].question));
        questions[obj.pos] = obj.value;
        obj.element.translations[language].question = questions;
      } else if (obj.name === "sliderAnswer") { //question slider answers
        if (!obj.element.translations[language].answers[obj.pos].label) {
          obj.element.translations[language].answers[obj.pos] = {
            value: obj.pos === 0 ? obj.element.propsObj.values.min : obj.element.propsObj.values.max,
            label: obj.value
          };
        } else {
          obj.element.translations[language].answers[obj.pos].label = obj.value;
        }
      } else if (obj.name === "video-subtitles") {
        const { link, filename } = obj.value;
        const translation = obj.element.translations[language];
        updated = translation.video_subtitle_uri !== link || translation.video_subtitle_original_name !== filename;
        translation.video_subtitle_uri = link;
        translation.video_subtitle_original_name = filename;
      } else {
        let translation = obj.element.translations[language];
        if (obj.name !== "headline" && obj.name !== "text" && obj.name !== "init-table" && obj.name !== "progressbar_text") {
          translation = translation[obj.name];
        }
        const a = translation.replace(/(\r\n|\n|\r)/gm, "").replace(/\s+/g, ' ').trim();
        const b = obj.value.replace(/(\r\n|\n|\r)/gm, "").replace(/\s+/g, ' ').trim();
        if (a != b) {
          if (obj.name === "init-table") {
            obj.element.translations[language] = obj.value;
            updated = false;
          } else if (obj.name !== "headline" && obj.name !== "text" && obj.name !== "progressbar_text") {
            obj.element.translations[language][obj.name] = obj.value;
          } else {
            obj.element.translations[language] = obj.value;
          }
        } else {
          updated = false;
        }
      }
      return [updated, updated && this.isSingleLocaleChange(obj.name)];
    },

    isSingleLocaleChange(name) {
      switch (name) {
        case "question":
        case "text":
        case "init-table":
        case "answers":
        case "tableQuestions":
        case "sliderAnswer":
        case "headline":
        case "description":
        case "subtitle":
        case "video-subtitles":
        case "uri":
        case "progressbar_text":
          return true;
        default:
          return false;
      }
    },

    addElementToJSON: function(obj, dragDrop){
      this.closeNotification();

      if(this.currentPage > 0){
        this.pages = 0;
      }

      var newElement;
      var newLabelList = JSON.parse(JSON.stringify(this.questionLabelList));
      var index = Number(obj.index);

      if(this.currentPopup != "" && document.getElementById(this.currentPopup)){
        document.getElementById(this.currentPopup).classList.remove("show");
        this.currentPopup = "";
      }

      if(obj.elementType != "duplicate"){
        if(!obj.top){
          index += 1;
        }

        var language = "";
        if(this.isTranslationMode){
          language = this.selectedLanguageForTranslation;
        }
        this.addElementsIdCounter++;
        newElement = this.getNewElementProperties(obj.elementType, this.addElementsIdCounter,
          this.getSelectedLanguage, language);

        if(!dragDrop){
          if(obj.parent){ //nested in block
            obj.parent.propsObj.elements.splice(index, 0, newElement);
          }else{ //not nested
            this.elements.splice(index, 0, newElement);
          }
        }

        if(obj.elementType === "ElementsQuestion"){
          newLabelList[this.addElementsIdCounter] = {
            label: "",
            value: [true,false],
            type: "question"
          };
        }else{
          newLabelList[this.addElementsIdCounter] = 0;
        }
      }else{
        //duplicate element
        if(obj.parent){ //nested in block
          newElement = JSON.parse(JSON.stringify(obj.parent.propsObj.elements[index - 1]));
        }else{
          newElement = JSON.parse(JSON.stringify(this.elements[index - 1]));
        }

        //update ids of element & its children
        var updatedElement = this.updateIdsOfElement(newElement);

        //add element to list
        if(obj.parent){ //nested in block
          obj.parent.propsObj.elements.splice(index, 0, updatedElement.element);
        }else{ //not nested
          this.elements.splice(index, 0, updatedElement.element);
        }

        for(var idIndex in updatedElement.oldIds){
          //duplicate labellist entries for all duplicated elements
          //check if oldId has entry -> if not it is 0
          if(newLabelList[updatedElement.oldIds[idIndex]]){
            newLabelList[updatedElement.newIds[idIndex]] = JSON.parse(JSON.stringify(newLabelList[updatedElement.oldIds[idIndex]]));
          }else{
            newLabelList[updatedElement.newIds[idIndex]] = 0;
            newLabelList[updatedElement.oldIds[idIndex]] = 0;
          }

          //if element selected, duplicate also selected
          var elementIndex = this.selectedElements.findIndex(el => Number(el.propsObj.id) === Number(updatedElement.oldIds[idIndex]));
          if(elementIndex != -1){
            var newElIndex = this.elements.findIndex(el => el.propsObj.id === Number(updatedElement.newIds[idIndex]));
            if(newElIndex != -1){
              this.selectedElements.push(this.elements[newElIndex]);
            }
          }
        }
      }

      //replace null values
      newLabelList = newLabelList.map(item => item === null ? 0 : item);

      if(dragDrop){
        return { newElement: newElement, newLabelList: newLabelList }
      }else{
        return newLabelList
      }
    },

    updateIdsOfElement: function(element){
      this.addElementsIdCounter++;

      var el = {
        oldIds: [element.propsObj.id],
        newIds: [this.addElementsIdCounter],
        element: element
      };

      element.propsObj.id = this.addElementsIdCounter;
      if(element.type === "ElementsBlock"){
        for(var child in element.propsObj.elements){
          var result = this.updateIdsOfElement(element.propsObj.elements[child]);
          element.propsObj.elements[child] = result.element;
          el.oldIds = el.oldIds.concat(result.oldIds);
          el.newIds = el.newIds.concat(result.newIds);
        }
      }

      return el
    },

    deleteElementFromJSON: function(obj){
      var bool = false;
      var questions = [];
      //check if element to be removed is question & is used in complex condition
      //if element is non-question element or question element which is not used in complex condition -> delete
      //else dialog is shown & deleting only continued if dialog not canceled
      if(obj.element.type === "ElementsQuestion" && this.checkIfQuestionUsedInCondition(obj.id, this.elements, -1)){
        bool = true;
      }else if(obj.element.type === "ElementsBlock"){
        //same for remove block which has question element inside
        questions = this.getQuestionElementsOfBlock(obj.element.propsObj.elements);
        for(var question in questions){
          if(this.checkIfQuestionUsedInCondition(questions[question], this.elements, obj.element.propsObj.id)){
            bool = true;
          }
        }
      }
      obj.questions = questions;

      if(bool){
        this.deleteElementData = obj;
        this.deleteElementData.deletePage = false;
        this.deleteQuestionVisible = true;
      }else{
        this.removeElement(obj);
      }
    },

    getQuestionElementsOfBlock: function(elements){
      var questions = [];
      for(var elem in elements){
        if(elements[elem].type === "ElementsQuestion"){
          questions.push(Number(elements[elem].propsObj.id));
        }else if(elements[elem].type === "ElementsBlock"){
          questions = questions.concat(this.getQuestionElementsOfBlock(elements[elem].propsObj.elements));
        }
      }

      return questions
    },

    getIdsOfElementsInBlock: function(elements){
      var ids = [];
      for(var elem in elements){
        ids.push(Number(elements[elem].propsObj.id));
        if(elements[elem].type === "ElementsBlock"){
          ids = ids.concat(this.getIdsOfElementsInBlock(elements[elem].propsObj.elements));
        }
      }
      return ids
    },

    //blockId is used to indentify if question is inside this block; then it can be ignored
    //(question & condition of question inside block which should be deleted -> okay)
    checkIfQuestionUsedInCondition: function(questionId, elements, blockId){
      for(var element in elements){
        if(elements[element].type === "ElementsBlock" && elements[element].propsObj.id != blockId){
          //check condition of block
          if(elements[element].propsObj.condition && elements[element].propsObj.isComplexCondition &&
              this.checkIfComplexConditionContainsLabel(elements[element].propsObj.condition, questionId)){
            return true
          }

          //check children
          if(this.checkIfQuestionUsedInCondition(questionId, elements[element].propsObj.elements, blockId)){
            return true
          }
        }else if(elements[element].type === "ElementsPage"){
          if(elements[element].propsObj.condition && elements[element].propsObj.isComplexCondition &&
              this.checkIfComplexConditionContainsLabel(elements[element].propsObj.condition, questionId)){
            return true
          }
        }
      }
      return false
    },

    checkIfComplexConditionContainsLabel: function(condition, questionId){
      if(condition.nodeType === "leaf"){
        return (Number(condition.questionLabel) === Number(questionId))
      }else{
        for(var child in condition.children){
          if(this.checkIfComplexConditionContainsLabel(condition.children[child], questionId)){
            return true
          }
        }
        return false
      }
    },

    closeModalWarning: function(done){
      this.deleteQuestionVisible = false;

      if(done){
        if(this.deleteElementData.deletePage){
          this.deletePage();
        }else{
          this.removeElement(this.deleteElementData);
        }
        this.deleteElementData = "";
      }
    },

    closeModalCopy: function(obj){
      this.copyElementsVisible = false;
      this.$emit('show-modal', {type: 'any', value: false});
      if(obj.done){
        this.elementsToCopy = [];
        this.loading = true;
        this.selectedElements = [];
        this.selectElementsBool = false;
        this.pages = obj.pages;
        this.currentPage = this.pages;
        this.SET_MYROLEFORSTUDY(obj.selectedTargetData.role);

        if(this.lessonId != obj.newLessonId){
          //open other lesson/diary editor
          if(!this.isDiary && !this.isSkill){
            this.$emit('route-to', { name: 'lessonEditor', params: { lessonId: obj.newLessonId }});
          }else if(this.isDiary){
            this.$emit('route-to', { name: 'diaryEditor', params: { diaryId: obj.selectedTargetData.diaryId, questionnaireId: obj.newLessonId }});
          }else{
            this.$emit('route-to', { name: 'skillEditor', params: { skillId: obj.newSkillId }});
          }
        }

        var elements = this.jsonToProperties(obj.allElements, false);
        this.SET_LESSONELEMENTSARR(elements);
        this.initElements();
        this.allElementsChanged();
        this.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('lessonTranslation.copyLessonElementsSuccess')});
      }
    },

    removeElement: function(obj){
      this.closeNotification();
      //remove element(s) (including elements nested in block)
      if(obj.parent){ //nested in block
        obj.parent.propsObj.elements.splice(obj.index, 1);
      }else{ //not nested
        this.elements.splice(obj.index, 1);
      }

      //delete selection of deleted element(s)
      var ids = [Number(obj.id)];
      if(obj.element.type === "ElementsBlock"){
        ids = ids.concat(this.getIdsOfElementsInBlock(obj.element.propsObj.elements));
      }
      this.selectedElements = this.selectedElements.filter(el => !ids.includes(Number(el.propsObj.id)));

      //update labellist
      var newLabelList = JSON.parse(JSON.stringify(this.questionLabelList));
      var questionIds = obj.questions ? obj.questions : [];
      questionIds.push(Number(obj.id));
      this.questionLabelList = newLabelList.map((item, index) => questionIds.includes(Number(index)) ? 0 : item);
      this.SET_QUESTIONLABELLIST(this.questionLabelList);
      this.getNumberOfPages(true);
      this.allElementsChanged();
    },

    changeElementPositionsInJSON: function(obj){
      this.closeNotification();
      var elementsList;

      // changeLevel false: element moved on same level
      // changeLevel true: element is moved
      // from out of block -> inside block        or
      // from inside of block -> outside block
      if(!obj.changeLevel){
        if(obj.parent){ //nested in block
          elementsList = JSON.parse(JSON.stringify(obj.parent.propsObj.elements));
        }else{ //not nested
          elementsList = JSON.parse(JSON.stringify(this.elements));
        }

        [elementsList[obj.index1], elementsList[obj.index2]] = [elementsList[obj.index2], elementsList[obj.index1]];

        if(obj.parent){ //nested in block
          obj.parent.propsObj.elements = JSON.parse(JSON.stringify(elementsList));
        }else{ //not nested
          this.elements = JSON.parse(JSON.stringify(elementsList));
        }
      }else{
        var moveElem;

        //inside -> above
        if(obj.direction === "up" && obj.index1 === -1){
          if(obj.parentOfParent){ //nested in block
            elementsList = obj.parentOfParent.propsObj.elements;
          }else{ //not nested
            elementsList = this.elements;
          }

          //get item which should be moved
          moveElem = JSON.parse(JSON.stringify(obj.parent.propsObj.elements[obj.index2]));
          //remove from inner list
          obj.parent.propsObj.elements.splice(obj.index2, 1);
          //add to outer list at parentIndex
          elementsList.splice(obj.parentIndex, 0, moveElem);
        }
        //below -> inside
        else if(obj.direction === "up" && obj.index1 > 0){
          if(obj.parent){ //nested in block
            elementsList = obj.parent.propsObj.elements;
          }else{ //not nested
            elementsList = this.elements;
          }

          //get item which should be moved
          moveElem = JSON.parse(JSON.stringify(elementsList[obj.index2]));
          //remove from outer list
          elementsList.splice(obj.index2, 1);
          //add to inner list -> at last index of block (block itself is on index1)
          elementsList[obj.index1].propsObj.elements.splice(elementsList[obj.index1].propsObj.elements.length, 0, moveElem);
        }
        //inside -> below
        else if(obj.direction === "down" && obj.parent && obj.index1 === obj.parent.propsObj.elements.length - 1){
          if(obj.parentOfParent){ //nested in block
            elementsList = obj.parentOfParent.propsObj.elements;
          }else{ //not nested
            elementsList = this.elements;
          }

          //get item which should be moved
          moveElem = JSON.parse(JSON.stringify(obj.parent.propsObj.elements[obj.index1]));
          //remove from inner list
          obj.parent.propsObj.elements.splice(obj.index1, 1);
          //add to outer list at parentIndex + 1
          elementsList.splice(obj.parentIndex + 1, 0, moveElem);
        }
        //above -> inside
        else{
          if(obj.parent){ //nested in block
            elementsList = obj.parent.propsObj.elements;
          }else{ //not nested
            elementsList = this.elements;
          }

          //get item which should be moved
          moveElem = JSON.parse(JSON.stringify(elementsList[obj.index1]));
          //remove from outer list
          elementsList.splice(obj.index1, 1);
          //add to inner list -> index = 0 of block (block itself is now on index1, since element above already removed)
          elementsList[obj.index1].propsObj.elements.splice(0, 0, moveElem);
        }
      }
    },

    changeFormElementsHasChanged: function() {
      //selecting elements does not result in SET_FORMELEMENTSCHANGED true
      if (!window.event.target.id.startsWith("selectField")) {
        this.closeNotification();
        this.SET_FORMELEMENTSCHANGED(true);
      }
    },

    nextPage: function(){
      this.currentPage++;
      this.changePage();
    },

    lastPage: function(){
      this.currentPage--;
      this.changePage();
    },

    changePage: function(){
      if(this.currentPopup != "" && document.getElementById(this.currentPopup)){
        document.getElementById(this.currentPopup).classList.remove("show");
        this.currentPopup = "";
      }

      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      if(this.pages < 1){
        this.getNumberOfPages(true);
      }else{
        this.getNumberOfPages(false);
      }
    },

    getNumberOfPages: function(bool){
      var allElements = this.elements;
      var pageIds = [];
      //number of pages
      if(bool){
        var numberOfPages = 0;
        if(allElements.length > 0){
          for(var elem in allElements){
            if(allElements[elem].type === "ElementsPage"){
              if(elem != 0){
                numberOfPages++;
              }
              pageIds.push(allElements[elem].propsObj.id)
            }
          }
        }
        this.pages = numberOfPages + 1;
        this.getNumberOfPages(false);
      }
      //change page to current page -> indexes of elements that are not shown added to list
      else{
        //show all elements
        if(this.currentPage < 1){
          this.hideOtherPages = [];
        }else{
          if(this.currentPage > this.pages){
            this.currentPage = this.pages;
          }
          var counter = 0;
          this.hideOtherPages = [];
          if(allElements.length > 0){
            for(var element in allElements){
              if(element != 0 && allElements[element].type === "ElementsPage"){
                if(counter + 1 != this.currentPage - 1){
                  this.hideOtherPages.push(allElements[element].propsObj.id);
                }
                counter++;
              }else if(counter + 1 != this.currentPage){
                this.hideOtherPages.push(allElements[element].propsObj.id);
              }
              if(allElements[element].type === "ElementsPage"){
                pageIds.push(allElements[element].propsObj.id)
              }
            }
          }
          this.pages = counter + 1;
        }
      }
      this.pageIds = pageIds;
    },

    showCopyElements: function(){
      this.closeNotification();

      if(this.getFormElementsChanged || this.formChanged){
        if(this.getFormElementsChanged && this.formChanged && !this.isDiary){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('lessonTranslation.saveElementsAndDetailsPrompt')});
        }else if(this.getFormElementsChanged && this.formChanged && this.isDiary){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('lessonTranslation.saveDiaryElementsAndDetailsPrompt')});
        }else if(this.getFormElementsChanged && !this.formChanged && !this.isDiary){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('lessonTranslation.saveElementsPrompt')});
        }else if(this.getFormElementsChanged && !this.formChanged && this.isDiary){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('lessonTranslation.saveDiaryElementsPrompt')});
        }else if(!this.getFormElementsChanged && this.formChanged && !this.isDiary){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('lessonTranslation.saveDetailsPrompt')});
        }else{
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('lessonTranslation.saveDiaryDetailsPrompt')});
        }
      }else{
        var errText = this.checkIfCopyElementsCompleteFunction(this.selectedElements, this.elements, this.addElementsIdCounter);

        if(errText === ""){
          //sort selected elements so that order is in order of lesson elements and not in order of selection
          var sortedSelectedElements = [];

          var result = this.flattenJSON(JSON.parse(JSON.stringify(this.elements)), this.addElementsIdCounter + 1, 1);
          //returns elements sorted by positions (which is what is seen in the editor) -> get list of ids sorted like this
          var sortedIds = [];
          for(var elem in result.elements){
            if(result.elements[elem]){
              sortedIds.push(result.elements[elem].propsObj.id)
            }
          }

          for(var id in sortedIds){
            var index = this.selectedElements.findIndex(el => el.propsObj.id === sortedIds[id]);
            if(index != -1){
              sortedSelectedElements.push(this.selectedElements[index]);
            }
          }

          this.elementsToCopy = this.propertiesToJSON(sortedSelectedElements, this.addElementsIdCounter).data.attributes.elements;

          this.copyElementsVisible = true;
        }else{
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('lessonTranslation.copyElementsErr') + " " + errText});
        }
      }
    },

    closeModalPreview: function(){
      this.SET_SELECTEDLANGUAGE(this.currentLanguage);
      this.lessonPreviewVisible = false;
      document.body.style.overflow = 'visible';
    },

    setPreviewPageTop: function(){
      document.getElementById(this.previewContentId).scrollTop = 0;
    },

    showPreview: function(bool){
      if(!this.editorModalsVisible && !this.deleteQuestionVisible && !this.copyElementsVisible){
        if(this.elements.length > 0){
          this.$emit('show-modal', {type: 'LessonPreview'})
          this.isShownFromStart = bool;

          //if shown from start, use currentPage
          //if specific page is shown & this page is conditional -> also use currentPage since ignorePageConditions will be activated in order to show page
          //if specific page is shown & page is not conditional -> calculate correct page
          if(this.isShownFromStart || !this.nonConditionalPages.includes(this.currentPage)){
            this.previewPageToShow = this.currentPage;
          }else{
            this.previewPageToShow = this.nonConditionalPages.findIndex(page => page === this.currentPage) + 1;
            if(this.previewPageToShow === 0){
              this.isShownFromStart = true;
            }
          }

          //set elements & get new questionLabelList to include everything for evaluationService
          this.SET_LESSONELEMENTSARR(JSON.parse(JSON.stringify(this.elements)));
          this.SET_QUESTIONLABELLIST(this.getLabelList(JSON.parse(JSON.stringify(this.elements))).list);

          this.currentLanguage = this.getSelectedLanguage;
          this.lessonPreviewVisible = true;
          document.body.style.overflow = 'hidden';
        }else{
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('lessonTranslation.errorPreviewNoElements')});
        }
      }
    },

    unloadFunction: function() {
      this.closeNotification();
      const elementsArr = JSON.parse(JSON.stringify(this.elements));
      this.SET_LESSONELEMENTSARR(elementsArr);
    },

    getShowProgressBars: function(gamification) {
      return !this.isDiary && !this.isSkill &&
        this.$enabledFeatures.includes("progress") &&
        gamification && gamification.progressbars_enabled;
    },
  }
}
</script>
