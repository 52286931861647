import Vue from "vue";
import { mapGetters } from "vuex";
import utils from "../mixins/utils";

export default {
  mixins: [utils],

  computed: {
    ...mapGetters([
      "getQuestionLabelList",
      "getSelectedLanguage",
    ]),
  },

  methods: {
    checkIfElementsCompleteAll: function(elements, locales) {
      let newErrorTextArr = [];
      let elementsComplete = true;
      let currentPage = 0;
      const pagesWithErrors = [];
      for (const elem in elements) {
        const element = elements[elem];
        let errorText = "";
        switch (element.type) {
          case "ElementsHeadline":
            errorText = this.checkIfHeadlineFieldsCompleteAll(element, locales);
            break;
          case "ElementsQuestion":
            errorText = this.checkIfQuestionFieldsCompleteAll(element, locales);
            break;
          case "ElementsText":
            errorText = this.checkIfTextFieldsCompleteAll(element, locales);
            break;
          case "ElementsMedia":
            errorText = this.checkIfMediaFieldsCompleteAll(element, locales);
            break;
          case "ElementsBlock":
            errorText = this.checkIfBlockFieldsCompleteAll(element, locales);
            break;
          case "ElementsSpace":
            errorText = this.checkIfSpaceFieldsCompleteAll(element);
            break;
          case "ElementsPage":
            currentPage++;
            errorText = this.checkIfPageFieldsCompleteAll(element);
            break;
          default:
        }

        if (element.type === "ElementsBlock") {
          //check children of block
          const resultBlock = this.checkIfElementsCompleteAll(element.propsObj.elements, locales);
          if (errorText !== "" || !resultBlock.elementsComplete) {
            elementsComplete = false;
            if (!pagesWithErrors.includes(currentPage)) {
              pagesWithErrors.push(currentPage);
            }
          }
          newErrorTextArr[element.propsObj.id] = errorText;
          newErrorTextArr = Object.assign(newErrorTextArr, resultBlock.errorTextsForElements);
        } else {
          if (errorText !== "") {
            elementsComplete = false;
            if (!pagesWithErrors.includes(currentPage)) {
              pagesWithErrors.push(currentPage);
            }
          }
          newErrorTextArr[element.propsObj.id] = errorText;
        }
      }

      // TODO: check if two page elements after one another
      return {
        errorTextsForElements: newErrorTextArr,
        elementsComplete: elementsComplete,
        pagesWithErrors: pagesWithErrors,
      };
    },

    elementsHaveError: function(elements, locales) {
      for (const elem in elements) {
        const element = elements[elem];
        let error;
        switch (element.type) {
          case "ElementsHeadline":
            error = this.checkIfHeadlineFieldsCompleteAll(element, locales);
            break;
          case "ElementsQuestion":
            error = this.checkIfQuestionFieldsCompleteAll(element, locales);
            break;
          case "ElementsText":
            error = this.checkIfTextFieldsCompleteAll(element, locales);
            break;
          case "ElementsMedia":
            error = this.checkIfMediaFieldsCompleteAll(element, locales);
            break;
          case "ElementsBlock":
            error = this.checkIfBlockFieldsCompleteAll(element, locales);
            break;
          case "ElementsSpace":
            error = this.checkIfSpaceFieldsCompleteAll(element);
            break;
          case "ElementsPage":
            error = this.checkIfPageFieldsCompleteAll(element);
            break;
          default:
            error = "";
        }
        if (error !== "" || (element.type === "ElementsBlock" && this.elementsHaveError(element.propsObj.elements, locales))) {
          return true;
        }
      }
      // TODO: check if two page elements after one another
      return false;
    },

    checkIfHeadlineFieldsCompleteAll: function(element, locales) {
      let errorText = "";
      for (const locale of locales) {
        if (!element.translations[locale]) {
          if (errorText === "") {
            errorText = Vue.i18n.translate('elementsTextTranslation.errorFillIn');
          } else {
            errorText += ", ";
          }
          errorText += Vue.i18n.translate('elementsHeadlineTranslation.headline') + " (" + locale + ")";
        }
      }
      return errorText;
    },

    checkIfQuestionFieldsCompleteAll: function(element, locales){
      let errorText = "";
      const errorSingleMultipleArr = [];
      let errorSingleMultipleText = "";
      let errorSlider = false;
      let errorTable = false;
      let errorSliderText = "";
      const errorsArr = [];
      const questionType = element.propsObj.selectedQuestionProp;

      if (questionType == "") {
        errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.questionType"));
      }

      if (element.propsObj.labelProp === "") {
        errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.questionLabel"));
      }

      if (questionType === "SingleChoice" || questionType === "MultipleChoice") {
        if (element.propsObj.values.length < 2) {
          errorSingleMultipleText = Vue.i18n.translate("elementsQuestionTranslation.singleMultipleChoiceError");
        }

        const valuesArr = [];
        for (const value in element.propsObj.values) {
          if (element.propsObj.values[value] === "") {
            errorsArr.push(
              Vue.i18n.translate("elementsQuestionTranslation.errorAnswerValueEmpty") + " " +
              Number(Number(value) + 1)
            );
          }
          if (valuesArr.includes(element.propsObj.values[value])) {
            errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.errorDuplicateAnswerLabel"));
          }
          valuesArr.push(element.propsObj.values[value]);
        }
      } else if(questionType === "Slider") {
        if (Number.isNaN(element.propsObj.values.min) || element.propsObj.values.min === "") {
          errorSlider = true;
          errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.valueMin"));
        }
        if (Number.isNaN(element.propsObj.values.max) || element.propsObj.values.max === "") {
          errorSlider = true;
          errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.valueMax"));
        }
        if (Number.isNaN(element.propsObj.values.step) || element.propsObj.values.step === "") {
          errorSlider = true;
          errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.step"));
        }
        if (Number.isNaN(element.propsObj.values.start) || element.propsObj.values.start === "") {
          errorSlider = true;
          errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.startValue"));
        }
        if (!errorSlider) {
          const start = Number(element.propsObj.values.min);
          const end = Number(element.propsObj.values.max);
          const step = Number(element.propsObj.values.step);
          const startVal = Number(element.propsObj.values.start);
          if (((end - start)/step) > 101) {
            errorSliderText = Vue.i18n.translate("elementsQuestionTranslation.errorSliderTooManyValues");
          }
          if (startVal < start || startVal > end) {
            errorSliderText += (errorSliderText != "" ? " " : "") + Vue.i18n.translate("elementsQuestionTranslation.startValueNotIncluded");
          }
          if (start > end) {
            errorSliderText += (errorSliderText != "" ? " " : "") + Vue.i18n.translate("elementsQuestionTranslation.minGreaterMax");
          }
        }
      } else if (element.propsObj.selectedQuestionProp === "QuestionTable") {
        if (element.propsObj.values.max - element.propsObj.values.min < 1) {
          errorTable = true;
          errorSingleMultipleText = Vue.i18n.translate("elementsQuestionTranslation.tableAnswersError");
        }
        if (element.propsObj.values.questions < 2) {
          errorTable = true;
          errorSingleMultipleText += (errorSingleMultipleText != "" ? " " : "") + Vue.i18n.translate("elementsQuestionTranslation.tableQuestionsError");
        }
      }

      for (const locale of locales) {
        const translation = element.translations[locale];
        if (element.propsObj.selectedQuestionProp != "QuestionTable" && !translation?.question) {
          errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.question") + " (" + locale + ")");
        }

        if (questionType === "SingleChoice" || questionType === "MultipleChoice") {
          if (translation !== undefined) {
            if (element.propsObj.values.length != translation.answers.length) {
              errorSingleMultipleArr.push(locale);
            }
            for (const answer in translation.answers){
              if (translation.answers[answer] === "") {
                errorsArr.push(`${Vue.i18n.translate("elementsQuestionTranslation.answerOption")} ${Number(answer) + 1} (${locale})`);
              }
            }
          } else {
            const answerCount = element.translations[this.getSelectedLanguage]?.answers.length;
            if (answerCount !== undefined) {
              for (let i = 0; i < answerCount; i++) {
                errorsArr.push(`${Vue.i18n.translate("elementsQuestionTranslation.answerOption")} ${i + 1} (${locale})`);
              }
            } else {
              errorSingleMultipleArr.push(locale);
            }
          }
        } else if (questionType === "Slider") {
          if (!translation?.answers[0].label) {
            errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.errorSliderMinTextValueEmpty") + " (" + locale + ")");
          }
          if (!translation?.answers[1].label) {
            errorsArr.push(
              Vue.i18n.translate("elementsQuestionTranslation.errorSliderMaxTextValueEmpty") + " (" + locale + ")"
            );
          }
        } else if (element.propsObj.selectedQuestionProp === "QuestionTable" && !errorTable) {
          for (let answer = element.propsObj.values.min; answer <= element.propsObj.values.max; answer++) {
            if (!translation?.answers[answer]) {
              errorsArr.push(`${Vue.i18n.translate("elementsQuestionTranslation.answerOption")} ${answer} (${locale})`);
            }
          }
          for (let question = 0; question < element.propsObj.values.questions; question++) {
            if (!translation?.question[question]) {
              errorsArr.push(`${Vue.i18n.translate("elementsQuestionTranslation.question")} ${question + 1} (${locale})`);
            }
          }
        }
      }

      if (errorsArr.length > 0) {
        errorText = Vue.i18n.translate("elementsTextTranslation.errorFillIn");
        for (const err in errorsArr) {
          errorText += (err > 0 ? ", " : "") + errorsArr[err];
        }
      }

      if (errorSingleMultipleArr.length > 0) {
        errorText += (errorText != "" ? ". " : "") + Vue.i18n.translate("elementsQuestionTranslation.errorSingleMultiple") + " (";
        for (const err in errorSingleMultipleArr) {
          errorText += (err > 0 ? ", " : "") + errorSingleMultipleArr[err];
        }
        errorText += ")";
      } else if (errorSliderText !== "") {
        errorText += (errorText !== "" ? ". " : "") + errorSliderText;
      }
      if (errorSingleMultipleText !== "") {
        errorText += (errorText !== "" ? ". " : "") + errorSingleMultipleText;
      }
      return errorText;
    },

    checkIfTextFieldsCompleteAll: function(element, locales) {
      let errorText = "";
      let includesOtherPictures = false;
      let languageOfOtherPictures = "";
      for (const locale of locales) {
        const translation = element.translations[locale];
        if (!translation) {
          const prefix = errorText === "" ? Vue.i18n.translate("elementsTextTranslation.errorFillIn") : ", ";
          const text = Vue.i18n.translate("elementsTextTranslation.text");
          errorText += `${prefix}${text} (${locale})`;
        } else {
          const regEx = new RegExp(/<img src='https:\/\/[A-Za-z0-9/.-_]*'/, "g");
          const urls = translation.match(regEx);
          for (const url in urls) {
            if (!urls[url].startsWith(this.$urlUploads)) {
              includesOtherPictures = true;
              languageOfOtherPictures += (languageOfOtherPictures != "" ? ", " : "") + locale;
              break;
            }
          }
        }
      }
      if (includesOtherPictures) {
        const prefix = errorText !== "" ? ". " : "";
        const errorOtherPictures = Vue.i18n.translate("elementsTextTranslation.errorOtherPictures");
        errorText += `${prefix}${errorOtherPictures} (${languageOfOtherPictures})`;
      }
      return errorText;
    },

    checkIfMediaFieldsCompleteAll: function(element, locales) {
      let errorText = "";
      const errorFillIn = Vue.i18n.translate("elementsTextTranslation.errorFillIn");
      if (element.propsObj.width > 100 || element.propsObj.width < 0) {
        errorText = errorFillIn + Vue.i18n.translate("elementsMediaTranslation.sizeValidValue");
      }
      const file = Vue.i18n.translate("elementsMediaTranslation.file");
      for (const locale of locales) {
        if (!element.translations[locale]?.uri) {
          const prefix = errorText === "" ? errorFillIn : ", ";
          errorText += `${prefix}${file} (${locale})`;
        }
      }
      return errorText;
    },

    checkIfSpaceFieldsCompleteAll: function(element) {
      return !element.propsObj.size ? Vue.i18n.translate("elementsSpaceTranslation.errorSize") : "";
    },

    checkIfBlockFieldsCompleteAll: function(element, locales) {
      let errorText = "";
      let errorTextComplex = "";
      let errorValueSecondValue = false;
      let errorsArr = [];

      if (element.propsObj.type == "") {
        errorsArr.push(Vue.i18n.translate("elementsBlockTranslation.errorTypeEmpty"));
      }

      if (element.propsObj.type != "details" && element.propsObj.type != "none") {
        const result = this.checkIfConditionCompleteAll(element, errorsArr, true);
        errorTextComplex = result.errorTextComplex;
        errorsArr = result.errorsArr;
        errorValueSecondValue = result.errorValueSecondValue;
      }

      if (!element.propsObj.thenBlock.repeat.question) {
        if (element.propsObj.thenBlock.repeat.data < 1) {
          errorsArr.push(Vue.i18n.translate("elementsBlockTranslation.errorRepetitionMin"));
        }
      } else if (
        element.propsObj.thenBlock.repeat.data === ""
          || this.getQuestionLabelList[element.propsObj.thenBlock.repeat.data] === 0
          || this.getQuestionLabelList[element.propsObj.thenBlock.repeat.data].type === "text"
      ) {
        errorsArr.push(Vue.i18n.translate("elementsBlockTranslation.errorRepetitionLabel"));
      } else if (this.getQuestionLabelList[element.propsObj.thenBlock.repeat.data].type != "slider") {
        errorsArr.push(Vue.i18n.translate("elementsBlockTranslation.errorRepetitionLabel"));
      }

      if (element.propsObj.type != "conditional" && element.propsObj.type != "none") {
        for (const locale of locales) {
          if (!element.translations[locale]) {
            errorsArr.push(Vue.i18n.translate("elementsBlockTranslation.errortextDetailsEmpty") + " (" + locale + ")");
          }
        }
      }

      if (errorsArr.length > 0) {
        errorText = Vue.i18n.translate("elementsTextTranslation.errorFillIn");
        for (const err in errorsArr) {
          errorText += (err > 0 ? ", " : "") + errorsArr[err];
        }

        //check if questionLabelList has questions that can be used in conditions
        if (
          this.getQuestionLabelList.length > 0
          && (element.propsObj.type === "conditional" || element.propsObj.type === "both" || element.propsObj.thenBlock.repeat.question)
        ) {
          let questionExists = false;
          for (const i in this.getQuestionLabelList) {
            const question = this.getQuestionLabelList[i];
            if (question != 0 && question.type != "text") {
              questionExists = true;
              break;
            }
          }
          if (!questionExists) {
            errorText += ". " + Vue.i18n.translate("elementsBlockTranslation.noQuestions");
          }
        }
      }

      if (errorText != "" && !errorText.endsWith(".")) {
        errorText += ".";
      }

      if (errorValueSecondValue) {
        errorText += " " + Vue.i18n.translate("elementsBlockTranslation.errorValueSecondValue");
      } else if (errorTextComplex !== "") {
        errorText += " " + errorTextComplex;
      }
      return errorText;
    },

    checkIfPageFieldsCompleteAll: function(element) {
      let errorText = "";
      let errorTextComplex = "";
      let errorValueSecondValue = false;
      let errorsArr = [];
      const isConditionalPage = element.propsObj.condition != "" && element.propsObj.condition != null;

      if (isConditionalPage) {
        const result = this.checkIfConditionCompleteAll(element, errorsArr, true);
        errorTextComplex = result.errorTextComplex;
        errorsArr = result.errorsArr;
        errorValueSecondValue = result.errorValueSecondValue;
      }

      if (errorsArr.length > 0) {
        errorText = Vue.i18n.translate("elementsTextTranslation.errorFillIn");
        for (const err in errorsArr) {
          errorText += (err > 0 ? ", " : "") + errorsArr[err];
        }

        if (isConditionalPage) {
          //check if questionLabelList has questions that can be used in conditions
          let questionExists = false;
          if (this.getQuestionLabelList.length > 0) {
            for (const i in this.getQuestionLabelList) {
              const question = this.getQuestionLabelList[i];
              if (question != 0 && question.type !== "text") {
                questionExists = true;
              }
            }
            if (!questionExists) {
              errorText += ". " + Vue.i18n.translate("elementsBlockTranslation.noQuestions");
            }
          }
        }
      }

      if (errorText !== "" && !errorText.endsWith(".")) {
        errorText += ".";
      }

      if (errorValueSecondValue) {
        errorText += " " + Vue.i18n.translate("elementsBlockTranslation.errorValueSecondValue");
      } else if (errorTextComplex !== "") {
        errorText += " " + errorTextComplex;
      }
      return errorText;
    },

    checkIfConditionCompleteAll: function(element, errorsArr, returnErrorDetails) {
      let errorTextComplex = "";
      const condition = element.propsObj.condition;
      //todo check if condition is ever fulfillable (for example slider > slider.max)
      if (element.propsObj.isComplexCondition) {
        if (condition.nodeType === "leaf") {
          //user clicked on complex condition but did not define a condition
          element.propsObj.isComplexCondition = false;
          condition.questionLabel = -1;
        } else if (!this.checkIfComplexConditionCompleteAll(condition)) {
          errorTextComplex = Vue.i18n.translate("elementsBlockTranslation.errorComplexCondition");
        }
      }
      let errorValueSecondValue = false;
      if (!element.propsObj.isComplexCondition) {
        if (condition.questionLabel === -1 || (!condition.questionLabel && condition.questionLabel !== 0)) {
          errorsArr.push(Vue.i18n.translate("elementsQuestionTranslation.questionLabel"));
        }
        if (condition.leafType !== "question" && condition.operation === "") {
          errorsArr.push(Vue.i18n.translate("elementsBlockTranslation.errorOperationEmpty"));
        }
        if (condition.operation !== "answered" && condition.value === "") {
          errorsArr.push(Vue.i18n.translate("elementsBlockTranslation.errorValueEmpty"));
        }
        if (condition.leafType !== "question" && condition.operation === "between") {
          if (condition.secondValue === "") {
            errorsArr.push(Vue.i18n.translate("elementsBlockTranslation.errorSecondValueEmpty"));
          } else {
            if (condition.leafType === "slider" || condition.leafType === "table") {
              if (Number(condition.value) > Number(condition.secondValue)) {
                errorValueSecondValue = true;
              }
            } else if (condition.value > condition.secondValue) {
              // Compare date-strings without turning them to numbers
              errorValueSecondValue = true;
            }
          }
        }
      }
      if (returnErrorDetails) {
        return { errorTextComplex, errorsArr, errorValueSecondValue };
      } else {
        return errorsArr.length === 0 && errorTextComplex === "" && !errorValueSecondValue;
      }
    },

    checkIfComplexConditionCompleteAll: function(condition) {
      if (condition.nodeType === "leaf") {
        if (condition.questionLabel === "" || (condition.operation !== "answered" && condition.value === "")) {
          return false;
        }
        if (condition.leafType === "date") {
          if (condition.operation === "") {
            return false;
          } else if (condition.operation === "between") {
            return condition.secondValue !== "" && condition.value <= condition.secondValue;
          }
        } else if (condition.leafType === "slider" || condition.leafType === "table") {
          if (condition.operation === "") {
            return false;
          } else if (condition.operation === "between") {
            return (
              condition.secondValue !== "" &&
              Number(condition.value) <= Number(condition.secondValue)
            );
          }
        }
        return true;
      } else if (condition.nodeType === "and" || condition.nodeType === "or") {
        const children = condition.children;
        return children.length > 1 && children.every(child => this.checkIfComplexConditionCompleteAll(child));
      } else if (condition.nodeType === "not") {
        const children = condition.children;
        return children.length === 1 && this.checkIfComplexConditionCompleteAll(children[0]);
      }
      return false;
    },
  },
};
