import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "getQuestionLabelList",
      "getSelectedLanguage",
    ]),

    summaryList: function() {
      return {
        ElementsBlock: Vue.i18n.translate("lessonTranslation.addBlock"),
        ElementsHeadline: Vue.i18n.translate("elementsHeadlineTranslation.headline"),
        ElementsMedia: Vue.i18n.translate("elementsMediaTranslation.media"),
        ElementsPage: Vue.i18n.translate("elementsPageTranslation.pageBreak"),
        ElementsLine: Vue.i18n.translate("elementsLineTranslation.line"),
        ElementsSpace: Vue.i18n.translate("elementsSpaceTranslation.space"),
        ElementsQuestion: Vue.i18n.translate("elementsQuestionTranslation.question"),
        ElementsTable: Vue.i18n.translate("elementsTableTranslation.table"),
        ElementsText: Vue.i18n.translate("elementsTextTranslation.text"),
      };
    },

    questionTypeList: function() {
      return [
        {
          value: "YesNoSwitch",
          text: Vue.i18n.translate("elementsQuestionTranslation.yesNoSwitch"),
        },
        {
          value: "SingleChoice",
          text: Vue.i18n.translate("elementsQuestionTranslation.singleChoice"),
        },
        {
          value: "MultipleChoice",
          text: Vue.i18n.translate("elementsQuestionTranslation.multipleChoice"),
        },
        {
          value: "Slider",
          text: Vue.i18n.translate("elementsQuestionTranslation.slider"),
        },
        {
          value: "TextDate",
          text: Vue.i18n.translate("elementsQuestionTranslation.textDate"),
        },
        {
          value: "TextTime",
          text: Vue.i18n.translate("elementsQuestionTranslation.textTime"),
        },
        {
          value: "TextDateTime",
          text: Vue.i18n.translate("elementsQuestionTranslation.textDateTime"),
        },
        {
          value: "TextString",
          text: Vue.i18n.translate("elementsQuestionTranslation.textString"),
        },
        {
          value: "TextArea",
          text: Vue.i18n.translate("elementsQuestionTranslation.textArea"),
        },
        {
          value: "QuestionTable",
          text: Vue.i18n.translate("elementsQuestionTranslation.questionTable"),
        },
      ];
    },

    optionsObj: function() {
      return {
        less: "<",
        lessEqual: "<=",
        greater: ">",
        greaterEqual: ">=",
        equals: "=",
        notEquals: "!=",
        between: Vue.i18n.translate("conditionEditorTranslation.between"),
      };
    },
  },

  methods: {
    addElementsButtons: function(isInBlock, isSkill) {
      const list = [
        {
          elementType: "ElementsHeadline",
          text: Vue.i18n.translate("lessonTranslation.addHeadline"),
        },
        {
          elementType: "ElementsText",
          text: Vue.i18n.translate("lessonTranslation.addText"),
        },
        {
          elementType: "ElementsMedia",
          text: Vue.i18n.translate("lessonTranslation.addMedia"),
        },
        {
          elementType: "ElementsQuestion",
          text: Vue.i18n.translate("lessonTranslation.addQuestion"),
        },
        {
          elementType: "ElementsLine",
          text: Vue.i18n.translate("lessonTranslation.addLine"),
        },
        {
          elementType: "ElementsSpace",
          text: Vue.i18n.translate("lessonTranslation.addSpace"),
        },
        {
          elementType: "ElementsTable",
          text: Vue.i18n.translate("lessonTranslation.addTable"),
        },
        {
          elementType: "ElementsBlock",
          text: Vue.i18n.translate("lessonTranslation.addBlock"),
        },
        {
          elementType: "ElementsPage",
          text: Vue.i18n.translate("lessonTranslation.addPage"),
        },
      ];
      if (isInBlock || isSkill) {
        list.splice(8, 1);
      }
      if(isSkill){
        list.splice(3, 1);
      }
      return list;
    },

    getElementSummary: function(questionElement, detailsOpen) {
      let summaryText = "";
      let textWithReferences = "";
      if (
        detailsOpen ||
        questionElement.type === "ElementsPage" ||
        questionElement.type === "ElementsTable" ||
        questionElement.type === "ElementsLine" ||
        questionElement.type === "ElementsSpace"
      ) {
        summaryText = this.summaryList[questionElement.type];
        if (questionElement.type === "ElementsHeadline" || questionElement.type === "ElementsText") {
          summaryText += " *";
        }
      } else if (questionElement.type === "ElementsHeadline") {
        if (questionElement.translations[this.getSelectedLanguage]) {
          textWithReferences = questionElement.translations[this.getSelectedLanguage];
        }
        summaryText = this.summaryList[questionElement.type];
      } else if (questionElement.type === "ElementsMedia") {
        if(questionElement.translations[this.getSelectedLanguage] && questionElement.translations[this.getSelectedLanguage].subtitle){
          textWithReferences = questionElement.translations[this.getSelectedLanguage].subtitle;
        }
        summaryText = this.summaryList[questionElement.type];

      } else if (questionElement.type === "ElementsText"){
        if (questionElement.translations[this.getSelectedLanguage]) {
          textWithReferences = questionElement.translations[this.getSelectedLanguage].replace(/<[^>]+>/g, "").replace(/&lt;/g, "<").replace(/&gt;/g, ">");
        }
        summaryText = this.summaryList[questionElement.type];
      } else if (questionElement.type === "ElementsQuestion") {
        if (questionElement.propsObj.selectedQuestionProp === "") {
          summaryText = this.summaryList[questionElement.type];
        } else {
          const index = this.questionTypeList.findIndex(elem => elem.value === questionElement.propsObj.selectedQuestionProp);
          summaryText = (index !== -1) ? this.questionTypeList[index].text : "";
          if (questionElement.propsObj.labelProp != "") {
            summaryText += " " + "(" + questionElement.propsObj.labelProp + ")";
          }
          if (
            questionElement.propsObj.selectedQuestionProp !== "QuestionTable" &&
            questionElement.translations[this.getSelectedLanguage] &&
            questionElement.translations[this.getSelectedLanguage].question
          ) {
            textWithReferences = questionElement.translations[this.getSelectedLanguage].question;
          }
        }
      } else if (questionElement.type === "ElementsBlock") {
        let detailsText = "";
        if (questionElement.translations[this.getSelectedLanguage]) {
          detailsText = questionElement.translations[this.getSelectedLanguage];
        }
        if (questionElement.propsObj.type === "conditional" || questionElement.propsObj.type === "both") {
          if (questionElement.propsObj.type === "conditional") {
            summaryText = Vue.i18n.translate("elementsBlockTranslation.blockFor");
          } else {
            summaryText = Vue.i18n.translate("elementsBlockTranslation.blockForWithDetails");
          }
          if (!questionElement.propsObj.isComplexCondition) {
            summaryText += " " + this.simpleConditionAsString(questionElement.propsObj, true);
          } else {
            summaryText += " " + Vue.i18n.translate("elementsBlockTranslation.complexCondition");
          }
          if (questionElement.propsObj.type === "both") {
            summaryText += ": " + detailsText;
          }
        }else if(questionElement.propsObj.type === "details"){
          summaryText = Vue.i18n.translate("elementsBlockTranslation.typeDetails") + ": " + detailsText;
        }else if(questionElement.propsObj.type === "none"){
          summaryText = Vue.i18n.translate("elementsBlockTranslation.noneBlock");
        }
        if (questionElement.propsObj.thenBlock) {
          if (!questionElement.propsObj.thenBlock.repeat.question && questionElement.propsObj.thenBlock.repeat.data > 1) {
            summaryText += ", " + Vue.i18n.translate("elementsBlockTranslation.repetitionContent") + " " +
              questionElement.propsObj.thenBlock.repeat.data + " " + Vue.i18n.translate("elementsBlockTranslation.repetitionDisplayed");
          } else if (questionElement.propsObj.thenBlock.repeat.question) {
            summaryText += ", " + Vue.i18n.translate("elementsBlockTranslation.repetitionQuestionContent") + " ";
            if (
              questionElement.propsObj.thenBlock.repeat.data != "" &&
              this.getQuestionLabelList[questionElement.propsObj.thenBlock.repeat.data] &&
              this.getQuestionLabelList[questionElement.propsObj.thenBlock.repeat.data] != 0
            ) {
              summaryText += this.getQuestionLabelList[questionElement.propsObj.thenBlock.repeat.data].label;
            }
          }
        }
      }

      if (textWithReferences != "") {
        textWithReferences = textWithReferences.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
        //first replace gets {{number}} as x -> brackets are replaced in second replace -> only number -> get label
        const self = this;
        textWithReferences = textWithReferences.replace(/\{{2}([^}{]+)}{2}/g, function(x) {
          const number = x.replace(/{/g, "").replace(/}/g, "");
          if (self.getQuestionLabelList[number] && self.getQuestionLabelList[number] != 0) {
            return "{{" + self.getQuestionLabelList[number].label + "}}";
          } else {
            return "";
          }
        });
        summaryText += ": " + textWithReferences;
      }
      return summaryText;
    },

    simpleConditionAsString: function(propsObj, summary) {
      let string = "";
      //label
      if (
        propsObj.condition.questionLabel != undefined &&
        propsObj.condition.questionLabel != "" &&
        this.getQuestionLabelList[propsObj.condition.questionLabel] &&
        this.getQuestionLabelList[propsObj.condition.questionLabel] != 0
      ) {
        string = this.getQuestionLabelList[propsObj.condition.questionLabel].label;
      }
      //operation
      if (propsObj.condition.operation === "answered") {
        string += " " + Vue.i18n.translate("elementsBlockTranslation.answeredAny");
      } else if (propsObj.condition.leafType !== "date" && propsObj.condition.leafType !== "slider") {
        if (summary) {
          string += " =";
        } else {
          string += " " + Vue.i18n.translate("elementsBlockTranslation.answeredWith");
        }
      } else {
        string += " " + this.optionsObj[propsObj.condition.operation];
      }
      //value question/slider/date
      if (propsObj.condition.operation !== "answered") {
        if (typeof propsObj.condition.value === "boolean") {
          if (propsObj.condition.value) {
            string += " " + Vue.i18n.translate("generalTranslation.yes");
          } else {
            string += " " + Vue.i18n.translate("generalTranslation.no");
          }
        } else {
          string += " " + propsObj.condition.value;
        }
      }
      //range for slider and date
      if (propsObj.condition.operation === "between") {
        string += " " + Vue.i18n.translate("conditionEditorTranslation.betweenAnd") + " " + propsObj.condition.secondValue;
      }
      return string;
    },

    setMissingTranslations: function(elements, isTranslation, language) {
      if (language != "") {
        for (const e in elements) {
          //if there is not yet a translation for the selected language, add it
          const element = elements[e];
          if (element.type === "ElementsBlock") {
            if (!element.translations[language]) {
              element.translations[language] = "";
            }
            element.propsObj.elements = this.setMissingTranslations(element.propsObj.elements, isTranslation, language);
          } else if (element.type !== "ElementsLine" && element.type !== "ElementsSpace" && !element.translations[language]) {
            switch (element.type) {
              case "ElementsHeadline":
              case "ElementsText":
              case "ElementsTable":
                element.translations[language] = "";
                break;
              case "ElementsMedia":
                element.translations[language] = {
                  uri: isTranslation ? element.translations[this.getSelectedLanguage].uri : "",
                  filename: isTranslation ? element.translations[this.getSelectedLanguage].filename : "",
                  subtitle: "",
                  description: "",
                };
                //if no file, use file of another language if there are any
                if (!element.translations[language].uri) {
                  for (const translation in element.translations) {
                    if (translation != language && element.translations[translation].uri) {
                      element.translations[language].uri = element.translations[translation].uri;
                      element.translations[language].filename = element.translations[translation].filename;
                    }
                  }
                }
                break;
              case "ElementsQuestion": {
                let question = "";
                let answers = [];
                switch (element.propsObj.selectedQuestionProp) {
                  case "YesNoSwitch":
                    answers = language === "de" ? ["JA", "NEIN"] : ["YES", "NO"];
                    break;
                  case "SingleChoice":
                  case "MultipleChoice":
                    answers = new Array(element.propsObj.values.length).fill("");
                    break;
                  case "Slider":
                    answers = [
                      { value: Number(element.propsObj.values.min), label: "" },
                      { value: Number(element.propsObj.values.max), label: "" },
                    ];
                    break;
                  case "QuestionTable": {
                    const count = Number(element.propsObj.values.max) - Number(element.propsObj.values.min);
                    answers = new Array(count > 0 ? count : 0).fill("");
                    question = new Array(element.propsObj.values.questions).fill("");
                    break;
                  }
                  default:
                }
                element.translations[language] = { question, answers };
                break;
              }
              case "ElementsPage":
                if (this.$enabledFeatures.includes("progress")) {
                  //page element did not have translations before
                  if (!element.translations) {
                    element.translations = [];
                  }
                  element.translations[language] = "";
                }
                break;
              default:
            }
          }
        }
      }
      return elements;
    },

    hasTypeFile: function(mimetype) {
      return (
        mimetype === "application/pdf" ||
        mimetype === "application/msword" ||
        mimetype === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        mimetype === "application/vnd.ms-powerpoint" ||
        mimetype === "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
        mimetype === "application/vnd.oasis.opendocument.text"
      );
    },

    hasTypeWord: function(mimetype) {
      return (
        mimetype === "application/msword" ||
        mimetype === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        mimetype === "application/vnd.oasis.opendocument.text"
      );
    },

    hasTypePPT: function(mimetype) {
      return (
        mimetype === "application/vnd.ms-powerpoint" ||
        mimetype === "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      );
    },

    hasTypePDF: function(mimetype) {
      return mimetype === "application/pdf";
    },

    getSubtitlesLanguage: function(sourceLanguage) {
      switch (sourceLanguage) {
        case "de": return "Deutsch";
        case "en": return "English";
        case "fr": return "Français";
        case "es": return "Español";
        case "ca": return "Català";
        case "ro": return "Română";
        default: return sourceLanguage;
      }
    },
  }
};
