<template>
  <div class="theResetPassword">
    <img src="eSano_cms.png">
    <form class="loginForm" @submit.prevent="resetPassword(email)">
      <h3>
        {{ 'loginTranslation.forgotPassword' | translate }}
      </h3>
      {{ 'resetPasswordTranslation.resetPasswordInstructions' | translate }}
      <br>
      <br>
      <input
        type="email"
        v-model="email"
        id="inputMailForgotPW"
        :placeholder="$t('generalTranslation.email') + ' *'"
        formControlName="email"
        autocomplete="email"
        required
      >
      <div v-if="email === ''" id="errorMailEmptyPW" class="loginErrorMessage">
        {{ 'loginTranslation.errorEnterEmail' | translate }}
      </div>
      <div v-if="email != '' && !emailRegEx" id="errorMailInvalidPW" class="loginErrorMessage">
        {{ 'loginTranslation.errorEnterValidEmail' | translate }}
      </div>
      <br>
      <button type="submit" id="resendButton" :disabled="email === '' || !emailRegEx">
        {{ 'resetPasswordTranslation.send' | translate }}
      </button>
      <br/>
      <a
        v-if="getNotificationText != '' && getNotificationText.text.includes('verifiziert')"
        :href="$urlAuth + '/resendVerification'"
        id="verifyEmailLink"
      >
        {{ 'resetPasswordTranslation.verifyEmail' | translate }}
      </a>
      <router-link to="/" id="loginLink" class="loginLink">
        {{ 'loginTranslation.login' | translate }}
      </router-link>
    </form>

    <BaseNotification ref="notificationRef"></BaseNotification>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import httpHelper from "../mixins/httpHelper";
import BaseNotification from "./BaseNotification.vue";

export default {
  name: "TheResetPassword",

  components: {
    BaseNotification,
  },

  mixins: [httpHelper],

  data: function() {
    return {
      email: "",
    };
  },

  computed: {
    ...mapGetters([
      "getNotificationText",
    ]),

    emailRegEx: function() {
      const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regExp.test(this.email);
    }
  },

  watch: {
    getNotificationText(newVal) {
      if (newVal != "") {
        if (newVal.type === "error") {
          localStorage.clear();
        } else if (newVal.type === "success") {
          this.email = "";
          localStorage.clear();
        }
      }
    },
  },

  mounted() {
    this.closeNotification();
  },

  methods: {
    ...mapMutations([
      "SET_NOTIFICATIONTEXT",
    ]),

    resetPassword: function(email) {
      this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("resetPasswordTranslation.resetPasswordLoad") });
      this.requestResetPasswordInstructions(email);
    },

    closeNotification: function() {
      this.$refs["notificationRef"].closeNotification();
    },
  },
}
</script>
