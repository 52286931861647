<template>
  <div class="elementsTable">
    <section class="notSummary">
      <section v-if="getIsEditable" key="editSec">
        <section class="wrapper">
          <button type="button" :id="'buttonAddTableHeader' + propsObj.id" class="buttonForm" :disabled="hasHeader" @click="addHeader(false)">
            <i class="fa fa-plus"></i>
            {{ 'elementsTableTranslation.header' | translate }}
          </button>
          <button type="button" :id="'buttonRemoveTableHeader' + propsObj.id" class="buttonForm" :disabled="!hasHeader || rows < 2" @click="removeHeader(false)">
            <i class="fa fa-minus"></i>
            {{ 'elementsTableTranslation.header' | translate }}
          </button>
        </section>

        <section class="wrapper">
          <button type="button" :id="'buttonAddTableRow' + propsObj.id" class="buttonForm" @click="addRow(false)">
            <i class="fa fa-plus"></i>
            {{ 'elementsTableTranslation.row' | translate }}
          </button>
          <button type="button" :id="'buttonRemoveTableRow' + propsObj.id" class="buttonForm" :disabled="(rows < 2 && !hasHeader) || (rows < 3 && hasHeader)" @click="removeRow(false)">
            <i class="fa fa-minus"></i>
            {{ 'elementsTableTranslation.row' | translate }}
          </button>
          <button type="button" :id="'buttonAddTableColumn' + propsObj.id" class="buttonForm" @click="addColumn(false)">
            <i class="fa fa-plus"></i>
            {{ 'elementsTableTranslation.column' | translate }}
          </button>
          <button type="button" :id="'buttonRemoveTableColumn' + propsObj.id" class="buttonForm" :disabled="columns < 2" @click="removeColumn(false)">
            <i class="fa fa-minus"></i>
            {{ 'elementsTableTranslation.column' | translate }}
          </button>
        </section>

        <section class="hide">
          {{content}}
        </section>

        <section :id="'tableSection' + propsObj.id + (isTranslation ? 'Translation' : '')" class="wrapper">
          <table :id="tableId" contenteditable @input="tableChanged(true)">
            <tbody>
            </tbody>
          </table>
        </section>
      </section>

      <section v-else key="nonEditSec" class="wrapper">
        <table :id="tableId">
          <tbody>
          </tbody>
        </table>
      </section>
    </section>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
  name: 'ElementsTable',

  props: {
    propsObj: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: String,
    },

    isTranslation: {
      required: true,
      type: Boolean,
    },
  },

  data: function() {
    return {
      content: '',
      table: '',
      rows: '',
      columns: '',
      initBool: true,
      textInserted: false,
      hasHeader: false,
      tableId: "",
    };
  },

  computed: {
    ...mapGetters([
      'getIsEditable',
    ]),
  },

  watch: {
    'translation': function(newVal) {
      if (!this.textInserted) {
        this.content = newVal;
        if (!this.initBool) {
          this.initTable(this.content);
        }
      }
    },
  },

  created() {
    this.tableId = "table" + this.propsObj.id + (this.isTranslation ? 'Translation' : '');
    if (!this.textInserted) {
      this.content = this.translation;
    }
  },

  mounted() {
    this.table = document.getElementById(this.tableId);
    if (!this.textInserted) {
      this.initTable(this.content);
    }
    this.initBool = false;
  },

  methods: {
    initTable: function(content) {
      if (content === "") {
        this.rows = 0;
        this.columns = 0;
        const initTable = true;
        this.addRow(initTable);
        this.addRow(initTable);
        this.addColumn(initTable);
        this.addColumn(initTable);
      } else {
        let split = content.split("<tbody>");
        if (split.length > 1) {
          const split2 = split[1].split("</tbody>");
          const htmlContent = split2[0];
          this.table.innerHTML = htmlContent;
        }
        split = content.split("<thead>");
        if (split.length > 1) {
          const header = this.table.createTHead();
          const split2 = split[1].split("</thead>");
          const htmlContent = split2[0];
          header.innerHTML = htmlContent;
          this.hasHeader = true;
        }
        this.rows = this.table.rows.length;
        this.columns = this.table.rows[0].cells.length;
      }
    },

    tableChanged: function(bool, initTable) {
      this.textInserted = bool;
      this.$emit(
        "change-element",
        {
          type: "translation-attribute",
          name: !initTable ? "text" : "init-table",
          isTranslation: this.isTranslation,
          value: this.getFormattedContent(),
        },
      );
    },

    getFormattedContent: function() {
      let string = "<table>" + this.table.outerHTML.substring(this.table.outerHTML.indexOf(">") + 1);
      string = string.replace(/(<!--.*?-->)|(<!--[\S\s]+?-->)|(<!--[\S\s]*?$)/g,'');
      string = string.replace(/ style="(.*?)(?=")"/g,'').replace(/ class="(.*?)(?=")"/g,'');
      return string;
    },

    addRow: function(initTable) {
      const columns = this.columns;
      const newRow = this.table.insertRow(this.rows);
      if (this.rows === 0) {
        newRow.insertCell(0);
        this.columns = 1;
      } else {
        for (let c = 0; c < columns; c++) {
          newRow.insertCell(c);
        }
      }
      this.rows++;
      this.tableChanged(false, initTable);
    },

    removeRow: function() {
      if (this.rows > 1) {
        this.table.deleteRow(this.rows-1);
        this.rows = this.table.rows.length;
        this.tableChanged(false);
      }
    },

    addColumn: function(initTable) {
      for (let r = 0; r < this.rows; r++){
        this.table.rows[r].insertCell(this.columns);
      }
      this.columns++;
      this.tableChanged(false, initTable);
    },

    removeColumn: function() {
      for (let r = 0; r < this.rows; r++) {
        this.table.rows[r].deleteCell(this.columns-1);
      }
      this.columns--;
      this.tableChanged(false);
    },

    addHeader: function() {
      const newRow = this.table.createTHead().insertRow(0);
      if (this.rows === 0) {
        newRow.insertCell(0);
      } else {
        for (let c = 0; c < this.columns; c++) {
          newRow.insertCell(c);
        }
      }
      this.rows++;
      this.hasHeader = true;
      this.tableChanged(false);
    },

    removeHeader: function() {
      if (this.hasHeader && this.rows > 1) {
        this.table.deleteTHead();
        this.rows = this.table.rows.length;
        this.hasHeader = false;
        this.tableChanged(false);
      }
    },
  }
}
</script>
