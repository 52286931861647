<template>
  <div class="studyEditor">
    <section v-show="!loadingPage">
      <BaseMenuBar
        :nameList="[studyDetails.name]"
        :idList="[]"
      ></BaseMenuBar>

      <BaseTabs
        :tabDetails="tabDetails"
        :selectedView="selectedView"
        @show-view="showView"
      ></BaseTabs>

      <select v-if="selectedView === 0" :value="getSelectedLanguage" id="inputSelectedLanguage" class="languageField" @change="changeLanguage1($event.target.value)">
        <option value="" id="inputSelectedLanguageNone" disabled>
          {{ 'generalTranslation.chooseLanguage' | translate }}
        </option>
        <option v-for="language in getAvailableLocales" :id="'inputSelectedLanguage' + language" :key="language">
          {{language}}
        </option>
      </select>

      <section v-show="selectedView === 1" id="groupPermissions">
        <StudyPermissions
          v-if="showStudyPermissions"
          :key="$route.fullPath"
          :studyId="studyId"
          @set-changed="setChanged"
        ></StudyPermissions>
      </section>

      <InterventionList
        v-show="selectedView === 2"
        id="interventionsGroup"
        :key="$route.fullPath"
        :interventionListData="interventionListData"
        :usedInRoute="usedInRoute"
        :disableWhileCopy="copyModalVisible"
        @get-new-list="getNewInterventions"
        @route-to="routeTo"
        @show-modal="showModal"
        @close-notification="closeNotification"
        @reset-page="resetPageInt"
      ></InterventionList>

      <DiaryList
        v-show="selectedView === 3"
        id="diariesGroup"
        :key="$route.fullPath"
        :diaryListData="diaryListData"
        :studyId="studyId"
        :copyBool="false"
        :disableWhileCopy="copyModalVisible"
        @get-new-list="getNewDiaries"
        @route-to="routeTo"
        @close-notification="closeNotification"
        @reset-page="resetPageDiary"
        @show-modal="showModal"
      ></DiaryList>

      <section v-show="selectedView === 4">
        <StudyMedia
          v-if="showStudyMedia"
          id="studyMedia"
          :allFiles="true"
          :isSelection="false"
          :studyId="studyId"
          :multiple="false"
          @set-changed="setChanged"
        ></StudyMedia>
      </section>

      <section v-show="selectedView === 0">
        <section v-show="!loadingLanguage">
          <section :id="groupDetailsFormId">
            <label>
              {{ 'generalTranslation.name' | translate }} *
            </label>
            <input type="text" v-model="studyDetails.name" id="inputName" :placeholder="$t('generalTranslation.name')" class="nameInput"
               :disabled="!isEditable">

            <section class="wrapper">
              <section class="col sec50">
                <label>
                  {{ 'interventionTranslation.description' | translate }}
                </label>
                <textarea v-model="description" id="inputDescription" :placeholder="$t('interventionTranslation.description')" :disabled="!isEditable"/>
              </section>
              <section class="col sec50">
              </section>
            </section>
            <section class="wrapper media">
              <section class="col sec50">
                <section class="wrapper">
                  <label>
                    {{ $t("interventionTranslation.image") }}
                  </label>
                  <br>
                  <img v-if="studyPictureLink != ''" :src="studyPictureLink" class="buttonForm">
                  <i class="far fa-image fa-2x" v-else></i>
                </section>
                <br>
                <section>
                  <button
                    type="button"
                    v-if="isEditable"
                    class="buttonForm"
                    id="buttonSelectPicture"
                    @click="getStudyMedia"
                  >
                    {{ $t("elementsMediaTranslation.selectPicture") }}
                  </button>
                  <button
                    type="button"
                    v-if="isEditable && studyPictureLink != ''"
                    class="buttonForm"
                    id="buttonDeleteStudyPicture"
                    @click="deletePictureVisible = true"
                  >
                    {{ $t("elementsMediaTranslation.deletePicture") }}
                  </button>
                </section>
              </section>
              <section class="col sec50">
              </section>
            </section>
            <section v-if="isEditable" class="wrapper">
              <section class="col sec50">
                <br>
                <button type="submit" id="buttonSaveGroupDetails" :disabled="isSaved" @click="saveStudyDetails">
                  {{ 'generalTranslation.save' | translate }}
                </button>
              </section>
              <section class="col sec50">
              </section>
            </section>

            <section v-if="usedInRoute === 'studyNotEditable'" class="s1">
              <label>
                {{ 'interventionTranslation.permissionContact' | translate }}
              </label>
              <section v-for="owner in groupOwners" :key="owner.attributes.email">
                {{getOwnerName(owner.attributes)}}
                <a :href="'mailto:' + owner.attributes.email">({{owner.attributes.email}})</a>
              </section>
            </section>
          </section>
        </section>

        <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loadingLanguage"></i>
      </section>

    </section>
    <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loadingPage"></i>

    <BaseModal
      v-if="warningChangesVisible"
      id="changesModal"
      :bodyText="warningText"
      :isLeaveSiteWarning="true"
      :leftButtonText="$t('interventionTranslation.leave')"
      @close-modal="resolvePromise"
    ></BaseModal>

    <BaseModal
      v-if="deletePictureVisible"
      id="deleteStudyPictureModal"
      :headerText="$t('elementsMediaTranslation.deletePicture')"
      :bodyText="$t('interventionTranslation.deletePicture')"
      :leftButtonText="$t('generalTranslation.yes')"
      @close-modal="closeDeletePictureModal"
    ></BaseModal>

    <!-- InterventionCreate, DiaryCreate, InterventionImport, DiaryImport (targetData, route-to)
      InterventionCopy, DiaryCopy, InterventionPublish (sourceData) -->
    <div
      :is="modalVisible"
      :sourceData="sourceData"
      :targetData="{id: studyId, name: studyDetails.name, isInStudy: true}"
      @set-changed="setChanged"
      @route-to="routeTo"
      @close-modal="closeModalIntDiaryFunction"
      @close-notification="closeNotification"
    ></div>

    <StudyMedia
      v-if="studyMediaVisible"
      id="mediaSelection"
      :allFiles="false"
      :isSelection="true"
      :studyId="studyId"
      :multiple="false"
      @close-modal="closeModalMedia"
      @item-selected="selectMedia"
    ></StudyMedia>

    <BaseNotification ref="notificationRef"></BaseNotification>
  </div>
</template>

<script>
import Vue from "vue";
import {mapGetters,mapMutations} from "vuex";
import httpHelper from "../mixins/httpHelper";
import elementJSONToPropsHelper from "../mixins/elementJSONToPropsHelper";
import router from "../router";
import BaseMenuBar from "./BaseMenuBar.vue";
import BaseTabs from "./BaseTabs.vue";
import BaseNotification from "./BaseNotification.vue";
import BaseModal from "./BaseModal.vue";
import InterventionCreate from "./InterventionCreate.vue";
import InterventionCopy from "./InterventionCopy.vue";
import InterventionPublish from "./InterventionPublish.vue";
import InterventionImport from "./InterventionImport.vue";
import InterventionList from "./InterventionList.vue";
import StudyPermissions from "./StudyPermissions.vue";
import DiaryCreate from "./DiaryCreate.vue";
import DiaryCopy from "./DiaryCopy.vue";
import DiaryImport from "./DiaryImport.vue";
import DiaryList from "./DiaryList.vue";
import StudyMedia from "./StudyMedia.vue";

export default {
  name: "StudyEditor",

  components: {
    BaseMenuBar,
    BaseTabs,
    BaseNotification,
    BaseModal,
    InterventionCreate,
    InterventionCopy,
    InterventionPublish,
    InterventionImport,
    InterventionList,
    StudyPermissions,
    DiaryCreate,
    DiaryCopy,
    DiaryImport,
    DiaryList,
    StudyMedia
  },

  mixins: [httpHelper, elementJSONToPropsHelper],

  props: ["view", "oldInterventionId", "oldDiaryId", "owners"],

  data: function() {
    return {
      modalVisible: "",
      selectedView: 2,
      description: "",
      mimetype: "",
      studyDetails: [],
      warningChangesVisible: false,
      loadingPage: true,
      loadingLanguage: false,
      permissionsChanged: false,
      mediaAttributesChanged: false,
      formChanged: false, //true if study details changed by user & not yet saved
      sourceData: "",
      diaryListData: {},
      showStudyMedia: false,
      showStudyPermissions: false,
      deleteInterventionVisible: false,
      deleteDiaryVisible: false,
      studyMediaVisible: false,
      isSaved: false,
      showLastPage: false,
      getSpecificPageIntervention: false,
      getSpecificPageDiary: false,
      resolvePromise: null,
      rejectPromise: null,
      groupOwners: [],
      interventionListData: {},
      groupDetailsFormId: "groupDetailsForm",
      deletePictureVisible: false,
    };
  },

  computed: {
    ...mapGetters([
      'getSelectedLanguage',
      'getAvailableLocales',
      'getMyRoleForStudy',
      'getNotificationText',
      'getUserId',
      'getPageRefreshBool',
      'getPageRefreshDetails',
    ]),

    studyId: function(){
      return Number(this.$route.params.studyId)
    },

    usedInRoute: function(){
      return this.getMyRoleForStudy === "None" ? "studyNotEditable" : "studyEditable"
    },

    isEditable: function(){
      return (this.getMyRoleForStudy === "Owner")
    },

    hasOwnerOrEditPermissions: function(){
      return (this.getMyRoleForStudy === "Owner" || this.getMyRoleForStudy === "Collaborator")
    },

    studyPictureLink: function(){
      return this.studyDetails.picture ? this.$urlUploads + "/studies/" + this.studyId + "/" + this.studyDetails.picture : ""
    },

    hasUnsavedChanges: function(){
      return (this.formChanged || this.permissionsChanged || this.mediaAttributesChanged)
    },

    warningText: function(){
      var string = "";
      if(this.formChanged){
        string += Vue.i18n.translate('studyTranslation.studyDetails');
      }
      if(this.permissionsChanged){
        string += ((string != "") ? ", " : "") + Vue.i18n.translate('studyTranslation.permissions');
      }
      if(this.mediaAttributesChanged){
        string += ((string != "") ? ", " : "") + Vue.i18n.translate('studyTranslation.studyMedia');
      }

      return Vue.i18n.translate('generalTranslation.unsavedChanges') + ": " + string
    },

    copyModalVisible: function(){
      return this.modalVisible.includes('Copy') || this.modalVisible === 'InterventionPublish'
    },

    tabDetails: function(){
      return [
        {text: Vue.i18n.translate('studyTranslation.studyDetails'), show: true, id: "details"},
        {text: Vue.i18n.translate('studyTranslation.permissions'), show: this.hasOwnerOrEditPermissions, id: "permissions"},
        {text: Vue.i18n.translate('studyTranslation.interventions'), show: true, id: "interventions"},
        {text: Vue.i18n.translate('studyTranslation.diaries'), show: true, id: "diaries"},
        {text: Vue.i18n.translate('studyTranslation.studyMedia'), show: this.hasOwnerOrEditPermissions, id: "media"},
      ]
    }
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.loadingLanguage = false;
      }
    },

    '$route.params.studyId': function(){
      this.loadingPage = true;
      this.loadingLanguage = true;
      this.showLastPage = true;
      this.showStudyMedia = false;
      this.getInitialData(true);
    }
  },

  mounted(){
    if(this.getNotificationText.text != Vue.i18n.translate('studyTranslation.createStudySuccess')){
      this.closeNotification();
    }

    if(this.getSelectedLanguage == ""){
      this.SET_SELECTEDLANGUAGE("de");
    }

    if(this.getPageRefreshBool && this.getPageRefreshDetails.id === this.studyId){
      this.studyDetails = this.getPageRefreshDetails.details;
      this.selectedView = this.getPageRefreshDetails.view;
      if(this.selectedView === 4){
        this.showStudyMedia = true;
      }
      this.formChanged = this.getPageRefreshDetails.formChanged;
      this.permissionsChanged = this.getPageRefreshDetails.permissionsChanged;
      this.mediaAttributesChanged = this.getPageRefreshDetails.mediaAttributesChanged;
      this.groupOwners = this.getPageRefreshDetails.groupOwners;
      this.getInitialData(false);
    }else{
      if(this.view != undefined){
        this.selectedView = this.view;
      }
      if(this.oldInterventionId != undefined){
        this.selectedView = 2;
        this.getSpecificPageIntervention = true;
      }
      if(this.oldDiaryId != undefined){
        this.selectedView = 3;
        this.getSpecificPageDiary = true;
      }
      if(this.owners != undefined){
        this.groupOwners = this.owners;
      }
      this.getInitialData(true);
    }

    this.SET_PAGEREFRESHBOOL(false);
    this.SET_PAGEREFRESHDETAILS([]);

    window.addEventListener('beforeunload', this.beforeunloadFunction);
    window.addEventListener('unload', this.unloadFunction);
  },

  beforeRouteLeave(to, from, next){
    this.SET_PAGEREFRESHBOOL(false);
    this.SET_PAGEREFRESHDETAILS([]);

    if(this.modalVisible.includes('Create') || this.modalVisible.includes('Import') || this.copyModalVisible || this.deleteInterventionVisible ||
        this.deleteDiaryVisible || this.studyMediaVisible){
      next(false);
    }else if(this.hasUnsavedChanges){
      var self = this;
      this.showWarningModal()
      .then(ok => {
        self.closeModalWarning();
        if(ok){
          self.formChanged = false;
          if(to.params.oldStudyId === undefined && ((to.name === "myStudies" && self.usedInRoute === "studyEditable") ||
              (to.name === "moreStudies" && self.usedInRoute === "studyNotEditable"))){
            to.params.oldStudyId = self.studyId;
          }
          next();
        }else{
          next(false);
        }
      });
    }else{
      if(to.params.oldStudyId === undefined && ((to.name === "myStudies" && this.usedInRoute === "studyEditable") ||
          (to.name === "moreStudies" && this.usedInRoute === "studyNotEditable"))){
        to.params.oldStudyId = this.studyId;
      }
      this.formChanged = false;
      next();
    }
  },

  beforeDestroy(){
    if(this.isEditable && document.getElementById(this.groupDetailsFormId) != null){
      document.getElementById(this.groupDetailsFormId).removeEventListener("input", this.changeFormHasChanged);
    }
    window.removeEventListener('beforeunload', this.beforeunloadFunction);
    window.removeEventListener('unload', this.unloadFunction);
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
      'SET_SELECTEDLANGUAGE',
      'SET_PAGEREFRESHBOOL',
      'SET_PAGEREFRESHDETAILS',
    ]),

    closeDeletePictureModal: function(done) {
      if (done) {
        this.studyDetails.picture = "";
      }
      this.deletePictureVisible = false;
    },

    showWarningModal: function(){
      return new Promise((resolve, reject) => {
        this.warningChangesVisible = true;
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      })
    },

    getInitialData: function(firstInit){
      var self = this;
      this.requestCollaboratorsIncludingPermissions("page=0&id=" + this.getUserId, this.studyId, false, -1, -1)
      .then( function(){
        return self.requestStudyDetails(self.studyId);
      })
      .then( function(response){
        if(firstInit){
          self.studyDetails = response;
        }
        self.init();
        self.loadingPage = false;
        return self.requestInterventionsOfStudy(self.studyId, self.interventionListData.currentPage, "", self.getSpecificPageIntervention, self.oldInterventionId, self.showLastPage)
      })
      .then( function(response){
        self.interventionListData = response;
        var pageDiary = self.diaryListData.currentPage;
        if(self.getSpecificPageDiary){
          pageDiary = 0;
        }
        return self.requestDiariesOfStudy(self.studyId, pageDiary, "", self.oldDiaryId, self.showLastPage)
      })
      .then( function(response){
        self.diaryListData = response;
        self.showLastPage = false;
        self.getSpecificPageIntervention = false;
        self.getSpecificPageDiary = false;

        if(self.selectedView === 1){
          self.showStudyPermissions = true;
        }
        if(self.isEditable && document.getElementById(self.groupDetailsFormId) != null){
          document.getElementById(self.groupDetailsFormId).addEventListener("input", self.changeFormHasChanged);
        }
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.getInitialData(firstInit) }, "studyDetails");
      });
    },

    init: function(){
      this.description = "";
      for(var translation in this.studyDetails.translations){
        if(this.studyDetails.translations[translation].locale === this.getSelectedLanguage){
          this.description = this.studyDetails.translations[translation].description;
          break;
        }
      }
      this.loadingLanguage = false;
    },

    showModal: function(obj){
      switch (obj.type) {
        case "InterventionDelete":
          this.deleteInterventionVisible = obj.value;
        break;

        case "DiaryDelete":
          this.deleteDiaryVisible = obj.value;
        break;

        default:
          this.closeNotification();

          if(obj.type === 'DiaryCopy' || obj.type === 'InterventionCopy' || obj.type === 'InterventionPublish'){
            this.sourceData = obj;
          }

          this.modalVisible = obj.type;
          this.showWarningUnsavedChanges();
        break;
      }
    },

    closeModalWarning: function(){
      this.modalVisible = '';
      this.warningChangesVisible = false;
    },

    closeModalMedia: function(){
      this.studyMediaVisible = false;
    },

    changeLanguage1: function(language){
      this.closeNotification();
      this.loadingLanguage = true;
      //saving with old language, then setting new language
      this.saveFields(this.getSelectedLanguage);
      this.SET_SELECTEDLANGUAGE(language);
      this.init();
    },

    closeNotification: function(){
      this.$refs["notificationRef"].closeNotification();
    },

    beforeunloadFunction: function(e){
      if(this.hasUnsavedChanges){
        e.preventDefault();
        e.returnValue = '';
        return;
      }
      delete e['returnValue'];
    },

    unloadFunction: function(){
      this.SET_PAGEREFRESHBOOL(true);
      this.closeNotification();

      var containsL1 = false;

      for(var translation in this.studyDetails.translations){
        if(this.studyDetails.translations[translation].locale === this.getSelectedLanguage){
          this.studyDetails.translations[translation].description = this.description;
          containsL1 = true;
          break;
        }
      }

      if(!containsL1){
        var newTranslation =
        {
          "locale": this.getSelectedLanguage,
          "description" : this.description,
        };
        this.studyDetails.translations.push(newTranslation);
      }

      var data = {
        id: this.studyId,
        details: this.studyDetails,
        view: this.selectedView,
        formChanged: this.formChanged,
        permissionsChanged: this.permissionsChanged,
        mediaAttributesChanged: this.mediaAttributesChanged,
        groupOwners: this.groupOwners
      };

      this.SET_PAGEREFRESHDETAILS(data);
    },

    showView: function(view){
      this.selectedView = view;
      if(view === 1){
        this.showStudyPermissions = true;
      }else if(view === 4){
        this.showStudyMedia = true;
      }
    },

    saveStudyDetails: function(){
      if(this.studyDetails.name === ""){
        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('generalTranslation.errorNameEmpty')});
      }else{
        this.isSaved = true;
        this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('generalTranslation.savingLoad', { name: Vue.i18n.translate('studyTranslation.studyDetails') })});

        var json = {
          "data" : {
            "type" : "studies",
            "attributes" : {
              "name" : this.studyDetails.name,
              "picture": this.studyDetails.picture ? this.studyDetails.picture : "",
              "translations": []
            }
          }
        };
        var containsL1 = false;

        for(var translation in this.studyDetails.translations){
          if(this.studyDetails.translations[translation].locale === this.getSelectedLanguage){
            this.studyDetails.translations[translation].description = this.description;
            containsL1 = true;
            break;
          }
        }

        if(!containsL1){
          var newTranslation = {
            "locale": this.getSelectedLanguage,
            "description" : this.description,
          };
          this.studyDetails.translations.push(newTranslation);
        }

        json.data.attributes.translations = this.studyDetails.translations;

        var self = this;
        this.updateStudyDetailsRequest(json, this.studyId)
        .then( function(){
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('generalTranslation.savingSuccess', { name: Vue.i18n.translate('studyTranslation.studyDetails') })});
          self.formChanged = false;
          return self.requestStudyDetails(self.studyId);
        })
        .then( function(response){
          self.studyDetails = response;
          self.isSaved = false;
          self.init();
        })
        .catch(function (error){
          self.handleErrors(error, function(){ self.saveStudyDetails() }, "");
        });
      }
    },

    saveFields: function(language){
      var containsL1 = false;

      for(var translation in this.studyDetails.translations){
        if(this.studyDetails.translations[translation].locale === language){
          this.studyDetails.translations[translation].description = this.description;
          containsL1 = true;
          break;
        }
      }

      if(!containsL1){
        var newTranslation =
        {
          "locale": language,
          "description" : this.description,
        };
        this.studyDetails.translations.push(newTranslation);
      }
    },

    changeFormHasChanged: function(){
      this.formChanged = true;
    },

    routeTo: function(obj){
      if(obj.name != 'interventionPreview'){ //todo check if closeModal should be for all
        this.closeModalIntDiary();
      }

      router.push(obj);
    },

    closeModalIntDiary: function(){
      this.modalVisible = '';
    },

    closeModalIntDiaryFunction: function(obj){
      var modalType = this.modalVisible;
      this.closeModalIntDiary();

      if(obj.done){
        if(modalType.includes('Create') || modalType.includes('Import') || this.studyId === obj.studyId){ //create, import, copy in same study
          //route stays the same -> only get new interventions/diaries array because contains 1 more
          this.getNewInterventionsDiaries(modalType);
        }else{ //copy in different study
          //route changes -> get new study Details, permissions, interventions (last page if copyInt), diaries (last page if copyDiary) in route.params.studyId watch function
          router.push({ name: 'studyEditor', params: { studyId: obj.studyId }});
        }
      }
    },

    getNewInterventionsDiaries: function(modalType){
      var page;
      if(modalType.startsWith('Intervention')){
        page = this.interventionListData.allPages;
        if(this.interventionListData.total > 0 && this.interventionListData.total % this.$perPage === 0){
          //last page has 15 -> new one is on next
          page++;
        }
        this.getNewInterventions({ page: page, filterTerm: "" });
      }else{
        page = this.diaryListData.allPages;
        if(this.diaryListData.total > 0 && this.diaryListData.total % this.$perPage === 0){
          //last page has 15 -> new one is on next
          page++;
        }
        this.getNewDiaries({ "studyId": this.studyId, "page": page, "filterTerm": "" });
      }
    },

    showWarningUnsavedChanges: function(){
      if(this.hasUnsavedChanges){
        var string = this.warningText + " ";

        if(this.modalVisible.includes("Create")){
          string += Vue.i18n.translate('generalTranslation.warningCreate');
        }else if(this.modalVisible.includes("Copy") || this.modalVisible === 'InterventionPublish'){
          string += Vue.i18n.translate('generalTranslation.warningCopy');
        }else if(this.modalVisible.includes("Import")){
          string += Vue.i18n.translate('generalTranslation.warningImport');
        }
        this.SET_NOTIFICATIONTEXT({type: "error", text: string});
      }
    },

    setChanged: function(obj){
      switch (obj.name) {
        case "media":
          this.mediaAttributesChanged = obj.value;
        break;

        case "permissions":
          this.permissionsChanged = obj.value;
        break;

        case "all":
          this.mediaAttributesChanged = obj.value;
          this.permissionsChanged = obj.value;
          this.formChanged = obj.value;
        break;

        default:
        break;
      }
    },

    getNewDiaries: function(obj){
      var self = this;
      this.requestDiariesOfStudy(this.studyId, obj.page, obj.filterTerm, this.oldDiaryId, false)
      .then( function(response){
        self.diaryListData = response;
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.getNewDiaries(obj) }, "");
      });
    },

    getNewInterventions: function(obj){
      var self = this;
      this.requestInterventionsOfStudy(this.studyId, obj.page, obj.filterTerm, false, this.oldInterventionId, false)
      .then( function(response){
        self.interventionListData = response;
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.getNewInterventions(obj) }, "");
      });
    },

    resetPageDiary: function(){
      this.diaryListData.currentPage = 1;
    },

    resetPageInt: function(){
      this.interventionListData.currentPage = 1;
    },

    getStudyMedia: function(){
      this.studyMediaVisible = true;
    },

    selectMedia: function(obj){
      var filename = obj.link.split("").reverse().join("");
      filename = filename.substr(0, filename.indexOf('/'));
      filename = filename.split("").reverse().join("");
      this.studyDetails.picture = filename;
      this.formChanged = true;
      this.closeModalMedia();
    },

    getOwnerName: function(owner){
      if(owner.first_name && owner.last_name){
        return owner.first_name + " " + owner.last_name
      }else{
        return owner.name
      }
    },
  }
}
</script>
