import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state:{
    token: '', //auth token
    lessonElementsArr: [], //list for lesson elements
    notificationText: "",
    roles: [], //roles of user
    selectedLanguage: "", //first language for content, not necessarily same as app language
    availableLocales: [], //all languages that are supported by API
    userId: '', //user id to filter not-collaborating interventions out of all interventions
    allDetailsLocalesChanged: false, // Contains whether all locales in LessonDetails were changed in the context of tracking change locales.
    changedDetailsLocales: [], // Locales that were changed by editing LessonDetails
    allElemLocalesChanged: false, // Contains whether all locales in LessonElements were changed in the context of tracking change locales.
    changedElemLocales: [], // Locales that were changed by editing LessonElements
    formElementsChanged: false, //indicating change of form elements in LessonEditor -> show popup if not saved (also used when accessing createStudy)
    pageRefreshBool: false, //indicating page reload -> take old details for intervention/lesson instead of fetching new
    pageRefreshDetails: [], //details for last variable
    questionLabelList: [], //list of questionlabels for condition in block (build in LessonEditor, updated in LessonEditor & ElementsQuestion, used in ElementsBlock)
    myRoleForStudy: "", //role for collaborating intervention -> Owner/Collaborator/Access -> for showing buttons etc in Intervention and LessonEditor; -1 if non-collab
    conditionEditor: false, //if true: show conditionEditor
    conditionEditorData: '', //contains pos of block for which condition is defined & condition when saved
    previewAnswers: {}, //list of answers for questions selected in preview
    codeViewData: '', //contains old condition (before condition editor was opened), new condition from condition editor or code view
    appLanguage: '', //language of app, not necessarily same as content language
    announcements: [], //list of current announcements
    roleIds: "", //list with ids of roles for owner, collaborator, access
  },
  getters: {
    getToken: state => {
      return state.token
    },
    getLessonElementsArr: state => {
      return state.lessonElementsArr
    },
    getNotificationText: state => {
      return state.notificationText
    },
    getRoles: state => {
      return state.roles
    },
    getSelectedLanguage: state => {
      return state.selectedLanguage
    },
    getAvailableLocales: state => {
      return state.availableLocales
    },
    getUserId: state => {
      return state.userId
    },
    getFormElementsChanged: state => {
      return state.formElementsChanged
    },
    getAllDetailsLocalesChanged: state => {
      return state.allDetailsLocalesChanged
    },
    getAllElemLocalesChanged: state => {
      return state.allElemLocalesChanged
    },
    getChangedDetailsLocales: state => {
      return state.changedDetailsLocales
    },
    getChangedElemLocales: state => {
      return state.changedElemLocales
    },
    getPageRefreshBool: state => {
      return state.pageRefreshBool
    },
    getPageRefreshDetails: state => {
      return state.pageRefreshDetails
    },
    getQuestionLabelList: state => {
      return state.questionLabelList
    },
    getMyRoleForStudy: state => {
      return state.myRoleForStudy
    },
    getConditionEditor: state => {
      return state.conditionEditor
    },
    getConditionEditorData: state => {
      return state.conditionEditorData
    },
    //do NOT change getPreviewAnswers -> is used as string in condition
    getPreviewAnswers: state => {
      return state.previewAnswers
    },
    getCodeViewData: state => {
      return state.codeViewData
    },
    getAppLanguage: state => {
      return state.appLanguage
    },
    getAnnouncements: state => {
      return state.announcements
    },
    getRoleIds: state => {
      return state.roleIds
    },
    getIsEditable: state => {
      return state.myRoleForStudy === "Owner" || state.myRoleForStudy === "Collaborator"
    },
    getIsOwner: state => {
      return state.myRoleForStudy === "Owner";
    },
  },
  mutations:{
    initialiseStore(state) {
			if(localStorage.getItem('store')) {
				//replace state object with stored item
				this.replaceState(
					Object.assign(state, JSON.parse(localStorage.getItem('store')))
				);
			}
		},
    CHANGE_TOKEN: (state, newValue) => {
      state.token = newValue
    },
    SET_LESSONELEMENTSARR: (state, newValue) => {
      state.lessonElementsArr = newValue;
    },
    SET_NOTIFICATIONTEXT: (state, newValue) => {
      state.notificationText = newValue;
    },
    SET_ROLES: (state, newValue) => {
      state.roles = newValue;
    },
    SET_SELECTEDLANGUAGE: (state, newValue) => {
      state.selectedLanguage = newValue;
    },
    SET_AVAILABLELOCALES: (state, newValue) => {
      state.availableLocales = newValue;
    },
    SET_USERID: (state, newValue) => {
      state.userId = newValue;
    },
    SET_FORMELEMENTSCHANGED: (state, newValue) => {
      state.formElementsChanged = newValue;
      if (!newValue) {
        state.allElemLocalesChanged = false;
        state.changedElemLocales = [];
      }
    },
    SET_ALLDETAILSLOCALESCHANGED: (state, newValue) => {
      state.allDetailsLocalesChanged = newValue;
    },
    SET_ALLELEMLOCALESCHANGED: (state, newValue) => {
      state.allElemLocalesChanged = newValue;
    },
    CLEAR_CHANGEDDETAILSLOCALES: (state) => {
      state.changedDetailsLocales = [];
    },
    SET_CHANGEDDETAILSLOCALES: (state, newValue) => {
      state.changedDetailsLocales = newValue;
    },
    ADD_CHANGEDDETAILSLOCALE: (state, newValue) => {
      if (!state.changedDetailsLocales.includes(newValue)) {
        state.changedDetailsLocales.push(newValue);
      }
    },
    CLEAR_CHANGEDELEMLOCALES: (state) => {
      state.changedElemLocales = [];
    },
    SET_CHANGEDELEMLOCALES: (state, newValue) => {
      state.changedElemLocales = newValue;
    },
    ADD_CHANGEDELEMLOCALE: (state, newValue) => {
      if (!state.changedElemLocales.includes(newValue)) {
        state.changedElemLocales.push(newValue);
      }
    },
    SET_PAGEREFRESHBOOL: (state, newValue) => {
      state.pageRefreshBool = newValue;
    },
    SET_PAGEREFRESHDETAILS: (state, newValue) => {
      state.pageRefreshDetails = newValue;
    },
    SET_QUESTIONLABELLIST: (state, newValue) => {
      state.questionLabelList = newValue;
    },
    SET_MYROLEFORSTUDY: (state, newValue) => {
      state.myRoleForStudy = newValue;
    },
    SET_CONDITIONEDITOR: (state, newValue) => {
      state.conditionEditor = newValue;
    },
    SET_CONDITIONEDITORDATA: (state, newValue) => {
      state.conditionEditorData = newValue;
    },
    SET_PREVIEWANSWERS: (state, newValue) => {
      state.previewAnswers = newValue;
    },
    SET_CODEVIEWDATA: (state, newValue) => {
      state.codeViewData = newValue;
    },
    SET_APPLANGUAGE: (state, newValue) => {
      state.appLanguage = newValue;
    },
    SET_ANNOUNCEMENTS: (state, newValue) => {
      state.announcements = newValue;
    },
    SET_ROLEIDS: (state, newValue) => {
      state.roleIds = newValue;
    },
  },
  actions:{

  }
})
