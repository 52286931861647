<template>
  <div class="skillEditorDetails">
    <section :style="{ visibility: showIfNotTranslation }">
      <label :class="{ error: invalidName }">
        {{ 'interventionTranslation.name' | translate }} *
      </label>
      <input
        type="text"
        :value="skillDetails.name"
        id="inputName"
        :placeholder="$t('interventionTranslation.name')"
        :disabled="!getIsEditable"
        @input="$emit('change-attribute', { name: 'name', value: $event.target.value })"
      >
    </section>

    <label :class="{ error: invalidTitle }">
      {{titleTextInLanguage}} *
    </label>
    <input
      type="text"
      :value="translation.title"
      :id="'inputTitle' + (isTranslation ? 'Translation' : '')"
      :placeholder="titleTextInLanguage"
      :disabled="!getIsEditable"
      @input="$emit('change-attribute', { name: 'title', isTranslation: isTranslation, value: $event.target.value })"
    >

    <section v-if="!isTranslation">
      <section class="col sec50">
        <label>
          {{ $t("interventionTranslation.languagesSkill") }} *
        </label>
        <ul>
          <li v-for="(languageOption,index) in skillDetails.locales" :id="'language' + languageOption" :key="languageOption">
            {{languageOption}}
            <i
              v-if="getIsEditable"
              @click="deleteLanguage(index)"
              :id="'buttonDeleteLanguage' + languageOption"
              v-tooltip="{ content: $t('lessonTranslation.deleteLanguage'), placement: 'right' }"
              class="fa fa-trash"
            ></i>
          </li>
        </ul>
      </section>

      <section v-if="getIsEditable && skillDetails.locales" class="col sec50">
        <label>
          {{ 'interventionTranslation.addLanguage' | translate }}
        </label>
        <form class="buttonForm" @submit.prevent="updateLanguages">
          <select v-model="selectedLanguageToAdd" id="inputLanguage" class="addLanguageSelect buttonForm">
            <option value="" id="inputLanguageNone" disabled>
              {{ 'generalTranslation.chooseLanguage' | translate }}
            </option>
            <option
              v-for="language in getAvailableLocales.filter(language => !skillDetails.locales.includes(language))"
              :id="'inputLanguage' + language" :key="language"
            >
              {{language}}
            </option>
          </select>
          <button type="submit" id="buttonAddLanguage" :disabled="selectedLanguageToAdd === ''" class="buttonMiddle2">
            {{ 'interventionTranslation.add' | translate }}
          </button>
        </form>
      </section>

      <section class="wrapper">
        <label>
          {{ 'interventionTranslation.selectSkillColor' | translate }} *
        </label>
        <input
          type="color"
          id="inputSkillColor"
          list="skillColorValues"
          :value="colorValue"
          :disabled="!getIsEditable"
          @input="$emit('change-attribute', { name: 'color', value: $event.target.value })"
        >
        <datalist id="skillColorValues">
          <option v-for="data in colorsList" :id="'skillColor' + data" :key="data" :value="data">
          </option>
        </datalist>
      </section>

      <section class="wrapper">
        <br>
        <label>
          {{ 'interventionTranslation.skillIcon' | translate }}
        </label>
        {{ 'interventionTranslation.skillIconType' | translate }}
        <section class="wrapper media">
          <section class="col sec50">
            <img v-if="pagePictureLink != ''" :src="pagePictureLink">
            <i class="far fa-image fa-2x" v-else></i>
            <br><br>
            <button type="button" v-if="getIsEditable" id="buttonSelectSkillIcon" @click="getStudyMedia">
              {{ 'interventionTranslation.selectSkillIcon' | translate }}
            </button>
            <button
              type="button"
              v-if="getIsEditable && pagePictureLink != ''"
              id="buttonRemoveSkillIcon"
              class="skillIconButton"
              @click="$emit('change-attribute', { name: 'icon', value: '' })"
            >
              {{ 'interventionTranslation.removeSkillIcon' | translate }}
            </button>
          </section>
          <section class="col sec50">
          </section>
        </section>
      </section>
    </section>

    <StudyMedia
      v-if="studyMediaVisible"
      id="mediaSelection"
      :allFiles="false"
      :isSelection="true"
      :studyId="studyId"
      :multiple="false"
      @item-selected="selectMedia"
      @close-modal="closeModal"
    ></StudyMedia>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import StudyMedia from "./StudyMedia.vue";

export default {
  name: "SkillEditorDetails",

  components: {
    StudyMedia,
  },

  props: {
    skillDetails: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: Object,
    },

    isTranslation: {
      required: true,
      type: Boolean,
    },

    selectedLanguageForTranslation: {
      required: true,
      type: String,
    },

    isTranslationMode: {
      required: true,
      type: Boolean,
    },

    studyId: {
      required: true,
      type: Number,
    },
  },

  data: function() {
    return {
      selectedLanguageToAdd: "",
      colorsList: [
        "#fefcf0", "#ffffff", "#bbbbbb", "#888888", "#444444",
        "#facccc", "#f06666", "#e60000", "#a10000", "#5c0000",
        "#ffebcc", "#ffc266", "#ff9900", "#b26b00", "#663d00",
        "#ffffcc", "#ffff66", "#ffff00", "#b2b200", "#666600",
        "#cce8cc", "#66b966", "#008a00", "#006100", "#003700",
        "#cce0f5", "#66a3e0", "#0066cc", "#0047b2", "#002966",
        "#ebd6ff", "#c285ff", "#9933ff", "#6b24b2", "#3d1466",
      ],
      studyMediaVisible: false,
    };
  },

  computed: {
    ...mapGetters([
      "getAvailableLocales",
      "getSelectedLanguage",
      "getIsEditable",
    ]),

    showIfNotTranslation: function() {
      return !this.isTranslation ? "visible" : "hidden";
    },

    pagePictureLink: function() {
      return this.skillDetails.icon
        ? `${this.$urlUploads}/studies/${this.studyId}/${this.skillDetails.icon}`
        : "";
    },

    colorValue: function() {
      return this.skillDetails.color ? this.skillDetails.color : "#15417e";
    },

    titleTextInLanguage: function() {
      if (!this.isTranslation) {
        return Vue.i18n.translate("interventionTranslation.title");
      } else {
        return Vue.i18n.translate("interventionTranslation.titleTranslation");
      }
    },

    invalidName: function() {
      return !this.skillDetails?.name || this.skillDetails.name.length > 255;
    },

    invalidTitle: function() {
      return !this.translation?.title || this.translation.title.length > 255;
    },
  },

  methods:{
    ...mapMutations([
      "SET_NOTIFICATIONTEXT",
    ]),

    updateLanguages: function() {
      if (this.selectedLanguageToAdd !== "") {
        this.closeNotification();
        const language = this.selectedLanguageToAdd;
        this.selectedLanguageToAdd = "";
        this.$emit("change-attribute", { name: "AddLanguage", value: language });
      } else {
        this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("interventionTranslation.selectLanguageWarning") });
      }
    },

    deleteLanguage: function(index) {
      const language = this.skillDetails.locales[index];
      if (language === this.getSelectedLanguage || (this.isTranslationMode && language === this.selectedLanguageForTranslation)) {
        this.$emit("show-modal", { type: "LanguageError" });
      } else {
        this.$emit("show-modal", { type: "DeleteLanguage", deleteLanguage: language });
      }
    },

    closeNotification: function() {
      this.$emit("close-notification");
    },

    getStudyMedia: function() {
      this.studyMediaVisible = true;
      this.$emit("show-modal", { type: "StudyMedia", value: true });
    },

    selectMedia: function(obj) {
      const fileUrl = obj.link;
      const filename = fileUrl.slice(fileUrl.lastIndexOf("/") + 1);
      this.$emit("change-attribute", { name: "icon", value: filename });
      this.closeModal();
    },

    closeModal: function() {
      this.studyMediaVisible = false;
      this.$emit("show-modal", { type: "StudyMedia", value: false });
    },
  }
}
</script>
