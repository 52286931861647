<template>
<BaseModal
  class="interventionPublish pageModal pageModalActivate"
  :headerText="$t('interventionTranslation.warningIntervention', { name: intervention.name })"
  :cancelButtonText="$t('generalTranslation.back')"
  :hideLeftButton="!leftButtonText"
  :leftButtonText="leftButtonText"
  @close-modal="closeWarningModal"
>
  <template v-slot:body>
    <div class="problemList3">
      <div v-if="result.publishError"> <label class="errorMessage">{{ publishErrorText }}</label> <br> </div>
      <div v-if="result.publishWarning"> <label class="warningMessage" v-html="sanitizeHTML(publishWarningText)" /> <br> </div>
      <hr class="problemListLine">
      <div v-if="result.publishProblems.intervention.length > 0">
        <h3>{{ $t("interventionTranslation.intervention") }}</h3>
        <dl>
          <div class="problemList" v-for="problem in result.publishProblems.intervention" :key="problem.name">
            <dt><label>- {{ problem.name }}</label></dt>
            <div class="problemList2" v-if="problem.errors.length > 0">
              <dt class="errorMessage">{{ $t("adminAnnouncementsTranslation.errors") }}</dt>
              <dd v-for="text in problem.errors" :key="text">
                - {{ text }}
              </dd>
            </div>
            <div class="problemList2" v-if="problem.warnings.length > 0">
              <dt class="warningMessage">{{ $t("adminAnnouncementsTranslation.warnings") }}</dt>
              <dd v-for="text in problem.warnings" :key="text">
                - {{ text }}
              </dd>
            </div>
          </div>
        </dl>
        <hr>
      </div>
      <div v-if="result.publishProblems.lessons.length > 0">
        <h3>{{ $t("generalTranslation.lessons") }}</h3>
        <dl>
          <div class="problemList" v-for="problem in result.publishProblems.lessons" :key="problem.name">
            <dt><label>- {{ problem.name }}</label></dt>
            <div class="problemList2" v-if="problem.errors.length > 0">
              <dt class="errorMessage">{{ $t("adminAnnouncementsTranslation.errors") }}</dt>
              <dd v-for="text in problem.errors" :key="text">
                - {{ text }}
              </dd>
            </div>
            <div class="problemList2" v-if="problem.warnings.length > 0">
              <dt class="warningMessage">{{ $t("adminAnnouncementsTranslation.warnings") }}</dt>
              <dd v-for="text in problem.warnings" :key="text">
                - {{ text }}
              </dd>
            </div>
          </div>
        </dl>
        <hr>
      </div>
      <div v-if="result.publishProblems.skills.length > 0">
        <h3>{{ $t("interventionTranslation.skills") }}</h3>
        <dl>
          <div class="problemList" v-for="problem in result.publishProblems.skills" :key="problem.name">
            <dt><label>- {{ problem.name }}</label></dt>
            <div class="problemList2" v-if="problem.errors.length > 0">
              <dt class="errorMessage">{{ $t("adminAnnouncementsTranslation.errors") }}</dt>
              <dd v-for="text in problem.errors" :key="text">
                - {{ text }}
              </dd>
            </div>
            <div class="problemList2" v-if="problem.warnings.length > 0">
              <dt class="warningMessage">{{ $t("adminAnnouncementsTranslation.warnings") }}</dt>
              <dd v-for="text in problem.warnings" :key="text">
                - {{ text }}
              </dd>
            </div>
          </div>
        </dl>
        <hr>
      </div>
      <div v-if="result.publishProblems.diaries.length > 0">
        <h3>{{ $t("studyTranslation.diaries") }}</h3>
        <dl>
          <div class="problemList" v-for="problem in result.publishProblems.diaries" :key="problem.name">
            <dt><label>- {{ problem.name }}</label></dt>
            <div class="problemList2" v-if="problem.errors.length > 0">
              <dt class="errorMessage">{{ $t("adminAnnouncementsTranslation.errors") }}</dt>
              <dd v-for="text in problem.errors" :key="text">
                - {{ text }}
              </dd>
            </div>
            <div class="problemList2" v-if="problem.warnings.length > 0">
              <dt class="warningMessage">{{ $t("adminAnnouncementsTranslation.warnings") }}</dt>
              <dd v-for="text in problem.warnings" :key="text">
                - {{ text }}
              </dd>
            </div>
          </div>
        </dl>
        <hr>
      </div>
    </div>
  </template>
</BaseModal>
</template>

<script>
import Vue from "vue";
import BaseModal from "./BaseModal.vue";
import utils from "../mixins/utils";

export default {
  name: "InterventionCheckModal",

  components: {
    BaseModal,
  },

  mixins: [utils],

  props: {
    result: {
      required: true,
      type: Object,
    },

    intervention: {
      required: true,
      type: Object,
    },

    checkedElements: {
      required: true,
      type: Boolean,
    },

    leftButtonText: {
      required: false,
      type: String,
    },
  },

  computed: {
    publishErrorText: function() {
      return Vue.i18n.translate("interventionTranslation.publishInterventionErrors");
    },

    publishWarningText: function() {
      const warnings = Vue.i18n.translate("interventionTranslation.publishInterventionWarnings");
      const unsopportedLocales = Vue.i18n.translate("interventionTranslation.publishInterventionWarningsLocales");
      if (this.checkedElements) {
        const incompleteElements = Vue.i18n.translate("interventionTranslation.publishInterventionWarningsElements");
        return `${warnings}<br><br>${unsopportedLocales}<br>${incompleteElements}`;
      } else {
        return `${warnings}<br><br>${unsopportedLocales}`;
      }
    },
  },

  methods: {
    closeWarningModal: function(done) {
      this.$emit("close-modal", done);
    },
  },
}
</script>
