<template>
  <div id="lessonList" class="lessonList">
    <section class="smallerComp">
      <section v-if="!loading">
        <BasePagination
          id="paginationLessonsTop"
          :numberOfPages="lessonListData.allPages"
          :currentPage="lessonListData.currentPage"
          @new-page="getNextPage"
        ></BasePagination>

        <!--<draggable class="list-group" tag="ul" :list="lessonListData.list" :group="{ name: 'lessonGroup'}" @change="moveElement">-->
        <ul :class="{selectFromList: copyBool && !disableWhileCopy}">
          <li v-for="(lesson, index) in lessonListData.list" :id="'entryLesson' + lesson.id" :key="lesson.id" class="interventionElement" :class="{selectedForCopy: copyBool && selectedLessonId === lesson.id}"
              @click="setLessonForCopy(lesson.id)">
            <section class="wrapper">
              <section class="col interventionPositionSection">
                <section v-if="getIsEditable && !copyBool" class="changePos">
                  <i
                    class="fa fa-chevron-up fa-2x"
                    v-if="lesson.attributes.position > '1'"
                    :id="'buttonChangePositionLessonUp' + lesson.id"
                    v-tooltip="$t('lessonTranslation.changePosition')"
                    @click="changePosition(lesson.attributes.position, false)"
                  ></i>
                  <h3 v-if="lessonListData.list.length > 1 || lessonListData.currentPage != 1">
                    Position
                  </h3>
                  <i
                    class="fa fa-chevron-down fa-2x"
                    v-if="
                      (lessonListData.currentPage != lessonListData.allPages)
                        || (index < lessonListData.list.length - 1 && lessonListData.currentPage === lessonListData.allPages)
                    "
                    :id="'buttonChangePositionLessonDown' + lesson.id"
                    v-tooltip="$t('lessonTranslation.changePosition')"
                    @click="changePosition(lesson.attributes.position, true)"
                  ></i>
                </section>
              </section>

              <section class="col interventionDescriptionSection">
                <button type="button" :id="'buttonShowLesson' + lesson.id" :disabled="hasNoPermissions || copyBool" class="linkButton" @click="updateLessonPage(lesson.id, 1)">
                  {{lesson.attributes.position}} {{lesson.attributes.name}}
                </button>

                <p>
                  {{getLessonTitle(lesson)}}
                </p>
                <p>
                  {{getLessonDescription(lesson)}}
                </p>
                <div v-if="skillsList.length > 0 && !copyBool" :id="'skillOfLesson' + lesson.id" class="skillDiv">
                  <span v-for="skill in lesson.attributes.skills" :id="'skillOfLesson' + lesson.id + '-' + skill" :key="skill" class="skillTag" :style="{'background': getSkillColor(skill)}">
                    {{getSkillTitle(skill)}}
                  </span>
                </div>
                <div v-if="!copyBool && lesson.attributes.translation_status" class="progressDiv">
                  <span v-for="(language, index) in lesson.attributes.translation_status" :key="lesson.id + index" class="progressLanguage">
                    {{index}}
                    <progress :value="language" max="1"></progress>
                    {{ Math.round(language * 100) + "%"}}
                  </span>
                </div>
              </section>

              <section v-if="!hasNoPermissions && !copyBool" class="col homeButtonSectionSmall">
                <i
                  class="fa fa-info-circle fa-2x homeButtons"
                  :id="'buttonShowLessonDetails' + lesson.id"
                  v-tooltip="$t('myStudiesTranslation.details')"
                  @click="updateLessonPage(lesson.id, 0)"
                ></i>

                <i
                  class="fa fa-copy fa-2x homeButtons"
                  :id="'buttonCopyLesson' + lesson.id"
                  v-tooltip="$t('generalTranslation.copy')"
                  @click="copyLec(lesson.id, lesson.attributes.name)"
                ></i>

                <i
                  class="fa fa-trash fa-2x homeButtons"
                  v-if="isOwner"
                  :id="'buttonDeleteLesson' + lesson.id"
                  v-tooltip="$t('generalTranslation.delete')"
                  @click="showModal(lesson.id, lesson.attributes.position - 1, lesson.attributes.name)"
                ></i>
                <!--<button type="button" @click="previewLesson(lesson.id)" class="buttonForm">Vorschau</button>-->
              </section>
            </section>
          </li>
        </ul>
        <!--</draggable>-->

        <section v-if="lessonListData.list.length === 0" id="noLessons" class="noLessons">
          <h4>
            {{ 'generalTranslation.noLessons' | translate }}
            <i class="far fa-question-circle" v-tooltip="{ content: $t('generalTranslation.noLessonsTooltip'), theme: 'info-tooltip' }"></i>
          </h4>
        </section>

        <BasePagination
          id="paginationLessonsBottom"
          :numberOfPages="lessonListData.allPages"
          :currentPage="lessonListData.currentPage"
          class="paginationBottom"
          @new-page="getNextPage"
        ></BasePagination>
      </section>

      <i class="fa fa-spinner fa-spin fa-2x loadData" v-else></i>
    </section>

    <BaseModal
      v-if="deleteLessonVisible"
      id="deleteLessonModal"
      :headerText="$t('interventionTranslation.deleteLessonWarning', { name: lessonNameForDeleting })"
      :bodyText="$t('myStudiesTranslation.warning')"
      :leftButtonText="$t('generalTranslation.delete')"
      :disableButtons="disableWhileCopy"
      @close-modal="closeModal"
    ></BaseModal>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import elementJSONToPropsHelper from '../mixins/elementJSONToPropsHelper';
import BasePagination from './BasePagination.vue';
import BaseModal from './BaseModal.vue';

export default {
  name: 'LessonList',

  mixins: [httpHelper, elementJSONToPropsHelper],

  components: {
    BaseModal,
    BasePagination,
  },

  props: {
    //true if used in copy elements functionality, false if used in Intervention
    copyBool: {
      required: true,
      type: Boolean,
    },

    //do not react in watch functions while copy lesson functionality is used
    disableWhileCopy: {
      required: true,
      type: Boolean,
    },

    interventionId: {
      required: true,
      type: Number,
    },

    interventionDetails: {
      required: true,
      type: Array,
    },

    lessonListData: { //with list, currentPage, allPages
      required: true,
      type: Object,
    },

    allLessonsArray: {
      required: true,
      type: Array,
    },

    skillsList: {
      required: true,
      type: Array,
    },
  },

  data: function(){
    return{
      deleteLessonVisible: false,
      lessonIdForDeleting: -1,
      lessonIndexForDeleting: '',
      loading: true,
      lessonNameForDeleting: "",
      selectedLessonId: "",
      configAfterDeletion: [],
      customOrderAfterDeletion: [],
    }
  },

  computed: {
    ...mapGetters([
      "getMyRoleForStudy",
      "getNotificationText",
      "getIsEditable",
    ]),

    deleteNoUnlockedLessonError: function() {
      const always = Vue.i18n.translate("interventionTranslation.always");
      const manually = Vue.i18n.translate("interventionTranslation.manually");
      const date = Vue.i18n.translate("interventionTranslation.atDate");
      const or = Vue.i18n.translate("interventionTranslation.or");
      const types = `"${always}", "${manually}" ${or} "${date}"`;
      return Vue.i18n.translate("interventionTranslation.deleteLessonNoUnlockedLesson", { types });
    },

    isOwner: function() {
      return this.getMyRoleForStudy === "Owner";
    },

    hasNoPermissions: function() {
      return this.getMyRoleForStudy === "None";
    },
  },

  watch:{
    'lessonListData.list'(){
      this.loading = false;
    },

    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error" && !this.disableWhileCopy){
        this.loading = false;
      }
    },
  },

  methods: {
    ...mapMutations([
      "SET_FORMELEMENTSCHANGED",
      "SET_LESSONELEMENTSARR",
      "SET_NOTIFICATIONTEXT",
    ]),

    updateLessonPage: function(lessonId, view) {
      this.closeNotification();
      this.SET_FORMELEMENTSCHANGED(false);
      this.SET_LESSONELEMENTSARR([]);
      this.$emit("route-to", { name: "lessonEditor", params: { lessonId: lessonId, view: view }});
    },

    showModal: function(lessonId, lessonIndex, lessonName) {
      this.lessonIdForDeleting = Number(lessonId);
      this.lessonIndexForDeleting = lessonIndex;
      this.lessonNameForDeleting = lessonName;
      const customOrder = JSON.parse(JSON.stringify(this.interventionDetails.default_configuration.custom_order));
      const configJSON = JSON.parse(JSON.stringify(this.interventionDetails.default_configuration.questionnaire_configuration));
      const deletedId = this.lessonIdForDeleting;
      if (customOrder.length > 1 && customOrder[0] == deletedId) {
        const lesson = configJSON.find(lesson => lesson.id == customOrder[1]);
        if (lesson?.unlock_type === "after_previous") {
          this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("interventionTranslation.deleteLessonAfterPrevious") });
          return;
        }
      }
      let deleteIndex = -1;
      for (const i in configJSON) {
        const config = configJSON[i];
        if (config.id == deletedId) {
          deleteIndex = i;
        } else if (Array.isArray(config.condition)) {
          const conditions = config.condition;
          for (let i = conditions.length - 1; i >= 0; i--) {
            if (conditions[i].questionnaire_id == deletedId) {
              conditions.splice(i, 1);
            }
          }
          if (conditions.length === 0) {
            config.unlock_type = "always";
            config.condition = null;
          }
        }
      }
      if (deleteIndex !== -1) {
        configJSON.splice(deleteIndex, 1);
      }
      const deletedIdIndex = customOrder.findIndex(id => id == deletedId);
      if (deletedIdIndex !== -1) {
        customOrder.splice(deletedIdIndex, 1);
      }
      const hasUnlockedLesson = customOrder.some(id => {
        const lesson = configJSON.find(l => l.id == id);
        return lesson !== undefined && lesson.unlock_type !== "after_previous" && lesson.unlock_type !== "conditional";
      });
      if (hasUnlockedLesson) {
        this.configAfterDeletion = configJSON;
        this.customOrderAfterDeletion = customOrder;
        this.deleteLessonVisible = true;
        this.$emit("show-modal", { type: "LessonDelete", value: true });
      } else {
        this.SET_NOTIFICATIONTEXT({ type: "error", text: this.deleteNoUnlockedLessonError });
      }
    },

    closeModal: function(done) {
      this.deleteLessonVisible = false;
      this.$emit("show-modal", { type: "LessonDelete", value: false });
      if (done) {
        this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("interventionTranslation.deleteLessonLoad") });
        let requestPage = this.lessonListData.currentPage;
        if (this.lessonListData.list.length === 1) {
          //delete last element of page -> request page before that
          requestPage--;
        }
        const self = this;
        this.deleteLessonRequest(this.lessonIdForDeleting)
          .then(function() {
            self.setNewInterventionConfig(requestPage);
          })
          .catch(function(error) {
            self.handleErrors(error, function() { self.closeModal(done) }, "");
          });
      }
    },

    setNewInterventionConfig: function(requestPage) {
      const json = {
        data : {
          type : "interventions",
          attributes: {
            default_configuration: {
              questionnaire_configuration: this.configAfterDeletion,
              custom_order: this.customOrderAfterDeletion,
            },
          },
        },
      };
      const self = this;
      this.updateInterventionConfigAfterDeleteLessonRequest(this.interventionId, json)
        .then(function() {
          const arr = [];
          if (self.lessonIndexForDeleting + 1 < self.allLessonsArray.length) {
            for (let i = self.lessonIndexForDeleting + 1; i < self.allLessonsArray.length; i++) {
              const lesson = self.allLessonsArray[i];
              arr.push({ questionnaire_id: lesson.id, position: lesson.attributes.position - 1 });
            }
          }
          if (arr.length > 0) {
            self.updatePositions(arr, true, requestPage);
          } else {
            self.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("interventionTranslation.deleteLessonSuccess") });
            self.loading = true;
            self.$emit("get-new-list", { page: requestPage, getDetails: true });
          }
          self.lessonIdForDeleting = -1;
          self.lessonIndexForDeleting = "";
        })
        .catch(function(error) {
          self.handleErrors(error, function() { self.setNewInterventionConfig() }, "");
        });
    },

    updatePositions: function(arr, deleteBool, requestPage){
      var self = this;
      this.updateLessonPositionsInInterventionRequest(arr)
      .then(function (){
        self.loading = true;
        if(deleteBool){
          self.deleteLessonVisible = false;
          self.$emit('show-modal', { type: 'LessonDelete', value: false });
          self.$emit('get-new-list', { page: requestPage, getDetails: true });
        }else{
          self.$emit('get-new-list', { page: 0, getDetails: false });
        }
        if(deleteBool){
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('interventionTranslation.deleteLessonSuccess')});
        }else{
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('interventionTranslation.updatePositionsLessonsSuccess')});
        }
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.updatePositions(arr, deleteBool, requestPage) }, "");
      });
    },

    copyLec: function(lessonIdToCopy, lessonName){
      this.$emit('show-modal', { type: 'LessonCopy', id: lessonIdToCopy, name: lessonName });
    },

    //down = true -> number of position gets higher
    //down = false -> number of position gets lower
    changePosition: function(oldPos, down){
      this.closeNotification();
      this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('interventionTranslation.updatePositionsLessonsLoad')});
      var lessonList = this.allLessonsArray;
      var newLessonList = JSON.parse(JSON.stringify(lessonList));
      var pos1;
      if(down){
        pos1 = oldPos + 1;
      }else{
        pos1 = oldPos - 1;
      }

      var id1 = lessonList[oldPos-1].id;
      var pos2 = oldPos;
      var id2;
      if(down){
        id2 = lessonList[oldPos].id;
      }else{
        id2 = lessonList[oldPos-2].id;
      }

      var arr = [];
      arr.push(
        {
          questionnaire_id: id1,
          position: pos1
        }
      );

      var l1 = newLessonList[oldPos-1];
      var l2;
      if(down){
        l2 = newLessonList[oldPos]
      }else{
        l2 = newLessonList[oldPos-2];
      }
      newLessonList[oldPos-1] = l2;
      newLessonList[oldPos-1].attributes.position = oldPos;
      if(down){
        newLessonList[oldPos] = l1;
        newLessonList[oldPos].attributes.position = oldPos + 1;
      }else{
        newLessonList[oldPos-2] = l1;
        newLessonList[oldPos-2].attributes.position = oldPos - 1;
      }

      arr.push(
        {
          questionnaire_id: id2,
          position: pos2
        }
      );
      this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('interventionTranslation.updatePositionsLessonsLoad')});
      this.updatePositions(arr, false, 0);
    },

    getNextPage(selectedNumber){
      if(selectedNumber != ""){
        this.closeNotification();
        this.loading = true;
        this.$emit('get-new-list', { page: selectedNumber, getDetails: false });
      }
    },

    closeNotification: function(){
      this.$emit('close-notification');
    },

    setLessonForCopy: function(lessonId){
      if(this.copyBool){
        this.selectedLessonId = lessonId;
        this.$emit('item-selected', {lessonId: Number(lessonId)});
      }
    },

    getLessonTitle: function(lesson){
      return lesson.attributes.title ? lesson.attributes.title : Vue.i18n.translate('generalTranslation.noTitle')
    },

    getLessonDescription: function(lesson){
      return lesson.attributes.description ? lesson.attributes.description : Vue.i18n.translate('generalTranslation.noDescription')
    },

    getSkillTitle: function(skillId){
      var index = this.skillsList.findIndex(skill => Number(skill.id) === Number(skillId));
      if(index != -1){
        if(!this.skillsList[index].attributes.title){
          return this.skillsList[index].attributes.name
        }else{
          return this.skillsList[index].attributes.title
        }
      }else{
        return ""
      }
    },

    getSkillColor: function(skillId){
      var index = this.skillsList.findIndex(skill => Number(skill.id) === Number(skillId));
      return (index != -1 && this.skillsList[index].attributes.color) ? this.skillsList[index].attributes.color : "#15417e"
    }
  }
}
</script>
