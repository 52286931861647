<template>
  <div class="lessonEditorDetails">
    <section :style="{ visibility: showIfNotTranslation }">
      <label :class="{ error: invalidName }">
        {{ 'interventionTranslation.name' | translate }} *
      </label>
      <input
        type="text"
        :value="lessonDetails.name"
        id="inputName"
        :placeholder="$t('interventionTranslation.name')"
        :disabled="!getIsEditable"
        @input="$emit('change-attribute', {name: 'name', value: $event.target.value})"
      >
    </section>

    <label :class="{ error: invalidTitle }">
      {{titleTextInLanguage}} *
    </label>
    <input
      type="text"
      :value="translation.title"
      :id="'inputTitle' + (isTranslation ? 'Translation' : '')"
      :placeholder="titleTextInLanguage"
      :disabled="!getIsEditable"
      @input="$emit('change-attribute', {name: 'title', isTranslation: isTranslation, value: $event.target.value})"
    >

    <label :class="{ error: invalidDescription }">
      {{ descriptionText }}
    </label>
    <textarea
      :value="translation.description"
      :id="'inputDescription' + (isTranslation ? 'Translation' : '')"
      :placeholder="descriptionTextInLanguage"
      :disabled="!getIsEditable"
      @input="$emit('change-attribute', {name: 'description', isTranslation: isTranslation, value: $event.target.value})"
    />

    <section v-if="!isTranslation">
      <section class="col sec50">
        <label>
          {{ 'lessonTranslation.languagesLesson' | translate }} *
        </label>
        <ul>
          <li v-for="(languageOption,index) in lessonDetails.locales" :id="'language' + languageOption" :key="languageOption">
            {{languageOption}}
            <i
              v-if="getIsEditable"
              @click="deleteLanguage(index)"
              :id="'buttonDeleteLanguage' + languageOption"
              v-tooltip="{ content: $t('lessonTranslation.deleteLanguage'), placement: 'right' }"
              class="fa fa-trash"
            ></i>
          </li>
        </ul>
      </section>

      <section v-if="getIsEditable && lessonDetails.locales" class="col sec50">
        <label>
          {{ 'interventionTranslation.addLanguage' | translate }}
        </label>
        <form class="buttonForm" @submit.prevent="updateLanguages">
          <select v-model="selectedLanguageToAdd" id="inputLanguage" class="addLanguageSelect buttonForm">
            <option value="" id="inputLanguageNone" disabled>
              {{ 'generalTranslation.chooseLanguage' | translate }}
            </option>
            <option
              v-for="language in getAvailableLocales.filter(language => !lessonDetails.locales.includes(language))"
              :id="'inputLanguage' + language" :key="language"
            >
              {{language}}
            </option>
          </select>
          <button type="submit" id="buttonAddLanguage" :disabled="selectedLanguageToAdd === ''" class="buttonMiddle2">
            {{ 'interventionTranslation.add' | translate }}
          </button>
        </form>
      </section>

      <section class="wrapper">
        <label>
          {{ 'lessonTranslation.selectDefaultPageColor' | translate }} *
        </label>

        <BaseSelectBackground
          :pageColor="lessonDetails.page_color"
          :studyId="studyId"
          @change-attribute="$emit('change-attribute', $event)"
          @show-modal="$emit('show-modal', $event)"
        ></BaseSelectBackground>

        <section class="wrapper">
          <label>
            {{ 'lessonTranslation.questionsRequired' | translate }} *
          </label>
          <select :value="lessonDetails.questions_required" id="inputQuestionsRequired" :disabled="!getIsEditable"
              @input="$emit('change-attribute',{'name': 'questions_required', 'value': Number($event.target.value)})">
            <option value="" id="inputQuestionsRequiredNone" disabled>
              {{ 'generalTranslation.select' | translate }}
            </option>
            <option :value="1" id="inputFinishWithoutQuestionsRequiredFalse" selected>
              {{ 'generalTranslation.no' | translate }}
            </option>
            <option :value="0" id="inputFinishWithoutQuestionsRequiredTrue">
              {{ 'generalTranslation.yes' | translate }}
            </option>
          </select>
          <i class="far fa-question-circle" v-tooltip="{ content: explanationText, theme: 'info-tooltip' }"></i>
        </section>
      </section>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import BaseSelectBackground from "./BaseSelectBackground.vue";

export default {
  name: "LessonEditorDetails",

  components: {
    BaseSelectBackground,
  },

  mixins: [],

  props: {
    lessonDetails: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: Object,
    },

    isTranslation: {
      required: true,
      type: Boolean,
    },

    selectedLanguageForTranslation: {
      required: true,
      type: String,
    },

    isTranslationMode: {
      required: true,
      type: Boolean,
    },

    studyId: {
      required: true,
      type: Number,
    },
  },

  data: function() {
    return {
      selectedLanguageToAdd: "",
    };
  },

  computed: {
    ...mapGetters([
      "getIsEditable",
      "getAvailableLocales",
      "getSelectedLanguage",
    ]),

    explanationText: function() {
      if (this.lessonDetails.questions_required === 1) {
        return Vue.i18n.translate("lessonTranslation.questionsRequiredExplanationYes");
      } else {
        return Vue.i18n.translate("lessonTranslation.questionsRequiredExplanationNo");
      }
    },

    showIfNotTranslation: function() {
      return !this.isTranslation ? "visible" : "hidden";
    },

    titleTextInLanguage: function() {
      return Vue.i18n.translate("interventionTranslation.title" + (this.isTranslation ? "Translation" : ""));
    },

    descriptionTextInLanguage: function() {
      return Vue.i18n.translate("interventionTranslation.description" + (this.isTranslation ? "Translation" : ""));
    },

    descriptionText: function() {
      const description = this.descriptionTextInLanguage;
      const length = this.translation?.description?.length ?? 0;
      const characters = Vue.i18n.translate("generalTranslation.characters");
      return `${description} (${length}/255 ${characters}) *`;
    },

    invalidName: function() {
      return !this.lessonDetails?.name || this.lessonDetails.name.length > 255;
    },

    invalidTitle: function() {
      return !this.translation?.title || this.translation.title.length > 255;
    },

    invalidDescription: function() {
      return !this.translation?.description || this.translation.description.length > 255;
    },
  },

  methods:{
    ...mapMutations([
      "SET_NOTIFICATIONTEXT",
    ]),

    updateLanguages: function() {
      if (this.selectedLanguageToAdd != "") {
        this.closeNotification();
        this.$emit("change-attribute", { name: "AddLanguage", value: this.selectedLanguageToAdd });
        this.selectedLanguageToAdd = "";
      } else {
        this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("interventionTranslation.selectLanguageWarning") });
      }
    },

    deleteLanguage: function(index) {
      const language = this.lessonDetails.locales[index];
      if ((language === this.getSelectedLanguage) || (this.isTranslationMode && language === this.selectedLanguageForTranslation)) {
        this.$emit("show-modal", { type: "LanguageError" });
      } else {
        this.$emit("show-modal", { type: "DeleteLanguage", deleteLanguage: language });
      }
    },

    closeNotification: function() {
      this.$emit("close-notification");
    },
  },
}
</script>
