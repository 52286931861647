import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "getRoleIds",
    ]),
  },

  methods: {
    //get my roles for list (study, intervention, diary)
    //access/collaborator/owner
    getMyRoles: function(list, usedForStudy, activateBool) {
      const roles = [];
      for (const item in list) {
        if (list[item].relationships) {
          const currentRoles = list[item].relationships.roles.data;
          const allRoles = [];
          for (const role in currentRoles) {
            allRoles.push(Number(currentRoles[role].id));
          }
          let highestEditorRole;
          if (allRoles.includes(this.getRoleIds.owner)) {
            highestEditorRole = "Owner";
          } else if (allRoles.includes(this.getRoleIds.collaborator)) {
            highestEditorRole = "Collaborator";
          } else if (allRoles.includes(this.getRoleIds.access)) {
            highestEditorRole = "Access";
          } else if (usedForStudy && allRoles.includes(this.getRoleIds.ecoachmanager)) {
            if (!activateBool) {
              highestEditorRole = "Access"; //ecoachmanager can only see content, not edit (-> is like access)
            } else {
              highestEditorRole = "Ecoachmanager";
            }
          } else {
            highestEditorRole = "None";
          }
          roles[item] = highestEditorRole;
        } else {
          roles[item] = "None";
        }
      }
      return roles;
    },

    //returns list with ids of roles for owner, collaborator, access
    getRolesList: function(includedRolesList) {
      const rolesList = {
        owner: 3,
        collaborator: 4,
        access: 8,
        ecoachmanager: 5,
      };
      //change roles if ids not like this anymore
      for (const r in includedRolesList) {
        const includedRole = includedRolesList[r];
        if (includedRole.type === "roles") {
          switch (includedRole.attributes.slug) {
            case "study.owner":
              rolesList.owner = Number(includedRole.id);
              break;
            case "study.collaborator":
              rolesList.collaborator = Number(includedRole.id);
              break;
            case "study.access":
              rolesList.access = Number(includedRole.id);
              break;
            case "study.ecoachmanager":
              rolesList.ecoachmanager = Number(includedRole.id);
              break;
            default:
          }
        }
      }
      return rolesList;
    },
  },
};
