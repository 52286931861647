<template>
  <div class="interventionEditorDetails">
    <section :style="{ visibility: showIfNotTranslation }">
      <label :class="{ error: invalidName }">
        {{ $t("interventionTranslation.name") }} *
      </label>
      <input
        type="text"
        :value="interventionDetails.name"
        id="inputName"
        :placeholder="$t('interventionTranslation.name')"
        :disabled="!getIsEditable"
        @input="$emit('change-attribute', { name: 'name', value: $event.target.value })"
      >
    </section>

    <label :class="{ error: invalidTitle }">
      {{ titleTextInLanguage }} *
    </label>
    <input
      type="text"
      :value="translation.title"
      :id="'inputTitle' + (isTranslation ? 'Translation' : '')"
      :placeholder="titleTextInLanguage"
      :disabled="!getIsEditable"
      @input="$emit('change-attribute', { name: 'title', isTranslation: isTranslation, value: $event.target.value })"
    >

    <label :class="{ error: invalidDescription }">
      {{ descriptionText }}
    </label>
    <textarea
      :value="translation.description"
      :id="'inputDescription' + (isTranslation ? 'Translation' : '')"
      :placeholder="descriptionTextInLanguage"
      :disabled="!getIsEditable"
      @input="$emit('change-attribute', { name: 'description', isTranslation: isTranslation, value: $event.target.value })"
    />

    <section v-if="!isTranslation">
      <section class="col sec50">
        <br>
        <label>{{ textLanguages }} *</label>
        <ul>
          <li v-for="(languageOption, index) in interventionDetails.locales" :id="'language' + languageOption" :key="languageOption">
            {{ languageOption }}
            <i
              v-if="getIsEditable"
              class="fa fa-trash"
              :id="'buttonDeleteLanguage' + index"
              v-tooltip="{ content: $t('lessonTranslation.deleteLanguage'), placement: 'right' }"
              @click="deleteLanguage(index)"
            ></i>
          </li>
        </ul>
      </section>

      <section class="col sec50">
        <section v-if="getIsEditable && interventionDetails.locales">
          <br>
          <label>
            {{ 'interventionTranslation.addLanguage' | translate }}
          </label>
          <form class="buttonForm" @submit.prevent="updateLanguages">
            <select v-model="selectedLanguageToAdd" id="inputLanguage" class="addLanguageSelect buttonForm">
              <option value="" id="inputLanguageNone" disabled>
                {{ 'generalTranslation.chooseLanguage' | translate }}
              </option>
              <option v-for="language in getAvailableLocales.filter(language => !interventionDetails.locales.includes(language))" :id="'inputLanguage' + language" :key="language">
                {{language}}
              </option>
            </select>
            <button type="submit" id="buttonAddLanguage" :disabled="selectedLanguageToAdd === ''" class="buttonMiddle2">
              {{ 'interventionTranslation.add' | translate }}
            </button>
          </form>
        </section>
      </section>
    </section>

    <section v-if="!isTranslation" class="wrapper media">
      <section class="col sec50">
        <section class="wrapper">
          <label>
            {{ $t("interventionTranslation.image") }}
          </label>
          <br>
          <img v-if="interventionPictureLink != ''" :src="interventionPictureLink" class="buttonForm">
          <i class="far fa-image fa-2x" v-else></i>
        </section>
        <br>
        <section>
          <button
            type="button"
            v-if="getIsEditable"
            class="buttonForm"
            id="buttonSelectPicture"
            @click="getStudyMedia"
          >
            {{ $t("elementsMediaTranslation.selectPicture") }}
          </button>
          <button
            type="button"
            v-if="getIsEditable && interventionPictureLink != ''"
            class="buttonForm"
            id="buttonDeleteInterventionPicture"
            @click="deletePictureVisible = true"
          >
            {{ $t("elementsMediaTranslation.deletePicture") }}
          </button>
        </section>
      </section>
      <section class="col sec50">
      </section>
    </section>

    <section class="wrapper" v-if="!isTranslation && isDiary">
      <br>
      <label>
        {{ 'lessonTranslation.selectDefaultPageColor' | translate }} *
      </label>
      <br>

      <BaseSelectBackground
        :pageColor="interventionDetails.page_color"
        :studyId="studyId"
        @change-attribute="$emit('change-attribute', $event)"
        @show-modal="$emit('show-modal', $event)"
      ></BaseSelectBackground>
    </section>

    <section v-if="$enabledFeatures.includes('jitai') && !isTranslation && isDiary" class="wrapper">
      <section v-if="interventionDetails.sensor_data" class="sensing">
        <label class="sensingHeader">
          {{ $t("interventionTranslation.sensing") }} *
          <i
            class="far fa-question-circle"
            v-tooltip="{ content: $t('interventionTranslation.sensingHint'), theme: 'info-tooltip' }"
          ></i>
        </label>
        <span class="sensingAttribute">
          <input
            class="checkmark"
            v-model="interventionDetails.sensor_data.pedometer"
            type="checkbox"
            id="sensor_pedometer"
          >
          <span :class="{ disabledInput: !interventionDetails.sensor_data.pedometer }">
            {{ $t("interventionTranslation.sensingPedometer") }}
          </span>
        </span>
        <input
          class="checkmark"
          v-model="interventionDetails.sensor_data.location"
          type="checkbox"
          id="sensor_location"
        >
        <span :class="{ disabledInput: !interventionDetails.sensor_data.location }">
          {{ $t("interventionTranslation.sensingLocation") }}
        </span>
      </section>
      <label>
        {{ 'interventionTranslation.schedule' | translate }} *
        <i
          class="far fa-question-circle"
          v-tooltip="{ content: $t('interventionTranslation.scheduleHint'), theme: 'info-tooltip' }"
        ></i>
      </label>
      <Schedules
        :interventionDetails="interventionDetails"
        :studyId="studyId"
        :schedules="interventionDetails.schedules"
        @update-schedule="updateSchedule"
      />
    </section>

    <section v-if="!isTranslation && !isDiary" class="wrapper">
      <label>
        {{ 'interventionTranslation.interventionGuided' | translate }} *
      </label>
      <select :value="interventionDetails.intervention_type" id="inputGuided" :disabled="!getIsEditable"
          @input="$emit('change-attribute',{'name': 'intervention_type', 'value': $event.target.value})">
        <option value="" id="inputGuidedNone" disabled>
          {{ 'generalTranslation.select' | translate }}
        </option>
        <option value="accompanied" id="inputGuidedTrue">
          {{ 'interventionTranslation.guided' | translate }}
        </option>
        <option value="unaccompanied" id="inputGuidedFalse">
          {{ 'interventionTranslation.unguided' | translate }}
        </option>
      </select>
    </section>

    <section v-if="!isTranslation" class="wrapper">
      <label>
        {{ 'interventionTranslation.smallLayout' | translate }} *
      </label>
      <select :value="interventionDetails.small_layout" id="inputSmallLayout" :disabled="!getIsEditable"
          @input="$emit('change-attribute',{'name': 'small_layout', 'value': $event.target.value})">
        <option value="" id="inputSmallLayoutNone" disabled>
          {{ 'generalTranslation.select' | translate }}
        </option>
        <option :value="0" id="inputSmallLayoutFalse">
          {{ 'generalTranslation.no' | translate }}
        </option>
        <option :value="1" id="inputSmallLayoutTrue">
          {{ 'generalTranslation.yes' | translate }}
        </option>
      </select>
    </section>

    <section v-if="!isTranslation && !isDiary && $enabledFeatures.includes('buddy')" class="wrapper">
      <label>
        {{ 'interventionTranslation.useBuddyFeature' | translate }} *
        <i
          class="far fa-question-circle"
          v-tooltip="{ content: $t('interventionTranslation.buddyFeatureExplanation'), theme: 'info-tooltip' }"
        ></i>
      </label>
      <select :value="interventionDetails.buddy_support" id="inputBuddy" :disabled="!getIsEditable"
          @input="$emit('change-attribute',{'name': 'buddy_support', 'value': $event.target.value})">
        <option value="" id="inputBuddyNone" disabled>
          {{ 'generalTranslation.select' | translate }}
        </option>
        <option :value="1" id="inputBuddyTrue">
          {{ 'generalTranslation.yes' | translate }}
        </option>
        <option :value="0" id="inputBuddyFalse" selected>
          {{ 'generalTranslation.no' | translate }}
        </option>
      </select>
    </section>

    <section v-if="$enabledFeatures.includes('progress') && !isTranslation && !isDiary" class="wrapper">
      <label>
        {{ 'interventionTranslation.useProgressBars' | translate }} *
      </label>
      <select :value="interventionDetails.gamification ? interventionDetails.gamification.progressbars_enabled : 0" id="inputProgress" :disabled="!getIsEditable"
          @input="$emit('change-attribute',{'name': 'progressbars_enabled', 'value': $event.target.value})">
        <option value="" id="inputProgressNone" disabled>
          {{ 'generalTranslation.select' | translate }}
        </option>
        <option :value="1" id="inputProgressTrue">
          {{ 'generalTranslation.yes' | translate }}
        </option>
        <option :value="0" id="inputProgressFalse" selected>
          {{ 'generalTranslation.no' | translate }}
        </option>
      </select>
    </section>

    <!-- only for picture, background in baseSelectBackground component -->
    <StudyMedia
      v-if="studyMediaVisible"
      id="mediaSelection"
      :allFiles="false"
      :isSelection="true"
      :studyId="studyId"
      :multiple="false"
      @close-modal="closeModal"
      @item-selected="selectMedia"
    ></StudyMedia>

    <BaseModal
      v-if="deletePictureVisible"
      id="deleteInterventionPictureModal"
      :headerText="$t('elementsMediaTranslation.deletePicture')"
      :bodyText="$t('interventionTranslation.deletePicture')"
      :leftButtonText="$t('generalTranslation.yes')"
      @close-modal="closeDeletePictureModal"
    ></BaseModal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import BaseModal from "./BaseModal.vue";
import BaseSelectBackground from "./BaseSelectBackground.vue";
import Schedules from "./Schedules.vue";
import StudyMedia from "./StudyMedia.vue";

export default {
  name: "InterventionEditorDetails",

  components: {
    BaseModal,
    BaseSelectBackground,
    Schedules,
    StudyMedia,
  },

  mixins: [],

  props: {
    interventionDetails: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: Object,
    },

    isTranslation: {
      required: true,
      type: Boolean,
    },

    isDiary: {
      required: true,
      type: Boolean,
    },

    selectedLanguageForTranslation: {
      required: true,
      type: String,
    },

    isTranslationMode: {
      required: true,
      type: Boolean,
    },

    studyId: {
      required: true,
      type: Number,
    },
  },

  data: function() {
    return {
      selectedLanguageToAdd: "",
      studyMediaVisible: false,
      deletePictureVisible: false,
    };
  },

  computed: {
    ...mapGetters([
      "getIsEditable",
      "getAvailableLocales",
      "getSelectedLanguage",
      "getAppLanguage",
    ]),

    interventionPictureLink: function() {
      let url = "";
      if ((this.studyId || this.studyId === 0) && this.interventionDetails.picture) {
        url =`${this.$urlUploads}/studies/${this.studyId}/${this.interventionDetails.picture}`;
      }
      return url;
    },

    showIfNotTranslation: function() {
      return !this.isTranslation ? "visible" : "hidden";
    },

    titleTextInLanguage: function() {
      const key = "interventionTranslation.title" + (this.isTranslation ? "Translation" : "");
      return Vue.i18n.translate(key);
    },

    descriptionTextInLanguage: function() {
      const key = "interventionTranslation.description" + (this.isTranslation ? "Translation" : "");
      return Vue.i18n.translate(key);
    },

    descriptionText: function() {
      const description = this.descriptionTextInLanguage;
      const length = this.translation?.description?.length ?? 0;
      const characters = Vue.i18n.translate("generalTranslation.characters");
      return `${description} (${length}/255 ${characters}) *`;
    },

    invalidName: function() {
      return !this.interventionDetails?.name || this.interventionDetails.name.length > 255;
    },

    invalidTitle: function() {
      return !this.translation?.title || this.translation.title.length > 255;
    },

    invalidDescription: function() {
      return !this.translation?.description || this.translation.description.length > 255;
    },

    textLanguages: function() {
      const key = !this.isDiary ? "interventionTranslation.languagesIntervention" : "diaryTranslation.languagesDiary";
      return Vue.i18n.translate(key);
    },

    textParticipants: function() {
      const key = !this.isDiary ? "interventionTranslation.participants" : "diaryTranslation.participants";
      return Vue.i18n.translate(key);
    },
  },

  watch: {
    "interventionDetails.intervention_type": function(newVal) {
      if (!this.isTranslation && newVal != undefined && newVal !== "" && !newVal) {
        this.$emit("change-attribute", { name: "intervention_type", value: "accompanied" });
      }
    },
  },

  methods:{
    ...mapMutations([
      "SET_NOTIFICATIONTEXT",
    ]),

    updateSchedule: function(obj) {
      this.$emit("change-schedules", obj);
    },

    closeDeletePictureModal: function(done) {
      if (done) {
        this.interventionDetails.picture = "";
      }
      this.deletePictureVisible = false;
    },

    updateLanguages: function(){
      if(this.selectedLanguageToAdd != ""){
        this.closeNotification();
        this.$emit("change-attribute", { name: "AddLanguage", value: this.selectedLanguageToAdd });
        this.selectedLanguageToAdd = "";
      }else{
        this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("interventionTranslation.selectLanguageWarning") });
      }
    },

    deleteLanguage: function(index){
      const language = this.interventionDetails.locales[index];
      if (
        language === this.getSelectedLanguage
          || (this.isTranslationMode && language === this.selectedLanguageForTranslation)
      ) {
        this.$emit('show-modal', { type: 'LanguageError' });
      } else {
        this.$emit('show-modal', { type: 'DeleteLanguage', deleteLanguage: language });
      }
    },

    closeNotification: function(){
      this.$emit('close-notification');
    },

    getStudyMedia: function(){
      this.studyMediaVisible = true;
      this.$emit('show-modal', { type: 'StudyMedia', value: true });
    },

    selectMedia: function(obj){
      var filename = obj.link.split("").reverse().join("");
      filename = filename.substr(0, filename.indexOf('/'));
      filename = filename.split("").reverse().join("");
      this.$emit('change-attribute', {'name': 'picture', 'value': filename });
      this.closeModal();
    },

    closeModal: function(){
      this.studyMediaVisible = false;
      this.$emit('show-modal', { type: 'StudyMedia', value: false });
    },
  }
}
</script>
