import * as DOMPurify from "dompurify";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "getAvailableLocales",
      "getSelectedLanguage",
      "getAppLanguage",
    ]),
  },

  methods: {
    sanitizeHTML(html) {
      return DOMPurify.sanitize(html);
    },

    usernameEquals(name1, name2) {
      return (name1 == null || name1 === "")
        ? (name2 == null || name2 === "")
        : name1.toLowerCase() === name2.toLowerCase();
    },

    usernameNotEquals(name1, name2) {
      return !this.usernameEquals(name1, name2);
    },

    stringEquals(s1, s2) {
      return (s1 == null || s1 === "") ? (s2 == null || s2 === "") : s1 === s2;
    },

    stringNotEquals(s1, s2) {
      return !this.stringEquals(s1, s2);
    },

    unusedLocale(locales, locale) {
      return locales != null && !locales.includes(locale);
    },

    getDefaultLocales() {
      let locales;
      if (this.getAvailableLocales.includes(this.getSelectedLanguage)) {
        locales = [this.getSelectedLanguage];
      } else if (this.getAvailableLocales.includes(this.getAppLanguage)) {
        locales = [this.getAppLanguage];
      } else {
        locales = ["de", "en"];
      }
      return locales;
    },

    addTranslation(translations, translation) {
      const index = translations.findIndex(t => t.locale === translation.locale);
      if (index === -1) {
        translations.push(translation);
      } else {
        translations[index] = translation;
      }
    },
  },
}
