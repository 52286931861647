<template>
  <BaseModal
    id="updatePositionsModal"
    class="pageModal"
    :headerText="$t('interventionTranslation.updateLessonPositions')"
    :leftButtonText="$t('generalTranslation.save')"
    :disableButtons="disablePositionsBool"
    @close-modal="closeModal"
  >
    <template v-slot:body>
      {{ $t("interventionTranslation.updatePositionsDragDrop") }}
      <draggable
        tag="ul"
        id="listOfLessons"
        :list="lessonPositionsArray"
        :group="{ name: 'lessonGroup' }"
        :scroll-sensitivity="40"
        :force-fallback="true"
        :fallback-tolerance="1"
        class="list-group"
        @change="moveElement"
      >
        <li
          v-for="(lesson, index) in lessonPositionsArray"
          :id="'entryLessonPosition' + lesson.id"
          :key="lesson.attributes.position"
          class="list-group-item interventionElement lessonsDragDrop"
        >
          <section class="wrapper">
            <section class="col interventionDescriptionSection">
              <b>
                {{ index + 1 }} {{ lesson.attributes.name }}
              </b>
              <p>
                {{ getLessonTitle(lesson) }}
              </p>
              <p>
                {{ getLessonDescription(lesson) }}
              </p>
            </section>
          </section>
        </li>
      </draggable>
    </template>
  </BaseModal>
</template>

<script>
import Vue from "vue";
import { mapGetters,mapMutations } from "vuex";
import draggable from "vuedraggable";
import httpHelper from "../mixins/httpHelper";
import BaseModal from "./BaseModal.vue";

export default {
  name: "LessonPositions",

  components: {
    draggable,
    BaseModal,
  },

  mixins: [httpHelper],

  props: {
    allLessonsArray: {
      required: true,
      type: Array,
    },
  },

  data: function() {
    return {
      disablePositionsBool: false,
      lessonPositionChanged: false,
      lessonPositionsArray: [],
    };
  },

  computed: {
    ...mapGetters([
      "getNotificationText",
    ]),
  },

  watch: {
    getNotificationText(newVal) {
      if (newVal != "" && newVal.type === "error") {
        this.disablePositionsBool = false;
      }
    },
  },

  created() {
    this.lessonPositionsArray = JSON.parse(JSON.stringify(this.allLessonsArray));
  },

  methods: {
    ...mapMutations([
      "SET_NOTIFICATIONTEXT",
    ]),

    moveElement: function() {
      this.lessonPositionChanged = true;
    },

    getLessonTitle: function(lesson) {
      return lesson.attributes.title || Vue.i18n.translate("generalTranslation.noTitle");
    },

    getLessonDescription: function(lesson) {
      return lesson.attributes.description || Vue.i18n.translate("generalTranslation.noDescription");
    },

    closeModal: function(done) {
      if (done) {
        this.$emit("close-notification");
        this.disablePositionsBool = true;
        this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("interventionTranslation.updatePositionsLessonsLoad") });
        const arr = [];
        for (const lesson in this.lessonPositionsArray) {
          arr.push({
            questionnaire_id: this.lessonPositionsArray[lesson].id,
            position: Number(lesson) + 1,
          });
        }
        this.updateLessonPositionsInInterventionRequest(arr)
          .then(() => {
            this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("interventionTranslation.updatePositionsLessonsSuccess") });
            this.$emit("close-modal", { done: true });
          })
          .catch((error) => {
            this.handleErrors(error, () => this.closeModal(done), "");
          });
      } else if (!this.disablePositionsBool) {
        if (
          this.lessonPositionChanged &&
          (this.getNotificationText === "" ||
            (this.getNotificationText.text !== Vue.i18n.translate("interventionTranslation.unsavedChangesPositionsLeave") &&
              this.getNotificationText.text !== Vue.i18n.translate("interventionTranslation.unsavedChangesPositionsLeaveInterventionEditor")))
        ) {
          this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("interventionTranslation.unsavedChangesPositionsLeave") });
        } else {
          this.$emit("close-notification");
          this.$emit("close-modal", { done: false });
        }
      }
    },
  },
}
</script>
