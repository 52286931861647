import elementsCompleteHelperAll from "../mixins/elementsCompleteHelperAll";
import elementJSONToPropsHelper from "../mixins/elementJSONToPropsHelper";
import Vue from "vue";

export default {
  mixins: [elementsCompleteHelperAll, elementJSONToPropsHelper],

  data() {
    return {
      checkedDiaries: new Set(),
    };
  },

  methods: {
    splitLocales: function(obj, locales) {
      const missingLocales = [];
      const usedLocales = [];
      for (const locale of locales) {
        if (obj.locales.includes(locale)) {
          usedLocales.push(locale);
        } else {
          missingLocales.push(locale);
        }
      }
      return { missingLocales, usedLocales };
    },

    checkAllElements: function(elements, locales) {
      return this.elementsHaveError(elements, locales);
    },

    checkIntervention: function(intervention, locales) {
      const interventionProblems = { name: intervention.name, warnings: [], errors: [] };
      const emptyTitles = [];
      const emptyDescriptions = [];
      for (const locale of locales) {
        const translation = intervention.translations.find(t => t.locale === locale);
        if (!translation?.title) {
          emptyTitles.push(locale);
        }
        if (!translation?.description) {
          emptyDescriptions.push(locale);
        }
      }
      if (emptyTitles.length > 0) {
        interventionProblems.warnings.push(
          Vue.i18n.translate(
            "interventionTranslation.publishInterventionEmptyTitle", { locales: emptyTitles.join(", ") },
          ),
        );
      }
      if (emptyDescriptions.length > 0) {
        interventionProblems.warnings.push(
          Vue.i18n.translate(
            "interventionTranslation.publishInterventionEmptyDescription", { locales: emptyDescriptions.join(", ") },
          ),
        );
      }
      return interventionProblems;
    },

    checkDiary: function(diary, locales, checkElements) {
      const problems = { name: diary.name, warnings: [], errors: [] };
      if (this.checkedDiaries.has(diary.id.toString())) {
        return problems;
      }
      this.checkedDiaries.add(diary.id.toString());
      const { missingLocales, usedLocales } = this.splitLocales(diary, locales);
      if (missingLocales.length > 0) {
        if (missingLocales.length !== locales.length) {
          problems.warnings.push(
            Vue.i18n.translate("interventionTranslation.publishInterventionLessonMissesLocale", { locales: missingLocales.join(", ") })
          );
        } else {
          problems.errors.push(Vue.i18n.translate("interventionTranslation.publishInterventionLessonNoLocale"));
        }
      }
      if (usedLocales.length > 0) {
        const emptyTitles = [];
        const emptyDescriptions = [];
        for (const locale of usedLocales) {
          const translation = diary.translations.find(t => t.locale === locale);
          if (!translation?.title) {
            emptyTitles.push(locale);
          }
          if (!translation?.description) {
            emptyDescriptions.push(locale);
          }
        }
        if (emptyTitles.length > 0) {
          problems.warnings.push(
            Vue.i18n.translate(
              "interventionTranslation.publishInterventionEmptyTitle", { locales: emptyTitles.join(", ") },
            ),
          );
        }
        if (emptyDescriptions.length > 0) {
          problems.warnings.push(
            Vue.i18n.translate(
              "interventionTranslation.publishInterventionEmptyDescription", { locales: emptyDescriptions.join(", ") },
            ),
          );
        }
        if (checkElements) {
          const elements = this.jsonToProperties(diary.questionnaire.elements, false);
          if (this.checkAllElements(elements, usedLocales)) {
            problems.warnings.push(Vue.i18n.translate("interventionTranslation.publishInterventionLessonIncompleteElements"));
          }
        }
      }
      return problems;
    },

    checkLesson: function(lesson, locales, checkElements) {
      const problems = { name: lesson.name, warnings: [], errors: [] };
      const { missingLocales, usedLocales } = this.splitLocales(lesson, locales);
      if (missingLocales.length > 0) {
        if (missingLocales.length !== locales.length) {
          problems.warnings.push(
            Vue.i18n.translate("interventionTranslation.publishInterventionLessonMissesLocale", { locales: missingLocales.join(", ") })
          );
        } else {
          problems.errors.push(Vue.i18n.translate("interventionTranslation.publishInterventionLessonNoLocale"));
        }
      }
      if (usedLocales.length > 0) {
        const emptyTitles = [];
        const emptyDescriptions = [];
        for (const locale of usedLocales) {
          const translation = lesson.translations.find(t => t.locale === locale);
          if (!translation?.title) {
            emptyTitles.push(locale);
          }
          if (!translation?.description) {
            emptyDescriptions.push(locale);
          }
        }
        if (emptyTitles.length > 0) {
          problems.warnings.push(
            Vue.i18n.translate(
              "interventionTranslation.publishInterventionEmptyTitle", { locales: emptyTitles.join(", ") },
            ),
          );
        }
        if (emptyDescriptions.length > 0) {
          problems.warnings.push(
            Vue.i18n.translate(
              "interventionTranslation.publishInterventionEmptyDescription", { locales: emptyDescriptions.join(", ") },
            ),
          );
        }
        if (checkElements) {
          const elements = this.jsonToProperties(lesson.elements, false);
          if (this.checkAllElements(elements, usedLocales)) {
            problems.warnings.push(Vue.i18n.translate("interventionTranslation.publishInterventionLessonIncompleteElements"));
          }
        }
      }
      return problems;
    },

    checkSkill: function(skill, locales, checkElements) {
      const problems = { name: skill.name, warnings: [], errors: [] };
      const { missingLocales, usedLocales } = this.splitLocales(skill, locales);
      if (missingLocales.length > 0) {
        if (missingLocales.length !== locales.length) {
          problems.warnings.push(
            Vue.i18n.translate("interventionTranslation.publishInterventionLessonMissesLocale", { locales: missingLocales.join(", ") })
          );
        } else {
          problems.errors.push(Vue.i18n.translate("interventionTranslation.publishInterventionLessonNoLocale"));
        }
      }
      if (usedLocales.length > 0) {
        const emptyTitles = [];
        for (const locale of usedLocales) {
          const translation = skill.translations.find(t => t.locale === locale);
          if (!translation?.title) {
            emptyTitles.push(locale);
          }
        }
        if (emptyTitles.length > 0) {
          problems.warnings.push(
            Vue.i18n.translate(
              "interventionTranslation.publishInterventionEmptyTitle", { locales: emptyTitles.join(", ") },
            ),
          );
        }
        if (checkElements) {
          const elements = this.jsonToProperties(skill.elements, false);
          if (this.checkAllElements(elements, usedLocales)) {
            problems.warnings.push(Vue.i18n.translate("interventionTranslation.publishInterventionLessonIncompleteElements"));
          }
        }
      }
      return problems;
    },
    
    checkInterventionConfiguration: function(intervention, locales, checkElements) {
      this.checkedDiaries.clear();
      const publishProblems = { intervention: [], lessons: [], skills: [], diaries: [] };
      let publishError = false;
      let publishWarning = false;

      const interventionProblems = this.checkIntervention(intervention, locales);
      if (interventionProblems.warnings.length > 0) {
        publishProblems.intervention.push(interventionProblems);
        publishWarning = true;
      }

      for (const q in intervention.questionnaires) {
        const lesson = intervention.questionnaires[q];
        const lessonProblems = this.checkLesson(lesson, locales, checkElements);
        if (lessonProblems.errors.length > 0) {
          publishProblems.lessons.push(lessonProblems);
          publishError = true;
        } else if (lessonProblems.warnings.length > 0) {
          publishProblems.lessons.push(lessonProblems);
          publishWarning = true;
        }
        for (const d in lesson.unlock_diaries) {
          const diary = lesson.unlock_diaries[d];
          const diaryProblems = this.checkDiary(diary, locales, checkElements);
          if (diaryProblems.errors.length > 0) {
            publishProblems.diaries.push(diaryProblems);
            publishError = true;
          } else if (diaryProblems.warnings.length > 0) {
            publishProblems.diaries.push(diaryProblems);
            publishWarning = true;
          }
        }
      }

      for (const s in intervention.skills) {
        const skill = intervention.skills[s];
        const skillProblems = this.checkSkill(skill, locales, checkElements);
        if (skillProblems.errors.length > 0) {
          publishProblems.skills.push(skillProblems);
          publishError = true;
        } else if (skillProblems.warnings.length > 0) {
          publishProblems.skills.push(skillProblems);
          publishWarning = true;
        }
      }

      for (const d in intervention.unlock_diaries) {
        const diary = intervention.unlock_diaries[d];
        const diaryProblems = this.checkDiary(diary, locales, checkElements);
        if (diaryProblems.errors.length > 0) {
          publishProblems.diaries.push(diaryProblems);
          publishError = true;
        } else if (diaryProblems.warnings.length > 0) {
          publishProblems.diaries.push(diaryProblems);
          publishWarning = true;
        }
      }

      return { publishProblems, publishWarning, publishError };
    }
  },
}
