<template>
  <div class="elementsPage">
    <details :id="detailsTagId" class="detailsClass sec99" open> <!-- dont remove detailsClass, is used in lessonEditorElements -->
      <summary :class="{errorMessage: errorTextsForElements[propsObj.id]}" @click="toggleSummary">
        {{ 'elementsPageTranslation.pageConfig' | translate }}
      </summary>
      <section v-if="getIsEditable && errorTextsForElements[propsObj.id] != ''" :id="'errorTextElement' + propsObj.id" class="wrapper errorMessage">
        {{errorTextsForElements[propsObj.id]}}
      </section>

      <section class="wrapper">
        <section class="col sec50">
          <section v-if="typeof colorValue != 'undefined'" class="wrapper">
            <section class="col sec50Middle">
              <br>
              <label>
                {{ 'elementsPageTranslation.selectPageColor' | translate }} *
              </label>

              <select v-model="colorSelected" :id="'inputBackground' + propsObj.id" :disabled="disableInputs">
                <option value="" :id="'inputBackground' + propsObj.id + 'None'" selected>
                  {{ 'elementsPageTranslation.defaultColor' | translate }}
                </option>
                <option :value="true" :id="'inputBackground' + propsObj.id + 'Color'">
                  {{ 'lessonTranslation.selectColor' | translate }}
                </option>
                <option :value="false" :id="'inputBackground' + propsObj.id + 'Image'">
                  {{ 'lessonTranslation.selectBackground' | translate }}
                </option>
              </select>

              <input type="color" v-if="showColorSelection" :id="'inputPageColor' + propsObj.id" :list="'pageColorValues' + propsObj.id" :value="colorValue"
                :disabled="disableInputs" @input="$emit('change-element', {'type': 'attribute', 'name': 'color', 'value': $event.target.value})">
              <datalist :id="'pageColorValues' + propsObj.id">
                <option v-for="data in colorsList" :value="data" :id="'pageColor' + propsObj.id + '-' + data" :key="data">
                </option>
              </datalist>
              <button type="button" v-if="!disableInputs && showPictureSelection" :id="'buttonSelectBackground' + propsObj.id" @click="showStudyMediaModal(true)">
                {{ 'elementsMediaTranslation.selectPicture' | translate }}
              </button>
            </section>
            <section v-if="showPictureSelection" class="col sec50 pageMedia">
              <img v-if="colorValue != '' && !colorValue.startsWith('#')" :src="colorValue" loading="lazy">
              <i class="far fa-image fa-2x" v-else></i>
            </section>
          </section>
        </section>
        <section class="col sec50">
          <button type="button" v-if="!isFirstPage && !disableInputs" :id="'buttonDeletePageBreak' + propsObj.id" class="buttonFormLeftLesson" @click="$emit('change-element', {'type': 'delete-element', 'index': index, 'id': propsObj.id})">
            {{ 'elementsPageTranslation.deletePageBreak' | translate }}
          </button>
          <button type="button" v-if="!isFirstPage && !disableInputs" :id="'buttonTogglePageCondition' + propsObj.id" class="buttonFormLeftLesson" @click="addRemoveCondition">
            {{addRemoveConditionText}}
          </button>
        </section>
      </section>

      <section v-if="propsObj.condition && !isFirstPage" class="wrapper pagePadding">
        <section v-if="!disableInputs" class="col sec50">
          <label class="labelWithMargin">
            {{ 'elementsPageTranslation.condition' | translate }} *
          </label>
          <select :value="propsObj.isComplexCondition" :id="'inputConditionType' + propsObj.id" @input="$emit('change-element', {'type': 'attribute', 'name': 'isComplexCondition',
              'value': Array.from($event.target.options).filter(o => o.selected).map(x => '_value' in x ? x._value : x.value)[0]})">
            <option value="" :id="'inputConditionType' + propsObj.id + 'None'" disabled>
              {{ 'generalTranslation.select' | translate }}
            </option>
            <option :value="false" :id="'inputConditionType' + propsObj.id + 'Simple'" :key="'yes' + propsObj.id">
              {{ 'elementsBlockTranslation.simpleCondition' | translate }}
            </option>
            <option :value="true" :id="'inputConditionType' + propsObj.id + 'Complex'" :key="'no' + propsObj.id">
              {{ 'elementsBlockTranslation.complexCondition' | translate }}
            </option>
          </select>

          <section v-if="!propsObj.isComplexCondition" :id="'simpleCondition' + propsObj.id" key="simpleSec" class="wrapper datepicker">
            <BaseCondition
              :id="propsObj.id"
              :condition="propsObj.condition"
              :valuesForLabel="valueListForSelectedLabel"
              @change-condition="changeConditionAttribute"
            ></BaseCondition>
          </section>
          <section v-else key="complexSec">
            <section :id="'complexCondition' + propsObj.id" class="labelWithMargin">
              {{complexConditionAsString}}
            </section>
            <button type="button" :id="'buttonOpenConditionEditor' + propsObj.id" class="buttonMiddle" @click="openConditionEditor">
              {{ 'elementsBlockTranslation.defineCondition' | translate }}
            </button>
          </section>
        </section>
        <section v-else class="col sec50">
          <label>
            {{ 'elementsBlockTranslation.condition' | translate }}
          </label>

          <section v-if="!propsObj.isComplexCondition" :id="'simpleCondition' + propsObj.id" key="simpleSec" class="wrapper">
            {{simpleConditionAsString(propsObj, false)}}
          </section>

          <section v-else :id="'complexCondition' + propsObj.id" key="complexSec" class="labelWithMargin">
            {{complexConditionAsString}}
          </section>
        </section>

        <section class="col sec50 pagePadding">
          <label>
            {{ 'elementsBlockTranslation.conditionDescription' | translate }}
          </label>
          <input type="text" :value="propsObj.conditionDescription" :id="'inputPageConditionDescription' + propsObj.id" :placeholder="$t('elementsBlockTranslation.conditionDescription')"
            :disabled="disableInputs" @input="$emit('change-element', {'type': 'attribute', 'name': 'condition-description', 'value': $event.target.value })">
        </section>
      </section>

      <section v-if="showProgressBars" class="wrapper pagePadding">
        <div class="col sec50">
          <label>
            {{progressTextInLanguage(false)}}
          </label>
          <input type="text" :value="translation" :id="'inputProgress' + propsObj.id" :placeholder="progressTextInLanguage(false)" :disabled="!getIsEditable"
            @input="$emit('change-element', {'type': 'translation-attribute', 'name': 'progressbar_text', 'isTranslation': false, 'value': $event.target.value})">
        </div>

        <!-- translation -->
        <div v-if="isTranslationMode" class="col sec50">
          <label>
            {{progressTextInLanguage(true)}}
          </label>
          <input type="text" :value="translation2" :id="'inputProgressTranslation' + propsObj.id" :placeholder="progressTextInLanguage(true)" :disabled="!getIsEditable"
            @input="$emit('change-element', {'type': 'translation-attribute', 'name': 'progressbar_text', 'isTranslation': true, 'value': $event.target.value })">
        </div>
      </section>
    </details>

    <StudyMedia
      v-if="studyMediaVisible"
      :id="'mediaSelection' + propsObj.id"
      :allFiles="false"
      :isSelection="true"
      :studyId="studyId"
      :multiple="false"
      @close-modal="showStudyMediaModal(false)"
      @item-selected="selectMedia"
    ></StudyMedia>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters, mapMutations} from 'vuex';
import StudyMedia from './StudyMedia.vue';
import BaseCondition from './BaseCondition.vue';
import questionConditionHelper from '../mixins/questionConditionHelper';
import elementsHelper from '../mixins/elementsHelper';

export default {
  name: 'ElementsPage',

  components: {
    StudyMedia,
    BaseCondition,
  },

  mixins: [questionConditionHelper, elementsHelper],

  props: {
    propsObj: {
      required: true,
      type: Object,
    },

    index: {
      required: true,
      type: Number
    },

    translation: {
      required: true,
      type: Object,
    },

    translation2: {
      required: true,
      type: Object,
    },

    isTranslationMode: {
      required: true,
      type: Boolean
    },

    defaultPageColor: {
      required: true,
      type: String
    },

    studyId: {
      required: true,
      type: Number,
    },

    errorTextsForElements: {
      required: true,
      type: Array,
    },

    showProgressBars: {
      required: true,
      type: Boolean
    },

    allowStructureChanges: {
      type: Boolean,
      default: null,
    },
  },

  data: function(){
    return {
      colorsList: [
        "#fefcf0", "#ffffff", "#bbbbbb", "#888888", "#444444",
        "#facccc", "#f06666", "#e60000", "#a10000", "#5c0000",
        "#ffebcc", "#ffc266", "#ff9900", "#b26b00", "#663d00",
        "#ffffcc", "#ffff66", "#ffff00", "#b2b200", "#666600",
        "#cce8cc", "#66b966", "#008a00", "#006100", "#003700",
        "#cce0f5", "#66a3e0", "#0066cc", "#0047b2", "#002966",
        "#ebd6ff", "#c285ff", "#9933ff", "#6b24b2", "#3d1466"
      ],
      colorSelected: true,
      studyMediaVisible: false,
      detailsTagId: ""
    }
  },

  computed: {
    ...mapGetters([
      'getQuestionLabelList',
      'getConditionEditorData',
      'getIsEditable',
    ]),

    showColorSelection: function(){
      return (this.colorSelected || (this.colorSelected === '' && this.colorValue.startsWith('#')))
    },

    showPictureSelection: function(){
      return ((typeof this.colorSelected === 'boolean' && !this.colorSelected) ||
        (this.colorSelected === '' && !this.colorValue.startsWith('#')))
    },

    colorValue: function(){
      if(!this.propsObj.color){
        if(this.defaultPageColor){
          if(this.defaultPageColor.startsWith("#")){
            return this.defaultPageColor
          }else{
            return this.$urlUploads + "/studies/" + this.studyId + "/" + this.defaultPageColor
          }
        }else{
          return this.$defaultLessonPageColor
        }
      }else{
        return this.propsObj.color
      }
    },

    complexConditionAsString: function(){
      return Vue.i18n.translate('conditionEditorTranslation.conditionString') +
        this.treeToString(this.propsObj.condition, this.getQuestionLabelList, true);
    },

    valueListForSelectedLabel: function(){
      const questionLabel = this.propsObj.condition.questionLabel;
      const definedQuestionLabel = typeof questionLabel === "number" && questionLabel >= 0;
      return (definedQuestionLabel && this.getQuestionLabelList[questionLabel] != 0) ? this.getQuestionLabelList[questionLabel].value : [];
    },

    addRemoveConditionText: function(){
      return (!this.propsObj.condition) ? Vue.i18n.translate('elementsPageTranslation.addCondition') : Vue.i18n.translate('elementsPageTranslation.removeCondition')
    },

    isFirstPage: function(){
      return this.index === 0
    },

    disableInputs: function() {
      return !this.getIsEditable || this.allowStructureChanges === false;
    },
  },

  watch: {
    'propsObj.color': function(newVal){
      if(!newVal){
        this.colorSelected = "";
      }else if(newVal.startsWith("#")){
        this.colorSelected = true;
      }else{
        this.colorSelected = false;
      }
    },

    colorSelected: function(newVal){
      if(newVal === "" && this.propsObj.color){
        this.$emit('change-element', {'type': 'attribute', 'name': 'color', 'value': "" });
      }
    },

    //needs deep:true & handler because array in array changes -> 'normal' watch function does not detect changes in nested array
    getQuestionLabelList: {
      handler: function(newVal, oldVal){
        if(this.propsObj.condition != ""){
          this.$emit('change-element', {'type': 'attribute', 'name': 'change-condition', 'oldVal': oldVal, 'newVal': newVal});
        }
      },
      deep: true
    },
  },

  created(){
    this.detailsTagId = "details" + this.propsObj.id; //has to be the same as in lessonElement

    if(!this.propsObj.color){
      this.colorSelected = "";
    }else if(this.propsObj.color.startsWith("#")){
      this.colorSelected = true;
    }else{
      this.colorSelected = false;
    }

    //only if block has id that is specified in data
    if(this.getConditionEditorData != "" && this.getConditionEditorData.pos === this.propsObj.id){
      var data = this.getConditionEditorData.condition;
      this.SET_CONDITIONEDITORDATA("");
      this.$emit('change-element', {'type': 'attribute', 'name': 'set-condition', 'value': data});
    }
  },

  methods:{
    ...mapMutations([
      'SET_CONDITIONEDITOR',
      'SET_CONDITIONEDITORDATA',
    ]),

    addRemoveCondition: function(){
      if(!this.propsObj.condition){ //add
        this.$emit('change-element', {'type': 'attribute', 'name': 'set-condition',
          'value': { leafType: "question", nodeType: "leaf", questionLabel: "", value: "", operation: "", secondValue: "" }});
      }else{ //remove
        this.$emit('change-element', {'type': 'attribute', 'name': 'set-condition', 'value': ""});
      }
    },

    openConditionEditor: function(){
      this.SET_CONDITIONEDITOR(true);
      this.SET_CONDITIONEDITORDATA({ "pos": this.propsObj.id, "condition": this.propsObj.condition });
    },

    changeConditionAttribute: function(obj){
      this.$emit('change-element', {'type': 'attribute', 'name': "condition-" + obj.name, 'value': obj.value});
    },

    showStudyMediaModal: function(bool){
      this.studyMediaVisible = bool;
      this.$emit('change-element', {'type': 'show-media-reference-modal', 'value': bool});
    },

    selectMedia: function(obj){
      this.$emit('change-element', {'type': 'attribute', 'name': 'color', 'value': obj.link });
      this.showStudyMediaModal(false);
    },

    toggleSummary: function(){
      this.$emit('change-element', {'type': 'toggle-summary', 'id': this.propsObj.id });
    },

    progressTextInLanguage: function(isTranslation){
      if(!isTranslation){
        return Vue.i18n.translate('elementsPageTranslation.progressbarText')
      }else{
        return Vue.i18n.translate('elementsPageTranslation.progressbarTextTranslation')
      }
    },
  }
}
</script>
