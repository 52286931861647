<template>
  <div class="preferences">
    <section v-if="!loading" id="preferences">
      <section class="wrapper">
        <h3>
          {{ 'preferencesTranslation.changeAppLanguage' | translate }}
        </h3>
        <select v-model="selectedAppLanguage" id="inputPreferencesLanguage" @change="changeAppLanguage">
          <option value="" id="inputPreferencesLanguageNone" disabled>
            {{ 'generalTranslation.chooseLanguage' | translate }}
          </option>
          <option v-for="language in getAvailableLocales" :id="'inputPreferencesLanguage' + language" :key="language">
            {{ language }}
          </option>
        </select>
        <br>
        {{ 'preferencesTranslation.appLanguageText' | translate }}
      </section>

      <form class="marginTop" @submit.prevent="updateProfile">
        <h3>
          {{ 'preferencesTranslation.profile' | translate }}
        </h3>
        <label>
          {{ 'generalTranslation.email' | translate }} ({{ 'preferencesTranslation.notEditable' | translate }})
        </label>
        {{ profileData.attributes.email }}<br>
        <br>

        <label>
          {{ 'preferencesTranslation.roles' | translate }} ({{ 'preferencesTranslation.notEditable' | translate }})
        </label>
        <div id="rolesUser">
          <div :id="'rolesUser-' + getRoles">
          {{ rolesOfUser }}
          </div>
        </div>
        <br>
        <br>
        <label>{{ username }}</label>
        <input
          type="text"
          v-if="!getRoles.includes('ecoach')"
          v-model="profileData.attributes.name"
          id="inputPreferencesUsername"
          :placeholder="$t('adminUsersTranslation.username')"
          required
        >
        <div v-else>
          {{ profileData.attributes.name }}<br>
          <br>
        </div>

        <label>
          {{ 'adminUsersTranslation.firstname' | translate }} *
        </label>
        <input
          type="text"
          v-model="profileData.attributes.firstname"
          id="inputPreferencesFirstname"
          :placeholder="$t('adminUsersTranslation.username')"
          required
        >

        <label>
          {{ 'adminUsersTranslation.lastname' | translate }} *
        </label>
        <input
          type="text"
          v-model="profileData.attributes.lastname"
          id="inputPreferencesLastName"
          :placeholder="$t('adminUsersTranslation.lastname')"
          required
        >

        <br>

        <button type="submit" id="savePreferences" :disabled="isSaved">
          {{ 'generalTranslation.save' | translate }}
        </button>
      </form>

      <form class="changePWForm marginTop" @submit.prevent="changePW">
        <h3>
          {{ 'preferencesTranslation.changePW' | translate }}
        </h3>
        <label>
          {{ 'preferencesTranslation.passwordOld' | translate }} *
        </label>
        <input
          :type="showPassword ? 'text' : 'password'"
          v-model="passwordOld"
          id="inputPreferencesPWOld"
          :placeholder="$t('preferencesTranslation.passwordOld')"
          autocomplete="current-password"
          required
        >
        <a class="suffix-icon" @click="showPassword = !showPassword">
          <i class="fas" :class="{'fa-eye-slash': !showPassword, 'fa-eye': showPassword}"></i>
        </a>
        <div v-if="passwordOld === ''" id="errorPWOldEmpty" class="loginErrorMessage">
          {{ 'loginTranslation.errorEnterPassword' | translate }}
        </div>

        <label>
          {{ 'preferencesTranslation.passwordNew' | translate }} *
        </label>
        <input
          :type="showPassword ? 'text' : 'password'"
          v-model="passwordNew"
          id="inputPreferencesPWNew"
          :placeholder="$t('preferencesTranslation.passwordNew')"
          autocomplete="new-password"
          required
        >
        <a class="suffix-icon" @click="showPassword = !showPassword">
          <i class="fas" :class="{'fa-eye-slash': !showPassword, 'fa-eye': showPassword}"></i>
        </a>
        <div v-if="passwordNew === ''" id="errorPWNewEmpty" class="loginErrorMessage">
          {{ 'loginTranslation.errorEnterPassword' | translate }}
        </div>
        <div v-if="passwordNew != '' && !pwRegEx" id="errorPWNewInvalid" class="loginErrorMessage">
          {{ 'loginTranslation.errorEnterValidPassword' | translate }}
        </div>

        <label>
          {{ 'preferencesTranslation.passwordNewConfirm' | translate }} *
        </label>
        <input
          :type="showPassword ? 'text' : 'password'"
          v-model="passwordNewConfirm"
          id="inputPreferencesPWConfirm"
          :placeholder="$t('preferencesTranslation.passwordNewConfirm')"
          autocomplete="new-password"
          required
        >
        <a class="suffix-icon" @click="showPassword = !showPassword">
          <i class="fas" :class="{'fa-eye-slash': !showPassword, 'fa-eye': showPassword}"></i>
        </a>
        <div v-if="passwordNewConfirm === ''" id="errorPWConfirmEmpty" class="loginErrorMessage">
          {{ 'loginTranslation.errorEnterPassword' | translate }}
        </div>
        <div v-if="passwordNewConfirm != '' && !pwRegEx2" id="errorPWConfirmInvalid" class="loginErrorMessage">
          {{ 'loginTranslation.errorEnterValidPassword' | translate }}
        </div>

        <br>

        <button
          type="submit"
          id="savePreferencesPW"
          :disabled="isSaved || passwordNew === '' || !pwRegEx || passwordNewConfirm === '' || !pwRegEx2 || passwordOld === ''"
        >
          {{ 'preferencesTranslation.change' | translate }}
        </button>
      </form>
    </section>
    <i class="fa fa-spinner fa-spin fa-2x loadData" v-else></i>

    <footer>
      <router-link to="/imprint-cms" id="imprintLink">
        {{ 'generalTranslation.imprint' | translate }}
      </router-link>
      |
      <router-link to="/privacy-cms" id="privacyLink">
        {{ 'generalTranslation.privacy' | translate }}
      </router-link>
    </footer>

    <BaseNotification ref="notificationRef"></BaseNotification>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters,mapMutations } from "vuex";
import httpHelper from "../mixins/httpHelper";
import BaseNotification from "./BaseNotification.vue";

export default {
  name: "Preferences",

  components: {
    BaseNotification,
  },

  mixins: [httpHelper],

  data: function() {
    return {
      isSaved: false,
      selectedAppLanguage: "",
      passwordOld: "",
      passwordNew: "",
      passwordNewConfirm: "",
      profileData: "",
      loading: true,
      oldProfileData: "",
      showPassword: false,
    };
  },

  computed: {
    ...mapGetters([
      "getAvailableLocales",
      "getAppLanguage",
      "getNotificationText",
      "getRoles",
    ]),

    rolesOfUser: function() {
      let text = "";
      if (this.getRoles.includes("admin")) {
        text += Vue.i18n.translate("adminUsersTranslation.admin");
      }
      if (this.getRoles.includes("ecoach")) {
        text += (text.length !== 0 ? ", " : "") + Vue.i18n.translate("adminUsersTranslation.ecoach");
      }
      if (this.getRoles.includes("editor")) {
        text += (text.length !== 0 ? ", " : "") + Vue.i18n.translate("adminUsersTranslation.editor");
      }
      return text + ", " + Vue.i18n.translate("adminUsersTranslation.patient");
    },

    username: function() {
      let text;
      const username = Vue.i18n.translate("adminUsersTranslation.username");
      if (!this.getRoles.includes("ecoach")) {
        text = username + " *";
      } else {
        const notEditable = Vue.i18n.translate("preferencesTranslation.notEditable");
        text = `${username} (${notEditable})`;
      }
      return text;
    },

    pwRegEx: function() {
      const regExp = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
      return regExp.test(this.passwordNew);
    },

    pwRegEx2: function() {
      const regExp = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
      return regExp.test(this.passwordNewConfirm);
    },
  },

  created() {
    if (this.getAppLanguage === "") {
      this.SET_APPLANGUAGE("de");
      Vue.i18n.set("de");
    }
    this.selectedAppLanguage = this.getAppLanguage;
    this.getProfile();
  },

  mounted() {
    this.closeNotification();
  },

  watch: {
    getNotificationText(newVal) {
      if (newVal == "") {
        return;
      }
      if (newVal.type === "error") {
        this.isSaved = false;
      } else if (newVal.type === "success") {
        this.isSaved = false;
        this.passwordOld = "";
        this.passwordNew = "";
        this.passwordNewConfirm = "";
      }
    },
  },

  methods:{
    ...mapMutations([
      "SET_NOTIFICATIONTEXT",
      "SET_APPLANGUAGE",
    ]),

    getProfile: function() {
      this.requestMyProfile()
        .then((response) => {
          this.profileData = response;
          this.oldProfileData = JSON.parse(JSON.stringify(response));
          this.loading = false;
        })
        .catch((error) => {
          this.handleErrors(error, () => this.getProfile(), "");
        });
    },

    changeAppLanguage: function() {
      this.closeNotification();
      Vue.i18n.set(this.selectedAppLanguage);
      this.SET_APPLANGUAGE(this.selectedAppLanguage);
    },

    changePW: function() {
      if (!this.isSaved) {
        if (this.passwordNew === this.passwordNewConfirm) {
          this.isSaved = true;
          this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("preferencesTranslation.changePWLoad") });
          const json = {
            data: {
              type: "users",
              attributes: {
                current_password: this.passwordOld,
                password: this.passwordNew,
                password_confirmation: this.passwordNewConfirm,
              },
            },
          };
          this.updatePasswordRequest(json);
        } else {
          this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("preferencesTranslation.changePWError") });
        }
      }
    },

    updateProfile: function() {
      this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("preferencesTranslation.updateProfileLoad") });

      const json = {
        data: {
          type: "users",
          attributes: {
            firstname: this.profileData.attributes.firstname,
            lastname: this.profileData.attributes.lastname,
          },
        },
      };
      if (this.profileData.attributes.name != this.oldProfileData.attributes.name) {
        json.data.attributes.name = this.profileData.attributes.name;
      }

      this.updateMyProfileRequest(json)
        .then((response) => {
          this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("preferencesTranslation.updateProfileSuccess") });
          this.profileData = response;
          this.oldProfileData = JSON.parse(JSON.stringify(response));
        })
        .catch((error) => {
          this.handleErrors(error, () => this.updateProfile(), "profile");
        });
    },

    closeNotification: function() {
      this.$refs["notificationRef"].closeNotification();
    },
  },
}
</script>
