<template>
  <BaseModal
    id="createDiaryModal"
    class="diaryCreate"
    :headerText="$t('diaryTranslation.newDiary', { name: targetData.name })"
    :leftButtonText="$t('generalTranslation.create')"
    :disableButtons="isCreated"
    :disableLeftButton="name === ''"
    @close-modal="closeModalCreate"
  >
    <template v-slot:body>
      <section class="wrapper">
        <form @submit.prevent="closeModalCreate(true)">
          <label>
            {{ 'generalTranslation.name' | translate }} *
          </label>
          <input type="text" v-model="name" id="inputNewName" :placeholder="$t('generalTranslation.name')" required><br>
        </form>
      </section>
    </template>
  </BaseModal>
</template>

<script>
import Vue from "vue";
import {mapGetters,mapMutations} from "vuex";
import httpHelper from "../mixins/httpHelper";
import utils from "../mixins/utils";
import BaseModal from "./BaseModal.vue";

export default {
  name: "DiaryCreate",

  components: {
    BaseModal,
  },

  mixins: [httpHelper, utils],

  props: {
    targetData: { //study in which diary should be created (name, id)
      required: true,
      type: Object
    },
  },

  data: function(){
    return{
      name: "",
      isCreated: false,
    }
  },

  computed: {
    ...mapGetters([
      "getNotificationText",
    ]),
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.isCreated = false;
      }
    },
  },

  methods: {
    ...mapMutations([
      "SET_NOTIFICATIONTEXT",
    ]),

    closeModalCreate(done) {
      if (done) {
        if (!this.name) {
          this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("generalTranslation.errorNameEmpty") });
          return;
        }
        this.isCreated = true;
        this.$emit("set-changed", { name: "all", value: false });
        this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("diaryTranslation.createDiaryLoad") });
        const locales = this.getDefaultLocales();
        const self = this;
        this.createDiaryRequest(this.targetData.id, this.name, locales)
          .then(function(diary) {
            self.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("diaryTranslation.createDiarySuccess") });
            const { diaryId, questionnaireId } = diary;
            self.$emit("route-to", { name: "diaryEditor", params: { diaryId, view: 0, questionnaireId } });
          })
          .catch(function(error) {
            self.handleErrors(error, function() { self.closeModalCreate(done) }, "");
          });
      } else if(!this.isCreated) {
        this.$emit("close-modal", { done: false });
      }
    },
  }
}
</script>
