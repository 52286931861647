<template>
  <div class="elementsHeadline">
    <section
      v-if="getIsEditable && errorTextsForElements[propsObj.id] != ''"
      :id="'errorTextElement' + propsObj.id"
      :style="{ visibility: showIfNotTranslation }"
      class="wrapper errorMessage"
    >
      {{ errorTextsForElements[propsObj.id] }}
    </section>

    <section class="wrapper">
      <ElementsReference
        :id="'' + propsObj.id + isTranslation"
        :text="translation"
        :classToSet="propsObj.selectedHeadlineProp"
        :color="propsObj.color"
        :font="propsObj.font !== 'none' ? propsObj.font : undefined"
        :placeholderData="placeholderText"
        :isSkill="isSkill"
        @change-text="changeText"
        @change-element="$emit('change-element', $event)"
      ></ElementsReference>
    </section>

    <section v-if="!isTranslation" class="elementsReference">
      <section class="col">
        <label>
          {{ $t("elementsHeadlineTranslation.headlineFontsize") }} *
        </label>
        <select
          :value="propsObj.selectedHeadlineProp"
          :id="'inputHeadlineSize' + propsObj.id"
          :disabled="disableInputs"
          @input="$emit('change-element', { type: 'attribute', name: 'selectedHeadlineProp', value: $event.target.value })"
        >
          <option value="" disabled :id="'inputHeadlineSize' + propsObj.id + 'None'">
            {{ $t("elementsHeadlineTranslation.selectHeadlineFontsize") }}
          </option>
          <option
            v-for="n in 7"
            :value="'headline' + (n - 1)"
            :id="'inputHeadlineSize' + propsObj.id + '-' + (n - 1)"
            :key="n"
          >
            {{ getHeadlineText(n - 1) }}
          </option>
        </select>
      </section>

      <section class="col">
        <label>
          {{ $t("elementsHeadlineTranslation.headlineFont") }} *
        </label>
        <select
          :value="propsObj.font"
          :id="'inputFont' + propsObj.id"
          :disabled="disableInputs"
          @input="$emit('change-element', { type: 'attribute', name: 'font', value: $event.target.value })"
        >
          <option class="ql-font-roboto" value="" disabled :id="'inputHeadlineFont' + propsObj.id + 'None'">
            {{ $t("elementsHeadlineTranslation.selectHeadlineFont") }}
          </option>
          <option class="ql-font-roboto" value="none" id="inputHeadlineFontNone" key="none">
            {{ $t("elementsHeadlineTranslation.fontNone") }}
          </option>
          <option v-for="font in FONTS" :value="font.class" :class="font.class" :id="'inputHeadlineFont' + font.value" :key="font.value">
            {{ font.label }}
          </option>
        </select>
      </section>

      <section class="col">
        <label>
          {{ $t("elementsHeadlineTranslation.selectHeadlineColor") }} *
        </label>
        <input
          type="color"
          :id="'inputHeadlineColor' + propsObj.id"
          :list="'headlineColorValues' + propsObj.id"
          :value="propsObj.color"
          :disabled="disableInputs"
          @input="$emit('change-element', { type: 'attribute', name: 'color', value: $event.target.value })"
        >
        <datalist :id="'headlineColorValues' + propsObj.id">
          <option
            v-for="data in colorsList"
            :value="data"
            :id="'headlineColorValues' + propsObj.id + '-' + data"
            :key="data"
          />
        </datalist>
      </section>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import ElementsReference from "./ElementsReference.vue";
import { FONTS } from "../utils/fonts";

export default {
  name: "ElementsHeadline",

  components: {
    ElementsReference,
  },

  props: {
    propsObj: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: String,
    },

    isTranslation: {
      required: true,
      type: Boolean,
    },

    errorTextsForElements: {
      required: true,
      type: Array,
    },

    isSkill: {
      required: true,
      type: Boolean,
    },

    allowStructureChanges: {
      type: Boolean,
      default: null,
    },
  },

  data: function(){
    return {
      colorsList: [
        "#ffffff", "#bbbbbb", "#888888", "#444444", "#000000",
        "#facccc", "#f06666", "#e60000", "#a10000", "#5c0000",
        "#ffebcc", "#ffc266", "#ff9900", "#b26b00", "#663d00",
        "#ffffcc", "#ffff66", "#ffff00", "#b2b200", "#666600",
        "#cce8cc", "#66b966", "#008a00", "#006100", "#003700",
        "#cce0f5", "#66a3e0", "#0066cc", "#0047b2", "#002966",
        "#ebd6ff", "#c285ff", "#9933ff", "#6b24b2", "#3d1466"
      ],
      FONTS,
    }
  },

  computed: {
    ...mapGetters([
      "getIsEditable",
    ]),

    showIfNotTranslation: function(){
      return !this.isTranslation ? "visible" : "hidden";
    },

    placeholderText: function(){
      return !this.isTranslation
        ? Vue.i18n.translate("elementsHeadlineTranslation.headline")
        : Vue.i18n.translate("elementsHeadlineTranslation.headlineTranslation");
    },

    disableInputs: function() {
      return !this.getIsEditable || this.allowStructureChanges === false;
    },
  },

  methods:{
    changeText: function(text){
      this.$emit("change-element", { type: "translation-attribute", name: "headline", isTranslation: this.isTranslation, value: text });
    },

    getHeadlineText: function(size){
      return Vue.i18n.translate("elementsHeadlineTranslation.headlineType" + size);
    },
  }
}
</script>
