<template>
  <div class="lessonEditorConditionCode">
    <section class="smallerComp">
      <section class="wrapper">
        <section class="col sec65">
          <section class="wrapper codeViewButtons">
            <section class="col sec17">
              <button type="button" id="buttonOpenConditionEditor" class="buttonFormLeft saveConditionButton" @click="openEditorView">
                {{ 'codeViewTranslation.editorView' | translate }}
              </button>
            </section>
            <section class="col sec66">
            </section>
            <section class="col sec17">
              <button type="button" id="buttonSaveConditionCode" class="buttonFormRight saveConditionButton" @click="saveCondition">
                {{ 'conditionEditorTranslation.apply' | translate }}
              </button>
            </section>
          </section>

          <prism-editor
            v-model="content"
            :code="content"
            language="json"
            :line-numbers="true"
            :emitEvents="true"
            id="codeEditor"
            class="codeEditor"
          ></prism-editor>
        </section>

        <section class="col sec35 codeViewSnippets" id="codeEditorSnippets">
          <details open>
            <summary>
              {{ 'codeViewTranslation.codeSnippets' | translate }}
            </summary>
            <h4>
              {{ 'codeViewTranslation.selectSnippet' | translate }}:
            </h4>
            <button
              type="button"
              v-for="(node, index) in nodeTypesList"
              :id="'buttonAddSnippetNode' + index"
              :key="'node' + index"
              class="buttonForm"
              @click="addSnippet(node.nodeTypeName)"
            >
              {{ node.nodeTypeLabel }}
            </button>

            <section class="wrapper">
              <h5>
                {{ 'codeViewTranslation.leafTypes' | translate }} ({{ 'codeViewTranslation.leafType' | translate }}):
              </h5>
              <button
                type="button"
                v-for="(leaf, index) in leafTypesList"
                :id="'buttonAddSnippetLeaf' + index"
                :key="'leaf' + index"
                class="buttonForm"
                @click="addSnippet('operationLabelValue', leaf.leafTypeName)"
              >
                {{ leaf.leafTypeLabel }}
              </button>
            </section>

            <section class="wrapper">
              <h5>
                {{ 'codeViewTranslation.questionLabels' | translate }}:
              </h5>
              <section class="buttonForm">
                <select v-model="selectedLabel" id="inputQuestionLabel">
                  <option value="-1" id="inputQuestionLabelNone" disabled selected>
                    {{ 'elementsBlockTranslation.selectLabel' | translate }}
                  </option>
                  <template v-for="(option, index) in getQuestionLabelList">
                    <option v-if="option && option != 0" :value="index" :id="'inputQuestionLabel' + index" :key="index">
                      {{ option.label }}
                    </option>
                  </template>
                </select>
                <button
                  type="button"
                  v-if="selectedLabel > -1"
                  id="buttonAddSnippetLabel"
                  @click="addSnippet('operationLabelValue', getQuestionLabelList[selectedLabel].label)"
                >
                  {{ 'codeViewTranslation.insert' | translate }}
                </button>
              </section>
            </section>

            <section class="wrapper">
              <h5>
                {{ 'codeViewTranslation.values' | translate }}:
              </h5>
              <section v-if="selectedLabel > -1" key="labelSelected">
                <section class="buttonForm">
                  <select
                    v-if="getQuestionLabelList[selectedLabel].type === 'question' && getQuestionLabelList[selectedLabel].type !== 'text'"
                    v-model="selectedValue"
                    id="inputQuestionValue"
                  >
                    <option value="" id="inputQuestionValueNone" disabled selected>
                      {{ 'codeViewTranslation.selectValue' | translate }}
                    </option>
                    <option
                      v-for="value in getQuestionLabelList[selectedLabel].value"
                      :value="value"
                      :id="'inputQuestionValue' + value"
                      :key="value"
                    >
                      {{ getValueText(value) }}
                    </option>
                  </select>

                  <input
                    type="number"
                    v-else-if="getQuestionLabelList[selectedLabel].type === 'slider'"
                    v-model="selectedValue" id="inputSliderValue"
                    :min="getQuestionLabelList[selectedLabel].value.min"
                    :max="getQuestionLabelList[selectedLabel].value.max"
                    :step="getQuestionLabelList[selectedLabel].value.step"
                  >

                  <flatPickr
                    v-else-if="getQuestionLabelList[selectedLabel].type === 'date' && getQuestionLabelList[selectedLabel].value[0] != 'TextTime'"
                    v-model="selectedValue"
                    :config="config"
                    id="inputDateValue"
                    :placeholder="$tlang(getSelectedLanguage, 'elementsQuestionTranslation.selectDate')"
                  ></flatPickr>

                  <button
                    type="button"
                    v-if="selectedValue != ''"
                    id="buttonAddSnippetValue"
                    @click="addSnippet(
                      'operationLabelValue',
                      selectedValue,
                      getQuestionLabelList[selectedLabel].type,
                      getQuestionLabelList[selectedLabel].value
                    )"
                  >
                    {{ 'codeViewTranslation.insert' | translate }}
                  </button>
                </section>
              </section>
              <section v-else id="noValueLabel" key="noLabelSelected" class="codeValueInstruction">
                {{ 'codeViewTranslation.valueInstruction' | translate }}
              </section>
            </section>

            <section class="wrapper">
              <h5>
                {{ 'codeViewTranslation.operations' | translate }}:
              </h5>
              <button
                type="button"
                v-for="(operation, index) in operationsList"
                :id="'buttonAddSnippetOperation' + index"
                :key="'operation' + index"
                class="buttonForm"
                @click="addSnippet('operationLabelValue', operation.operationName)"
              >
                {{ operation.operationLabel }}
              </button>
            </section>
          </details>

          <section class="wrapper">
            <details open>
              <summary>
                {{ 'codeViewTranslation.showExplanation' | translate }}
              </summary>
              <select v-model="selectedExplanation" id="inputExplanation">
                <option v-for="(explanation, index) in explanationsList" :value="index" :id="'inputExplanation' + index" :key="index">
                  {{ explanation }}
                </option>
              </select>

              <section v-if="selectedExplanation === 1 || selectedExplanation === 2 || selectedExplanation === 3">
                {
                  <section class="indented">
                  nodeType: "{{nodeTypeExplanation}}", <br>
                  children: [
                    <section class="indented">
                      <b v-if="selectedExplanation != 3">
                        {{ 'codeViewTranslation.addAtLeastTwo' | translate }} <br>
                        ({{ 'codeViewTranslation.separated' | translate }})
                      </b>
                      <b v-else>
                        {{ 'codeViewTranslation.addExactlyOne' | translate }}
                      </b>
                    </section>
                  ]
                  </section>
                }
              </section>
              <section v-if="selectedExplanation === 4">
                {
                  <section class="indented">
                  nodeType: "{{nodeTypeExplanation}}", <br>
                  leafType:
                    <b>
                      {{ 'codeViewTranslation.oneOfLeafType' | translate }} "question", "slider", "date", "text"
                    </b>, <br>
                  questionLabel:
                    <b>
                      "{{ 'codeViewTranslation.label' | translate }}"
                    </b>, <br>
                  operation: <br>
                    <section class="indented">
                      <b>
                        {{ 'codeViewTranslation.forAll' | translate }}: "answered" <br>
                        {{ 'codeViewTranslation.forQuestion' | translate }}: "" <br>
                        {{ 'codeViewTranslation.ifNotQuestion' | translate }}: <br>
                        <section class="indented">
                          "less", "lessEqual", "greater", "greaterEqual", "equals", "notEquals", "between"
                        </section>
                      </b>
                      ,
                    </section>
                  value:
                    <i>
                      ({{ 'codeViewTranslation.ifNotOperationAnswered' | translate }})
                    </i>
                    <section class="indented">
                      <b>
                        {{ 'codeViewTranslation.ifQuestion' | translate }} <br>
                        {{ 'codeViewTranslation.ifSlider' | translate }} <br>
                        {{ 'codeViewTranslation.ifDate' | translate }} <br>
                      </b>
                      ,
                    </section>
                    secondValue:
                      <i>
                        ({{ 'codeViewTranslation.ifBetween' | translate }})
                      </i>
                      <section class="indented">
                        <b>
                          {{ 'codeViewTranslation.ifSlider' | translate }} <br>
                          {{ 'codeViewTranslation.ifDate' | translate }} <br>
                        </b>
                      </section>
                  </section>
                }
              </section>
            </details>
          </section>
        </section>
      </section>

      <section class="wrapper">
        <section v-if="errorsExist" id="codeEditorErrors">
          {{ 'codeViewTranslation.correctErrors' | translate }}:
          <section v-for="(error, index) in errorsList" :key="index">
            {{ error.dataPath }}
            {{ error.message }}
          </section>
        </section>
      </section>
    </section>

    <BaseModal
      v-if="warningChangesVisible"
      id="changesModal"
      :bodyText="modalText"
      :isLeaveSiteWarning="true"
      :leftButtonText="$t('interventionTranslation.leave')"
      @close-modal="closeModal"
    ></BaseModal>

    <BaseNotification ref="notificationRef"></BaseNotification>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import router from "../router";
import "prismjs";
import "prismjs/components/prism-json.js";
import "prismjs/themes/prism.css";
import "vue-prism-editor/dist/VuePrismEditor.css";
import PrismEditor from "vue-prism-editor";
import selectionRange from "vue-prism-editor/src/utils/selection-range.js";
//import "prismjs/plugins/line-highlight/prism-line-highlight.js";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/l10n/de.js";
import BaseNotification from "./BaseNotification.vue";
import BaseModal from "./BaseModal.vue";
import questionConditionHelper from "../mixins/questionConditionHelper";
import { validateCondition } from "../utils/ValidateConditionJSON";

export default {
  name: "LessonEditorConditionCode",

  components: {
    PrismEditor,
    BaseNotification,
    BaseModal,
    flatPickr,
  },

  mixins: [questionConditionHelper],

  data: function() {
    return {
      content: "",
      errorsList: [],
      errorsExist: false,
      selectedLabel: -1,
      selectedValue: "",
      isSaved: false,
      errorsForSnippet: false,
      warningChangesVisible: false,
      routerPathTo: "",
      modalText: "",
      andJson: {
        nodeType: "and",
        children: []
      },
      orJson: {
        nodeType: "or",
        children: []
      },
      notJson: {
        nodeType: "not",
        children: []
      },
      leafJson: {
        nodeType: "leaf",
        leafType: "",
        questionLabel: "",
        value: "",
        operation: "",
        secondValue: ""
      },
      selectedExplanation: 0,
    };
  },

  computed: {
    ...mapGetters([
      "getQuestionLabelList",
      "getCodeViewData",
      "getConditionEditorData",
      "getFormElementsChanged",
      "getIsEditable",
      "getConditionEditor",
      "getSelectedLanguage",
    ]),

    lessonId: function() {
      return Number(this.$route.params.lessonId);
    },

    nodeTypesList: function() {
      return [
        {
          nodeTypeName: "and",
          nodeTypeLabel: Vue.i18n.translate("conditionEditorTranslation.and"),
        },
        {
          nodeTypeName: "or",
          nodeTypeLabel: Vue.i18n.translate("conditionEditorTranslation.or"),
        },
        {
          nodeTypeName: "not",
          nodeTypeLabel: Vue.i18n.translate("conditionEditorTranslation.not"),
        },
        {
          nodeTypeName: "leaf",
          nodeTypeLabel: Vue.i18n.translate("codeViewTranslation.questionLeaf"),
        },
      ];
    },

    leafTypesList: function() {
      return [
        {
          leafTypeName: "question",
          leafTypeLabel: Vue.i18n.translate("codeViewTranslation.question"),
        },
        {
          leafTypeName: "slider",
          leafTypeLabel: Vue.i18n.translate("codeViewTranslation.slider"),
        },
        {
          leafTypeName: "date",
          leafTypeLabel: Vue.i18n.translate("codeViewTranslation.date"),
        },
        {
          leafTypeName: "text",
          leafTypeLabel: Vue.i18n.translate("codeViewTranslation.text"),
        }
      ];
    },

    operationsList: function() {
      return [
        {
          operationName: "less",
          operationLabel: Vue.i18n.translate("conditionEditorTranslation.less"),
        },
        {
          operationName: "lessEqual",
          operationLabel: Vue.i18n.translate("conditionEditorTranslation.lessEqual"),
        },
        {
          operationName: "greater",
          operationLabel: Vue.i18n.translate("conditionEditorTranslation.greater"),
        },
        {
          operationName: "greaterEqual",
          operationLabel: Vue.i18n.translate("conditionEditorTranslation.greaterEqual"),
        },
        {
          operationName: "equals",
          operationLabel: Vue.i18n.translate("conditionEditorTranslation.equals"),
        },
        {
          operationName: "notEquals",
          operationLabel: Vue.i18n.translate("conditionEditorTranslation.notEquals"),
        },
        {
          operationName: "between",
          operationLabel: Vue.i18n.translate("conditionEditorTranslation.between"),
        },
        {
          operationName: "answered",
          operationLabel: Vue.i18n.translate("elementsBlockTranslation.answeredAny"),
        },
      ];
    },

    nodeTypeExplanation: function() {
      if (this.selectedExplanation === 1) {
        return "and";
      } else if (this.selectedExplanation === 2) {
        return "or";
      } else if (this.selectedExplanation === 3) {
        return "not";
      } else if (this.selectedExplanation === 4) {
        return "leaf";
      } else {
        return "";
      }
    },

    explanationsList: function() {
      return [
        Vue.i18n.translate("codeViewTranslation.none"),
        Vue.i18n.translate("conditionEditorTranslation.and"),
        Vue.i18n.translate("conditionEditorTranslation.or"),
        Vue.i18n.translate("conditionEditorTranslation.not"),
        Vue.i18n.translate("codeViewTranslation.questionLeaf"),
      ];
    },

    config: function() {
      return {
        altFormat: Vue.i18n.translateIn(this.getSelectedLanguage, "elementsQuestionTranslation.dateFormatCondition"),
        altInput: true,
        dateFormat: "Y-m-d",
        enableTime: false,
        locale: this.getSelectedLanguage,
        position: "auto center",
      };
    },
  },

  watch: {
    selectedLabel() {
      this.selectedValue = "";
    },
    errorsList(newVal) {
      if (newVal.length > 0 && this.errorsExist) {
        if (this.isSaved) {
          this.SET_NOTIFICATIONTEXT({
            type: "error",
            text: Vue.i18n.translate("codeViewTranslation.errorSave"),
          });
        } else if (this.errorsForSnippet) {
          this.SET_NOTIFICATIONTEXT({
            type: "error",
            text: Vue.i18n.translate("codeViewTranslation.errorSnippet"),
          });
        } else {
          this.SET_NOTIFICATIONTEXT({
            type: "error",
            text: Vue.i18n.translate("codeViewTranslation.errorSwitchCondition"),
          });
        }

        for (const error in newVal) {
          if (typeof newVal[error].origin !== "undefined") {
            const message = newVal[error].message;
            const posArr = message.split("at position ");
            if (posArr[1] != "" && !isNaN(posArr[1])) {
              const pos = Number(posArr[1]);
              const contentText = document.getElementsByTagName("PRE")[0].getElementsByTagName("CODE")[0].innerText;
              const textBeforePos = contentText.slice(0,pos);
              const linebreaksBeforePos = textBeforePos.split("\n").length - 1;
              const errorInLine = linebreaksBeforePos + 1;
              newVal[error].message = posArr[0] + Vue.i18n.translate("codeViewTranslation.inLine") + " " + errorInLine;
              //document.getElementsByTagName("PRE")[0].setAttribute("data-line",errorInLine);
            }
          }
        }
      }
    }
  },

  mounted() {
    if (
      !this.getConditionEditor ||
      this.getConditionEditorData === "" ||
      this.getCodeViewData === "" ||
      !this.getIsEditable
    ) {
      this.SET_FORMELEMENTSCHANGED(false);
      this.SET_NOTIFICATIONTEXT({
        type: "error",
        text: Vue.i18n.translate("httpHelperTranslation.errorCode10"),
      });
      router.go(-1);
    } else {
      this.closeNotification();
      if (this.getCodeViewData.new != "") {
        const codeViewData = JSON.parse(JSON.stringify(this.getCodeViewData.new));
        const newJson = this.replaceLabelNumbersWithTexts(codeViewData);
        this.content = JSON.stringify(newJson, null, 2);
      }
    }
    window.addEventListener("beforeunload", this.beforeunloadFunction);
  },

  beforeRouteLeave(to, _from, next) {
    /*
    codeViewData.new set in openEditorView
    if saveCondition was clicked -> new json has more complex validation etc & is set in saveCondition
    */
    this.routerPathTo = to;
    let treeChanged = false;
    //only if these not "" it is okay that codeview is open -> if not route leave without check
    if (this.getConditionEditorData != "" && this.getCodeViewData != "") {
      if (this.getCodeViewData.old != JSON.stringify(this.getCodeViewData.new)) {
        treeChanged = true;
      } else {
        const parsedJson = this.parseJSON();
        if (!this.errorsExist && this.validateJSON(parsedJson)) {
          //parsedJson still contains labels -> if errors occur later: show this
          const newJson = this.replaceLabelTextsWithNumbers(JSON.parse(JSON.stringify(parsedJson.condition)));
          if (this.getCodeViewData.old != JSON.stringify(newJson)) {
            treeChanged = true;
          }
        } else {
          treeChanged = true;
        }
      }
    }

    if (
      this.getConditionEditor &&
      to.name !== "conditionEditor" &&
      ((to.name !== "lessonEditor" && to.name !== "diaryEditor") || this.lessonId == 0) &&
      (to.name !== "interventionEditor" || this.lessonId !== 0)
    ) {
      let text = "";
      //if condition for lesson or diary editor: elements & condition may be different
      if (this.lessonId != 0) {
        //check lesson elements
        if (this.getFormElementsChanged) {
          text = Vue.i18n.translate("generalTranslation.unsavedChanges") + ": " +  Vue.i18n.translate("lessonTranslation.lessonElements");
        }
      } else { //if condition for config: intervention details, config & condition may have changed
        //check intervention details
        if (this.getConditionEditorData.formChanged) {
          text = Vue.i18n.translate("generalTranslation.unsavedChanges") + ": " + Vue.i18n.translate("interventionTranslation.interventionDetails");
        }

        if (this.getConditionEditorData.configChanged) {
          text += (text === "") ? Vue.i18n.translate("generalTranslation.unsavedChanges") + ": " : ", ";
          text += Vue.i18n.translate("interventionTranslation.unsavedChangesConfig");
        }
      }

      //check condition
      if (treeChanged && !this.isSaved) {
        text += (text === "") ? Vue.i18n.translate("generalTranslation.unsavedChanges") + ": " : ", ";
        text += Vue.i18n.translate("conditionEditorTranslation.condition");
      }

      if (text != "") {
        this.modalText = text;
        this.warningChangesVisible = true;
        next(false);
      } else {
        //if not saved set conditioneditordata empty in lesson; not for config since it contains all data of interventioneditor, condition stays as it was
        if (!this.isSaved && to.name !== "conditionEditor" && !(to.name === "interventionEditor" && this.lessonId === 0)) {
          this.SET_CONDITIONEDITORDATA("");
        }
        next({ replace: true });
      }
    } else {
      //if not saved set conditioneditordata empty in lesson; not for config since it contains all data of interventioneditor, condition stays as it was
      if (!this.isSaved && to.name !== "conditionEditor" && !(to.name === "interventionEditor" && this.lessonId === 0)) {
        this.SET_CONDITIONEDITORDATA("");
      }
      next({ replace: true });
    }
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeunloadFunction);
  },

  methods: {
    ...mapMutations([
      "SET_CODEVIEWDATA",
      "SET_CONDITIONEDITORDATA",
      "SET_FORMELEMENTSCHANGED",
      "SET_ALLELEMLOCALESCHANGED",
      "SET_NOTIFICATIONTEXT",
    ]),

    allElementsChanged: function() {
      this.SET_FORMELEMENTSCHANGED(true);
      this.SET_ALLELEMLOCALESCHANGED(true);
    },

    addSnippet: function(type, value, leafType, values) {
      this.errorsForSnippet = true;
      const pos = this.getCursorPos();
      if (typeof pos !== "undefined") {
        const selectionStart = pos.start;
        const selectionEnd = pos.end;
        const contentLength = this.content.length;
        const textBeforeSelection = this.content.slice(0, selectionStart);
        const textAfterSelection = this.content.slice(selectionEnd, contentLength);
        let newContent = textBeforeSelection;
        switch (type) {
          case "and":
            newContent += JSON.stringify(this.andJson, null, 2);
            break;
          case "or":
            newContent += JSON.stringify(this.orJson, null, 2);
            break;
          case "not":
            newContent += JSON.stringify(this.notJson, null, 2);
            break;
          case "leaf":
            newContent += JSON.stringify(this.leafJson, null, 2);
            break;
          case "operationLabelValue":
            //question (yesno) as bool if values true false (not as string), slider as number
            if (
              leafType === "slider" || (leafType === "question" && values.includes(Boolean("true")))
            ) {
              newContent += value;
            } else { //date as string, question (single & multiple) as string
              newContent += `"${value}"`;
            }
            break;
          default:
        }
        newContent += textAfterSelection;
        try {
          this.content = JSON.stringify(JSON.parse(newContent), null, 2);
        } catch(e) {
          this.content = newContent;
        }
        this.errorsList = [];
        this.errorsExist = false;
      } else {
        this.errorsList = [];
        this.errorsList.push({
          dataPath: "",
          message: Vue.i18n.translate("codeViewTranslation.clickOrSelect"),
        });
        this.errorsExist = true;
      }
    },

    getCursorPos: function() {
      const currentCursorPos = selectionRange(document.getElementsByTagName("PRE")[0]);
      return currentCursorPos;
    },

    saveCondition: function() {
      this.errorsForSnippet = false;
      this.isSaved = true;
      this.errorsExist = false;
      const parsedJson = this.parseJSON();
      if (!this.errorsExist && this.validateJSON(parsedJson)) {
        //parsedJson still contains labels -> if errors occur later: show this
        const newJson = this.replaceLabelTextsWithNumbers(JSON.parse(JSON.stringify(parsedJson.condition)));
        if (!this.errorsExist) {
          this.errorsList = [];
          if (!this.checkLabelsAndValues(newJson)) {
            this.errorsExist = true;
          } else {
            //save condition in conditionEditorData to have access to data in lessonEditor
            const data = this.getConditionEditorData;
            data.condition = newJson;
            this.SET_CONDITIONEDITORDATA(data);
            this.allElementsChanged();
            router.go(-1);
          }
        }
      }
    },

    parseJSON: function() {
      let parsedJson = "";
      try {
        parsedJson = { condition: JSON.parse(this.content) };
      } catch(e) {
        this.errorsList = [];
        if (this.content === "") {
          this.errorsList.push({
            dataPath: "",
            message: Vue.i18n.translate("codeViewTranslation.conditionEmpty"),
          });
        }
        this.errorsList.push({
          dataPath: "",
          message: e.message,
          origin: "parse",
        });
        this.errorsExist = true;
      }
      return parsedJson;
    },

    validateJSON: function(json) {
      const result = validateCondition(json, this.isSaved);
      this.errorsExist = !result.valid;
      this.errorsList = result.errors;
      return result.valid;
    },

    replaceLabelTextsWithNumbers: function(json) {
      if (json.nodeType !== "leaf") {
        const childrenArr = [];
        for (const child in json.children) {
          childrenArr.push(this.replaceLabelTextsWithNumbers(json.children[child]));
        }
        json.children = childrenArr;
        return json;
      } else {
        const index = this.getQuestionLabelList.findIndex(elem => elem && elem.label === json.questionLabel);
        if (index !== -1) {
          json.questionLabel = index;
          return json;
        }
        //only if condition is saved (invalid label is okay when changing view to condition editor -> is then set to "" in condition editor)
        if (this.isSaved) {
          //label not valid (string not in questionLabelList)
          this.errorsExist = true;
          this.errorsList.push({
            dataPath: "",
            message: Vue.i18n.translate("codeViewTranslation.questionLabel") + " " + json.questionLabel + " " +
                      Vue.i18n.translate("codeViewTranslation.isNotValid"),
          });
        }
        json.questionLabel = 0;
        return json;
      }
    },

    replaceLabelNumbersWithTexts: function(json) {
      if (json.nodeType !== "leaf") {
        const childrenArr = [];
        for (const child in json.children) {
          childrenArr.push(this.replaceLabelNumbersWithTexts(json.children[child]));
        }
        json.children = childrenArr;
        return json;
      } else {
        //if questionLabel has no number set -> leave ""
        if (json.questionLabel != "") {
          json.questionLabel = this.getQuestionLabelList[json.questionLabel].label;
        }
        return json;
      }
    },

    checkLabelsAndValues: function(json) {
      if (json.nodeType !== "leaf") {
        const childrenBoolArr = [];
        for (const child in json.children) {
          childrenBoolArr.push(this.checkLabelsAndValues(json.children[child]));
        }
        return !childrenBoolArr.includes(false);
      } else {
        if (json.leafType != this.getQuestionLabelList[json.questionLabel].type) {
          this.errorsList.push({
            dataPath: "",
            message: Vue.i18n.translate("codeViewTranslation.leafTypeToQuestionLabel") + " " + this.getQuestionLabelList[json.questionLabel].label + " " +
                      Vue.i18n.translate("codeViewTranslation.hasToBe") + " " + this.getQuestionLabelList[json.questionLabel].type,
          });
        }
        if (json.operation !== "answered") {
          if (json.leafType === "date") {
            let bool = this.checkIfStringIsValidDate(json.value);
            if (json.operation === "between") {
              bool = bool && this.checkIfStringIsValidDate(json.secondValue);
            }
            if (!bool) {
              this.errorsList.push({
                dataPath: "",
                message: Vue.i18n.translate("codeViewTranslation.valueToQuestionLabel") + " " + this.getQuestionLabelList[json.questionLabel].label + " " +
                          Vue.i18n.translate("codeViewTranslation.hasToBeValidDate"),
              });
            }
          } else if (json.leafType === "slider") {
            const question = this.getQuestionLabelList[json.questionLabel];
            const questionValues = question.value;
            const value = Number(json.value);
            const min = Number(questionValues.min);
            const max = Number(questionValues.max);
            if (json.operation === "between") {
              const secondValue = Number(json.secondValue);
              if (value > secondValue) {
                this.errorsList.push({
                  dataPath: "",
                  message: Vue.i18n.translate("codeViewTranslation.valueToQuestionLabel") + " " + question.label + " " +
                            Vue.i18n.translate("codeViewTranslation.hasToBeSmallerOrEqualThanSecondValue"),
                });
              }
              if (value < min || value > max) {
                this.errorsList.push({
                  dataPath: "",
                  message: Vue.i18n.translate("codeViewTranslation.valueToQuestionLabel") + " " + question.label + " " + Vue.i18n.translate("codeViewTranslation.forOperationBetween") + " " +
                    Vue.i18n.translate("codeViewTranslation.hasToBeBetween", { min, max }),
                });
              }
              if (secondValue > max || secondValue < min) {
                this.errorsList.push({
                  dataPath: "",
                  message: Vue.i18n.translate("codeViewTranslation.secondValueToQuestionLabel") + " " + question.label + " " + Vue.i18n.translate("codeViewTranslation.forOperationBetween") + " " +
                    Vue.i18n.translate("codeViewTranslation.hasToBeBetween", { min, max }),
                });
              }
            } else if (json.operation === "less") {
              const lessMin = min + 1; // value has to be bigger than min
              if (value < lessMin || value > max) {
                this.errorsList.push({
                  dataPath: "",
                  message: Vue.i18n.translate("codeViewTranslation.valueToQuestionLabel") + " " + question.label + " " + Vue.i18n.translate("codeViewTranslation.forOperationLess") + " " +
                    Vue.i18n.translate("codeViewTranslation.hasToBeBetween", { min: lessMin, max }),
                });
              }
            } else if (json.operation === "greater") {
              const greaterMax = max - 1; // value has to be smaller than max
              if (value < min || value > greaterMax) {
                this.errorsList.push({
                  dataPath: "",
                  message: Vue.i18n.translate("codeViewTranslation.valueToQuestionLabel") + " " + question.label + " " + Vue.i18n.translate("codeViewTranslation.forOperationGreater") + " " +
                    Vue.i18n.translate("codeViewTranslation.hasToBeBetween", { min, max: greaterMax }),
                });
              }
            } else if (json.operation === "lessEqual" || json.operation === "greaterEqual" || json.operation === "equals" || json.operation === "notEquals") {
              if (value < min || value > max) {
                const operationKey = "codeViewTranslation.forOperation" + json.operation;
                this.errorsList.push({
                  dataPath: "",
                  message: Vue.i18n.translate("codeViewTranslation.valueToQuestionLabel") + " " + question.label + " " + Vue.i18n.translate(operationKey) + " " +
                    Vue.i18n.translate("codeViewTranslation.hasToBeBetween", { min, max }),
                });
              }
            }
          } else if (json.leafType === "table") {
            const question = this.getQuestionLabelList[json.questionLabel];
            const questionValues = question.value;
            const value = Number(json.value);
            const numberOfQuestions = Number(questionValues.questions);
            const min = Number(questionValues.min) * numberOfQuestions;
            const max = Number(questionValues.max) * numberOfQuestions;
            if (json.operation === "between") {
              const secondValue = Number(json.secondValue);
              if (value > secondValue) {
                this.errorsList.push({
                  dataPath: "",
                  message: Vue.i18n.translate("codeViewTranslation.valueToQuestionLabel") + " " + question.label + " " +
                            Vue.i18n.translate("codeViewTranslation.hasToBeSmallerOrEqualThanSecondValue"),
                });
              }
              if (value < min || value > max) {
                this.errorsList.push({
                  dataPath: "",
                  message: Vue.i18n.translate("codeViewTranslation.valueToQuestionLabel") + " " + question.label + " " + Vue.i18n.translate("codeViewTranslation.forOperationBetween") + " " +
                    Vue.i18n.translate("codeViewTranslation.hasToBeBetween", { min, max }),
                });
              }
              if (secondValue > max || secondValue < min) {
                this.errorsList.push({
                  dataPath: "",
                  message: Vue.i18n.translate("codeViewTranslation.secondValueToQuestionLabel") + " " + question.label + " " + Vue.i18n.translate("codeViewTranslation.forOperationBetween") + " " +
                    Vue.i18n.translate("codeViewTranslation.hasToBeBetween", { min, max }),
                });
              }
            } else if (json.operation === "less") {
              const lessMin = min + 1; // value has to be bigger than min
              if (value < lessMin || value > max) {
                this.errorsList.push({
                  dataPath: "",
                  message: Vue.i18n.translate("codeViewTranslation.valueToQuestionLabel") + " " + question.label + " " + Vue.i18n.translate("codeViewTranslation.forOperationLess") + " " +
                    Vue.i18n.translate("codeViewTranslation.hasToBeBetween", { min: lessMin, max }),
                });
              }
            } else if (json.operation === "greater") {
              const greaterMax = max - 1; // value has to be smaller than max
              if (value < min || value > greaterMax) {
                this.errorsList.push({
                  dataPath: "",
                  message: Vue.i18n.translate("codeViewTranslation.valueToQuestionLabel") + " " + question.label + " " + Vue.i18n.translate("codeViewTranslation.forOperationGreater") + " " +
                    Vue.i18n.translate("codeViewTranslation.hasToBeBetween", { min, max: greaterMax }),
                });
              }
            } else if (json.operation === "lessEqual" || json.operation === "greaterEqual" || json.operation === "equals" || json.operation === "notEquals") {
              if (value < min || value > max) {
                const operationKey = "codeViewTranslation.forOperation" + json.operation;
                this.errorsList.push({
                  dataPath: "",
                  message: Vue.i18n.translate("codeViewTranslation.valueToQuestionLabel") + " " + question.label + " " + Vue.i18n.translate(operationKey) + " " +
                    Vue.i18n.translate("codeViewTranslation.hasToBeBetween", { min, max }),
                });
              }
            }
          } else {
            if(!this.getQuestionLabelList[json.questionLabel].value.includes(json.value)){
              this.errorsList.push({
                dataPath: "",
                message: Vue.i18n.translate("codeViewTranslation.valueToQuestionLabel") + " " + this.getQuestionLabelList[json.questionLabel].label + " " +
                          Vue.i18n.translate("codeViewTranslation.hasToBeOneOf") + " " + this.getQuestionLabelList[json.questionLabel].value
              });
            }
            if(json.leafType === "slider" && json.operation === "between" && !this.getQuestionLabelList[json.questionLabel].value.includes(json.secondValue)){
              this.errorsList.push({
                dataPath: "",
                message: Vue.i18n.translate("codeViewTranslation.secondValueToQuestionLabel") + " " + this.getQuestionLabelList[json.questionLabel].label + " " +
                          Vue.i18n.translate("codeViewTranslation.hasToBeOneOf") + " " + this.getQuestionLabelList[json.questionLabel].value
              });
            }
          }
        }
        return this.errorsList.length === 0;
      }
    },

    checkLabelsAndType: function(json) {
      if (json.nodeType !== "leaf") {
        const childrenBoolArr = [];
        for (const child in json.children) {
          childrenBoolArr.push(this.checkLabelsAndType(json.children[child]));
        }
        return !childrenBoolArr.includes(false);
      } else if (json.questionLabel != "" && json.leafType != this.getQuestionLabelList[json.questionLabel].type) {
        this.errorsList.push({
          dataPath: "",
          message: Vue.i18n.translate("codeViewTranslation.leafTypeToQuestionLabel") + " " + this.getQuestionLabelList[json.questionLabel].label + " " +
                    Vue.i18n.translate("codeViewTranslation.hasToBe") + " " + this.getQuestionLabelList[json.questionLabel].type,
        });
      }
      return this.errorsList.length === 0;
    },

    checkIfStringIsValidDate: function(value) {
      const regEx = /^\d{4}-\d{2}-\d{2}$/;
      if (!value.match(regEx)) {
        return false;
      }
      const date = new Date(value);
      const time = date.getTime();
      if (!time && time !== 0) {
        return false;
      }
      return date.toISOString().slice(0,10) === value;
    },

    closeNotification: function() {
      this.$refs["notificationRef"].closeNotification();
    },

    openEditorView: function() {
      this.errorsForSnippet = false;
      this.isSaved = false;
      this.errorsExist = false;

      const linebreaks = this.content.split("\n");
      if (this.content === "" || (this.content.length === linebreaks.length - 1)) {
        const data2 = { old: "", new: "", clipboard: "" };
        if (this.getCodeViewData != "") {
          data2.old = this.getCodeViewData.old;
          data2.clipboard = this.getCodeViewData.clipboard;
        }
        this.SET_CODEVIEWDATA(data2);
        router.replace({ name: "conditionEditor", params: { lessonId: this.lessonId } });
      } else {
        const parsedJson = this.parseJSON();
        if (!this.errorsExist && this.validateJSON(parsedJson)) {
          //parsedJson still contains labels -> if errors occur later: show this
          const newJson = this.replaceLabelTextsWithNumbers(JSON.parse(JSON.stringify(parsedJson.condition)));
          //check if label & type fit
          if (!this.checkLabelsAndType(newJson)) {
            this.errorsExist = true;
          } else {
            let old = "";
            let clipboard = "";
            if (this.getCodeViewData != "") {
              old = this.getCodeViewData.old;
              clipboard = this.getCodeViewData.clipboard;
            }
            const data = { old: old, new: newJson, clipboard: clipboard };
            this.SET_CODEVIEWDATA(data);
            if (!this.errorsExist) {
              router.replace({ name: "conditionEditor", params: { lessonId: this.lessonId } });
            }
          }
        }
      }
    },

    closeModal: function(done) {
      this.warningChangesVisible = false;
      if (done) {
        this.SET_CODEVIEWDATA("");
        this.SET_CONDITIONEDITORDATA("");
        //if not saved set conditioneditordata empty in lesson; not for config since it contains all data of interventioneditor, condition stays as it was
        if (!(this.routerPathTo.name === "interventionEditor" && this.lessonId === 0)) {
          this.SET_CONDITIONEDITORDATA("");
        }
        if ((
            (this.routerPathTo.name === "lessonEditor" || this.routerPathTo.name === "diaryEditor") &&
            this.lessonId != 0
          ) ||
          (this.routerPathTo.name === "interventionEditor" && this.lessonId === 0)
        ) {
          //formElementsChanged stays as it was before opening conditionEditor & codeView
          router.go(-1);
        } else {
          this.SET_FORMELEMENTSCHANGED(false);
          router.replace(this.routerPathTo);
        }
      }
    },

    beforeunloadFunction: function(e) {
      e.preventDefault();
      e.returnValue = "";
    },
  },
}
</script>
