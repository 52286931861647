<template>
  <div class="elementsMedia">
    <section
      v-if="getIsEditable && errorTextsForElements[propsObj.id] != ''"
      :id="'errorTextElement' + propsObj.id"
      :style="{visibility: showIfNotTranslation}"
      class="wrapper errorMessage"
    >
      {{ errorTextsForElements[propsObj.id] }}
    </section>

    <p>
      <label>
        {{titleLabelInLanguage}}
      </label>

      <ElementsReference
        v-if="translation"
        :id="propsObj.id + 'Title' + isTranslation"
        :text="translation.subtitle"
        :placeholderData="titleLabelInLanguage"
        :isSkill="isSkill"
        @change-text="changeTitle"
        @change-element="$emit('change-element', $event)"
      ></ElementsReference>
    </p>

    <p>
      <label>
        {{ 'elementsMediaTranslation.file' | translate }} *
      </label>
    </p>

    <section v-if="isTranslation" class="wrapper" :class="{spaceDescription: sameFile}">
      <section class="checkboxSection">
        <label class="container">
          {{ 'elementsMediaTranslation.sameFile' | translate }}
          <input type="checkbox" :id="'inputSameFile' + propsObj.id" :value="sameFile" checked @input="changeSameFile">
          <span class="checkmark"></span>
        </label>
      </section>
      <br>
      <br>
    </section>

    <p v-if="(getIsEditable && (!isTranslation || (isTranslation && !sameFile))) || !getIsEditable" class="media">
      <img
        v-if="propsObj.linkType.startsWith('image')"
        :src="translation.uri"
        loading="lazy"
        :id="'media' + propsObj.id + 'Image'"
      >
      <video
        v-else-if="propsObj.linkType.startsWith('video') && !propsObj.isAudio"
        width="450"
        controls
        :id="'media' + propsObj.id + 'Video'"
        crossorigin="anonymous"
      >
        <source :src="translation.uri" :type="propsObj.linkType" />
        <track
          v-if="translation.uri && translation.video_subtitle_uri"
          :src="translation.video_subtitle_uri"
          kind="subtitles"
          :label="getSubtitlesLanguage(subtitleSourceLanguage)"
          :srclang="subtitleSourceLanguage"
          default
        />
      </video>

      <audio
        v-else-if="(propsObj.linkType.startsWith('video') && propsObj.isAudio) || propsObj.linkType.startsWith('audio')"
        :src="translation.uri"
        width="450"
        controls
        :id="'media' + propsObj.id + 'Audio'"
      ></audio>
      <span v-else-if="hasTypeFile(propsObj.linkType)">
        <i class="fas fa-4x" :class="fileClass" :id="'media' + propsObj.id + 'File' + fileClass"></i>
        <br>
        <a
          :href="translation.uri"
          target="_blank"
          rel="noopener noreferrer"
          :download="translation.filename"
          :id="'media' + propsObj.id + 'FileLink'"
        >
            {{ translation.filename }}
        </a>
      </span>
      <span v-else-if="propsObj.linkType === 'text/vtt'">
        <i class="fa fa-4x fa-closed-captioning" :id="'media' + propsObj.id + 'WebVTT'"></i>
        <br>
        <a
          :href="translation.uri"
          target="_blank"
          rel="noopener noreferrer"
          :download="translation.filename"
          :id="'media' + propsObj.id + 'WebVTTFileLink'"
        >
            {{ translation.filename }}
        </a>
      </span>
    </p>

    <section v-if="getIsEditable" class="wrapper media">
      <button
        type="button"
        v-if="(!isTranslation && !disableInputs) || (isTranslation && !sameFile)"
        :id="'buttonSelectMedia' + propsObj.id"
        @click="showStudyMediaModal(true)"
      >
        {{ $t("elementsMediaTranslation.selectMedia") }}
      </button>
    </section>

    <section v-if="!isTranslation && (propsObj.linkType.startsWith('image') || propsObj.linkType.startsWith('video'))" class="sec50">
      <section
        v-if="(propsObj.linkType.startsWith('image') && !propsObj.banner) || (propsObj.linkType.startsWith('video') && !propsObj.isAudio)"
        class="col sec33"
      >
        <label>{{ 'elementsMediaTranslation.size' | translate }} *</label>
        <input
          type="number"
          min="0"
          max="100"
          :id="'inputMediaSize' + propsObj.id"
          :value="propsObj.width"
          :disabled="disableInputs"
          @input="$emit('change-element', { type: 'attribute', name: 'width', value: $event.target.value })"
        >
      </section>

      <section v-if="propsObj.linkType.startsWith('image')" class="col sec33">
        <label>
          {{ $t("elementsMediaTranslation.banner") }} *
        </label>
        <select
          :value="propsObj.banner"
          :disabled="disableInputs"
          :id="'inputMediaBanner' + propsObj.id"
          @input="$emit(
            'change-element',
            {
              type: 'attribute',
              name: 'banner',
              value: Array.from($event.target.options).filter(o => o.selected).map(x => '_value' in x ? x._value : x.value)[0],
            },
          )"
        >
          <option value="" disabled :id="'inputMediaBanner' + propsObj.id + 'None'">
            {{ $t("generalTranslation.select") }}
          </option>
          <option :value="true" :id="'inputMediaBanner' + propsObj.id + 'True'" :key="'isBannerTrue' + propsObj.id">
            {{ $t("generalTranslation.yes") }}
          </option>
          <option :value="false" :id="'inputMediaBanner' + propsObj.id + 'False'" :key="'isBannerFalse' + propsObj.id">
            {{ $t("generalTranslation.no") }}
          </option>
        </select>
      </section>
      <section v-else class="col sec33">
        <label>
          {{ $t("elementsMediaTranslation.isAudio") }} *
        </label>
        <select
          :value="propsObj.isAudio"
          :disabled="disableInputs"
          :id="'inputMediaIsAudio' + propsObj.id"
          @input="$emit(
            'change-element',
            {
              type: 'attribute',
              name: 'isAudio',
              value: Array.from($event.target.options).filter(o => o.selected).map(x => '_value' in x ? x._value : x.value)[0],
            },
          )"
        >
          <option value="" disabled :id="'inputMediaIsAudio' + propsObj.id + 'None'">
            {{ 'generalTranslation.select' | translate }}
          </option>
          <option :value="true" :id="'inputMediaIsAudio' + propsObj.id + 'True'" :key="'isAudioTrue' + propsObj.id">
            {{ 'generalTranslation.yes' | translate }}
          </option>
          <option :value="false" selected :id="'inputMediaIsAudio' + propsObj.id + 'False'" :key="'isAudioFalse' + propsObj.id">
            {{ 'generalTranslation.no' | translate }}
          </option>
        </select>
      </section>
    </section>

    <section v-if="propsObj.linkType.startsWith('video') && !propsObj.isAudio" class="wrapper media">
      <label>
        {{ $t("elementsMediaTranslation.subtitles") }}
      </label>
      <div class="subtitleFilename" :key="subtitleKey">
        <span v-if="translation.video_subtitle_uri">
          {{ $t("elementsMediaTranslation.file") }}:
          <i
            v-if="getIsEditable"
            class="fa fa-trash"
            :id="'buttonDeleteSubtitles' + propsObj.id"
            v-tooltip="$t('elementsMediaTranslation.deleteSubtitles')"
            @click="deleteSubtitles"
          ></i>
          <a
            :href="translation.video_subtitle_uri"
            target="_blank"
            rel="noopener noreferrer"
            :download="translation.video_subtitle_original_name"
            :id="'media' + propsObj.id + 'SubtitlesFileLink'"
          >
              {{ translation.video_subtitle_original_name }}
          </a>
        </span>
        <span v-else>
          {{ $t("elementsMediaTranslation.noSelectSubtitles") }}
        </span>
      </div>
      <button
        v-if="getIsEditable"
        type="button"
        :id="'buttonSelectSubtitles' + propsObj.id"
        @click="showSubtitlesStudyMediaModal(true)"
      >
        <i class="fa fa-closed-captioning"></i>
        {{ $t("elementsMediaTranslation.selectSubtitles") }}
      </button>
    </section>

    <p>
      <label>
        {{descriptionLabelInLanguage}}
      </label>

      <ElementsReference
        v-if="translation"
        :id="propsObj.id + 'Description' + isTranslation"
        :text="translation.description"
        :placeholderData="descriptionLabelInLanguage"
        :isSkill="isSkill"
        @change-text="changeDesc"
        @change-element="$emit('change-element', $event)"
      ></ElementsReference>
    </p>

    <StudyMedia
      v-if="studyMediaVisible"
      :id="'mediaSelection' + propsObj.id"
      :allFiles="true"
      :subtitlesOnly="false"
      :isSelection="true"
      :studyId="studyId"
      :multiple="false"
      @close-modal="showStudyMediaModal(false)"
      @item-selected="selectMedia"
    ></StudyMedia>

    <StudyMedia
      v-if="subtitlesMediaVisible"
      :id="'subtitlesSelection' + propsObj.id"
      :allFiles="false"
      :subtitlesOnly="true"
      :isSelection="true"
      :studyId="studyId"
      :multiple="false"
      @close-modal="showSubtitlesStudyMediaModal(false)"
      @item-selected="selectedSubtitles"
    ></StudyMedia>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import ElementsReference from "./ElementsReference.vue";
import StudyMedia from "./StudyMedia.vue";
import elementsHelper from "../mixins/elementsHelper";

export default {
  name: "ElementsMedia",

  components: {
    ElementsReference,
    StudyMedia,
  },

  mixins: [elementsHelper],

  props: {
    selectedLanguageForTranslation: {
      required: true,
      type: String,
    },

    propsObj: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: Object,
      
    },

    isTranslation: {
      required: true,
      type: Boolean,
    },

    linkForMedia: {
      required: true,
      type: String,
    },

    errorTextsForElements: {
      required: true,
      type: Array,
    },

    studyId: {
      required: true,
      type: Number,
    },

    isSkill: {
      required: true,
      type: Boolean,
    },

    allowStructureChanges: {
      type: Boolean,
      default: null,
    },
  },

  data: function() {
    return {
      oldLink: "",
      sameFile: true,
      studyMediaVisible: false,
      subtitlesMediaVisible: false,
      subtitleKey: 0,
    };
  },

  computed: {
    ...mapGetters([
      "getIsEditable",
      "getSelectedLanguage",
    ]),

    showIfNotTranslation: function() {
      return !this.isTranslation ? "visible" : "hidden";
    },

    titleLabelInLanguage: function() {
      return Vue.i18n.translate("elementsMediaTranslation.title" + (this.isTranslation ? "Translation" : ""));
    },

    descriptionLabelInLanguage: function() {
      return Vue.i18n.translate("elementsMediaTranslation.description" + (this.isTranslation ? "Translation" : ""));
    },

    disableInputs: function() {
      return !this.getIsEditable || this.allowStructureChanges === false;
    },

    subtitleSourceLanguage: function() {
      return !this.isTranslation ? this.getSelectedLanguage : this.selectedLanguageForTranslation;
    },
  },

  watch: {
    "translation.uri": function(newVal) {
      if (this.isTranslation) {
        if (newVal === this.linkForMedia) {
          this.sameFile = true;
        } else {
          this.sameFile = false;
        }
      }
    },
  },

  created() {
    if (this.translation && this.translation.uri === null) {
      this.translation.uri = "";
    }
    if (this.translation && this.translation.video_subtitle_uri == null) {
      this.translation.video_subtitle_uri = "";
    }
  },

  methods:{
    fileClass: function() {
      if (this.hasTypeWord(this.propsObj.linkType)) {
        return "fa-file-word";
      } else if (this.hasTypePPT(this.propsObj.linkType)) {
        return "fa-file-powerpoint";
      } else {
        return "fa-file-pdf";
      }
    },

    changeSameFile: function(evt) {
      let newLink;
      if (evt.target.checked) {
        this.oldLink = this.translation.uri;
        newLink = this.linkForMedia;
      } else {
        newLink = this.oldLink;
      }
      this.sameFile = evt.target.checked;
      this.$emit("change-element", { type: "translation-attribute", name: "uri", isTranslation: this.isTranslation, value: newLink });
    },

    showStudyMediaModal: function(bool) {
      this.studyMediaVisible = bool;
      this.$emit("change-element", { type: "show-media-reference-modal", value: bool });
    },

    showSubtitlesStudyMediaModal: function(bool) {
      this.subtitlesMediaVisible = bool;
    },

    selectMedia: function(obj) {
      const newObj = obj;
      newObj.type = "media-attributes";
      newObj.isTranslation = this.isTranslation;
      newObj.sameFile = this.sameFile;
      this.$emit("change-element", newObj);
      this.showStudyMediaModal(false);
    },

    selectedSubtitles: function(obj) {
      this.$emit(
        "change-element",
        {
          type: "translation-attribute",
          name: "video-subtitles",
          isTranslation: this.isTranslation,
          value: obj,
        },
      );
      this.showSubtitlesStudyMediaModal(false);
    },

    deleteSubtitles: function() {
      const value = {
        link: "",
        filename: "",
      };
      this.$emit(
        "change-element",
        {
          type: "translation-attribute",
          name: "video-subtitles",
          isTranslation: this.isTranslation,
          value,
        },
      );
      this.subtitleKey++;
    },

    changeTitle: function(text) {
      this.$emit("change-element", { type: "translation-attribute", name: "subtitle", isTranslation: this.isTranslation, value: text });
    },

    changeDesc: function(text) {
      this.$emit("change-element", { type: "translation-attribute", name: "description", isTranslation: this.isTranslation, value: text });
    },
  }
}
</script>
