export default {

  methods: {
    flattenJSON: function(elementsArr, addElementsIdCounter, currentPos) {
      let newArr = [];
      let idPosList = {};
      for (const elem in elementsArr) {
        const element = elementsArr[elem];
        newArr[currentPos] = element;
        idPosList[element.propsObj.id] = currentPos;
        currentPos++;
        if (element.type === "ElementsBlock") {
          newArr[currentPos - 1].propsObj.blockType = true;
          //add nested elements
          const nested = this.flattenJSON(element.propsObj.elements, addElementsIdCounter, currentPos);
          newArr = Object.assign(newArr, nested.elements);
          idPosList = Object.assign(idPosList, nested.idPosList);
          currentPos = nested.currentPos;
          addElementsIdCounter = nested.addElementsIdCounter;

          //add end of block
          newArr[currentPos] = {
            type : "ElementsBlock",
            propsObj: {
              elementId: element.propsObj.closeElementId,
              id: addElementsIdCounter,
              blockType: false,
            },
          };
          idPosList[addElementsIdCounter] = currentPos;
          addElementsIdCounter++;
          currentPos++;
        }
      }
      return {
        elements: newArr,
        idPosList: idPosList,
        currentPos: currentPos,
        addElementsIdCounter: addElementsIdCounter,
      };
    },

    propertiesToJSON: function(elementsJSONArr, addElementsIdCounter, locales) {
      const elementsPropArr = [];
      //replace nested blocks & get idPosList (has pos at index id)
      const result = this.flattenJSON(elementsJSONArr, addElementsIdCounter + 1, 1);
      result.elements.forEach(jsonObj => {
        let newObj;
        switch (jsonObj.type) {
          case "ElementsHeadline":
            newObj = this.createHeadlineJSON(jsonObj, result.idPosList, locales);
            break;
          case "ElementsPage":
            newObj = this.createPageJSON(jsonObj, result.idPosList, locales);
            break;
          case "ElementsLine":
            newObj = this.createLineJSON(jsonObj, result.idPosList);
            break;
          case "ElementsSpace":
            newObj = this.createSpaceJSON(jsonObj, result.idPosList);
            break;
          case "ElementsQuestion":
            newObj = this.createQuestionJSON(jsonObj, result.idPosList, locales);
            break;
          case "ElementsText":
            newObj = this.createTextJSON(jsonObj, result.idPosList, locales);
            break;
          case "ElementsTable":
            newObj = this.createTableJSON(jsonObj, result.idPosList, locales);
            break;
          case "ElementsMedia":
            newObj = this.createMediaJSON(jsonObj, result.idPosList, locales);
            break;
          case "ElementsBlock":
            newObj = this.createBlockJSON(jsonObj, result.idPosList, locales);
            break;
          default:
            break;
        }
        elementsPropArr.push(newObj);
      });

      return {
        data : {
          type : "elements/elements",
          attributes : {
            elements : elementsPropArr,
          },
        },
      };
    },

    createHeadlineJSON: function(elementJSON, idPosList, locales) {
      const size = elementJSON.propsObj.selectedHeadlineProp.replace("headline", "");
      const font = elementJSON.propsObj.font;
      const json = {
        name : "HEADLINE",
        elementtype: "elements/headlines",
        id: elementJSON.propsObj.elementId,
        position: parseInt(idPosList[elementJSON.propsObj.id]),
        color: elementJSON.propsObj.color,
        is_active: true,
        translations: [],
      };
      const checkAllLocales = locales === undefined;
      for (const locale in elementJSON.translations) {
        let headline = elementJSON.translations[locale];
        // remove empty translations when using all locales
        const validTranslation = checkAllLocales ? headline != "" : locales.includes(locale);
        if (validTranslation) {
          headline = this.replaceIdsWithPositions(headline, idPosList);
          if (font !== "none") {
            headline = `<span class="${font}">${headline}</span>`;
          }
          if (size > 0) {
            headline = `<h${size}>${headline}</h${size}>`;
          }
          json.translations.push({ locale, headline });
        }
      }
      return json;
    },

    createPageJSON: function(elementJSON, idPosList, locales) {
      const json = {
        name: "PAGE",
        elementtype: "elements/pages",
        id: elementJSON.propsObj.elementId,
        position: parseInt(idPosList[elementJSON.propsObj.id]),
        color: elementJSON.propsObj.color,
        is_active: true,
        condition: "",
      };
      if (elementJSON.propsObj.condition) {
        const condition = this.replaceIdsWithPositionsInCondition(elementJSON.propsObj.condition, idPosList);
        json.condition = {
          ifBlock: condition,
          description: elementJSON.propsObj.conditionDescription,
        };
      }
      if (this.$enabledFeatures.includes("progress")) {
        json.translations = [];
        const checkAllLocales = locales === undefined;
        for (const translation in elementJSON.translations) {
          if (checkAllLocales || locales.includes(translation)) {
            json.translations.push({
              locale: translation,
              progressbar_text: elementJSON.translations[translation],
            });
          }
        }
      }
      return json;
    },

    createLineJSON: function(elementJSON, idPosList) {
      const json = {
        name: "LINE",
        elementtype: "elements/lines",
        id: elementJSON.propsObj.elementId,
        position: parseInt(idPosList[elementJSON.propsObj.id]),
        is_active: true,
      };
      return json;
    },

    createSpaceJSON: function(elementJSON, idPosList) {
      const json = {
        name: "SPACE",
        elementtype: "elements/spaces",
        id: elementJSON.propsObj.elementId,
        position: parseInt(idPosList[elementJSON.propsObj.id]),
        is_active: true,
        size: elementJSON.propsObj.size,
      };
      return json;
    },

    createQuestionJSON: function(elementJSON, idPosList, locales) {
      const json = {
        name: "QUESTION",
        elementtype: "elements/questions",
        id: elementJSON.propsObj.elementId,
        position: parseInt(idPosList[elementJSON.propsObj.id]),
        is_active: true,
        label: elementJSON.propsObj.labelProp,
        questiontype: elementJSON.propsObj.selectedQuestionProp,
        values: elementJSON.propsObj.values,
        required: elementJSON.propsObj.questionReq,
        condition: "null",
        translations: [],
      };

      if (elementJSON.propsObj.selectedQuestionProp === "TextDate") {
        json.values = ["date"];
      } else if (elementJSON.propsObj.selectedQuestionProp === "TextTime") {
        json.values = ["time"];
        json.questiontype = "TextDate";
      } else if (elementJSON.propsObj.selectedQuestionProp === "TextDateTime") {
        json.values = ["datetime"];
        json.questiontype = "TextDate";
      }

      const checkAllLocales = locales === undefined;
      for (const locale in elementJSON.translations) {
        const translation = elementJSON.translations[locale];
        // remove empty translations when using all locales
        const validTranslation = checkAllLocales ? translation.question != "" : locales.includes(locale);
        if (validTranslation) {
          const translationJSON = {
            locale: locale,
            question: "",
            answers: [],
          };
          const question = translation.question;
          if (elementJSON.propsObj.selectedQuestionProp !== "QuestionTable") {
            translationJSON.question = this.replaceIdsWithPositions(question, idPosList);
          } else {
            translationJSON.question = [];
            for (const q in question) {
              translationJSON.question.push(this.replaceIdsWithPositions(question[q], idPosList));
            }
          }
          if (
            elementJSON.propsObj.selectedQuestionProp === "SingleChoice"
              || elementJSON.propsObj.selectedQuestionProp === "MultipleChoice"
              || elementJSON.propsObj.selectedQuestionProp === "QuestionTable"
          ) {
            const answers = translation.answers;
            for (const answer in answers) { //answers of questiontable are not removed before
              if (
                elementJSON.propsObj.selectedQuestionProp != "QuestionTable"
                  || answer >= elementJSON.propsObj.values.min
                  && answer <= elementJSON.propsObj.values.max
              ) {
                translationJSON.answers.push(this.replaceIdsWithPositions(answers[answer], idPosList));
              } else {
                translationJSON.answers.push(null);
              }
            }
          } else if (elementJSON.propsObj.selectedQuestionProp === "Slider") {
            translationJSON.answers = translation.answers;
            if (!translationJSON.answers[0].label) {
              translationJSON.answers[0] = {
                value: elementJSON.propsObj.values.min,
                label: "",
              };
            }
            if (!translationJSON.answers[1].label) {
              translationJSON.answers[1] = {
                value: elementJSON.propsObj.values.max,
                label: "",
              };
            }
          }
          json.translations.push(translationJSON);
        }
      }
      return json;
    },

    createTextJSON: function(elementJSON, idPosList, locales) {
      const json = {
        name: "TEXT",
        elementtype: "elements/texts",
        id: elementJSON.propsObj.elementId,
        position: parseInt(idPosList[elementJSON.propsObj.id]),
        type: elementJSON.propsObj.type,
        is_active: true,
        translations: [],
      };
      const checkAllLocales = locales === undefined;
      for (const locale in elementJSON.translations) {
        const translation = elementJSON.translations[locale];
        // remove empty translations when using all locales
        const validTranslation = checkAllLocales ? translation != "" : locales.includes(locale);
        if (validTranslation) {
          const text = this.replaceIdsWithPositions(translation, idPosList);
          json.translations.push({ locale, text });
        }
      }
      return json;
    },

    createTableJSON: function(elementJSON, idPosList, locales) {
      const json = {
        name: "TEXT",
        elementtype: "elements/texts",
        id: elementJSON.propsObj.elementId,
        position: parseInt(idPosList[elementJSON.propsObj.id]),
        type: "table",
        is_active: true,
        translations: [],
      };
      const checkAllLocales = locales === undefined;
      for (const locale in elementJSON.translations) {
        if (checkAllLocales || locales.includes(locale)) {
          const table = elementJSON.translations[locale];
          json.translations.push({ locale: locale, text: table });
        }
      }
      return json;
    },

    createMediaJSON: function(elementJSON, idPosList, locales) {
      const json = {
        name: "MEDIA",
        elementtype: "elements/media",
        id: elementJSON.propsObj.elementId,
        position: parseInt(idPosList[elementJSON.propsObj.id]),
        is_active: true,
        mimetype: elementJSON.propsObj.linkType,
        width: elementJSON.propsObj.width,
        height: elementJSON.propsObj.height,
        banner: elementJSON.propsObj.banner,
        is_audio: elementJSON.propsObj.isAudio,
        translations: [],
      };
      const checkAllLocales = locales === undefined;
      const isVideo = json.mimetype?.startsWith("video") && !json.is_audio;
      for (const locale in elementJSON.translations) {
        const translation = elementJSON.translations[locale];
        //remove empty translations (it is now allowed to fill in only one language)
        const validTranslation = checkAllLocales ? translation.uri != "" : locales.includes(locale);
        if (validTranslation) {
          const translationJSON = {
            locale: locale,
            uri: translation.uri,
            filename: translation.filename,
            subtitle: "",
            description: "",
          };
          translationJSON.subtitle = this.replaceIdsWithPositions(translation.subtitle, idPosList);
          translationJSON.description = this.replaceIdsWithPositions(translation.description, idPosList);
          if (isVideo) {
            translationJSON.video_subtitle_uri = translation.video_subtitle_uri;
            translationJSON.video_subtitle_original_name = translation.video_subtitle_original_name;
          }
          json.translations.push(translationJSON);
        }
      }
      return json;
    },

    createBlockJSON: function(elementJSON, idPosList, locales) {
      const json = {
        elementtype: "elements/blockopens",
        name: "BLOCKOPEN",
        id: elementJSON.propsObj.elementId,
        position: parseInt(idPosList[elementJSON.propsObj.id]),
        condition: "",
        is_active: true,
        type: "",
        translations: [],
      };
      if (elementJSON.propsObj.blockType) {
        json.type = elementJSON.propsObj.type;
        const condition = this.replaceIdsWithPositionsInCondition(elementJSON.propsObj.condition, idPosList);
        const thenBlock = this.replaceIdsWithPositionsInRepetition(elementJSON.propsObj.thenBlock, idPosList);
        json.condition = {
          ifBlock: condition,
          thenBlock: thenBlock,
          description: elementJSON.propsObj.conditionDescription,
        };
        const checkAllLocales = locales === undefined;
        for (const locale in elementJSON.translations) {
          //remove empty translations (it is now allowed to fill in only one language)
          const text = elementJSON.translations[locale];
          const validTranslation = checkAllLocales ? text != "" : locales.includes(locale);
          if (validTranslation) {
            json.translations.push({ locale, text });
          }
        }
      } else {
        json.elementtype = "elements/blockcloses";
        json.name = "BLOCKCLOSE";
      }
      return json;
    },

    //idPosList has pos at index id
    replaceIdsWithPositions: function(content, idPosList) {
      //first replace gets {{number}} as x -> brackets are replaced in second replace
      //-> only number -> change number -> {{newNumber}}
      content = content.replace(/\{{2}([^}{]+)}{2}/g, function(x) {
        const number = Number(x.replace(/{/g, "").replace(/}/g, ""));
        if (Number.isNaN(number) || idPosList[number] == null) {
          return "";
        } else {
          return "{{" + idPosList[number] + "}}";
        }
      });
      return content;
    },

    //idPosList has pos at index id
    replaceIdsWithPositionsInCondition(condition, idPosList) {
      if (condition.nodeType === "leaf") {
        condition.questionLabel = idPosList[condition.questionLabel];
      } else {
        for (const child in condition.children) {
          condition.children[child] = this.replaceIdsWithPositionsInCondition(condition.children[child], idPosList);
        }
      }
      return condition;
    },

    //idPosList has pos at index id
    replaceIdsWithPositionsInRepetition(thenBlock, idPosList) {
      if (thenBlock.repeat.question) {
        thenBlock.repeat.data = idPosList[thenBlock.repeat.data];
      }
      return thenBlock;
    }
  }
};
