<template>
  <div>
    <section class="checkboxSection notBold">
      <label class="container">
        {{ $t("interventionTranslation.publishInterventionCompleteCheck") }}
        <input v-model="checkElements" type="checkbox" id="complexValidationToggle">
        <span class="checkmark"></span>
      </label>
      <button :disabled="noLanguagePublished" @click="validateIntervention">
        {{ $t("interventionTranslation.publishInterventionCheck") }}
      </button>
    </section>

    <InterventionCheckModal
      id="publishInterventionWarningModal"
      v-if="showWarningModal"
      :intervention="intervention"
      :result="publishCheckResult"
      :checkedElements="checkElements"
      @close-modal="closeWarningModal"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import InterventionCheckModal from "./InterventionCheckModal.vue";
import httpHelper from "../mixins/httpHelper";
import interventionChecker from "../mixins/interventionChecker";

export default {
  name: "InterventionCheck",

  components: {
    InterventionCheckModal,
  },

  mixins: [httpHelper, interventionChecker],

  props: {
    languages: {
      required: true,
      type: Array,
    },

    intervention: {
      required: true,
      type: Object,
    },
  },

  data: function() {
    return {
      showWarningModal: false,
      publishCheckResult: {},
      checkElements: false,
    };
  },

  computed: {
    noLanguagePublished: function() {
      return this.languages.length === 0;
    },
  },

  methods: {
    ...mapMutations([
      "SET_NOTIFICATIONTEXT",
    ]),

    validateIntervention: async function() {
      this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("interventionTranslation.publishInterventionCheckConfig") });
      await this.requestAndCheckInterventionConfiguration();
      if (this.publishCheckResult.publishWarning || this.publishCheckResult.publishError) {
        this.closeNotification();
        this.showWarningModal = true;
      } else {
        this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("interventionTranslation.publishInterventionCheckConfigSuccess") });
      }
    },

    requestAndCheckInterventionConfiguration: async function() {
      try {
        const completeInterventionDetails = await this.requestPublishInterventionDetails(this.intervention.id, this.checkElements);
        this.publishCheckResult = this.checkInterventionConfiguration(completeInterventionDetails, this.languages, this.checkElements);
      } catch (error) {
        const self = this;
        this.handleErrors(error, function() { self.requestAndCheckInterventionConfiguration() }, "");
      }
    },

    closeWarningModal: function(done) {
      if (done === null) {
        this.$emit("quit-check");
      }
      this.showWarningModal = false;
    },

    closeNotification: function() {
      this.$emit("close-notification");
    },
  },
}
</script>
