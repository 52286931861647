<template>
  <div class="elementsReference">
    <section class="wrapper">
      <div
        v-if="getIsEditable"
        :id="textfieldId"
        key="editableSec"
        contenteditable="true"
        :placeholderData="placeholderData"
        :class="['textFieldReference', classToSet, font]"
        :style="{'color': color}"
        @focus="isFocused = true"
        @blur="isFocused = false"
        @input="changeText"
        @keypress="disallowCurlyBraces($event)"
        v-html="sanitizeHTML(textWithHTML)"
      ></div>
      <div
        v-else
        :id="textfieldId"
        key="nonEditableSec"
        contenteditable="false"
        :class="['textFieldReferenceNotEditable', classToSet]"
        :style="{'color': color}"
        v-html="sanitizeHTML(textWithHTML)"
      ></div>
      <section v-if="getIsEditable && !isSkill" class="col refButton">
        <button
          type="button"
          :id="'buttonInsertReference' + id"
          v-tooltip="$t('referenceTranslation.insertReference')"
          @click="showReferenceModal(true)"
        >
          {{ buttonText }}
        </button>
      </section>
    </section>

    <BaseModal
      v-if="insertReferenceVisible"
      id="insertReferenceModal"
      :headerText="$t('referenceTranslation.insertReference')"
      :leftButtonText="$t('referenceTranslation.insert')"
      :disableLeftButton="insertLabel === ''"
      @close-modal="closeModal"
    >
      <template v-slot:body>
        {{ 'referenceTranslation.referenceFor' | translate }} *<br>
        <select v-model="insertLabel" :id="'inputReference' + id">
          <option value="" disabled :id="'inputReference' + id + 'None'">
            {{ 'elementsBlockTranslation.selectLabel' | translate }}
          </option>
          <option
            v-for="(option, index) in getQuestionLabelList.filter(option => option && option != 0)"
            :value="index"
            :id="'inputReference' + id + '-' + index"
            :key="index"
          >
            {{ option.label }}
          </option>
        </select>
        <p v-if="errorDE !== ''" class="errorMessage" id="errorTextReference">
          {{ errorDE }}
        </p>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import BaseModal from "./BaseModal.vue";
import utils from "../mixins/utils";

export default {
  name: "ElementsReference",

  components: {
    BaseModal,
  },

  mixins: [utils],

  props: {
    id: {
      required: true,
      type: Number,
    },

    text: {
      required: true,
      type: String,
    },

    classToSet: {
      required: false,
      type: String,
    },

    color: {
      required: false,
      type: String,
    },

    font: {
      required: false,
      type: String,
    },

    placeholderData: {
      required: false,
      type: String,
    },

    isSkill: {
      required: true,
      type: Boolean,
    },
  },

  data: function() {
    return {
      insertPos: 0,
      newInsertPos: 0,
      isFocused: false,
      textField: "",
      insertLabel: "",
      insertReferenceVisible: false,
      errorDE: "",
      textfieldId: "",
      textForAnswerOf: "",
      buttonText: "{{ }}",
      textWithHTML: "",
    };
  },

  computed: {
    ...mapGetters([
      "getQuestionLabelList",
      "getIsEditable",
    ]),
  },

  watch: {
    text: function(newVal) {
      if ((newVal !== "" && this.newInsertPos === "") || newVal === "") {
        this.textWithHTML = this.insertHTMLinContent(newVal);
      }
    },

    //needs deep:true & handler because array in array changes -> 'normal' watch function does not detect changes in nested array
    getQuestionLabelList: {
      handler: function() {
        if (this.text) {
          this.textWithHTML = this.insertHTMLinContent(this.text);
        }
      },
      deep: true,
    },
  },

  created() {
    this.textfieldId = "referenceText" + this.id;
    this.textForAnswerOf = Vue.i18n.translate("referenceTranslation.answerOf");
    if (this.text) {
      this.textWithHTML = this.insertHTMLinContent(this.text);
    }
  },

  mounted() {
    document.addEventListener("selectionchange", this.getSelection, false);
    document.addEventListener("mouseup", this.getSelection, false);
    document.addEventListener("mousedown", this.getSelection, false);
    document.addEventListener("keyup", this.getSelection, false);
    this.textField = document.getElementById(this.textfieldId);
  },

  beforeDestroy() {
    document.removeEventListener("selectionchange", this.getSelection);
    document.removeEventListener("mouseup", this.getSelection);
    document.removeEventListener("mousedown", this.getSelection);
    document.removeEventListener("keyup", this.getSelection);
  },

  methods: {
    ...mapMutations([
      "SET_NOTIFICATIONTEXT",
    ]),

    showReferenceModal: function(bool) {
      this.insertReferenceVisible = bool;
      this.$emit("change-element", { type: "show-media-reference-modal", value: bool });
    },

    closeModal: function(done) {
      if (done) {
        //check if label was selected
        if (this.insertLabel !== "") {
          this.errorDE = "";
          this.insertAtPos();
        } else {
          this.errorDE = Vue.i18n.translate("elementsBlockTranslation.errorLabel");
        }
      } else {
        this.showReferenceModal(false)
      }
    },

    //replace {{answerPos}} with <cite contenteditable='false'>{{Antwort von answerLabel}}</cite>
    insertHTMLinContent: function(content) {
      content = content.replace(/</g, "&lt;");
      content = content.replace(/>/g, "&gt;");
      const self = this;
      //first replace gets {{number}} as x -> brackets are replaced in second replace -> only number -> create html
      content = content.replace(/\{{2}([^}{]+)}{2}/g, function(x) {
        const number = Number(x.replace(/{/g, "").replace(/}/g, ""));
        if (Number.isNaN(number)) {
          return "";
        } else if (self.getQuestionLabelList[number] != 0) {
          const label = self.getQuestionLabelList[number].label;
          return "<cite contenteditable='false'>{{" + self.textForAnswerOf + " " + label + "}}</cite>";
        } else {
          let textContent = self.textField.textContent;
          textContent = textContent.replace(/</g, "&lt;");
          textContent = textContent.replace(/>/g, "&gt;");
          self.$emit("change-text", self.changeFormatOfReferences(textContent));
          return "";
        }
      });
      return content;
    },

    //replace {{Antwort von answerLabel}} with {{answerPos}}
    changeFormatOfReferences: function(content) {
      const self = this;
      //first replace gets {{Antwort von label}} as x -> brackets & Antwort von are replaced in second replace -> only label -> get index of this label from getQuestionLabelList
      const regEx = new RegExp("\\{{2}(" + this.textForAnswerOf + " )([^}{]+)}{2}", "g");
      const regEx2 = new RegExp("\\{{2}(" + this.textForAnswerOf + " )", "g");
      // /\{{2}(Antwort von )([^}{]+)}{2}/g
      // /\{{2}(Antwort von )/g
      content = content.replace(regEx, function(x) {
        const label = x.replace(regEx2, "").replace(/}{2}/g, "");
        const index = self.getQuestionLabelList.findIndex(elem => elem && elem.label === label);
        return index !== -1 ? "{{" + index + "}}" : "";
      });
      return content;
    },

    insertAtPos: function() {
      let textContent = this.textField.textContent;
      //option: use this.textField.outerHTML -> instead of getting {{label}} one gets <cite>..</cite>
      //-> simply delete all {{ }} (user can not insert them) instead of changing format from {{}} to html and filtering {{}} with non-valid text inside
      //not working because position can only be determined in non-HTML text

      //insert new reference
      const start = textContent.slice(0, this.insertPos);
      const end = textContent.slice(this.insertPos, textContent.length);
      const labelList = this.getQuestionLabelList.filter(option => option && option != 0);
      if (labelList[this.insertLabel] != 0) {
        textContent = start + "{{" + this.textForAnswerOf + " " + labelList[this.insertLabel].label + "}}" + end;
      } else {
        //no label
        textContent = start + end;
      }
      //AFTER inserting delete < & > so that no html tags can be inserted by user (else wrong position)
      textContent = textContent.replace(/</g, "&lt;");
      textContent = textContent.replace(/>/g, "&gt;");
      //delete {{number}} -> can not be inserted manually
      //textContent = textContent.replace(/{{2}\d}{2}/g,'');

      //replace all {{word}} where not (word is Antwort von label & label is in questionLabelList)
      //first replace gets all {{word}}
      //then delete first two and last two chars: {{word}} -> word
      // const self = this;
      const text = this.resolveReferences(textContent, labelList);
      let newTextWithRef = this.changeFormatOfReferences(text);
      newTextWithRef = newTextWithRef.replace(/</g, "&lt;");
      newTextWithRef = newTextWithRef.replace(/>/g, "&gt;");
      this.$emit("change-text", newTextWithRef);
      this.textWithHTML = this.insertHTMLinContent(newTextWithRef);
      this.showReferenceModal(false);
    },

    resolveReferences: function(text, labelList) {
      const references = this.getReferences(text);
      // Sort to make sure nested references are to the right of the parent
      references.sort((a, b) => a.open < b.open ? -1 : 1);
      this.filterNestedReferences(references);
      // Iterate from the end to account for moving indeces to the right of new values
      for (let i = references.length - 1; i >= 0; i--) {
        const { open, close } = references[i];
        const reference = text.slice(open, close + 2);
        const newValue = this.replaceReference(reference, labelList);
        text = text.slice(0, open) + newValue + text.slice(close + 2);
      }
      return text;
    },

    getReferences: function(text) {
      let nextOpen = text.indexOf("{{");
      if (nextOpen === -1) {
        return [];
      }
      const references = [];
      const openReferences = [];
      let nextClose = text.indexOf("}}", nextOpen + 2);
      while (nextClose !== -1) {
        if (nextOpen === -1) {
          // Close the remaining open
          while (openReferences.length !== 0 && nextClose !== -1) {
            const open = openReferences.pop();
            references.push({ open, close: nextClose });
            nextClose = text.indexOf("}}", nextClose + 2);
          }
          break;
        } else if (nextOpen < nextClose) {
          // There could be another open between nextOpen and nextClose
          openReferences.push(nextOpen);
          nextOpen = text.indexOf("{{", nextOpen + 2);
        } else {
          // Match previous open with close, otherwise close is ignored
          if (openReferences.length !== 0) {
            const open = openReferences.pop();
            references.push({ open, close: nextClose });
          }
          nextClose = text.indexOf("}}", nextClose + 2);
        }
      }
      return references;
    },

    filterNestedReferences: function(references) {
      let left = 0, right = 1;
      while (right < references.length) {
        if (references[left].close > references[right].close) {
          references.splice(right, 1);
        } else {
          left++; right++;
        }
      }
    },

    replaceReference: function(reference, labelList) {
      // reference has format {{...}}
      const content = reference.slice(2, reference.length - 2);
      //if not Antwort von label -> return word without {{ }}
      //else check if valid label
      if (!content.startsWith(this.textForAnswerOf)) {
        //delete }} inside word {{a{{b}} {{c}}d}}: {{ab c}}d -> which results in new {{word}}
        return content.replace(/{{2}/g, "").replace(/}{2}/g, "");
      } else {
        const label = content.replace(this.textForAnswerOf + " ", "");
        const index = labelList.findIndex(elem => elem && elem.label === label);
        //if not valid label -> return word without {{ }}
        //(delete }} inside word {{a{{b}} {{c}}d}}: {{ab c}}d -> which results in new {{word}})
        //else return
        return index === -1 ? content.replace(/{{2}/g, "").replace(/}{2}/g, "") : reference;
      }
    },

    // https://stackoverflow.com/questions/4811822/get-a-ranges-start-and-end-offsets-relative-to-its-parent-container/4812022#4812022
    getSelectionCharacterOffsetWithin: function(element) {
      let start = 0;
      let end = 0;
      const doc = element.ownerDocument || element.document;
      const win = doc.defaultView || doc.parentWindow;
      let sel;
      if (typeof win.getSelection !== "undefined") {
        sel = win.getSelection();
        if (sel.rangeCount > 0) {
          const range = win.getSelection().getRangeAt(0);
          const preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.startContainer, range.startOffset);
          start = preCaretRange.toString().length;
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          end = preCaretRange.toString().length;
        }
      } else if ((sel = doc.selection) && sel.type !== "Control") {
        const textRange = sel.createRange();
        const preCaretTextRange = doc.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint("EndToStart", textRange);
        start = preCaretTextRange.text.length;
        preCaretTextRange.setEndPoint("EndToEnd", textRange);
        end = preCaretTextRange.text.length;
      }
      return { start, end };
    },

    getSelection: function() {
      const selOffsets = this.getSelectionCharacterOffsetWithin(this.textField);
      if (this.isFocused) {
        this.insertPos = this.newInsertPos;
        this.newInsertPos = selOffsets.start;
      }
    },

    changeText: function() {
      let textContent = this.textField.textContent;
      textContent = textContent.replace(/</g, "&lt;");
      textContent = textContent.replace(/>/g, "&gt;");
      this.$emit("change-text", this.changeFormatOfReferences(textContent));
    },

    disallowCurlyBraces: function(event) {
      if (event.key === "{" || event.key === "}") {
        event.preventDefault();
        this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("elementsQuestionTranslation.errorCurlyBraceLabel") });
      }
    },
  },
}
</script>
